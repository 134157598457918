import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { H1, Text } from '@amzn/stencil-react-components/text';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { IconArrowLeft } from '@amzn/stencil-react-components/icons';
import { Config } from 'src/utils/apis/serviceApiTypes';
import { Input } from '@amzn/stencil-react-components/form';
import isEmpty from 'lodash/isEmpty';
import isBoolean from 'lodash/isBoolean';
import { boundLoadingEnd, boundRemoveAlerts } from 'src/actions/boundActions';
import { onConfirmOpt } from 'src/utils/authService';
import ResendOtp from 'src/components/Registration/ResendOtpComponent';
import Constants, { SIGN_IN_PAGES } from '../../utils/constants';
import { handleRedirection } from 'src/utils/helper';
import { addAdobeMetric } from 'src/customerTracking/adobeAnalytics';
import { AdobeEvent, PageName } from 'src/customerTracking/adobeAnalytics/types';
import { skipPhoneVerification } from 'src/utils/featureFlag/featureFlag';
import useHVHTranslation from 'src/Hooks/useHVHTranslation';

interface SendOTPProps {
  candidate: any;
  goToPage: Function;
  loginType: string;
  config: Config;
  router: any;
  onNextTest?: Function;
  isEmailVerifiedOverride?: boolean;
  pin: string;
}

export const ConfirmOtp = ({
  candidate,
  goToPage,
  loginType,
  onNextTest,
  isEmailVerifiedOverride,
  pin,
}: SendOTPProps) => {
  const { t } = useHVHTranslation();
  const history: any = useHistory();
  const [otp, setOtp] = useState<string>('');
  const isSendOTPToEmail = loginType === 'email';
  const imageLink = isSendOTPToEmail ? Constants.ImageLinks.EMAIL_ICON : Constants.ImageLinks.PHONE_ICON;
  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(
    isBoolean(isEmailVerifiedOverride) ? isBoolean(isEmailVerifiedOverride) : false,
  );
  const loginID = candidate ? (isSendOTPToEmail ? candidate.emailId : candidate.phoneNumber) : '';

  useEffect(() => {
    document.title = t('ConfirmOtp-title', 'Amazon Jobs - Verify code');
    addAdobeMetric(AdobeEvent.PAGE_LOAD, PageName.ENTER_OTP, {
      verificationOptionChosen: loginType,
    });
  }, []);

  useEffect(() => {
    return undefined;
  }, [isSendOTPToEmail]);

  useEffect(() => {
    if (isEmpty(candidate) || !candidate) {
      goToPage(SIGN_IN_PAGES.LOGIN);
    }
  }, [candidate, history]);

  // willUnmount
  useEffect(
    () => () => {
      boundRemoveAlerts();
      boundLoadingEnd();
    },
    [],
  );

  const goToLoginPage = () => {
    boundRemoveAlerts();
    goToPage(SIGN_IN_PAGES.LOGIN);
  };

  const onLoginWithPhone = () => {
    goToVerifyPhonePage();
  };

  const goToVerifyPhonePage = () => {
    goToPage(SIGN_IN_PAGES.CHECK_PHONE);
  };

  const onOtpChange = (e: ChangeEvent<HTMLInputElement>) => {
    const data = e.target.value;
    setOtp(data);
    if (data.length === 6) {
      onNextTest && onNextTest();
      onConfirmOpt({
        candidate,
        otp: data,
        onNext: candidate.isPhoneVerified ? onNext : () => setIsEmailVerified(true),
      });
    }
  };

  const resetOtp = () => {
    boundRemoveAlerts();
    setOtp('');
  };

  const onNext = () => {
    addAdobeMetric(AdobeEvent.NEXT_BUTTON_CLICKED, PageName.OTP_CONFIRMED, {
      verificationOptionChosen: loginType,
    });
    handleRedirection();
  };

  return (
    <Col gridGap="S300" className="hvhLogin" padding={{ left: 'S200', right: 'S200' }}>
      <Col gridGap="S300" className="colContainerTop">
        <Row>
          <Button dataTestId="button-back" onClick={goToLoginPage} variant={ButtonVariant.Tertiary}>
            <Row alignItems="center">
              <IconArrowLeft aria-hidden />
              <Text>{t('ConfirmOtp-lbl-back-button', 'back')}</Text>
            </Row>
          </Button>
        </Row>
        <img
          src={imageLink}
          className="image-icon"
          alt={isSendOTPToEmail ? 'email' : 'phone'}
          aria-hidden="true"
          role="presentation"
          tabIndex={-1}
        />
        <H1 fontSize="T400" fontWeight="regular" color="accent1">
          {t('ConfirmOtp-lbl-send-otp-to-success', `A verification code has been sent to ${loginID}`, {
            login: loginID,
          })}
        </H1>
        <Text fontSize="T100">
          {t(
            `ConfirmOtp-lbl-send-otp-to-${isSendOTPToEmail ? 'an-email' : 'a-text'}`,
            `Enter the verification code sent to ${loginID}. 
            Look for ${isSendOTPToEmail ? 'an email with the subject' : 'a text message that says'} "Your Amazon Jobs verification code". It will expire in 3 minutes.`,
            { login: loginID },
          )}
        </Text>
        <Row gridGap="S300" alignItems="center">
          <Input
            dataTestId="input-test-id-confirmOtp"
            value={otp}
            onChange={onOtpChange}
            width="100%"
            maxLength={6}
            aria-label="verificationCode"
            disabled={isEmailVerified}
          />
        </Row>
        {!isEmailVerified && (
          <ResendOtp candidate={candidate} resetOtpTextBox={resetOtp} loginType={loginType} pin={pin} />
        )}
        {candidate && !candidate.isPhoneVerified && isEmailVerified && (
          <Col gridGap="S300">
            <Row gridGap="S300">
              {!skipPhoneVerification() && (
                <Button
                  dataTestId="button-onLoginWithPhone"
                  variant={ButtonVariant.Tertiary}
                  onClick={onLoginWithPhone}
                >
                  {t('otp-login-with-phone-button-text', 'Login with your phone number instead')}
                </Button>
              )}
            </Row>
            <Button dataTestId="button-continue" onClick={onNext} variant={ButtonVariant.Primary}>
              {t('otp-continue-button-text', 'Continue')}
            </Button>
          </Col>
        )}
      </Col>
    </Col>
  );
};

export default ConfirmOtp;
