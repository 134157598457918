import {
  countryCodeMap,
  CSDomainByCountryFullName,
  SensitiveAuthQueryParams,
  THREE_LETTER_TO_TWO_LETTER_COUNTRY_CODE_MAP,
} from 'src/utils/constants';
import urlcat from 'urlcat';
import { HVH_LOCALE } from 'src/utils/localization/constants';
import Cookies from 'js-cookie';

export const parseSearchParamFromHash = (hashURL: string): { [key: string]: string } => {
  let url = hashURL.split('#')[1];
  if (!url) return {};

  url = url.split('?')[1];

  if (!url) return {};

  return url.split('&').reduce((result: { [key: string]: string }, param) => {
    const [key, value] = param.split('=');
    result[key] = decodeURIComponent(value);
    return result;
  }, {});
};

export const parseSearchParamFromLocationSearch = (): { [key: string]: string } => {
  const searchParams = new URLSearchParams(window.location.search);
  const searchParamObject: { [key: string]: string } = {};

  searchParams.forEach((value, key) => {
    //clean up forward slash to avoid infinite loop
    searchParamObject[key] = cleanSearchParamValue(value);
  });

  return searchParamObject;
};

export const extractAndSaveSearchParamsFromHash = (): void => {
  const url = new URL(window.location.href);
  const queryParams = parseSearchParamFromHash(window.location.hash);
  const searchParams = parseSearchParamFromLocationSearch();
  const hashPath = url.hash.split('?')[0];

  Object.keys(queryParams).forEach(async (paramKey) => {
    let value = queryParams[paramKey];
    if (paramKey === 'countryCode') {
      const countryCode = THREE_LETTER_TO_TWO_LETTER_COUNTRY_CODE_MAP[value.toUpperCase()];
      value = countryCode || value.toUpperCase();

      // only save supported country code
      if (countryCodeMap.has(value)) {
        window.sessionStorage.setItem('notDisplayCountry', 'true');
      } else {
        value = '';
      }
    }

    if (value) {
      if (paramKey === 'locale') {
        // TODO: call syncLocaleValue
        Cookies.set(HVH_LOCALE, value, { secure: true });
        window.sessionStorage.setItem('locale', value);
        // Emit locale change event so that translation can be reloaded
        // await updateI18nLocale(value);
      } else {
        window.sessionStorage.setItem(paramKey, value);
      }
    }
  });

  // Adding back all search params
  const urlWithSearchParams = urlcat(url.origin, `${url.pathname}`, searchParams);
  // update redirect URL without any hash Param
  const redirectUrl = urlcat(urlWithSearchParams, hashPath);

  window.location.assign(redirectUrl);
};

export function getCSDomainByCountryFullName(country: string, stage: string) {
  const isCanary = window.sessionStorage.getItem('canary');
  const bypassCorp = window.sessionStorage.getItem('bypasscorp');
  const url = CSDomainByCountryFullName[country][stage];
  return isCanary === 'true' ? urlcat(url, { bypasscorp: bypassCorp }) : url;
}

export const cleanSearchParamValue = (searchValue: string): string => {
  return searchValue?.replace(/\/+$/, '') || '';
};

export const maskSensitiveTokensFromLog = (log: string): string => {
  if (!log) return '';

  // Iterates through sensitive auth tokens and replaces their values with the token name itself in the log string
  // Example: "code=abc123" becomes "code=code"
  return SensitiveAuthQueryParams.reduce(
    (maskedLog, token) => maskedLog.replace(new RegExp(`(${token})=([^&\\s]+)`, 'g'), `$1=${token}`),
    log,
  );
};
