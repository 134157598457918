import React, { FC, Suspense, useEffect, useMemo, useState } from 'react';
import store, { history, persistor } from '../store/store';
import { ConnectedRouter } from 'connected-react-router';
import PageRouter from './pageRouter';
import NavigationWithStore from './Navigation/index';
import ErrorMessage from 'src/components/common/ErrorMessage';
import LoadingComponent from 'src/components/common/LoadingComponent';
import { PageContainer } from '@amzn/stencil-react-components/page';
import { HVHColors } from 'src/utils/colors';
import { AppSEOHeader } from 'src/components/AppSEOHeader';
import i18n, { i18nWhenReady } from 'src/i18n';
import Footer from './Footer';
import { isRightToLeftTextEnabled, isStencil2021Theme } from 'src/utils/featureFlag/featureFlag';
import { AWSWAFCaptchaModal } from 'src/components/AWSWAFCaptchaModal';
import defaultTheme from '@amzn/stencil-react-theme-default';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { mapI18nStringKey } from 'src/utils/translator';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { StencilProvider } from '@amzn/stencil-react-components/context';
import { PersistGate } from 'redux-persist/integration/react';
import { getCurrentLocale } from 'src/utils/localization/localeHelper';
import { Locale } from 'src/utils/commonTypes';
import { emitEvent } from 'src/utils/rum';
import { RumEventType } from 'src/utils/constants';

export const App: FC = () => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [i18nState, setI18nState] = useState<boolean>(false);

  useEffect(() => {
    i18nWhenReady(() => {
      setI18nState(true);
    });

    // Emitting RUM Events
    emitEvent({
      event: RumEventType.PAGE_LOAD,
      status: true,
      message: `Mounted App component`,
    });
  }, []);

  const currentLocale = getCurrentLocale();
  const isRTL = useMemo(() => currentLocale === Locale.AR_AE && isRightToLeftTextEnabled(), [currentLocale]);

  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <StencilProvider theme={defaultTheme} isRTL={isRTL}>
          <PersistGate
            loading={
              <Spinner
                size={SpinnerSize.Large}
                loadingText={i18n.t(mapI18nStringKey('Common-lbl-loading'), 'Loading') + '...'}
              />
            }
            persistor={persistor}
          >
            <Suspense
              fallback={
                <Spinner
                  size={SpinnerSize.Small}
                  loadingText={i18n.t(mapI18nStringKey('Common-lbl-loading'), 'Loading') + '...'}
                />
              }
            >
              <div className={isStencil2021Theme() ? 'stencil-2021' : ''}>
                <ConnectedRouter history={history}>
                  <div className="app-container">
                    <NavigationWithStore />
                    <AppSEOHeader />
                    <PageContainer
                      backgroundColor={HVHColors.MainContentBackground}
                      paddingTop="0"
                      paddingBottom="0"
                      data-skip-link="true"
                      id={'pageContainer'}
                    >
                      <LoadingComponent />
                      <ErrorMessage />
                      <PageRouter />
                    </PageContainer>
                    <Footer />
                  </div>
                </ConnectedRouter>
                <AWSWAFCaptchaModal />
              </div>
            </Suspense>
          </PersistGate>
        </StencilProvider>
      </I18nextProvider>
    </Provider>
  );
};

export default App;
