import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import alertReducer from "./alert.reducer";
import configReducer from "./config.reducer";
import authReducer from "./auth.reducer";
import uiReducer from "./ui.reducer";
import registrationReducer from "./registration.reducer";
import candidateReducer from "./candidate.reducer";
import candidateDetailReducer from "./candidateDetail.reducer";
import webLabReducer from 'src/reducers/weblab.reducer';
import communicationPreferenceReducer from 'src/reducers/communicationPreference.reducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'

const alertPersistConfig = {
    key: 'alert',
    storage,
    whitelist: ['showGlobalAlerts', 'globalAlerts', 'showJobAlertSuccess']
};

const createRootReducer = (history: any) =>
    combineReducers({
        config: configReducer,
        router: connectRouter(history),
        alert: persistReducer(alertPersistConfig, alertReducer),
        auth: authReducer,
        ui: uiReducer,
        registration: registrationReducer,
        candidate: candidateReducer,
        candidateDetail: candidateDetailReducer,
        communicationPreference: communicationPreferenceReducer,
        webLab: webLabReducer
    });

export default createRootReducer;
