/* istanbul ignore file */
import KatalLogger, { Level } from "@amzn/katal-logger";

const katalLoggerConfig = {
  url: "{{KatalLoggerUrl}}",
  logThreshold: Level.INFO,
  maxLogLineSize: 10000,
  logToConsole: false,
};

export const logger = new KatalLogger(katalLoggerConfig);
/**
 * TODO: Re-enable Katal Logger once we bring it back in CDK
 * Related ticket: https://t.corp.amazon.com/V371053947/communication.
 * */
// logger.addErrorListener();
