import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { LinkCard } from '@amzn/stencil-react-components/card';
import { H1, Text } from '@amzn/stencil-react-components/text';
import { countryCodeKeys, RumEventType } from 'src/utils/constants';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import Helper from 'src/components/Helper';
import { getStage } from 'src/utils/apis/apiHelper';
import { getCSDomainByCountryFullName } from 'src/utils/urlUtils';
import useHVHTranslation from 'src/Hooks/useHVHTranslation';
import { emitEvent } from 'src/utils/rum';

export const SelectCountryPage = () => {
  const { t } = useHVHTranslation();
  const stage = getStage();

  useEffect(() => {
    // Emitting RUM events
    emitEvent({
      event: RumEventType.PAGE_LOAD,
      status: true,
      message: 'Mounted Select country component',
    });
  }, []);

  const countryList = (
    <div
      style={{
        display: 'flex',
        gap: '20px', // Space between the cards
        justifyContent: 'center', // Center the row
        alignItems: 'center', // Align items vertically
        flexDirection: 'column', // Layout in column
      }}
    >
      {countryCodeKeys.map((country) => (
        <LinkCard
          key={country.key}
          height={50}
          width={500}
          justifyContent="left"
          alignItems="center"
          href={getCSDomainByCountryFullName(country.value, stage)}
        >
          <Row
            style={{
              gap: '30px', // Add horizontal space between items
              display: 'flex',
            }}
          >
            <img
              src={country.imageLink}
              alt={`${country.value} flag`}
              style={{
                width: '24px',
                height: '24px',
                gap: '20px',
              }}
            />
            <Text>{t('select-country-button', country.value)}</Text>
          </Row>
        </LinkCard>
      ))}
    </div>
  );
  const selectCountryPage = (
    <Row width="100%" height="100%">
      <Col justifyContent="space-between" height="100%" width="100%" alignItems="center">
        <Col gridGap="S300" padding={{ left: '50px', right: '50px', top: '50px' }}>
          <Col className="colContainerTop">
            <H1 fontSize="T400" font="primary" fontWeight="bold" color="black">
              {t('create-account-title', 'Create your account')}
            </H1>
            <Text fontSize="T100">
              {t('selectCountryPage-sub-title', 'Select the country and then find Create Account under My Account')}
            </Text>
          </Col>
          <Col gridGap="S300" className="colContainer">
            {countryList}
          </Col>
        </Col>
        <Helper />
      </Col>
    </Row>
  );
  return selectCountryPage;
};

interface State {
  initPage?: string;
  router?: any;
}

const mapStateToProps = (state: any, ownState: State) => {
  return { ...state, ...ownState };
};

export default connect(mapStateToProps)(SelectCountryPage);
