import {
    ASHUSEndpoint,
    AuthClientIdByCountryFullName,
    AuthSecretIdByCountryFullName, BBMXUnifiedEndpoint,
    BBSignOutEndPointsByCountryFullName,
    BBUKApplicationEndpoint,
    BBUKEndpoint,
    BBUSEndpoint,
    BBUSUnifiedEndpoint,
    CSAuthServiceEndPointsByCountryFullName,
    CSAuthServiceEndPointsCloudFront,
    CSIntegrationEndPoints,
    CSDomainByCountryFullName,
    loginHelpPageRoute,
    SFCountry,
    StageToLanguageByCountryFullName,
    StageToLocaleByCountryFullName,
    UnifiedDomainEnabled,
    THREE_LETTER_TO_TWO_LETTER_COUNTRY_CODE_MAP, PhoneNumberReminderCountryList, CSDomainsUS, countryCodeMap,
    AtoZDomains
} from "src/utils/constants";
import { CountryFullName, Language, Locale, MarketPlace, Stage } from "src/utils/commonTypes";
import { shouldUseBBSignOut } from "src/utils/featureFlag/featureFlag";
import isEmpty from "lodash/isEmpty";

interface stageCountryFullNameOverride {
    stageOverride?: Stage, 
    countryFullNameOverride?: CountryFullName,
    isUnifiedDomain?: boolean
}

export const parseSearchParamFromHash = (hashURL: string): {[key: string]: string } => {
    let url = hashURL.split("#")[1];
    if (!url) return {};

    url = url.split("?")[1];

    if (!url) return {};

    return url.split('&')
        .reduce((result: {[key: string]: string}, param) => {
            let [key, value] = param.split("=");
            result[key] = decodeURIComponent(value);
            return result;
        }, {});
};

export const parseAndSaveSearchParamFromHash = (hashURL: string) => {
    let url = hashURL.split("#")[1];
    let splitBy = "&";
    const startIndex = url.indexOf("?") + 1;
    const endIndex = url.length;
    if (startIndex > 0) {
        const params = url.substring(startIndex, endIndex).split(splitBy);
        params.forEach((param) => {
            let [key, value] = param.split("=");
            if (key === "countryCode" && value) {
                const countryCode = THREE_LETTER_TO_TWO_LETTER_COUNTRY_CODE_MAP[value.toUpperCase()];
                value = countryCode ? countryCode : value.toUpperCase();
                // only save supported country code
                let keys = Array.from( countryCodeMap.keys() );
                if (keys.includes(value)) {
                    window.sessionStorage.setItem('notDisplayCountry', 'true');
                }
                else{
                    value = "";
                }
            }
            if ( !isEmpty(value)) {
                window.sessionStorage.setItem(key, value);
            }
        });
    }
};

export const parseSearchParamFromLocationSearch = (): {[key: string]: string} => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchParamObject: {[key: string]: string} = {};

    searchParams.forEach((value, key) => {
        searchParamObject[key] = value;
    });

    return searchParamObject;
};

export const getStage = (stageOverride?: Stage) => {
    const paramsFromHash = parseSearchParamFromHash(window.location.hash);
    const paramsFromSearch = parseSearchParamFromLocationSearch();
    const stageOverrideFromUrl = paramsFromHash.stageOverride? paramsFromHash.stageOverride : paramsFromSearch.stageOverride ? paramsFromSearch.stageOverride : undefined;
    const stage = stageOverrideFromUrl? stageOverrideFromUrl : stageOverride? stageOverride : '{{Stage}}';
    return stage as Stage;
}

export const getCSAuthServiceEndpoint = () => {
    const countryFullName = getCountryFullName();
    const stage = getStage();
    const endpoint = CSAuthServiceEndPointsByCountryFullName[countryFullName] && CSAuthServiceEndPointsByCountryFullName[countryFullName][stage]
        ? CSAuthServiceEndPointsByCountryFullName[countryFullName][stage]:CSAuthServiceEndPointsCloudFront[stage]
    return endpoint;
};


export const getCSDomain = () => {
    const countryFullName = getCountryFullName();
    const stage = getStage();
    const endpoint = CSDomainByCountryFullName[countryFullName] && CSDomainByCountryFullName[countryFullName][stage]
        ? CSDomainByCountryFullName[countryFullName][stage]:CSDomainsUS[stage]
    return endpoint;
};

export const getAtoZDomain = () => {
    const stage = getStage();
    return AtoZDomains[stage]
}

export const getCountryCode = () => {
    // const storedCountryCode = window.sessionStorage.getItem('countryCode');
    // if (storedCountryCode) {
    //   return storedCountryCode;
    // }

    let countryCode = 'US';
    if (window.location.hash) {
        parseAndSaveSearchParamFromHash(window.location.hash);
    }
    return window.sessionStorage.getItem('countryCode') || countryCode;
};

export const getAuthClientId = ({ stageOverride, countryFullNameOverride } : stageCountryFullNameOverride) => {
    const countryFullName = countryFullNameOverride? countryFullNameOverride : getCountryFullName();
    const stage = getStage(stageOverride);
    const endpoint = AuthClientIdByCountryFullName[countryFullName] && AuthClientIdByCountryFullName[countryFullName][stage]
        ? AuthClientIdByCountryFullName[countryFullName][stage]
        : '{{AuthClientId}}';
    return endpoint;
};

export const getAuthSecretId = ({ stageOverride, countryFullNameOverride } : stageCountryFullNameOverride) => {
    const countryFullName = countryFullNameOverride? countryFullNameOverride : getCountryFullName();
    const stage = getStage(stageOverride);
    const endpoint = AuthSecretIdByCountryFullName[countryFullName] && AuthSecretIdByCountryFullName[countryFullName][stage]
        ? AuthSecretIdByCountryFullName[countryFullName][stage]
        : '{{AuthSecretId}}';
    return endpoint;
};

export const getCSIntegrationEndpoint = (stageOverride?: Stage) => {
    const stage = getStage(stageOverride);
    const endpoint = CSIntegrationEndPoints[stage] || '{{CSEndpoint}}';
    return endpoint;
};

export const getBBUKEndpoint = (stageOverride?: Stage) => {
    const stage = getStage(stageOverride);
    const endpoint = BBUKEndpoint[stage];
    return endpoint;
};

export const getBBUKApplicationEndpoint = (stageOverride?: Stage) => {
    const stage = getStage(stageOverride);
    const endpoint = BBUKApplicationEndpoint[stage];
    return endpoint;
};

export const getBBUSEndpoint = (stageOverride?: Stage) => {
    const stage = getStage(stageOverride);
    return BBUSEndpoint[stage];
};

export const getBBMXUnifiedEndpoint = (stageOverride?: Stage): string => {
    const stage = getStage(stageOverride);
    return BBMXUnifiedEndpoint[stage] || BBMXUnifiedEndpoint['beta'];
};

export const getBBUSUnifiedEndpoint = (stageOverride?: Stage): string => {
    const stage = getStage(stageOverride);
    return BBUSUnifiedEndpoint[stage] || BBUSUnifiedEndpoint['beta'];
};

export const isUnifiedDomain = (stageOverride?: Stage): boolean => {
    const stage = getStage(stageOverride);
    return UnifiedDomainEnabled[stage] || false;
};

export const getBBEndpoint = ({ stageOverride, countryFullNameOverride} : stageCountryFullNameOverride) => {
    const countryFullName = countryFullNameOverride ? countryFullNameOverride : getCountryFullName();
    const stage = getStage(stageOverride);
    switch (countryFullName) {
        case CountryFullName.US:
            return isUnifiedDomain(stage) ? getBBUSUnifiedEndpoint(stage) : getBBUSEndpoint(stage);
        case CountryFullName.UK:
            return getBBUKApplicationEndpoint(stageOverride);
        case CountryFullName.MX:
                return getBBMXUnifiedEndpoint(stageOverride);
        case CountryFullName.DEVO:
            return getBBUKApplicationEndpoint(stage);
        default:
            return getBBUKApplicationEndpoint(stage);
    }
};

export const getASHUSEndpoint = (stageOverride?: Stage) => {
    const stage = getStage(stageOverride);
    return ASHUSEndpoint[stage];
};

export const getBBSignOutEndpoint = ({ stageOverride, countryFullNameOverride } : stageCountryFullNameOverride) => {
    const countryFullName = countryFullNameOverride? countryFullNameOverride : getCountryFullName();
    const stage = getStage(stageOverride);
    const endpoint = BBSignOutEndPointsByCountryFullName[countryFullName][stage];
    return endpoint;
};

export const getSFCountry = (stageOverride?: Stage) => {
    const stage = getStage(stageOverride);
    const country = SFCountry[stage];
    return country;
};

export const getLocale = ({ stageOverride, countryFullNameOverride } : stageCountryFullNameOverride) => {
    const countryFullName = countryFullNameOverride? countryFullNameOverride : getCountryFullName();
    const stage = getStage(stageOverride);
    const locale = StageToLocaleByCountryFullName[countryFullName] && StageToLocaleByCountryFullName[countryFullName][stage]
        ? StageToLocaleByCountryFullName[countryFullName][stage]
        : Locale.UK;
    return locale;
};

export const getLanguage = ({ stageOverride, countryFullNameOverride } : stageCountryFullNameOverride) => {
    const countryFullName = countryFullNameOverride? countryFullNameOverride : getCountryFullName();
    const stage = getStage(stageOverride);
    const language = StageToLanguageByCountryFullName[countryFullName] && StageToLanguageByCountryFullName[countryFullName][stage]
        ? StageToLanguageByCountryFullName[countryFullName][stage]
        : Language.UK;
    return language;
};

export const getBBRegion = (override?: Stage) => {
    const bbRegion = override? override : '{{BBRegion}}';
    return bbRegion;
};

export const getCountryFullName = (): CountryFullName => {
    let countryCode = getCountryCode();
    const countryFullName = countryCode ? (CountryFullName as any)[countryCode] : CountryFullName.DEVO;
    return countryFullName;
};

export const getMarketPlace = (override?: MarketPlace): MarketPlace => {
    return  override ? override : '{{Marketplace}}' as any as MarketPlace;
}

export const getSignOutRedirectUrl = ({stageOverride, countryFullNameOverride}: stageCountryFullNameOverride) => {
    return getBBSignOutEndpoint({stageOverride, countryFullNameOverride});
};

export const showPhoneNumberInputReminder = (override?: CountryFullName): boolean => {
    const countryFullName = override? override: getCountryFullName();
    return PhoneNumberReminderCountryList.includes(countryFullName);
}

export const getOrganizationIdFromSession = (key: string): string | null => {
    return window.sessionStorage.getItem(key);
};