import React from 'react';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import { RegistrationForm, PrivacyNotice } from 'src/utils/commonTypes';
import { Button } from '@amzn/stencil-react-components/button';
import { Link } from '@amzn/stencil-react-components/link';
import { Status, StatusIndicator, StatusIndicatorColorScheme } from '@amzn/stencil-react-components/status-indicator';
import { AuthErrorMessages } from 'src/utils/errorMessages';
import { connect } from 'react-redux';
import useHVHTranslation from 'src/Hooks/useHVHTranslation';

interface MXConsentProps {
  registrationForm: RegistrationForm;
  setIsAgreeToDataRetention: Function;
  setIsAgreeToCommunication: Function;
  setIsWhatsAppEnabled: Function;
  consentError: boolean[];
  consentToggleRef: React.RefObject<HTMLButtonElement>;
  privacyNotice: PrivacyNotice;
}

interface MapStateToProps {}

export const MXConsent = (props: MXConsentProps) => {
  const { t } = useHVHTranslation();
  const { registrationForm, setIsWhatsAppEnabled, consentError, consentToggleRef, privacyNotice } = props;

  return (
    <>
      <Col>
        <Col className="colContainer">
          <Row className="rowContainer" gridGap="S300">
            <Text color="accent1" fontSize="T200" textAlign="left">
              {
                'Al dar tu(s) número(s) telefónico(s), aceptas recibir llamadas, mensajes de texto y mensajes de voz pregrabados o artificiales, incluso desde un sistema de marcación telefónica automática, por parte de o en nombre de Amazon a tu(s) número(s) telefónico(s) acerca de cualquier asunto. Para desactivar, puedes cambiar tus preferencias de contacto en la sección Editar perfil o Mi información personal en el portal de empleo.'
              }
            </Text>
          </Row>
        </Col>
      </Col>
      <Col>
        <Col className="colContainer">
          <Row className="rowContainer" gridGap="S300">
            <Text color="accent1" fontSize="T200" textAlign="left">
              {
                'Al seleccionar SÍ, aceptas que Amazon se ponga en contacto contigo a través de WhatsApp. Puedes dejar de recibir mensajes de WhatsApp en cualquier momento respondiendo "ALTO".'
              }
            </Text>
          </Row>
        </Col>
      </Col>
      <Col>
        <Col className="colContainer">
          <Row className="rowContainer" gridGap="S300">
            <Text color="accent1" fontSize="T200" textAlign="left">
              {'Al continuar con esta solicitud das tu consentimiento para el tratamiento de tus datos personales conforme al' +
                ' '}
              <Link href={privacyNotice.urlLink} target="_blank">
                {privacyNotice.text}
              </Link>
              {'.'}
            </Text>
          </Row>
          <Row gridGap="S300" padding={{ top: 16 }}>
            <Button
              className={`registrationToggleButton ${registrationForm.isWhatsAppEnabled ? 'selected' : ''}`}
              dataTestId="communication-agree-button"
              onClick={() => {
                setIsWhatsAppEnabled(true);
              }}
              ref={consentToggleRef}
            >
              {t('consent-yes-button', 'Yes')}
            </Button>
            <Button
              className={`registrationToggleButton ${registrationForm.isWhatsAppEnabled === false ? 'selected' : ''}`}
              dataTestId="communication-disagree-button"
              onClick={() => {
                setIsWhatsAppEnabled(false);
              }}
            >
              {t('consent-no-button', 'No')}
            </Button>
          </Row>
          {consentError[2] && (
            <Row>
              <StatusIndicator
                status={Status.Negative}
                colorScheme={StatusIndicatorColorScheme.Default}
                messageText={t(
                  AuthErrorMessages['registration-consent-validation-error'].key,
                  AuthErrorMessages['registration-consent-validation-error'].value,
                )}
              />
            </Row>
          )}
        </Col>
      </Col>
    </>
  );
};

const mapStateToProps = (state: MapStateToProps) => {
  return state;
};

export default connect(mapStateToProps)(MXConsent);
