import React from 'react';
import { history } from "../store/store";
import { ConnectedRouter } from "connected-react-router";
import PageRouter from "./pageRouter";
import NavigationWithStore from "./Navigation/index";
import { WidgetPositions } from "src/utils/constants";
import ErrorMessage from "src/components/common/ErrorMessage";
import LoadingComponent from "src/components/common/LoadingComponent";
import { PageContainer } from "@amzn/stencil-react-components/page";
import { HVHColors } from "src/utils/colors";

import {
    clearReduxPersistOnload,
    persistSsoCodeFromSearchParams,
    routeToAppPageWithPath
} from "src/utils/helper";
import {
    PAGE_ROUTE_LOGIN,
    PAGE_ROUTE_REGISTRATION
} from "src/components/pageRoutes";
import { Col } from "@amzn/stencil-react-components/layout";
import { AppSEOHeader } from "src/components/AppSEOHeader";
import {i18nWhenReady} from "src/i18n";
import { initRum } from 'src/utils/rum';
import Footer from './Footer';

interface Props {
    document: Element;
}

interface State {
    i18nState: boolean;
}

export class App extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        clearReduxPersistOnload();
        initRum();
    }

    componentDidMount() {
        // When sso code is returned from redirect_url, the url cannot contain # symbol.
        // We need to persist the code before redirecting to the login page
        persistSsoCodeFromSearchParams();
        if (window.location.hash.includes("registration")) {
            routeToAppPageWithPath(PAGE_ROUTE_REGISTRATION);
        } else {
            // Landing to login page by default
            routeToAppPageWithPath(PAGE_ROUTE_LOGIN);
        }
        i18nWhenReady((err) => {
            this.setState((state: State) => {
                return  {i18nState: true};
            });
        });
    }

    getWidget(document: Element) {
        switch (document.getAttribute('data-hvh-position')) {
            case WidgetPositions.CONTENT:
                return <>
                    <NavigationWithStore/>
                    <AppSEOHeader isDynamic={true}/>
                    <PageContainer
                        backgroundColor={HVHColors.MainContentBackground}
                        paddingTop='0'
                        paddingBottom='0'
                        data-skip-link='true'
                        id={"pageContainer"}
                    >
                        <LoadingComponent/>
                        <ErrorMessage/>
                        <PageRouter/>
                    </PageContainer>
                </>;
            case WidgetPositions.HEADER:
                return <>
                    <NavigationWithStore/>
                    <AppSEOHeader isDynamic={false}/>
                    <Col className='banner_container'>
                        <Col>
                            <ErrorMessage/>
                        </Col>
                    </Col>
                </>;
            default:
                return null;
        }
    }

    render() {
        return (
            <ConnectedRouter history={history}>
                <div className='app-container'>
                    {this.getWidget(this.props.document)}
                    <Footer />
                </div>
            </ConnectedRouter>
        )
    }
}

export default App;
