import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { PAGE_ROUTE_LOGIN, PAGE_ROUTE_VERIFY_PHONE } from '../pageRoutes';
import isNil from 'lodash/isNil';
import isBoolean from 'lodash/isBoolean';
import { LOGIN_TYPE, REGISTRATION_PAGES } from 'src/utils/constants';
import { onEmailVerificationComplete, onVerifyEmail } from 'src/utils/authService';
import ResendOtp from './ResendOtpComponent';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { H1, Text } from '@amzn/stencil-react-components/text';
import { Input } from '@amzn/stencil-react-components/form';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { IconCheckCircleFill, IconCloseCircleFill } from '@amzn/stencil-react-components/icons';
import { CommonColors, HVHColors } from 'src/utils/colors';
import { isStencil2021Theme, skipPhoneVerification } from 'src/utils/featureFlag/featureFlag';
import { RouterState } from 'connected-react-router';
import { handleRedirection } from 'src/utils/helper';
import { recordPageView } from 'src/utils/rum';
import { addAdobeMetric } from 'src/customerTracking/adobeAnalytics';
import { AdobeEvent, PageName } from 'src/customerTracking/adobeAnalytics/types';
import useHVHTranslation from 'src/Hooks/useHVHTranslation';

export const VerifyEmailPage = (props: any) => {
  const { t } = useHVHTranslation();
  const history: any = useHistory();
  const [otp, setOtp] = useState<string>('');
  const [isInvaildToVerify, setInvaildToVerify] = useState<boolean>(false);
  const [isEmailVerified, setEmailVerified] = useState<boolean>(
    isBoolean(props.isEmailVerifiedOverride) ? props.isEmailVerifiedOverride : false,
  );
  const currentCandidate = props.candidate.results;
  const goToLoginPage = () => {
    props.redirectToLoginTest && props.redirectToLoginTest();
    history && history.push(`/${PAGE_ROUTE_LOGIN}`);
  };

  const goToVerifyPhonePage = () => {
    props.redirectToLoginTest && props.redirectToLoginTest();
    history && history.push(`/${PAGE_ROUTE_VERIFY_PHONE}`);
  };
  useEffect(() => {
    addAdobeMetric(AdobeEvent.PAGE_LOAD, PageName.VERIFY_EMAIL);
    if (currentCandidate && !currentCandidate.isEmailVerified && currentCandidate.candidateLogin) {
      setInvaildToVerify(false);
    } else if (
      currentCandidate &&
      !currentCandidate.isEmailVerified &&
      props.registration.emailId &&
      props.registration.emailId.length >= 0
    ) {
      setInvaildToVerify(false);
    } else {
      !props.redirectOverride && goToLoginPage();
    }
    // document.title = "Amazon Jobs - Email verification";
  }, []);

  useEffect(() => {
    if (isNil(currentCandidate)) {
      setInvaildToVerify(true);
    }
  }, [currentCandidate]);

  const onOtpChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setOtp(value);
    if (value.length === 6) {
      onVerifyEmail(currentCandidate, value, setEmailVerified, props.onVerifyEmail);
    }
  };

  const EmailVerificationComplete = () => {
    addAdobeMetric(AdobeEvent.NEXT_BUTTON_CLICKED, PageName.VERIFY_EMAIL);
    onEmailVerificationComplete({
      candidate: currentCandidate,
      onNext:
        skipPhoneVerification() || currentCandidate.isPhoneVerified ? onSkipPhoneVerification : goToVerifyPhonePage,
      onEmailVerificationCompleteTest: () => {
        props.onEmailVerificationCompleteTest && props.onEmailVerificationCompleteTest();
        props.onEmailVerificationCompleteTest && goToVerifyPhonePage();
      },
    });
  };

  const onSkipPhoneVerification = () => {
    handleRedirection();
  };

  const resetOtp = () => {
    setOtp('');
  };

  recordPageView({ pageId: REGISTRATION_PAGES.VERIFY_EMAIL });

  return (
    <Row justifyContent="center">
      {isInvaildToVerify ? (
        <Col gridGap="S300" className="hvhRegistration">
          <img
            src="https://m.media-amazon.com/images/G/01/HVH-CandidateApplication/jobs/illustration-email.svg"
            className="image-icon"
            alt="email"
            aria-hidden="true"
            role="presentation"
            tabIndex={-1}
          />
          <Text fontSize="T400" color="accent1">
            <Row gridGap="S300">
              <IconCloseCircleFill aria-hidden color="negative" />
              <Text>
                {t(
                  'VerifyEmailPage-lbl-email-failed-phone-login',
                  'Email verification is failed. Please login to verify your phone number.',
                )}
              </Text>
            </Row>
          </Text>
        </Col>
      ) : (
        <Col gridGap="S300" className="hvhRegistration">
          <Col className="colContainerTop">
            <img
              src="https://m.media-amazon.com/images/G/01/HVH-CandidateApplication/jobs/illustration-email.svg"
              className="image-icon"
              alt="email"
              aria-hidden="true"
              role="presentation"
              tabIndex={-1}
            />
          </Col>
          <Col className="colContainer">
            <H1 fontSize="T400" color="accent1" fontWeight="regular" textAlign="left">
              {t('VerifyEmailPage-title-verify-email', "First, let's verify your email address.")}
            </H1>
          </Col>
          <Col className="colContainer">
            <Text fontSize="T100" color="accent1" textAlign="left">
              {t(
                'VerifyEmailPage-paragraph-verify-email-description',
                'Enter the verification code sent to {email}. Look for an email with the subject "Your Amazon Jobs verification code". It will expire in 3 minutes',
                { email: currentCandidate.emailId },
              )}
            </Text>
          </Col>
          <Col gridGap="S300" className="colContainer" alignItems="center">
            <Row gridGap="S300" alignItems="center" className="rowContainer">
              <Input
                dataTestId="input-test-id-emailOtp"
                value={otp}
                onChange={onOtpChange}
                width="100%"
                maxLength={6}
                aria-label="verificationCode"
                disabled={isEmailVerified}
              />
              <IconCheckCircleFill
                aria-hidden
                color={isStencil2021Theme() ? CommonColors.Blue70 : HVHColors.BlueLagoon}
                display={isEmailVerified ? 'block' : 'none'}
                margin="0px 0px 0px 8px"
              />
            </Row>
            {!isEmailVerified && (
              <ResendOtp
                candidate={currentCandidate}
                resetOtpTextBox={resetOtp}
                loginType={LOGIN_TYPE.EMAIL}
                waitTime={60}
                pin={currentCandidate.pin}
              />
            )}
            {isEmailVerified && (
              <Col gridGap="S300" alignItems="center">
                <Text textAlign="center" color="positive">
                  {t('VerifyEmailPage-lbl-email-verify-success-message', 'Your email has been verified!')}
                </Text>
                <Button
                  dataTestId="button-test-id-emailVerificationComplete"
                  onClick={EmailVerificationComplete}
                  className="buttonRoundBorderRadius emailVerificationCompleteButton"
                  variant={ButtonVariant.Primary}
                >
                  {t('VerifyEmailPage-btn-continue-label', 'Continue')}
                </Button>
              </Col>
            )}
          </Col>
        </Col>
      )}
    </Row>
  );
};

interface Props {
  onVerifyEmail?: Function;
  redirectOverride?: boolean;
  redirectToLoginTest?: Function;
  isEmailVerifiedOverride?: boolean;
  onEmailVerificationCompleteTest?: Function;
  router?: RouterState;
}

const mapStateToProps = (state: any, ownProps?: Props) => {
  return { ...state, ...ownProps };
};

export default connect(mapStateToProps)(VerifyEmailPage);
