import {
  AdobeEvent,
  AdobeMetricType,
  AuthAdobeApplicationName,
  MetricProps,
  OverrideProps,
  PageName,
  PageRenderType,
  UserLogInStatus,
} from 'src/customerTracking/adobeAnalytics/types';
import { getAdobeSessionId, getUserId, parsePathNameFromHash } from 'src/utils/helper';
import { getCurrentLocale } from 'src/utils/localization/localeHelper';
import { getCountryCode } from 'src/utils/apis/apiHelper';

const pushLayer = (metric: AdobeMetricType) => {
  if (process.env.NODE_ENV === 'development') {
    /* eslint-disable-next-line no-console */
    console.log(metric);
  }

  window.dataLayerArray = window.dataLayerArray || [];
  window.dataLayerArray.push(metric);
};

export const addMetric = (event: string, props?: any): void => {
  const metric: AdobeMetricType = {
    event,
    ...props,
  };

  pushLayer(metric);
};

export const addAdobeMetric = (
  event: AdobeEvent,
  pageName: PageName,
  props?: MetricProps,
  overrideProps?: OverrideProps,
) => {
  const pagePath = parsePathNameFromHash();
  const userId = getUserId();
  const adobeSessionId = getAdobeSessionId();
  const adobeMetric: AdobeMetricType = {
    event,
    page: {
      name: pageName,
      path: pagePath,
      renderType: PageRenderType.DYNAMIC,
      application: AuthAdobeApplicationName,
      locale: getCurrentLocale() || undefined,
      ...overrideProps?.page,
    },
    environment: '{{Stage}}',
    countryCode: getCountryCode(),
    candidate: {
      adobeSessionId: adobeSessionId, // TODO: Auth doesn't have adobeSessionId
      bbCandidateId: overrideProps?.candidate?.bbCandidateId ?? undefined, // TODO: double check with auth team if this is needed on page load as it never been set
      status: !!userId ? UserLogInStatus.AUTHENTICATED : UserLogInStatus.UNAUTHENTICATED, // TODO: same to this
      ...overrideProps?.candidate,
    },
    timestamp: new Date().toISOString().slice(0, 19).replace('T', ' '),
    ...props,
  };

  pushLayer(adobeMetric);
};
