import React from 'react';
import { registrationSessionDataExists } from 'src/utils/helper';
import { Redirect } from 'react-router';
import { PAGE_ROUTE_SELECT_COUNTRY } from 'src/components/pageRoutes';
import { USER_SIGN_UP_TYPE } from 'src/utils/constants';

interface RegistrationHocProps {
  signUpType: USER_SIGN_UP_TYPE;
}

type OriginalComponentProps<E> = Omit<E, keyof RegistrationHocProps>;

const RegistrationHoc = <P extends OriginalComponentProps<P>>(WrappedComponent: React.ComponentType<P>) => {
  return function WithRegistrationGuard(props: P & RegistrationHocProps): JSX.Element {
    const registrationSessionExists = registrationSessionDataExists();
    const { signUpType } = props;

    // Route to select country component if session data don't exist
    if (!registrationSessionExists && signUpType === USER_SIGN_UP_TYPE.CREATE) {
      return <Redirect to={`/${PAGE_ROUTE_SELECT_COUNTRY}`} />;
    }

    // Pass through all props to the wrapped component
    return <WrappedComponent {...props} />;
  };
};

export default RegistrationHoc;
