import {API, graphqlOperation } from '@aws-amplify/api';
import { GraphQLResult } from "@aws-amplify/api-graphql";
import {
    getJobDetailQuery,
    jobAlertSignupQuery,
    queryGeoInfoByAddressQuery,
    queryGeoInfoByLocationQuery,
    searchJobCardsByLocationQuery,
    searchScheduleCardsQuery,
    getAutoCompleteHintQuery,
    contactUsCallQuery,
    contactUsSMSQuery,
    contactUsEmailQuery,
    getCandidateDetailQuery,
    getCommunicationPreferenceQuery,
    updateCandidateDetailQuery,
    updateCommunicationPreferenceQuery
} from "src/utils/apis/appSyncAPI/queries";
import {
    GetJobDetailRequest,
    GetJobDetailResponseGQL,
    GeoAddressQueryRequest,
    QueryGeoInfoByAddressResponseGQL,
    GeoLocationQueryRequest,
    QueryGeoInfoByLocationResponseGQL,
    SearchJobCardsByLocationRequest,
    SearchJobCardsByLocationResponseGQL,
    SearchScheduleRequest,
    SearchScheduleResponseGQL,
    GetAutoCompleteHintRequest,
    GetAutoCompleteHintResponseGQL,
    JobAlertSignupRequest,
    JobAlertSignupResponseGQL,
    ContactUsCallRequest,
    contactUsCallResponseGQL,
    ContactUsSMSRequest,
    contactUsSMSResponseGQL,
    ContactUsEmailRequest,
    contactUsEmailResponseGQL,
    GetCandidateDetailRequest,
    GetCandidateDetailResponseGQL,
    GetCommunicationPreferenceRequest,
    UpdateCandidateDetailRequest,
    UpdateCommunicationPreferenceRequest,
    GetCommunicationPreferenceResponseGQL,
    UpdateCommunicationPreferenceResponseGQL,
    UpdateCandidateDetailResponseGQL
} from "src/utils/apis/appSyncAPI/types";

export const getJobDetails =
    async (getJobDetailRequest: GetJobDetailRequest): Promise<GraphQLResult<GetJobDetailResponseGQL>> =>
        await API.graphql(graphqlOperation(
            getJobDetailQuery,
            { getJobDetailRequest })) as GraphQLResult<GetJobDetailResponseGQL>;

export const getCandidateDetail =
    async (getCandidateDetailRequest: GetCandidateDetailRequest): Promise<GraphQLResult<GetCandidateDetailResponseGQL>> =>
        await API.graphql(graphqlOperation(
            getCandidateDetailQuery,
            { bbCandidateId: getCandidateDetailRequest.bbCandidateId }), { accessToken: getCandidateDetailRequest.accessToken }) as GraphQLResult<GetCandidateDetailResponseGQL>;

export const updateCandidateDetail =
    async (UpdateCandidateDetailRequest: UpdateCandidateDetailRequest): Promise<GraphQLResult<UpdateCandidateDetailResponseGQL>> =>{
        return await API.graphql(graphqlOperation(
            updateCandidateDetailQuery,
            {
                input:{
                    ...UpdateCandidateDetailRequest.candidateInput,
                    bbCandidateId:UpdateCandidateDetailRequest.bbCandidateId
                }
            }), { accessToken: UpdateCandidateDetailRequest.accessToken }) as GraphQLResult<UpdateCandidateDetailResponseGQL>;
        }

export const getCommunicationPreference =
    async (getCommunicationPreferenceRequest: GetCommunicationPreferenceRequest): Promise<GraphQLResult<GetCommunicationPreferenceResponseGQL>> =>
        await API.graphql(graphqlOperation(
            getCommunicationPreferenceQuery,
            { bbCandidateId:getCommunicationPreferenceRequest.bbCandidateId }), {accessToken: getCommunicationPreferenceRequest.accessToken}) as GraphQLResult<GetCommunicationPreferenceResponseGQL>;

export const updateCommunicationPreference =
    async (UpdateCommunicationPreferenceRequest: UpdateCommunicationPreferenceRequest): Promise<GraphQLResult<GetCommunicationPreferenceResponseGQL>> =>
        await API.graphql(graphqlOperation(
            updateCommunicationPreferenceQuery,
            {
                input:{
                    ...UpdateCommunicationPreferenceRequest.communicationPreferenceInput,
                    bbCandidateId:UpdateCommunicationPreferenceRequest.communicationPreferenceInput.bbCandidateId
                }
            }), { accessToken: UpdateCommunicationPreferenceRequest.accessToken }) as GraphQLResult<UpdateCommunicationPreferenceResponseGQL>;
