import {
  GET_CANDIDATE_FAILED,
  REMOVE_ALERTS,
  REMOVE_STICKY_ALERTS,
  CREATE_CANDIDATE_FAILED,
  VERIFY_EMAIL_FAILED,
  VERIFY_PHONE_FAILED,
  ADD_ALERT,
  SIGN_IN_FAILED,
  GET_CONFIG_FAILED,
  SIGN_OUT_FAILED,
  CONFIRM_OTP_FAILED,
  FORGOT_PIN_FAILED,
  RESET_PIN_FAILED,
  GET_CSRF_FAILED,
  VERIFY_SIGN_IN_FAILED,
  ADD_STICKY_ALERTS,
  HIDE_ALERTS,
} from '../actions/actionTypes';
import { AlertMessage, BannerMessage } from 'src/utils/commonTypes';
import { RedirectBannerActionTypes } from 'src/actions/UIActions/UIActionTypes';

export interface AlertState {
  alerts: AlertMessage[];
  alert: boolean;
  showRedirectBanner: boolean;
  redirectBannerMessages: BannerMessage[];
  stickyAlerts: AlertMessage[];
  showStickyAlerts: boolean;
  displayAlerts: boolean;
}

export const initialAlertState: AlertState = {
  alerts: [],
  alert: false,
  showRedirectBanner: false,
  redirectBannerMessages: [],
  stickyAlerts: [],
  showStickyAlerts: false,
  displayAlerts: true,
};

export default function alertReducer(state: AlertState = initialAlertState, action: any) {
  const newAlerts = [...state.alerts, action.payload];
  switch (action.type) {
    case GET_CANDIDATE_FAILED:
    case CREATE_CANDIDATE_FAILED:
    case VERIFY_EMAIL_FAILED:
    case VERIFY_PHONE_FAILED:
    case SIGN_IN_FAILED:
    case VERIFY_SIGN_IN_FAILED:
    case GET_CONFIG_FAILED:
    case GET_CSRF_FAILED:
    case SIGN_OUT_FAILED:
    case CONFIRM_OTP_FAILED:
    case FORGOT_PIN_FAILED:
    case RESET_PIN_FAILED:
    case ADD_ALERT:
      return {
        ...state,
        alerts: newAlerts,
        alert: true,
      };
    case REMOVE_ALERTS:
      return {
        ...state,
        alerts: [],
        alert: false,
      };
    case RedirectBannerActionTypes.DISPLAY: {
      return {
        ...state,
        showRedirectBanner: true,
        redirectBannerMessages: [...state.redirectBannerMessages, action.payload],
      };
    }
    case RedirectBannerActionTypes.CLOSE: {
      return {
        ...state,
        showRedirectBanner: false,
        redirectBannerMessages: [],
      };
    }
    case ADD_STICKY_ALERTS:
      return {
        ...state,
        stickyAlerts: [...state.stickyAlerts, action.payload],
        showStickyAlerts: true,
      };
    case REMOVE_STICKY_ALERTS:
      return {
        ...state,
        stickyAlerts: [],
        showStickyAlerts: false,
      };
    case HIDE_ALERTS:
      return {
        ...state,
        displayAlerts: false,
      };
    default:
      return state;
  }
}
