import React from 'react';
import {MessageBannerType} from "@amzn/stencil-react-components/message-banner";
import {GraphQLError} from "graphql";

export interface Candidate {
  candidateLogin: string;
  isEmailVerified: boolean;
  isPhoneVerified: boolean;
  phoneNumber: string;
  emailId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  nameSuffix: string;
  phoneCountryCode: phoneCountryCode;
  locale?: locale;
  language?: string;
  timezone?: timeZone;
  isAgencyUser?: boolean;
  isAgreeToCommunication: boolean | undefined;
  pin: string;
  isBBUser?: boolean | undefined | null;
  isAgreeToDataRetention: boolean | undefined;
  country?: string;
  bbRegion?: BBRegion;
  reEnterPhoneNumber?: string;
}

export interface CandidateDetail {
  candidateId: string
  candidateSFId: string
  firstName: string
  middleName: string
  lastName: string
  preferredFirstName: string | null
  preferredLastName: string | null
  nameSuffix: string
  emailId: string
  phoneNumber: string
  phoneCountryCode: string
  locale: string
  additionalBackgroundInfo: AdditionalBackgroundInfo
  timezone?: string | {key: string, value: string}
  language?: string | {key: string, value: string}
  dateOfBirth?: string
}

export interface Address {
  addressLine1?: string
  addressLine2?: string
  city?: string
  state?: string
  country?: string
  zipcode?: string
  countryCode?: string
}

interface AdditionalBackgroundInfo {
  address: Address
  dateOfBirth?: string
}
/*
 * Marked a lot of fields as optional due to they are not currently being used.
 * Feel free to make them required if needed.
 */

export interface CandidateDetailInput {
  firstName?: string
  middleName?: string
  lastName?: string
  preferredFirstName?: string
  preferredLastName?: string
  nameSuffix?: string
  phoneNumber?: string
  phoneCountryCode?: string
  addressLine1?: string
  addressLine2?: string
  city?: string
  state?: string
  country?: string
  zipcode?: string
  countryCode?: string
  dateOfBirth?: string
  timezone?: string | {key: string, value: string}
  language?: string | {key: string, value: string}
  preferredLocale?: string
}

export interface WithdrawApplicationsInput {
  bbCandidateId: String
  withdrawReason: String
  sfApplications: String[]
  bbApplications: String[]
}

export interface WithdrawApplicationsResponse {
  error: any
  statusCode: any
}
export interface CommunicationPreference {
  candidateId: string
  isConsent: boolean
  isEmailEnabled: boolean
  isSMSEnabled: boolean
}

export interface CommunicationPreferenceInput {
  bbCandidateId: string
  isConsent: boolean
  isSMSEnabled: boolean
  isEmailEnabled: boolean
}

export interface CandidateUpdate {
  candidateLogin?: string;
  isEmailVerified?: boolean;
  isPhoneVerified?: boolean;
  phoneNumber?: string;
  emailId?: string;
  firstName?: string;
  lastName?: string;
  phoneCountryCode?: phoneCountryCode;
  locale?: locale;
  language?: Language;
  timezone?: string;
  isAgencyUser?: boolean;
  isAgreeToCommunication?: boolean;
  pin?: string;
  isBBUser?: boolean | undefined | null;
  isAgreeToDataRetention?: boolean;
  country?: string;
  bbRegion?: BBRegion;
}

export type RegistrationFormProperty =
    "emailId" |
    "firstName" |
    "middleName" |
    "nameSuffix" |
    "englishFirstName" |
    "englishLastName" |
    "preferredFirstName" |
    "preferredLastName" |
    "isAgencyUser" |
    "isAgreeToCommunication" |
    "language" |
    "lastName" |
    "locale" |
    "phoneCountryCode" |
    "phoneNumber" |
    "pin" |
    "reEnterEmailId" |
    "reEnterPhoneNumber" |
    "reEnterPin" |
    "timezone" |
    "isActive" |
    "isAgreeToDataRetention" |
    "country";

export enum CountryFullName {
  UK = "United Kingdom",
  US = "United States",
  MX = "Mexico",
  CA = "Canada",
  JP = "Japan",
  GB = "United Kingdom",
  BR = "Brazil",
  CO = "Colombia",
  CR = "Costa Rica",
  EG = "Egypt",
  JO = "Jordan",
  SA = "Kingdom of Saudi Arabia",
  AE = "United Arab Emirates",
  DEVO = "development"
}

export const CountryCode: {[key in CountryFullName]: string} = {
  [CountryFullName.UK]: "UK",
  [CountryFullName.US]: "US",
  [CountryFullName.MX]: "MX",
  [CountryFullName.CA]: "CA",
  [CountryFullName.JP]: "JP",
  [CountryFullName.GB]: "GB",
  [CountryFullName.BR]: "BR",
  [CountryFullName.CO]: "CO",
  [CountryFullName.CR]: "CR",
  [CountryFullName.EG]: "EG",
  [CountryFullName.JO]: "JO",
  [CountryFullName.SA]: "SA",
  [CountryFullName.AE]: "AE",
  [CountryFullName.DEVO]: "DEVO"
};

export enum BBRegion {
  NA = "NA",
  EU = "EU",
  FE = "FE",
  MX = "MX",
  ZA = "ZA",
  DEVO = "development",
}


export const FullCountryBBRegion: {[key in CountryFullName]: BBRegion} = {
  [CountryFullName.UK]: BBRegion.EU,
  [CountryFullName.GB]: BBRegion.EU,
  [CountryFullName.US]: BBRegion.NA,
  [CountryFullName.MX]: BBRegion.MX,
  [CountryFullName.CA]: BBRegion.NA,
  [CountryFullName.JP]: BBRegion.FE,
  [CountryFullName.BR]: BBRegion.NA,
  [CountryFullName.CO]: BBRegion.NA,
  [CountryFullName.CR]: BBRegion.NA,
  [CountryFullName.EG]: BBRegion.ZA,
  [CountryFullName.JO]: BBRegion.ZA,
  [CountryFullName.SA]: BBRegion.EU,
  [CountryFullName.AE]: BBRegion.EU,
  [CountryFullName.DEVO]: BBRegion.DEVO,
};

export interface UpdateFromTarget {
  key: RegistrationFormProperty,
  value: any
}

export interface PhoneNumberInput {
  phoneCountryCode: string,
  phoneNumber: string,
}

export interface RegistrationForm {
  emailId: string;
  firstName: string;
  middleName: string;
  noMiddleNameChecked: boolean;
  nameSuffix: string;
  englishFirstName?: string,
  englishLastName?: string,
  preferredFirstName?: string;
  preferredLastName?: string;
  isAgencyUser: boolean;
  isAgreeToCommunication: boolean | undefined;
  language: string | {key: string; value: string};
  lastName: string;
  locale: locale;
  phoneCountryCode: string;
  phoneNumber: string;
  pin: string;
  reEnterEmailId: string;
  reEnterPhoneNumber: string;
  reEnterPin: string;
  timezone: timeZone;
  isActive: boolean;
  country?: CountryFullName | string;
  isAgreeToDataRetention: boolean | undefined;
  isWhatsAppEnabled: boolean | undefined;
  bbRegion?: BBRegion;
}

export type locale = "en-US" | "en-GB" | "en-CA" | "es-MX" | "en-IN" | "ja-JP" | "es-CO" | "es-CR" | "pt-BR" | "en-EG" | "en-JO" | "en-SA" | "en-AE" | "ar-AE";

export type phoneCountryCode = "+1" | "+44" | "+20" | "+962";

export type timeZone =
"(GMT+00:00) Greenwich Mean Time (GMT)"
| "(GMT-08:00) Pacific Standard Time (America/Los_Angeles)";

export interface AlertMessage {
  alertType?: MessageBannerType;
  errorTitle?: { [key: string]: string } | string;
  errorMessageStringId?: string;
  errorMessage: { [key: string]: string } | string;
}

export type errorMessage = { key: string, value: string }

export interface BannerMessage {
  bannerType?: MessageBannerType;
  title?: string;
  message: React.ReactNode;
}

export interface AppSyncEpicError {
  errors: GraphQLError[]
  data?: any
}

export enum GeoUnit {
  KM = 'km',
  MILE = 'mi'
}

export enum Locale {
  UK = "en-GB",
  US = "en-US",
  ES_US = "es-US",
  ES_MX = "es-MX",
  EN_CA = "en-CA",
  FR_CA = "fr-CA",
  JP = "ja-JP",
  CO = "es-CO",
  CR = "es-CR",
  BR = "pt-BR",
  PT_CO = "pt-CO",
  EN_CO = "en-CO",
  EN_CR = "en-CR",
  EG = "en-EG",
  JO = "en-JO",
  SA = "en-SA",
  AE = "en-AE",
  AR_EG = "ar-AE",
  AR_SA = "ar-AE",
  AR_AE = "ar-AE"
}

export enum Language {
  UK = "English (UK)",
  US = "English",
  CA = "English",
  ES = "Spanish",
  MX = "Spanish",
  FR = "French",
  FR_CA = "French (Canadian)",
  JP = "Japanese",
  CO = "Spanish",
  CR = "Spanish",
  BR = "Portuguese (Brazil)",
  EG = "English",
  JO = "English",
  SA = "English",
  AE = "English",
  AR = "Arabic"
}

export interface GeoInfo {
  country: string | null;
  postalCode: string | null;
  lng: number | null;
  lat: number | null;
  label?: string | null;
  municipality?: string | null;
  region?: string | null;
  subRegion?: string | null;
  addressNumber?: string | null;
  key?: string | null;
  customPostal?: string | null;
  shownValue?: string | null;
}

export interface JobCard {
  jobId: string;
  language: string;
  dataSource: string | null;
  requisitionType: string | null;
  jobTitle: string | null;
  jobType: string | null;
  employmentType: string | null;
  city: string | null;
  state: string | null;
  locationName: string | null;
  postalCode: string | null;
  totalPayRateMin: number;
  totalPayRateMax: number;
  tagLine: string | null;
  distance: number | null;
  bannerText: string | null;
  image: string | null;
  jobPreviewVideo: string | null;
  featuredJob: boolean | null;
  bonusJob: boolean | null;
  bonusPay: number | null;
  scheduleCount: number | null;
  currencyCode: string | null;
  geoClusterDescription: string | null;
  surgePay: number | null;
  jobTypeL10N: string | null;
  employmentTypeL10N: string | null;
  bonusPayL10N: string | null;
  surgePayL10N: string | null;
  totalPayRateMinL10N: string | null;
  totalPayRateMaxL10N: string | null;
  distanceL10N: string | null;
}

export type JobCards = JobCard[];

export interface JobDetail {
  agencyName: string | null;
  jobId: string;
  language: string;
  dataSource: string;
  requisitionType: string | null;
  jobIdNumber: string | null;
  jobTitle: string | null;
  jobType: string | null;
  employmentType: string | null;
  fullAddress: string | null;
  country: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
  totalPayRateMin: number | null;
  totalPayRateMax: number | null;
  image: string | null;
  jobPreviewVideo: string | null;
  distance: number | null;
  featuredJob: boolean;
  bonusJob: boolean;
  tagLine: string | null;
  jobDescription: string | null;
  jobQualification: string | null;
  blackbirdPortalURL: string | null;
  careerPortalUrl: string | null;
  postingStatus: string | null;
  currencyCode: string | null;
  uiPath: string | null;
  siteId: string[] | null;
  locationDescription: string | null;
  locationName: string | null;
  jobBannerText: string | null;
  geoClusterId: string | null;
  geoClusterName: string | null;
  geoClusterRegion: string | null;
  geoClusterZone: string | null;
  geoClusterDescription: string | null;
  locationCode: string | null;
  complianceText: string | null;
  requiredLanguage?: string[] | null;
}

export type ScheduleCards = ScheduleCard[];

export interface ScheduleCard {
  scheduleId: string;
  jobId: string;
  bonusSchedule: boolean;
  dataSource: string;
  language: string;
  basePay: number;
  featuredSchedule: boolean;
  externalJobTitle: string | null;
  currencyCode: string | null;
  scheduleText: string | null;
  hoursPerWeek: number | null;
  firstDayOnSite: string | null;
  scheduleBannerText: string | null;
  scheduleType: string | null;
  employmentType: string | null;
  tagLine: string | null;
  image: string | null;
  jobPreviewVideo: string | null;
  address: string | null;
  city: string | null;
  state: string | null;
  signOnBonus: number | null;
  postalCode: string | null;
  distance: number | null;
  priorityRank: number | null;
  surgePay: number | null;
  geoClusterId: string | null;
  geoClusterName: string | null;
  siteId: string | null;
  scheduleBusinessCategory: string | null;
  totalPayRate: number | null;
  financeWeekStartDate: string | null;
  laborDemandAvailableCount: number | null;
  scheduleBusinessCategoryL10N: string | null;
  firstDayOnSiteL10N: string | null;
  financeWeekStartDateL10N: string | null;
  scheduleTypeL10N: string | null;
  employmentTypeL10N: string | null;
  basePayL10N: string | null;
  signOnBonusL10N: string | null;
  totalPayRateL10N: string | null;
  distanceL10N: string | null;
  requiredLanguage: string | null;
}

export interface AutoCompleteHint {
  hints: string[]
}

export enum Stage {
  Local = 'test',
  Devo = 'devo',
  Beta = 'beta',
  Gamma = 'gamma',
  PreProd = 'preprod',
  Prod = 'prod'
}

export enum MarketPlace {
  NA = 'NA',
  EU = 'EU',
  FE = 'FE',
}

export interface JobAlert {
  firstName: string
  lastName: string
  email: string
  phoneNumber?: string
  locale?: string
  country?: string
  latitude: number
  longitude: number
  distance?: number
  filters?: string,
  zipcode?: string
}

export interface CheckAuth {
  candidateId?: string;
  isValid?: boolean;
  message?: string;
  clientId?: string;
  sub?: string;
  exp?: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  phoneCountryCode?: string;
  locale?: string;
  language?: string;
  timezone?: string;
}

export interface QueryParams {
  query?: string;
  postal?: string;
  jobType?: string;
  employmentType?: string;
  employeeClass?: string;
  jobTitle?: string;
  distance?: string;
  locale?: string;
  latitude?: string;
  longitude?: string;
  country?: string;
  zipCode?: string;
  jobAlert?: string;
  isBonus?: string,
  shift?: string;
  fromVanity?: string;
}

export enum UKRoleProperty {
  WAREHOUSE_OPERATIVE = "Warehouse Operative",
  SORTATION_OPERATIVE = "Sortation Operative"
}

export enum EN_USRoleProperty {
  FULFILMENT_CENTER_ASSOCIATE = "Amazon Fulfillment Center Warehouse Associate",
  SORTATION_CENTER_ASSOCIATE = "Amazon Sortation Center Warehouse Associate",
  DELIVERY_STATION_ASSOCIATE = "Amazon Delivery Station Warehouse Associate",
  DISTRIBUTION_CENTER_ASSOCIATE = 'Amazon Distribution Center Associate',
  GROCERY_WAREHOUSE_ASSOCIATE = "Amazon Grocery Warehouse Associate",
  AIR_ASSOCIATE = "Amazon Air Associate",
  LOCKER_CUSTOMER_ASSOCIATE = "Amazon Locker+ Customer Service Associate",
  HOURLY_TEAM_MEMBER = "Amazon Hourly Team Member",
  WAREHOUSE_TEAM_MEMBER = "Amazon Warehouse Team Member",
  XL_WAREHOUSE_ASSOCIATE = "Amazon XL Warehouse Associate",
  SCOUT = "Amazon Scout Robotics Associate"
}

export enum ES_USRoleProperty {
  FULFILMENT_CENTER_ASSOCIATE = "Auxiliar de almacén de Centro de Cumplimiento de Amazon",
  SORTATION_CENTER_ASSOCIATE = "Auxiliar de Centro de Clasificación de Amazon",
  DELIVERY_STATION_ASSOCIATE = "Auxiliar de almacén en estación de entrega de Amazon ",
  DISTRIBUTION_CENTER_ASSOCIATE = 'Auxiliar del Centro de Distribución de Amazon',
  GROCERY_WAREHOUSE_ASSOCIATE = "Auxiliar de Almacén de Comestibles",
  AIR_ASSOCIATE = "Auxiliar Aire",
  LOCKER_CUSTOMER_ASSOCIATE = "Auxiliar de Atención al Cliente de Locker+",
  HOURLY_TEAM_MEMBER = "Miembro del equipo de trabajo por horas",
  WAREHOUSE_TEAM_MEMBER = "Miembro del equipo de almacén",
  XL_WAREHOUSE_ASSOCIATE = "Auxiliar de Almacén XL",
  SCOUT = "Auxiliar de Robótica de Amazon Scout"
}

export type RoleProperty = EN_USRoleProperty | UKRoleProperty | ES_USRoleProperty ;

export interface UKRoles {
  [UKRoleProperty.WAREHOUSE_OPERATIVE]: boolean,
  [UKRoleProperty.SORTATION_OPERATIVE]: boolean

  [key: string]: boolean
}

export interface USRoles {
  [EN_USRoleProperty.FULFILMENT_CENTER_ASSOCIATE]: boolean,
  [EN_USRoleProperty.SORTATION_CENTER_ASSOCIATE]: boolean,
  [EN_USRoleProperty.DELIVERY_STATION_ASSOCIATE]: boolean,
  [EN_USRoleProperty.DISTRIBUTION_CENTER_ASSOCIATE]: boolean,
  [EN_USRoleProperty.GROCERY_WAREHOUSE_ASSOCIATE]: boolean,
  [EN_USRoleProperty.AIR_ASSOCIATE]: boolean,
  [EN_USRoleProperty.LOCKER_CUSTOMER_ASSOCIATE]: boolean,
  [EN_USRoleProperty.HOURLY_TEAM_MEMBER]: boolean,
  [EN_USRoleProperty.WAREHOUSE_TEAM_MEMBER]: boolean,
  [EN_USRoleProperty.XL_WAREHOUSE_ASSOCIATE]: boolean,
  [EN_USRoleProperty.SCOUT]: boolean

  [key: string]: boolean
}

export type JobRoles = UKRoles | USRoles;

export interface ScheduleShift {
  value: ScheduleShiftValue;
  shownValue: string;
  selected: boolean;
}

export type ScheduleShifts = ScheduleShift[]

export enum ScheduleShiftValue {
  EarlyMorning = 'EarlyMorning',
  Daytime = 'Daytime',
  Evening = 'Evening',
  Night = 'Night',
  Weekday = 'Weekday',
  Weekend = 'Weekend',
}

export type InputType = 'number' | 'text' | 'email' | 'tel' | 'password' | 'url' | undefined;

export enum TimeRange {
  OneWeek = 'OneWeek',
  TwoWeeks = 'TwoWeeks',
}

export type i18nKeyWithDefaultStr = [stringKey: string, defaultString: string]

export interface PreferredLanguagesItem {
  key: string;
  value: Language;
  locale: Locale;
}

export type PreferredLanguagesList = PreferredLanguagesItem[]

export interface PreferredLanguagesMap {
  [CountryFullName.DEVO]: PreferredLanguagesList;
  [CountryFullName.UK]: PreferredLanguagesList;
  [CountryFullName.US]: PreferredLanguagesList;
  [CountryFullName.CA]: PreferredLanguagesList;
  [CountryFullName.MX]: PreferredLanguagesList;
  [CountryFullName.JP]: PreferredLanguagesList;

  [key: string]: PreferredLanguagesList;
}

export interface PrivacyNotice {
  key: string;
  text: string;
  urlLink: string;
}

export enum RunEnabledRegion {
  US_EAST_1 = 'us-east-1',
}

export const RumSensitiveInfoEnabledCountry = ['US'];

export type RumConfigType = {
  config: {
    sessionSampleRate: number;
    guestRoleArn: string;
    identityPoolId: string;
    endpoint: string;
    telemetries: string[];
    allowCookies: boolean;
    enableXRay: boolean;
    sessionEventLimit: number;
    disableAutoPageView: boolean;
  },
  applicationId: string;
  applicationVersion: string;
  applicationRegion: RunEnabledRegion;
};
