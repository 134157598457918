import React, { useEffect, useState } from 'react';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { H1, Text } from '@amzn/stencil-react-components/text';
import InputComponent from 'src/components/Registration/InputComponent';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { IconArrowLeft } from '@amzn/stencil-react-components/icons';
import { useHistory } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import isBoolean from 'lodash/isBoolean';
import { FORGOT_PIN_PAGES } from '../../utils/constants';
import { PAGE_ROUTE_LOGIN } from 'src/components/pageRoutes';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { boundAddAlert, boundRemoveAlerts } from 'src/actions/boundActions';
import { onUpdatePin } from 'src/utils/authService';
import {
  checkIfHalfRepeatedNumber,
  checkIfRepeatedNumber,
  checkIfSequentialNumber,
} from 'src/utils/registrationService';
import { AuthErrorMessages } from 'src/utils/errorMessages';
import { addAdobeMetric } from 'src/customerTracking/adobeAnalytics';
import { AdobeEvent, PageName } from 'src/customerTracking/adobeAnalytics/types';
import { isEmailFieldOptional } from 'src/utils/featureFlag/featureFlag';
import useHVHTranslation from 'src/Hooks/useHVHTranslation';

interface ResetPinProps {
  candidate: any;
  goToPage: Function;
  onNextTest?: Function;
  isPinUpdateSuccessOverride?: boolean;
}

export const ResetPin = ({ candidate, goToPage, onNextTest, isPinUpdateSuccessOverride }: ResetPinProps) => {
  const history: any = useHistory();
  const [isPinUpdateSuccess, setIsPinUpdateSuccess] = useState(
    isBoolean(isPinUpdateSuccessOverride) ? isBoolean(isPinUpdateSuccessOverride) : false,
  );
  const { t } = useHVHTranslation();

  useEffect(() => {
    if (isEmpty(candidate) || !candidate) {
      goToPage(FORGOT_PIN_PAGES.FORGOT_PIN);
    }
  }, [candidate, history]);

  useEffect(() => {
    // document.title = "Amazon Jobs - Reset pin";
    addAdobeMetric(AdobeEvent.PAGE_LOAD, PageName.RESET_PERSONAL_PIN);
  }, []);

  const [form, setForm] = useState<any>({});
  const [pinError, setPinError] = useState<any>({ hasError: false, errorText: '' });
  const [reEnterPinError, setReEnterPinError] = useState<any>({ hasError: false, errorText: '' });

  const updateForm = (targets: []) => {
    const currentForm = form;
    const newForm: any = { ...currentForm };
    targets.forEach((target: { key: string; value: any }) => {
      newForm[target.key] = target.value;
    });

    setForm(newForm);
  };

  const checkFormValuesExists = () => {
    if (isEmpty(form)) {
      return false;
    } else {
      return !isEmpty(form.otp) && !isEmpty(form.pin) && !isEmpty(form.reEnterPin);
    }
  };

  const onNext = () => {
    setIsPinUpdateSuccess(true);
    addAdobeMetric(AdobeEvent.PAGE_LOAD, PageName.PIN_RESET_SUCCESS);
  };

  const onSubmitPinClick = () => {
    boundRemoveAlerts();
    setPinError(false);
    setReEnterPinError(false);
    if (!checkFormValuesExists()) {
      boundAddAlert({
        errorMessage: t(
          AuthErrorMessages['empty-form-value-error-message'].key,
          AuthErrorMessages['empty-form-value-error-message'].value,
        ),
        errorMessageStringId: 'empty-form-value-error-message',
      });
    } else if (!RegExp(/^[0-9]{6,6}$/).test(form.pin)) {
      setPinError({
        hasError: true,
        errorText: t(
          AuthErrorMessages['pin-non-numeric-error-message'].key,
          AuthErrorMessages['pin-non-numeric-error-message'].value,
        ),
      });
    } else if (form.pin !== form.reEnterPin) {
      setReEnterPinError({
        hasError: true,
        errorText: t(
          AuthErrorMessages['pin-not-match-error-message'].key,
          AuthErrorMessages['pin-not-match-error-message'].value,
        ),
      });
    } else if (
      checkIfHalfRepeatedNumber(form.pin) ||
      checkIfRepeatedNumber(form.pin) ||
      checkIfSequentialNumber(form.pin)
    ) {
      setPinError({
        hasError: true,
        errorText: t(
          AuthErrorMessages['hvh-error-commonly-used-pin'].key,
          AuthErrorMessages['hvh-error-commonly-used-pin'].value,
        ),
      });
    } else {
      addAdobeMetric(AdobeEvent.BUTTON_CLICK_UPDATE, PageName.RESET_PERSONAL_PIN);
      onNextTest && onNextTest();
      onUpdatePin({ candidate, otp: form.otp, pin: form.pin, onNext });
    }
  };

  const goToForgotPinPage = () => {
    boundRemoveAlerts();
    setIsPinUpdateSuccess(false);
    goToPage(FORGOT_PIN_PAGES.FORGOT_PIN);
  };

  const goToLoginPage = () => {
    //clean the flag: isPinUpdateSuccess before goes to the page
    boundRemoveAlerts();
    setIsPinUpdateSuccess(false);
    history.push(`/${PAGE_ROUTE_LOGIN}`);
  };

  const labelText = isEmailFieldOptional()
    ? t(
        'ResetPin-label-Verify-phone-description',
        `Enter the verification code sent to {phoneCountryCode}{phoneNumber}. Look for a message with the subject "Your Amazon Jobs verification code"`,
        {
          phoneCountryCode: candidate.phoneCountryCode,
          phoneNumber: candidate.phoneNumber,
        },
      )
    : t(
        'ResetPin-label-Verify-email-description',
        `Enter the verification code sent to {email}. Look for an email with the subject "Your Amazon Jobs verification code"`,
        { email: candidate.emailId },
      );

  return (
    <Col gridGap="S300" className="hvhLogin" padding={{ left: 'S200', right: 'S200' }}>
      {isPinUpdateSuccess ? (
        <Col gridGap="S300" className="colContainerTop">
          <MessageBanner type={MessageBannerType.Success} aria-live="assertive" iconAltText="success">
            {t('ResetPin-label-Update-pin-success-message', 'Pin is successfully updated')}
          </MessageBanner>
          <Button dataTestId="button-back-to-login" onClick={goToLoginPage} variant={ButtonVariant.Primary}>
            {t('ResetPin-label-Back-to-login', 'Back to login')}
          </Button>
        </Col>
      ) : (
        <Col gridGap="S300" className="colContainerTop">
          <Row>
            <Button dataTestId="button-back" onClick={goToForgotPinPage} variant={ButtonVariant.Tertiary}>
              <Row alignItems="center">
                <IconArrowLeft aria-hidden />
                <Text>{t('ResetPin-label-Forgot-pin-link', 'Forgot personal pin')}</Text>
              </Row>
            </Button>
          </Row>
          <H1 fontSize="T400" fontWeight="regular" color="accent1">
            {t('ResetPin-title-Forgot-pin', 'Reset your personal pin')}
          </H1>
          <InputComponent
            id="otp"
            labelText={labelText}
            name="verificationCode"
            value={form.otp}
            loading={false}
            onChange={updateForm}
            maxLength={6}
          />
          <InputComponent
            id="pin"
            labelText={t('ResetPin-label-six-digit-pin', 'Create personal pin. A 6-digit number you can remember.')}
            name="pin"
            value={form.pin}
            loading={false}
            onChange={updateForm}
            inputType="password"
            maxLength={6}
            hasError={pinError.hasError}
            errorText={pinError.errorText}
          />
          <InputComponent
            id="reEnterPin"
            labelText={t('ResetPin-label-Re-enter-pin', 'Re-enter personal pin')}
            name="reEnterPin"
            value={form.reEnterPin}
            loading={false}
            onChange={updateForm}
            inputType="password"
            maxLength={6}
            hasError={reEnterPinError.hasError}
            errorText={reEnterPinError.errorText}
          />
          <Button dataTestId="button-update" onClick={onSubmitPinClick} variant={ButtonVariant.Primary}>
            {t('ResetPin-label-Update-pin-btn', 'Update')}
          </Button>
        </Col>
      )}
    </Col>
  );
};

export default ResetPin;
