import { Address } from "../commonTypes";
import { getDefaultLocale } from "../localization/localeHelper";
import { PreferredLanguageByCountry } from "../registrationFrom/constants";

export const getLanguageForPreferredLocale = (language: string | {key: string, value: string} | undefined) : string => {
    if (!language) {
        return "";
    }

    if (typeof language === 'string') {
        return language;
    }

    return language.value;
}

export const getCountry = (address: Address) => {
    if (!address.country) {
        return "";
    }

    return address.country;
}

export const getPreferredLocaleFromCountryAndLanguage = (language: string) => {
    const languageSelected = PreferredLanguageByCountry.find( lang => lang.value === language);
  
    return languageSelected?.locale || getDefaultLocale();
  };