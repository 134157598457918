import { VerifySignInRequest } from './apis/serviceApiTypes';
import { Candidate } from './commonTypes';
import {
  checkUsernameIsEmail,
  cleanPhoneNumberFormat,
  setConfirmOtpSession,
  setSignInUserSession,
  setUserId,
} from './helper';
import {
  LOGIN_TYPE,
  MultiplePhoneCountryCodeCountryList,
  OtpError,
  PinError,
  RumEventType,
  VerificationError,
} from './constants';
import {
  boundAddAlert,
  boundConfirmOtp,
  boundForgotPin,
  boundGetCandidate,
  boundLoadingStart,
  boundRemoveAlerts,
  boundResetPin,
  boundSignIn,
  boundSignOut,
  boundUpdateCandidate,
  boundUpdatePhone,
  boundVerifyEmail,
  boundVerifyPhone,
  boundVerifySignIn,
} from 'src/actions/boundActions';
import { getCountryCode, getSignOutRedirectUrl } from 'src/utils/apis/apiHelper';
import { AuthErrorMessages } from './errorMessages';
import 'url-polyfill';
import { emitEvent } from './rum';
import { addAdobeMetric } from 'src/customerTracking/adobeAnalytics';
import { AdobeEvent, PageName } from 'src/customerTracking/adobeAnalytics/types';
import { PhoneCodeByCountry } from 'src/utils/phoneCodes';
import { skipPhoneVerification } from 'src/utils/featureFlag/featureFlag';

export const onResendOtp = ({ candidate, loginType, pin }: any) => {
  boundRemoveAlerts();
  if (!candidate.candidateLogin || candidate.candidateLogin.length < 0) {
    emitEvent({
      event: RumEventType.RESEND_OTP,
      error: OtpError.NO_CANDIDATE_FOR_OTP,
      status: false,
      availability: 1,
    });
    boundAddAlert({
      errorMessage: AuthErrorMessages['error-resending-one-time-code-message'],
      errorMessageStringId: 'error-resending-one-time-code-message',
    });
  } else {
    boundSignIn(
      {
        user: candidate.candidateLogin,
        pin: pin,
        loginType: loginType,
      },
      (payload: any) => {
        setConfirmOtpSession(payload.session);
        emitEvent({
          event: RumEventType.RESEND_OTP,
          status: true,
          availability: 1,
        });
      },
      () => {
        emitEvent({
          event: RumEventType.RESEND_OTP,
          error: OtpError.RESEND_OTP_ERROR,
          status: false,
          availability: 0,
        });
        boundAddAlert({
          errorMessage: AuthErrorMessages['error-resending-one-time-code-message'],
          errorMessageStringId: 'error-resending-one-time-code-message',
        });
      },
    );
  }
};

export const onVerifyEmail = (
  candidate: Candidate,
  otp: string,
  setEmailVerified: Function,
  onVerifyEmail?: Function,
) => {
  boundRemoveAlerts();
  boundVerifyEmail(
    {
      candidate,
      otp,
    },
    (payload: any) => {
      if (payload.session) {
        setEmailVerified(false);
        setConfirmOtpSession(payload.session);
        emitEvent({
          event: RumEventType.VERIFY_EMAIL,
          error: VerificationError.FAILED_EMAIL_VERIFY,
          status: false,
          availability: 1,
        });
        return boundAddAlert({
          errorMessage: AuthErrorMessages['error-submitting-one-time-code-message'],
          errorMessageStringId: 'error-submitting-one-time-code-message',
        });
      } else {
        setEmailVerified(true);
        setSignInUserSession(payload.signInUserSession);
        setUserId(candidate.candidateLogin);
        emitEvent({
          event: RumEventType.VERIFY_EMAIL,
          status: true,
          availability: 1,
        });
        if (!candidate.isPhoneVerified) {
          // Go verify phone or not
          // will check UX flow and add later
        } else {
          // onCheckAuth() // TO dashboard
        }
      }
    },
    () => {
      setEmailVerified(false);
      emitEvent({
        event: RumEventType.VERIFY_EMAIL,
        error: VerificationError.VERIFY_EMAIL_ERROR,
        status: false,
        availability: 0,
      });
      boundAddAlert({
        errorMessage: AuthErrorMessages['error-submitting-one-time-code-message'],
        errorMessageStringId: 'error-submitting-one-time-code-message',
      });
    },
  );
  onVerifyEmail && onVerifyEmail();
};

interface EmailVerificationComplete {
  candidate: Candidate;
  pin?: string;
  onNext: Function;
  onEmailVerificationCompleteTest?: Function;
}
/* eslint-disable @typescript-eslint/no-unused-vars */
export const onEmailVerificationComplete = ({
  candidate,
  onNext,
  onEmailVerificationCompleteTest,
}: EmailVerificationComplete) => {
  boundRemoveAlerts();
  skipPhoneVerification() || candidate.isPhoneVerified
    ? onNext()
    : boundSignIn(
        {
          user: candidate.candidateLogin,
          pin: candidate.pin,
          loginType: LOGIN_TYPE.PHONE,
        },
        (payload: any) => {
          setConfirmOtpSession(payload.session);
          onNext();
        },
        () => {
          emitEvent({
            event: RumEventType.VERIFY_EMAIL,
            error: VerificationError.VERIFY_EMAIL_ERROR,
            status: false,
            availability: 0,
          });
          boundAddAlert({
            errorMessage: AuthErrorMessages['error-sending-one-time-code-message'],
            errorMessageStringId: 'error-sending-one-time-code-message',
          });
        },
      );
  onEmailVerificationCompleteTest && onEmailVerificationCompleteTest();
};

export const onVerifyPhone = (
  candidate: Candidate,
  otp: string,
  setPhoneVerified: Function,
  onVerifyPhone: Function,
) => {
  boundRemoveAlerts();
  boundVerifyPhone(
    {
      candidate,
      otp,
    },
    (payload: any) => {
      if (payload.session) {
        setPhoneVerified(false);
        // setRegistrationSession(payload.session);
        setConfirmOtpSession(payload.session);
        setUserId(candidate.candidateLogin);
        emitEvent({
          event: RumEventType.VERIFY_PHONE,
          error: VerificationError.FAILED_PHONE_VERIFY,
          status: false,
          availability: 1,
        });
        boundAddAlert({
          errorMessage: AuthErrorMessages['error-submitting-one-time-code-message'],
          errorMessageStringId: 'error-submitting-one-time-code-message',
        });
      } else {
        setPhoneVerified(true);
        setSignInUserSession(payload.signInUserSession);
        emitEvent({
          event: RumEventType.VERIFY_PHONE,
          status: true,
          availability: 1,
        });
      }
    },
    () => {
      setPhoneVerified(false);
      emitEvent({
        event: RumEventType.VERIFY_PHONE,
        error: VerificationError.VERIFY_PHONE_ERROR,
        status: false,
        availability: 0,
      });
      boundAddAlert({
        errorMessage: AuthErrorMessages['error-submitting-one-time-code-message'],
        errorMessageStringId: 'error-submitting-one-time-code-message',
      });
    },
  );
  onVerifyPhone && onVerifyPhone();
};

export const onSignOut = (redirectUrl = `${getSignOutRedirectUrl({})}`, callback?: Function, onError?: Function) => {
  boundRemoveAlerts();
  boundSignOut(
    {
      redirectUrl: redirectUrl,
    },
    (payload: any) => {
      callback && callback();
      if (payload && payload.result) {
        boundLoadingStart();
        window.location.assign(decodeURIComponent(redirectUrl));
      }
    },
    () => {
      onError && onError();
    },
  );
};

export const onGetCandidate = ({
  candidateLoginProp,
  onPhoneLogin,
  onInputPin,
  onVerifyRegistration,
}: {
  candidateLoginProp: string;
  onPhoneLogin: Function;
  onInputPin: Function;
  onVerifyRegistration: Function;
}) => {
  boundRemoveAlerts();
  const curCode = getCountryCode();
  let username = candidateLoginProp;
  const isLoginEmail = checkUsernameIsEmail(username);

  // don't need to do duplicated check as LoginPage already verify the input validity
  if (isLoginEmail || !MultiplePhoneCountryCodeCountryList.includes(curCode)) {
    if (!isLoginEmail) {
      if (!username.includes('+')) {
        const phoneCountryCode = PhoneCodeByCountry.get(curCode);
        username = phoneCountryCode + username; // add corresponding phone country code
      }
      username = cleanPhoneNumberFormat(username);
    }

    boundGetCandidate(username, (payload: any) => {
      boundUpdateCandidate({ candidateLogin: username });

      const { isEmailVerified, isPhoneVerified, isBBUser } = payload;

      if (!isEmailVerified && !isPhoneVerified && !isBBUser) {
        onVerifyRegistration();
      } else {
        onInputPin();
      }
    });
  } else {
    // Go to phone login and pass current phone number
    boundUpdateCandidate({ candidateLogin: username });
    onPhoneLogin();
  }
};

export const onGetCandidateWithPhoneNumber = ({
  phoneNumberWithPhoneCountryCode,
  onNext,
}: {
  phoneNumberWithPhoneCountryCode: string;
  onNext: Function;
}) => {
  boundRemoveAlerts();
  boundGetCandidate(
    phoneNumberWithPhoneCountryCode,
    () => {
      onNext();
    },
    () => {
      boundAddAlert({
        errorMessage: AuthErrorMessages['get-candidate-error-message'],
        errorMessageStringId: 'get-candidate-error-message',
      });
    },
  );
};

export const onVerifySignIn = (payload: VerifySignInRequest, onSuccess: Function) => {
  boundRemoveAlerts();
  boundVerifySignIn(payload, onSuccess);
};
/* eslint-disable @typescript-eslint/no-unused-vars */
export const onSignIn = ({ user, pin, loginType, onNext, onPinError }: any) => {
  boundRemoveAlerts();
  boundSignIn(
    {
      user: user,
      pin: pin,
      loginType: loginType,
    },
    (payload: any) => {
      setConfirmOtpSession(payload.session);
      onNext();
    },
    (errorResponse: any) => {
      if (errorResponse.errorMessageStringId === 'un-authorized-error') {
        onPinError();
      } else {
        boundAddAlert({
          errorMessage: AuthErrorMessages['error-sending-one-time-code-message'],
          errorMessageStringId: 'error-sending-one-time-code-message',
        });
      }
    },
  );
};

export const onConfirmOpt = ({
  candidate,
  otp,
  onConfirmOpt,
  onNext,
}: {
  candidate: Candidate;
  otp: string;
  onConfirmOpt?: Function;
  onNext: Function;
}) => {
  boundRemoveAlerts();
  boundConfirmOtp(
    {
      user: candidate.candidateLogin,
      otp,
    },
    (payload: any) => {
      const { signInUserSession, session } = payload;
      if (!signInUserSession) {
        setConfirmOtpSession(session);

        emitEvent({
          event: RumEventType.CONFIRM_OTP,
          status: false,
          error: OtpError.INVALID_OTP,
          availability: 1,
        });

        return boundAddAlert({
          errorMessage: AuthErrorMessages['error-submitting-one-time-code-message'],
          errorMessageStringId: 'error-submitting-one-time-code-message',
        });
      } else {
        emitEvent({
          event: RumEventType.CONFIRM_OTP,
          status: true,
          availability: 1,
        });

        setUserId(candidate.candidateLogin);
        setSignInUserSession(payload.signInUserSession);
        onNext();
      }
    },
    () => {
      boundAddAlert({
        errorMessage: AuthErrorMessages['error-submitting-one-time-code-message'],
        errorMessageStringId: 'Error-while-submitting-one-time-code',
      });
      addAdobeMetric(AdobeEvent.NEXT_BUTTON_CLICKED, PageName.OTP_CONFIRMED, {
        errorMessage: 'Error-while-submitting-one-time-code',
      });
    },
  );
  onConfirmOpt && onConfirmOpt();
};

export const onForgotPin = ({ candidateLogin, onNext }: { candidateLogin: string; onNext: Function }) => {
  boundGetCandidate(
    candidateLogin,
    () => {
      boundUpdateCandidate({ candidateLogin: candidateLogin });
      boundForgotPin(candidateLogin, () => {
        onNext();
      });
    },
    () => {
      emitEvent({
        event: RumEventType.UI_VALIDATION_ENTER_PIN,
        error: PinError.FORGOT_PIN_ERROR,
        status: false,
        availability: 0,
      });
      boundAddAlert({
        errorMessage: AuthErrorMessages['get-candidate-error-message'],
        errorMessageStringId: 'get-candidate-error-message',
      });
    },
  );
};

export const onUpdatePin = ({
  candidate,
  otp,
  pin,
  onNext,
}: {
  candidate: Candidate;
  otp: string;
  pin: string;
  onNext: Function;
}) => {
  boundResetPin(
    {
      otp: otp,
      pin: pin,
      user: candidate.candidateLogin,
    },
    () => {
      onNext();
    },
    () => {
      emitEvent({
        event: RumEventType.UI_VALIDATION_ENTER_PIN,
        error: PinError.UPDATE_PIN_ERROR,
        status: false,
        availability: 0,
      });
      boundAddAlert({
        errorMessage: AuthErrorMessages['reset-pin-error-message'],
        errorMessageStringId: 'reset-pin-error-message',
      });
    },
  );
};

export const onCheckPhoneNumber = (
  phoneNumber: string,
  phoneCountryCode: string,
  pin: string,
  candidateLogin: string,
  onNext: Function,
) => {
  boundRemoveAlerts();
  boundUpdatePhone(
    {
      phoneNumber: phoneNumber,
      phoneCountryCode: phoneCountryCode,
      pin: pin,
      candidateLogin: candidateLogin,
    },
    (payload: any) => {
      // setRegistrationSession(payload.session);
      setConfirmOtpSession(payload.session);
      onNext();
    },
    () => {
      boundAddAlert({
        errorMessage: AuthErrorMessages['error-sending-one-time-code-message'],
        errorMessageStringId: 'error-sending-one-time-code-message',
      });
    },
  );
};
