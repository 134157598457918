// This file is adapated from https://github.com/aws-samples/aws-waf-captcha-react-demo

import axios from "axios";
import { getCurrentLocale } from "../localization/localeHelper";

export function loadWAFEnv() {
  window.AWS_WAF_ENV = {
    JSAPI_URL: "{{CaptchaApiUrl}}",
    CAPTCHA_API_KEY: "{{CaptchaApiKey}}",
  };
}

export function getWAFEnv() {
  return window.AWS_WAF_ENV;
}

export function loadScript() {
  if (document.getElementById("AwsWAFScript")) return;

  const AwsWafScript = document.createElement("script");
  AwsWafScript.id = "AwsWAFScript";
  AwsWafScript.async = false;
  AwsWafScript.src = getWAFEnv().JSAPI_URL;
  document.head.appendChild(AwsWafScript);
}

export function useAWSWAFCaptchaAxios (onCaptchaEvent = console.log) {
  if (process.env.NODE_ENV === 'test') {
    return axios;
  }
  const captchaAxios = axios.create()
  function renderCaptcha () {
    document.body.style.cursor = 'wait'
    document.getElementById('captchaModalOverlay')!.style.display = 'block'
    document.getElementById('captchaModal')!.style.display = 'block'

    return new Promise((resolve) => {
      onCaptchaEvent('onCaptchaRequired')
      window.AwsWafCaptcha.renderCaptcha(document.getElementById('captchaForm'), {
        defaultLocale: getCurrentLocale(),
        onSuccess: (wafToken: string) => {
          document.getElementById('captchaModalOverlay')!.style.display = 'none'
          document.getElementById('captchaModal')!.style.display = 'none'
          onCaptchaEvent('onSuccess')
          resolve(wafToken)
        },
        onLoad: () => {
          document.body.style.cursor = 'default'
          onCaptchaEvent('onLoad')
        },
        onError: () => onCaptchaEvent('onError'),
        onPuzzleTimeout: () => onCaptchaEvent('onPuzzleTimeout'),
        onPuzzleIncorrect: () => onCaptchaEvent('onPuzzleIncorrect'),
        onPuzzleCorrect: () => onCaptchaEvent('onPuzzleCorrect'),

        apiKey: getWAFEnv().CAPTCHA_API_KEY
      })
    })
  }

  const captchaRequired = (error: any) =>
    error.response && error.response.status === 405 && error.response.headers['x-amzn-waf-action'] === 'captcha'

  // Use an Axios interceptor to render the CAPTCHA if the WAF requires it
  captchaAxios.interceptors.response.use(response => response, (error) => {
    if (captchaRequired(error)) {
      return renderCaptcha().then(token => {
        // add the header x-aws-waf-token: token if doing cross domain requests
        return captchaAxios.request(error.config);
      })
    } else return Promise.reject(error)
  })

  // Ensure a token exists before making the request
  captchaAxios.interceptors.request.use(config => {
    // skip setting token in cookie when waf script is not loaded yet
    if (!window.AwsWafIntegration) {
      return config;
    }
    return window.AwsWafIntegration.getToken().then((token) => {
      // add the header x-aws-waf-token: token if doing cross domain requests
      return config
    })
  }, _ => Promise.reject(_))

  return captchaAxios
}