import React from "react";
import { connect } from "react-redux";
import { translate as t } from "../../utils/translator";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Text } from "@amzn/stencil-react-components/text";
import { Button } from "@amzn/stencil-react-components/button";
import { Status, StatusIndicator, StatusIndicatorColorScheme } from "@amzn/stencil-react-components/status-indicator";
import { RegistrationForm } from "src/utils/commonTypes";
import { AuthErrorMessages } from "src/utils/errorMessages";
import { Link } from "@amzn/stencil-react-components/link";
import { constructPrivacyNoticeUri } from "src/utils/helper";

interface CAConsentProps {
    registrationForm: RegistrationForm
    setIsAgreeToDataRetention: Function
    setIsAgreeToCommunication: Function
    consentError: boolean[]
    consentToggleRef: React.RefObject<HTMLButtonElement>
}

interface MapStateToProps {
}

export const CAConsent = (props: CAConsentProps) => {
    const {
        registrationForm,
        setIsAgreeToCommunication,
        consentError,
        consentToggleRef
    } = props;

    return (
        <Col>
            <Col className="colContainer">
                <Row className="rowContainer" gridGap="S300">
                    <Text
                        color="accent1"
                        fontSize="T200"
                        textAlign="left"
                    >
                        {t('CAConsentComponent-paragraph-contact-sms-text-part1', 'Enable Amazon to send you SMS updates on your job applications and other postings that may be of interest. Message frequency varies. Message and data rates may apply. By enabling, you acknowledge that you agree to our')}&nbsp;
                        <Link href={`${constructPrivacyNoticeUri()}/sms-terms-of-service`} target="_blank" >{t('CAConsentComponent-paragraph-contact-sms-text-part2', 'Terms of Service and Privacy Notice.')}</Link>&nbsp;
                        {t('CAConsentComponent-paragraph-contact-sms-text-part3', 'You can withdraw consent at any time.')}
                    </Text>
                </Row>
                <Row gridGap='S300' padding={{top: 16}}>
                    <Button
                        className={`registrationToggleButton ${registrationForm.isAgreeToCommunication ? "selected" : ""}`}
                        dataTestId='communication-agree-button'
                        onClick={() => {
                            setIsAgreeToCommunication(true);
                        }}
                        ref={consentToggleRef}
                    >
                        {t("consent-yes-button", "Yes")}
                    </Button>
                    <Button
                        className={`registrationToggleButton ${registrationForm.isAgreeToCommunication === false ? "selected" : ""}`}
                        dataTestId='communication-disagree-button'
                        onClick={() => {
                            setIsAgreeToCommunication(false);
                        }}
                    >
                        {t("consent-no-button", "No")}
                    </Button>
                </Row>
                {consentError[1] && <Row>
                    <StatusIndicator
                        status={Status.Negative}
                        colorScheme={StatusIndicatorColorScheme.Default}
                        messageText={t(AuthErrorMessages["registration-consent-validation-error"].key, AuthErrorMessages["registration-consent-validation-error"].value)}
                    />
                </Row>}
            </Col>
        </Col>
    )
}

const mapStateToProps = (state: MapStateToProps) => {
    return state;
};

export default connect(mapStateToProps)(CAConsent);