import { from, Observable, of } from "rxjs";
import { flatMap, map, catchError } from "rxjs/internal/operators";
import { ofType } from "redux-observable";
import { signOutApi } from '../utils/apis/serviceApi';
import { parseErrorMessage } from "../utils/helper";
import { actionSignOutSuccess } from "../actions/actions";
import {
  SIGN_OUT,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILED,
  SIGN_OUT_CALLBACK_SUCCESS,
} from "../actions/actionTypes";

export const signOutEpic = (action$: Observable<any>) => {
  return action$.pipe(
    ofType(SIGN_OUT),
    flatMap((action: any) =>
      from<Promise<any>>(
        signOutApi(action.payload)
      ).pipe(
        flatMap(async (response) => {
          return response.data
        }),
        map((x) => actionSignOutSuccess(x, action.callback)),
        catchError((error) => {
            const response = error.response;
            if (action.onError) {
                action.onError(response);
            }
            return of({
                type: SIGN_OUT_FAILED,
                payload: parseErrorMessage(response.data),
            });
        })
      )
    )
  );
};

export const signOutSuccessEpic = (action$: Observable<any>) => {
    return action$.pipe(
      ofType(SIGN_OUT_SUCCESS),
      map((action) => {
        if (action.callback) {
          action.callback(action.payload);
        }
        return { type: SIGN_OUT_CALLBACK_SUCCESS, payload: null };
      })
    );
};