import React from 'react';
import {getCountryFullName} from 'src/utils/apis/apiHelper';
import { translate as t } from "../utils/translator"
import { IconQuestionCircle } from '@amzn/stencil-react-components/icons';
import {Row} from "@amzn/stencil-react-components/layout";
import { Link } from '@amzn/stencil-react-components/link';
import { getLoginHelpPage } from 'src/utils/helper';

const Helper = () => {
  // @ts-ignore
  return (
    <div className='helper'>
      <Row alignItems="center" gridGap={6}>
        <IconQuestionCircle size={14} />
        <Link id={"helper-link"}
          dataTestId="link-needHelp"
          href={`${getLoginHelpPage(getCountryFullName())}`} target="_blank" rel="noopener noreferrer"
        >
          {t("LoginPage-lbl-get-help", "Get help")}
        </Link>
      </Row>
    </div>
  );
}

export default Helper;
