import { from, Observable, of } from "rxjs";
import { ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/internal/operators";
import { getCommunicationPreference, updateCommunicationPreference} from "src/utils/apis/appSyncAPI/appSyncApi";
import { GetCommunicationPreferenceResponseGQL } from "src/utils/apis/appSyncAPI/types";
import { AppSyncCommunicationPreferenceMessages } from "src/utils/errorMessages";
import { createAppSyncEpicError } from "src/utils/helper";
import { AppSyncEpicError } from "src/utils/commonTypes";
import { GetCommunicationPreferenceAction, GET_COMMUNICATION_PREFERENCE_TYPE, UpdateCommunicationPreferenceAction, UPDATE_COMMUNICATION_PREFERENCE_TYPE } from "src/actions/CommunicationPreferenceActions/communicationPreferenceActionTypes";
import { actionGetCommunicationPreferenceFailed, actionGetCommunicationPreferenceSuccess, actionUpdateCommunicationPreferenceFailed, actionUpdateCommunicationPreferenceSuccess } from "src/actions/CommunicationPreferenceActions/communicationPreferenceActions";

export const getCommunicationPreferenceEpic = (action$: Observable<any>) => {
    return action$.pipe(
        ofType(GET_COMMUNICATION_PREFERENCE_TYPE.GET),
        switchMap((action: GetCommunicationPreferenceAction) =>
            from(getCommunicationPreference({ bbCandidateId: action.payload.bbCandidateId, accessToken: action.payload.accessToken}))
                .pipe(
                    switchMap(async (response) => {
                        if(!response.data) throw createAppSyncEpicError('ERR_RESPONSE_SERVICE');
                        return response.data
                    }),
                    map((data: GetCommunicationPreferenceResponseGQL) => {
                        const communicationPreference = data.queryCommunicationPreference;
                        return actionGetCommunicationPreferenceSuccess(communicationPreference);
                    }),
                    catchError((error: AppSyncEpicError) => {
                        const errorCode = error.errors ? error.errors[0].message : 'DEFAULT';
                        return of(actionGetCommunicationPreferenceFailed({
                            errorMessage: AppSyncCommunicationPreferenceMessages[errorCode] || AppSyncCommunicationPreferenceMessages.DEFAULT
                        }));
                    })
                )
        )
    )
};

export const updateCommunicationPreferenceEpic = (action$: Observable<any>) => {
    return action$.pipe(
        ofType(UPDATE_COMMUNICATION_PREFERENCE_TYPE.UPDATE),
        switchMap((action: UpdateCommunicationPreferenceAction) =>
            from(updateCommunicationPreference({...action.payload}))
                .pipe(
                    switchMap(async (response) => {
                        if(!response.data) throw createAppSyncEpicError('ERR_RESPONSE_SERVICE');
                        return response.data
                    }),
                    map(() => {
                        if (action.callback) action.callback();

                        const newCommunicationPreference = action.payload.newCommunicationPreference;
                        return actionUpdateCommunicationPreferenceSuccess(newCommunicationPreference);
                    }),
                    catchError((error: AppSyncEpicError) => {
                        const errorCode = error.errors ? error.errors[0].message : 'DEFAULT';
                        return of(actionUpdateCommunicationPreferenceFailed({
                            errorMessage: AppSyncCommunicationPreferenceMessages[errorCode] || AppSyncCommunicationPreferenceMessages.DEFAULT
                        }));
                    })
                )
        )
    )
};
