import store from "../store/store";
import {
    actionGetConfig,
    actionCheckAuth,
    actionUpdateRegistrationForm,
    actionUpdateCandidate,
    actionResetCandidate,
    actionGetCandidate,
    actionAddAlert,
    actionRemoveAlerts,
    actionRemoveStickyAlerts,
    actionCreateCandidate,
    actionVerifyEmail,
    actionVerifyPhone,
    actionVerifySignIn,
    actionSignIn,
    actionSignOut,
    actionConfirmOtp,
    actionForgotPin,
    actionResetPin,
    actionCheckState,
    actionLoadingStart,
    actionLoadingEnd,
    actionUpdatePhone,
    actionDismissGlobalAlerts,
    actionGetCSRF,
    actionResetRequest,
    actionAddStickyAlerts,
    actionHideAlerts
} from "./actions";
import {
    CheckAuthRequest,
    VerifySignInRequest
} from "../utils/apis/serviceApiTypes";
import {
    RegistrationForm,
    CandidateUpdate, AlertMessage
} from "../utils/commonTypes";
import { getWafToken } from "src/utils/wafHelper";

export const boundGetConfig = (
    payload?: any,
    callback?: Function,
) => store.dispatch(actionGetConfig(payload, callback));

export const boundGetCSRF = (
    callback?: Function,
) => store.dispatch(actionGetCSRF(callback));

export const boundCheckAuth = (
    payload: CheckAuthRequest,
    callback?: Function,
    onError?: Function,
) => store.dispatch(actionCheckAuth(payload, callback, onError));

export const boundUpdateRegistrationForm = (
    payload: RegistrationForm,
    callback?: Function,
) => store.dispatch(actionUpdateRegistrationForm(payload, callback));

export const boundUpdateCandidate = (
    payload: CandidateUpdate,
    callback?: Function,
) => store.dispatch(actionUpdateCandidate(payload, callback));

export const boundGetCandidate = async (
    payload: any,
    callback?: Function,
    onError?: Function,
) => {
    await getWafToken();
    store.dispatch(actionGetCandidate(payload, callback, onError));
};

export const boundResetCandidate = (
    callback?: Function,
) => store.dispatch(actionResetCandidate(callback));

export const boundAddAlert = (
    payload: any,
    callback?: Function,
) => store.dispatch(actionAddAlert(payload, callback));

export const boundDismissGlobalAlert = () => store.dispatch(actionDismissGlobalAlerts());

export const boundRemoveAlerts = (
    callback?: Function,
) => store.dispatch(actionRemoveAlerts());

export const boundAddStickyAlerts = (
    payload: AlertMessage,
    callback?: Function,
) => store.dispatch(actionAddStickyAlerts(payload, callback));

export const boundRemoveStickyAlerts = (
    callback?: Function,
) => store.dispatch(actionRemoveStickyAlerts(callback))

export const boundHideAlerts = () => store.dispatch(actionHideAlerts());

export const boundCreateCandidate = async (
    payload: any,
    callback?: Function,
    onError?: Function,
) => {
    await getWafToken();
    store.dispatch(actionCreateCandidate(payload, callback, onError));
}

export const boundVerifyEmail = (
    payload: any,
    callback?: Function,
    onError?: Function,
) => store.dispatch(actionVerifyEmail(payload, callback, onError));

export const boundVerifyPhone = (
    payload: any,
    callback?: Function,
    onError?: Function,
) => store.dispatch(actionVerifyPhone(payload, callback, onError));

export const boundSignIn = async (
    payload: any,
    callback?: Function,
    onError?: Function,
) => {
    await getWafToken();
    store.dispatch(actionSignIn(payload, callback, onError));
};

export const boundVerifySignIn = async (
    payload: VerifySignInRequest,
    onSuccess?: Function,
) => {
    await getWafToken();
    store.dispatch(actionVerifySignIn(payload, onSuccess));
}

export const boundSignOut = (
    payload?: any,
    callback?: Function,
    onError?: Function,
) => store.dispatch(actionSignOut(payload, callback, onError));

export const boundConfirmOtp = (
    payload: any,
    callback?: Function,
    onError?: Function,
) => store.dispatch(actionConfirmOtp(payload, callback, onError));

export const boundForgotPin = (
    payload: any,
    callback?: Function,
    onError?: Function,
) => store.dispatch(actionForgotPin(payload, callback, onError));

export const boundResetPin = (
    payload: any,
    callback?: Function,
    onError?: Function,
) => store.dispatch(actionResetPin(payload, callback, onError));

export const boundCheckState = (
    payload?: any,
    callback?: Function,
    onError?: Function,
) => store.dispatch(actionCheckState(payload, callback, onError));

export const boundLoadingStart = (
    callback?: Function,
    onError?: Function,
) => store.dispatch(actionLoadingStart(callback, onError));

export const boundLoadingEnd = (
    callback?: Function,
    onError?: Function,
) => store.dispatch(actionLoadingEnd(callback, onError));

export const boundUpdatePhone = (
    payload: any,
    callback?: Function,
    onError?: Function,
) => store.dispatch(actionUpdatePhone(payload, callback, onError));

export const boundResetRequest = () => store.dispatch(actionResetRequest());
