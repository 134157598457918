import {
  GET_CONFIG,
  GET_CONFIG_SUCCESS,
  UPDATE_REGISTRATION_FORM,
  UPDATE_CANDIDATE,
  RESET_CANDIDATE,
  GET_CANDIDATE,
  GET_CANDIDATE_SUCCESS,
  ADD_ALERT,
  REMOVE_ALERTS,
  REMOVE_STICKY_ALERTS,
  CREATE_CANDIDATE,
  CREATE_CANDIDATE_SUCCESS,
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_PHONE,
  VERIFY_PHONE_SUCCESS,
  VERIFY_SIGN_IN,
  VERIFY_SIGN_IN_SUCCESS,
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_OUT,
  SIGN_OUT_SUCCESS,
  CONFIRM_OTP,
  CONFIRM_OTP_SUCCESS,
  FORGOT_PIN,
  FORGOT_PIN_SUCCESS,
  RESET_PIN,
  RESET_PIN_SUCCESS,
  CHECK_STATE,
  LOADIND_START,
  LOADIND_END,
  UPDATE_PHONE,
  UPDATE_PHONE_SUCCESS,
  DISMISS_GLOBAL_ALERTS,
  GET_CSRF,
  GET_CSRF_SUCCESS,
  RESET_REQUEST,
  VERIFY_SIGN_IN_FAILED,
  ADD_STICKY_ALERTS,
  HIDE_ALERTS,
} from './actionTypes';
import { VerifySignInRequest } from '../utils/apis/serviceApiTypes';
import { RegistrationForm, CandidateUpdate, AlertMessage } from '../utils/commonTypes';

export function actionGetConfig(payload?: any, callback?: Function) {
  return { type: GET_CONFIG, payload, callback };
}

export function actionGetConfigSuccess(payload?: any, callback?: Function) {
  return { type: GET_CONFIG_SUCCESS, payload, callback };
}

export function actionGetCSRF(callback?: Function) {
  return { type: GET_CSRF, callback };
}

export function actionGetCSRFSuccess(payload?: any, callback?: Function) {
  return { type: GET_CSRF_SUCCESS, payload, callback };
}

export function actionUpdateRegistrationForm(payload: RegistrationForm, callback?: Function) {
  return { type: UPDATE_REGISTRATION_FORM, payload, callback };
}

export function actionGetCandidate(payload?: any, callback?: Function, onError?: Function) {
  return { type: GET_CANDIDATE, payload, callback, onError };
}

export function actionGetCandidateSuccess(payload?: any, callback?: Function) {
  return { type: GET_CANDIDATE_SUCCESS, payload, callback };
}

export function actionResetCandidate(callback?: Function) {
  return { type: RESET_CANDIDATE, callback };
}

export function actionUpdateCandidate(payload: CandidateUpdate, callback?: Function) {
  return { type: UPDATE_CANDIDATE, payload, callback };
}

export function actionAddAlert(payload: any, callback?: Function) {
  return { type: ADD_ALERT, payload, callback };
}

export function actionRemoveAlerts(callback?: Function) {
  return { type: REMOVE_ALERTS, callback };
}

export function actionAddStickyAlerts(payload: AlertMessage, callback?: Function) {
  return { type: ADD_STICKY_ALERTS, payload, callback };
}

export function actionRemoveStickyAlerts(callback?: Function) {
  return { type: REMOVE_STICKY_ALERTS, callback };
}

export function actionDismissGlobalAlerts() {
  return { type: DISMISS_GLOBAL_ALERTS };
}

export function actionHideAlerts() {
  return { type: HIDE_ALERTS };
}

export function actionCreateCandidate(payload?: any, callback?: Function, onError?: Function) {
  return { type: CREATE_CANDIDATE, payload, callback, onError };
}

export function actionCreateCandidateSuccess(payload?: any, callback?: Function) {
  return { type: CREATE_CANDIDATE_SUCCESS, payload, callback };
}

export function actionVerifyEmail(payload: any, callback?: Function, onError?: Function) {
  return { type: VERIFY_EMAIL, payload, callback, onError };
}

export function actionVerifyEmailSuccess(payload?: any, callback?: Function) {
  return { type: VERIFY_EMAIL_SUCCESS, payload, callback };
}

export function actionVerifyPhone(payload?: any, callback?: Function, onError?: Function) {
  return { type: VERIFY_PHONE, payload, callback, onError };
}

export function actionVerifyPhoneSuccess(payload?: any, callback?: Function) {
  return { type: VERIFY_PHONE_SUCCESS, payload, callback };
}

export function actionVerifySignIn(payload: VerifySignInRequest, onSuccess?: Function) {
  return { type: VERIFY_SIGN_IN, payload, onSuccess };
}

export function actionVerifySignInSuccess(payload: { phoneNumber: string; emailId: string }) {
  return { type: VERIFY_SIGN_IN_SUCCESS, payload };
}

export function actionVerifySignInFail(payload: { errorMessage: string; errorMessageStringId?: string }) {
  return { type: VERIFY_SIGN_IN_FAILED, payload };
}

export function actionSignIn(payload?: any, callback?: Function, onError?: Function) {
  return { type: SIGN_IN, payload, callback, onError };
}

export function actionSignInSuccess(payload?: any, callback?: Function) {
  return { type: SIGN_IN_SUCCESS, payload, callback };
}

export function actionSignOut(payload?: any, callback?: Function, onError?: Function) {
  return { type: SIGN_OUT, payload, callback, onError };
}

export function actionSignOutSuccess(payload?: any, callback?: Function) {
  return { type: SIGN_OUT_SUCCESS, payload, callback };
}

export function actionConfirmOtp(payload: any, callback?: Function, onError?: Function) {
  return { type: CONFIRM_OTP, payload, callback, onError };
}

export function actionConfirmOtpSuccess(payload: any, callback?: Function) {
  return { type: CONFIRM_OTP_SUCCESS, payload, callback };
}

export function actionForgotPin(payload: any, callback?: Function, onError?: Function) {
  return { type: FORGOT_PIN, payload, callback, onError };
}

export function actionForgotPinSuccess(payload: any, callback?: Function) {
  return { type: FORGOT_PIN_SUCCESS, payload, callback };
}

export function actionResetPin(payload: any, callback?: Function, onError?: Function) {
  return { type: RESET_PIN, payload, callback, onError };
}

export function actionResetPinSuccess(payload: any, callback?: Function) {
  return { type: RESET_PIN_SUCCESS, payload, callback };
}

export function actionCheckState(payload?: any, callback?: Function, onError?: Function) {
  return { type: CHECK_STATE, payload, callback, onError };
}

export function actionLoadingStart(callback?: Function, onError?: Function) {
  return { type: LOADIND_START, callback, onError };
}

export function actionLoadingEnd(callback?: Function, onError?: Function) {
  return { type: LOADIND_END, callback, onError };
}

export function actionUpdatePhone(payload: any, callback?: Function, onError?: Function) {
  return { type: UPDATE_PHONE, payload, callback, onError };
}

export function actionUpdatePhoneSuccess(payload: any, callback?: Function, onError?: Function) {
  return { type: UPDATE_PHONE_SUCCESS, payload, callback, onError };
}

export function actionResetRequest() {
  return { type: RESET_REQUEST };
}
