import {globalLocaleConfigMap, HVH_LOCALE} from "src/utils/localization/constants";
import {getStage, parseSearchParamFromHash} from "src/utils/apis/apiHelper";
import {
    IHVHLocaleConfiguration,
    IHVHLocaleSetting,
    LocaleOption,
    LocaleOptions
} from "src/utils/localization/types";
import { Stage } from "src/utils/commonTypes";
import pickBy from "lodash/pickBy";
import Cookies from "js-cookie";
import { getCountryCodeSession, setToSessionStorage } from "src/utils/helper";

export const getCurrentLocale = (): string => {
    const queryParams = parseSearchParamFromHash(window.location.hash);
    const { locale } = queryParams;

    const validLocales = getValidLocales();
    if (locale && validLocales.includes(locale)){
        Cookies.set(HVH_LOCALE, locale, { secure: true });
        return locale;
    }

    const cookieLocale = Cookies.get(HVH_LOCALE);
    if (cookieLocale && validLocales.includes(cookieLocale)) {
        return cookieLocale;
    }

    // Get the user's preferred languages and take the first locale as their default
    const preferredLanguage = getPreferredLanguage();
    if (preferredLanguage) {
        Cookies.set(HVH_LOCALE, preferredLanguage, { secure: true });
        setToSessionStorage('locale', preferredLanguage)
        return preferredLanguage;
    }

    const currentLocale = getDefaultLocale();
    Cookies.set(HVH_LOCALE, currentLocale, { secure: true });
    return currentLocale;
}

// Get the user's preferred languages and take the first locale as their default
export const getPreferredLanguage = () => {
    const userLanguages = navigator.languages;
    return userLanguages[0];
}

export const getCurrentLocaleDisplayName = (): string => {
    const supportedLocales: { [key: string] : IHVHLocaleSetting } = getSupportedLocales();
    const currentLocale = getCurrentLocale();

    return supportedLocales[currentLocale]["display-name"]
}

export const getLocaleConfig = (countryCodeOverride?: string): IHVHLocaleConfiguration => {
    const countryCode = countryCodeOverride||getCountryCodeSession();
    return globalLocaleConfigMap[countryCode];
}

export const getDefaultLocale = (): string => {
    const localeSetting = getLocaleConfig();
    return localeSetting["default-locale"];
}

export const getSupportedLocales = (): { [key: string] : IHVHLocaleSetting } => {
    const stage = getStage();
    const localeConfig =  getLocaleConfig();
    const localeSettings = localeConfig.locales;
    return pickBy(localeSettings, (value) => {
        if (stage !== Stage.PreProd && stage !== Stage.Prod) {
            // For non-prod environments, we want to consider all locales are supported on UI.
            return true;
        }

        // For Preprod and Prod, we only consider GA locales to be supported.
        return value.status === 'GA'
    });
}

export const getLocaleOptions = (): LocaleOptions => {
    const supportedLocales: { [key: string] : IHVHLocaleSetting } = getSupportedLocales();
    const localOptions: LocaleOptions = [];

    Object.keys(supportedLocales).forEach((key) => {
        const localeOption: LocaleOption = {
            locale: key,
            'mobile-display-name': key.substring(0, 2).toUpperCase(),
            'display-name': supportedLocales[key]["display-name"]
        };

        localOptions.push(localeOption);
    });

    return localOptions;
}

export const getValidLocales = ():string[] => {
    const supportedLocales: { [key: string] : IHVHLocaleSetting } = getSupportedLocales();
    return Object.keys(supportedLocales);
}
