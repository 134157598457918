import React, { useEffect, useState } from "react";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Text } from "@amzn/stencil-react-components/text";
import { translate as t } from "../../utils/translator";
import InputComponent from "../Registration/InputComponent";
import { Link } from "@amzn/stencil-react-components/link";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { IconArrowLeft } from "@amzn/stencil-react-components/icons";
import { useHistory } from "react-router";
import isEmpty from "lodash/isEmpty";
import { CheckboxComponent } from "./CheckboxComponent";
import { boundRemoveAlerts } from "src/actions/boundActions";
import { Candidate } from "src/utils/commonTypes";
import { PinError, RumEventType, SIGN_IN_PAGES } from "../../utils/constants";
import { PAGE_ROUTE_FORGOT_PIN,} from "../pageRoutes";
import { AuthErrorMessages } from "src/utils/errorMessages";
import { onVerifySignIn } from 'src/utils/authService'
import { getCountryFullName } from "src/utils/apis/apiHelper";
import { emitEvent } from "src/utils/rum";
import { getCountryCode } from "src/utils/apis/apiHelper";
import { getLoginHelpPage, getPath } from "src/utils/helper";
import {addAdobeMetric} from "src/customerTracking/adobeAnalytics";
import {AdobeEvent, PageName} from "src/customerTracking/adobeAnalytics/types";

interface PinProps {
  candidate: Candidate;
  goToPage: Function;
  pin: string;
  setPin: Function;
  testUser?: any;
  router?:any;
}
export const PinPage = ({
  candidate,
  goToPage,
  pin,
  setPin,
  testUser,
}: PinProps) => {
  const user = testUser
                ? testUser
                : candidate.candidateLogin;
  const history: any = useHistory();
  useEffect(() => {
    // document.title = "Amazon Jobs - Login Pin";
    addAdobeMetric(AdobeEvent.PAGE_LOAD, PageName.PERSONAL_PIN_ENTRY);
  }, []);

  useEffect(() => {
    if (isEmpty(user)) {
      goToPage(SIGN_IN_PAGES.LOGIN);
    }
  }, [user, history]);

  const [showPin, setShowPin] = useState<boolean>(false);
  const [error, setError] = useState<any>({});

  const onPinChange = (targets:any[]) => {
    const data = targets[0];
    setPin(data.value);
  };

  const goToForgotPinPage = () => {
    history.push(`/${PAGE_ROUTE_FORGOT_PIN}`);
  };

  const onSubmitPin = () => {
    boundRemoveAlerts();
    const path = getPath();

    if (isEmpty(pin)) {
      setError({
        hasError: true,
        message: t(AuthErrorMessages["empty-pin-error-message"].key, AuthErrorMessages["empty-pin-error-message"].value),
      });

      emitEvent(
        {
          event: RumEventType.UI_VALIDATION_ENTER_PIN,
          status: false,
          error: PinError.EMPTY_PIN,
          country: getCountryCode(),
          path
        }
      );
    } else if (!RegExp(/^[0-9]{6,6}$/).test(pin)) {
      emitEvent(
        {
          event: RumEventType.UI_VALIDATION_ENTER_PIN,
          status: false,
          error: PinError.NON_NUMERIC_PIN,
          country: getCountryCode(),
          path
        }
      );

      setError({
        hasError: true,
        message: t(AuthErrorMessages["pin-non-numeric-error-message"].key, AuthErrorMessages["pin-non-numeric-error-message"].value),
      });
    } else {
      setError({
        hasError: false,
        message: "",
      });

      emitEvent(
        {
          event: RumEventType.UI_VALIDATION_ENTER_PIN,
          status: true,
          country: getCountryCode(),
          path
        }
      );

      onNext();
    }
  };

  const onNext = () => {
    addAdobeMetric(AdobeEvent.NEXT_BUTTON_CLICKED, PageName.PERSONAL_PIN_ENTRY);
    onVerifySignIn(
      { user, pin },
      () => goToPage(SIGN_IN_PAGES.OTP_TYPE)
    );
  };

  const goToLoginPage = () => {
    boundRemoveAlerts();
    goToPage(SIGN_IN_PAGES.LOGIN);
  };

  return (
    <Col 
      gridGap="S300"
      className="hvhLogin"
      padding={{left:'S200',right:'S200'}}
    >
      <Col gridGap="S300" className="colContainerTop">
        <Row>
          <Button 
            dataTestId="button-back"
            onClick={goToLoginPage}
            variant={ButtonVariant.Tertiary}
          >
            <Row alignItems="center">
              <IconArrowLeft aria-hidden={true} />
              <Text>{t("PinPage-lbl-back-button", "back")}</Text>
            </Row>
          </Button>
        </Row>

        <InputComponent
          labelText={t("PinPage-title-pin", "Enter your personal pin")}
          id="pin"
          name="pin"
          value={pin}
          loading={false}
          onChange={onPinChange}
          inputType={showPin ? "text" : "password"}
          maxLength={6}
          hasError={error.hasError}
          errorText={error.message}
        />
        <CheckboxComponent 
          labelText={t("PinPage-lbl-show-pin", "Show personal pin")}
          onChange={setShowPin}
          alignItems="center"
          color="accent2"
        />
        <Link 
          dataTestId="link-forgotPin"
          fontSize="T200" 
          onClick={goToForgotPinPage} 
          tabIndex={0}
        >
          {t("PinPage-lbl-forgot-pin", "Forgot your personal pin?")}
        </Link>
        <Link
            dataTestId="link-needHelp"
            href={`${getLoginHelpPage(getCountryFullName())}`} target="_blank"
            fontSize="T200"
        >
          {t("PinPage-lbl-need-help-login", "Need help to login?")}
        </Link>
        <Button 
          dataTestId="button-continue"
          onClick={onSubmitPin} variant={ButtonVariant.Primary}>
          {t("PinPage-lbl-submit-pin-button", "Continue")}
        </Button>
      </Col>
    </Col>
  );
};

export default PinPage;
