import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { loadScript } from "../utils/captcha";

export function AWSWAFCaptchaModal() {
  useEffect(() => loadScript());

  return createPortal(
    <div className="captcha-overlay" id="captchaModalOverlay" data-testid="captchaModalOverlay" >
      <div className="captcha-modal" id="captchaModal" data-testid="captchaModal">
        <div id="captchaForm" data-testid="captchaForm" />
      </div>
    </div>,
    document.body
  );
}
