import React from "react";
import { connect } from "react-redux";
import { translate as t } from "../../utils/translator";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Text } from "@amzn/stencil-react-components/text";
import { Button } from "@amzn/stencil-react-components/button";
import { Link } from '@amzn/stencil-react-components/link';
import { Status, StatusIndicator, StatusIndicatorColorScheme } from "@amzn/stencil-react-components/status-indicator";
import {PrivacyNotice, RegistrationForm} from "src/utils/commonTypes";
import { AuthErrorMessages } from "src/utils/errorMessages";

interface UKConsentProps {
  registrationForm: RegistrationForm
  setIsAgreeToDataRetention: Function
  setIsAgreeToCommunication: Function
  consentError: boolean[]
  consentToggleRef1: React.RefObject<HTMLButtonElement>
  consentToggleRef2: React.RefObject<HTMLButtonElement>
  privacyNotice: PrivacyNotice
}
  
interface MapStateToProps {
}

export const UKConsent = (
  props: UKConsentProps
) => {
  const {
    registrationForm,
    setIsAgreeToDataRetention,
    setIsAgreeToCommunication,
    consentError,
    consentToggleRef1,
    consentToggleRef2,
    privacyNotice
  } = props;
  return(
    <>
      <Col className="colContainerTop">
        <Row className="rowContainer" gridGap="S300">
          <Text
            color="accent1"
            fontSize="T200"
            textAlign="left"
          >
            {t("UKConsentComponent-paragraph-consent-disclaimer-before-link", "By selecting 'Yes', you allow Amazon, its affiliated entities, and hiring agencies to retain and use your application data (i.e. the data you submit in the course of your application) to consider and contact you for other job opportunities in the future. If you provide your consent, your data will be retained and used for these purposes up to 7 years from your last application, depending on country-specific requirements. You can revoke your consent at any time by changing your  consent settings in “My Personal Information” section on the job portal. You can apply for other jobs in the future even if you do not provide consent. Please refer to our") + " "}
            <Link
              href={privacyNotice.urlLink}
              target="_blank"
            >{t(privacyNotice.key, privacyNotice.text)}
            </Link>
            {" " + t("UKConsentComponent-paragraph-conset-disclaimer-after-link", "for more information on how we manage your personal data.")}
          </Text>
        </Row>
        <Row gridGap="S300" padding={{ top: 16 }}>
          <Button
            className={`registrationToggleButton ${registrationForm.isAgreeToDataRetention ? "selected" : ""}`}
            dataTestId="data-retention-agree-button"
            onClick={() => setIsAgreeToDataRetention(true)}
            ref={consentToggleRef1}
          >
            {t("consent-yes-button", "Yes")}
          </Button>
          <Button
            className={`registrationToggleButton ${registrationForm.isAgreeToDataRetention === false ? "selected" : ""}`}
            dataTestId="data-retention-disagree-button"
            onClick={() => setIsAgreeToDataRetention(false)}
          >
            {t("consent-no-button", "No")}
          </Button>
        </Row>
        {consentError[0] && (
          <Row>
            <StatusIndicator
              status={Status.Negative}
              colorScheme={StatusIndicatorColorScheme.Default}
              messageText={t(AuthErrorMessages["registration-consent-validation-error"].key, AuthErrorMessages["registration-consent-validation-error"].value)}
            />
          </Row>
        )}
      </Col>
      <Col className="colContainer">
        <Row className="rowContainer" gridGap="S300">
          <Text
            color="accent1"
            fontSize="T200"
            textAlign="left"
          >
            {t("UKConsentComponent-paragraph-contact-disclaimer-before-link", "By clicking 'Next', you may be contacted by phone or email by or on behalf of Amazon about your application. To opt out please refer to our") + " "} <Link href={privacyNotice.urlLink} target="_blank">{t(privacyNotice.key, privacyNotice.text)}</Link>.
          </Text>
        </Row>
      </Col>
    </>
  );
};

const mapStateToProps = (state: MapStateToProps) => {
  return state;
};

export default connect(mapStateToProps)(UKConsent);