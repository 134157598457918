import { VerifySignInRequest } from "./apis/serviceApiTypes";
import { Candidate } from "./commonTypes";
import {
  setSignInUserSession,
  setConfirmOtpSession,
  setUserId,
  getPath,
} from "./helper";
import {
  OtpError,
  LOGIN_TYPE,
  RumEventType,
  VerificationError,
  PinError,
  MultiplePhoneCountryCodeCountryList
} from "./constants";
import {
  boundVerifyEmail,
  boundAddAlert,
  boundVerifyPhone,
  boundRemoveAlerts,
  boundVerifySignIn,
  boundSignIn,
  boundSignOut,
  boundGetCandidate,
  boundConfirmOtp,
  boundUpdateCandidate,
  boundForgotPin,
  boundResetPin,
  boundUpdatePhone,
  boundLoadingStart,
} from "src/actions/boundActions";
import {
  checkUsernameIsEmail,
  cleanPhoneNumberFormat,
} from "../utils/helper";
import {
  getSignOutRedirectUrl,
  getCountryCode,
} from "src/utils/apis/apiHelper";
import { AuthErrorMessages } from "./errorMessages";
import 'url-polyfill';
import { emitEvent } from "./rum";
import {addAdobeMetric} from "src/customerTracking/adobeAnalytics";
import {AdobeEvent, PageName} from "src/customerTracking/adobeAnalytics/types";
import {PhoneCodeByCountry} from "src/utils/phoneCodes";
import {skipPhoneVerification} from "src/utils/featureFlag/featureFlag";

export const onResendOtp = ({candidate, loginType, pin}: any) => {
  const responseTime = Date.now();
  boundRemoveAlerts();
  if(!candidate.candidateLogin || candidate.candidateLogin.length<0){
    emitEvent(
      {
        event: RumEventType.RESEND_OTP,
        error: OtpError.NO_CANDIDATE_FOR_OTP,
        status: false,
        country: getCountryCode(),
        availability: 1,
        path: getPath(),
      }
    );
    boundAddAlert({
      errorMessage: AuthErrorMessages["error-resending-one-time-code-message"],
      errorMessageStringId: "error-resending-one-time-code-message",
    });
  } else {
    boundSignIn(
      {
        user:candidate.candidateLogin,
        pin:pin,
        loginType: loginType,
      },
      (payload: any)=>{
        setConfirmOtpSession(payload.session);
        emitEvent(
          {
            event: RumEventType.RESEND_OTP,
            status: true,
            country: getCountryCode(),
            availability: 1,
            path: getPath(),
          }
        );
      },
      ()=>{
        emitEvent(
          {
            event: RumEventType.RESEND_OTP,
            error: OtpError.RESEND_OTP_ERROR,
            status: false,
            country: getCountryCode(),
            availability: 0,
            path: getPath(),
          }
        );
        boundAddAlert({
          errorMessage: AuthErrorMessages["error-resending-one-time-code-message"],
          errorMessageStringId: "error-resending-one-time-code-message",
        });
      }
    );
  }
};

export const onVerifyEmail = (
  candidate: Candidate,
  otp: string,
  setEmailVerified: Function,
  onVerifyEmail?: Function
) => {
  const responseTime = Date.now();
  boundRemoveAlerts();
  boundVerifyEmail(
    {
      candidate,
      otp
    },
    (payload: any)=>{
      if (payload.session) {
        setEmailVerified(false);
        setConfirmOtpSession(payload.session);
        emitEvent(
          {
            event: RumEventType.VERIFY_EMAIL,
            error: VerificationError.FAILED_EMAIL_VERIFY,
            status: false,
            country: getCountryCode(),
            availability: 1,
            path: getPath(),
          }
        );
        return boundAddAlert({
          errorMessage: AuthErrorMessages["error-submitting-one-time-code-message"],
          errorMessageStringId: "error-submitting-one-time-code-message"
        });
      } else {
        setEmailVerified(true);
        setSignInUserSession(payload.signInUserSession);
        setUserId(candidate.candidateLogin);
        emitEvent(
          {
            event: RumEventType.VERIFY_EMAIL,
            status: true,
            country: getCountryCode(),
            availability: 1,
            path: getPath(),
          }
        );
        if (!candidate.isPhoneVerified) {
          // Go verify phone or not
          // will check UX flow and add later
        } else {
          // onCheckAuth() // TO dashboard
        }
      }
    },
    ()=>{
      setEmailVerified(false);
      emitEvent(
        {
          event: RumEventType.VERIFY_EMAIL,
          error: VerificationError.VERIFY_EMAIL_ERROR,
          status: false,
          country: getCountryCode(),
          availability: 0,
          path: getPath(),
        }
      );
      boundAddAlert({
        errorMessage: AuthErrorMessages["error-submitting-one-time-code-message"],
        errorMessageStringId: "error-submitting-one-time-code-message"
      });
    }
  );
  onVerifyEmail && onVerifyEmail();
};

interface EmailVerificationComplete {
  candidate: Candidate,
  pin?: string,
  onNext: Function,
  onEmailVerificationCompleteTest?: Function
}

export const onEmailVerificationComplete = ({
  candidate,
  pin,
  onNext,
  onEmailVerificationCompleteTest
  }: EmailVerificationComplete) => {
  const responseTime = Date.now();
  boundRemoveAlerts();
  (skipPhoneVerification() || candidate.isPhoneVerified) ? onNext() : boundSignIn(
    {
      user:candidate.candidateLogin,
      pin:candidate.pin,
      loginType: LOGIN_TYPE.PHONE,
    },
    (payload: any)=>{
      setConfirmOtpSession(payload.session);
      onNext();
    },
    ()=>{
      emitEvent(
        {
          event: RumEventType.VERIFY_EMAIL,
          error: VerificationError.VERIFY_EMAIL_ERROR,
          status: false,
          country: getCountryCode(),
          availability: 0,
          path: getPath(),
        }
      );
      boundAddAlert({
        errorMessage: AuthErrorMessages["error-sending-one-time-code-message"],
        errorMessageStringId: "error-sending-one-time-code-message",
      });
    }
  );
  onEmailVerificationCompleteTest && onEmailVerificationCompleteTest();
};

export const onVerifyPhone = (
  candidate: Candidate,
  otp: string,
  setPhoneVerified: Function,
  onVerifyPhone: Function,
) => {
  const responseTime = Date.now();

  boundRemoveAlerts();
  boundVerifyPhone(
    {
      candidate,
      otp,
    },
    (payload: any)=>{
      if (payload.session) {
        setPhoneVerified(false);
        // setRegistrationSession(payload.session);
        setConfirmOtpSession(payload.session);
        setUserId(candidate.candidateLogin);
        emitEvent(
          {
            event: RumEventType.VERIFY_PHONE,
            error: VerificationError.FAILED_PHONE_VERIFY,
            status: false,
            country: getCountryCode(),
            availability: 1,
            path: getPath(),
          }
        );
        boundAddAlert({
          errorMessage: AuthErrorMessages["error-submitting-one-time-code-message"],
          errorMessageStringId: "error-submitting-one-time-code-message"
        });
      } else {
        setPhoneVerified(true);
        setSignInUserSession(payload.signInUserSession);
        emitEvent(
          {
            event: RumEventType.VERIFY_PHONE,
            status: true,
            country: getCountryCode(),
            availability: 1,
            path: getPath(),
          }
        );
      }
    },
    ()=>{
      setPhoneVerified(false);
      emitEvent(
        {
          event: RumEventType.VERIFY_PHONE,
          error: VerificationError.VERIFY_PHONE_ERROR,
          status: false,
          country: getCountryCode(),
          availability: 0,
          path: getPath(),
        }
      );
      boundAddAlert({
        errorMessage: AuthErrorMessages["error-submitting-one-time-code-message"],
        errorMessageStringId: "error-submitting-one-time-code-message"
      });
    }
  );
  onVerifyPhone && onVerifyPhone();
};

export const onSignOut = (redirectUrl=`${getSignOutRedirectUrl({})}`, callback?:Function, onError?: Function) => {
  boundRemoveAlerts();
  boundSignOut(
    {
      redirectUrl: redirectUrl
    },
    (payload: any)=>{
      callback && callback();
      if(payload && payload.result){
        boundLoadingStart();
        window.location.assign(decodeURIComponent(redirectUrl));
      }
    },
    ()=>{
      onError && onError();
    }
  );
}

export const onGetCandidate = (
  {
    candidateLoginProp,
    onPhoneLogin,
    onInputPin,
    onVerifyRegistration,
  }:
  {
    candidateLoginProp: string, 
    onPhoneLogin: Function,
    onInputPin: Function,
    onVerifyRegistration: Function,
  }) => {
  boundRemoveAlerts();
  const curCode = getCountryCode();
  let username = candidateLoginProp;
  const isLoginEmail = checkUsernameIsEmail(username);

  // don't need to do duplicated check as LoginPage already verify the input validity
  if (isLoginEmail || !MultiplePhoneCountryCodeCountryList.includes(curCode)) {
    if (!isLoginEmail){
      if (!username.includes("+")){
        const phoneCountryCode = PhoneCodeByCountry.get(curCode);
        username = phoneCountryCode + username; // add corresponding phone country code
      }
      username = cleanPhoneNumberFormat(username);
    }

    boundGetCandidate(username,
      (payload: any)=>{
        boundUpdateCandidate({candidateLogin:username});

        const { isEmailVerified, isPhoneVerified, isBBUser } = payload;

        if (!isEmailVerified && !isPhoneVerified && !isBBUser) {
          onVerifyRegistration();
        } else {
          onInputPin();
        }
      });
  } else {
    // Go to phone login and pass current phone number
    boundUpdateCandidate({candidateLogin:username});
    onPhoneLogin();
  }
}

export const onGetCandidateWithPhoneNumber = (
  {
    phoneNumberWithPhoneCountryCode,
    onNext,
  }:{
    phoneNumberWithPhoneCountryCode: string,
    onNext: Function
  }) => {
  boundRemoveAlerts();
  const responseTime = Date.now();
  boundGetCandidate(
    phoneNumberWithPhoneCountryCode,
    ()=>{
      onNext();
    },
    ()=>{
      boundAddAlert({
        errorMessage: AuthErrorMessages["get-candidate-error-message"],
        errorMessageStringId: "get-candidate-error-message"
      });
    }
  );
};

export const onVerifySignIn = (
    payload: VerifySignInRequest,
    onSuccess: Function
) => {
  boundRemoveAlerts();
  boundVerifySignIn(payload, onSuccess);
}

export const onSignIn = (
  {
    user,
    pin,
    loginType,
    onNext,
    onSignInCompleteTest,
    onPinError,
  }: any
) => {
  boundRemoveAlerts();
  boundSignIn(
    {
      user:user,
      pin:pin,
      loginType: loginType,
    },
    (payload: any)=>{
      setConfirmOtpSession(payload.session);
      onNext();
    },
    (errorResponse: any)=>{
      if(errorResponse.errorMessageStringId === "un-authorized-error"){
        onPinError();
      } else {
        boundAddAlert({
          errorMessage: AuthErrorMessages["error-sending-one-time-code-message"],
          errorMessageStringId: "error-sending-one-time-code-message",
        });
      }
    }
  );
}

export const onConfirmOpt = (
  {
    candidate,
    otp,
    onConfirmOpt,
    onNext
  }: {
    candidate: Candidate
    otp: string
    onConfirmOpt?: Function
    onNext: Function
  }
) => {
  const responseTime = Date.now();
  boundRemoveAlerts();
  boundConfirmOtp(
    { 
      user:candidate.candidateLogin,
      otp
    },
    (payload: any)=>{
      const { signInUserSession, session } = payload;
      if (!signInUserSession) {
        setConfirmOtpSession(session);

        emitEvent(
          {
            event: RumEventType.CONFIRM_OTP,
            status: false,
            error: OtpError.INVALID_OTP,
            country: getCountryCode(),
            availability: 1,
            path: getPath()
          }
        );

        return boundAddAlert({
          errorMessage: AuthErrorMessages["error-submitting-one-time-code-message"],
          errorMessageStringId: "error-submitting-one-time-code-message"
        });
      } else {
        emitEvent(
          {
            event: RumEventType.CONFIRM_OTP,
            status: true,
            country: getCountryCode(),
            availability: 1,
            path: getPath()
          }
        );

        setUserId(candidate.candidateLogin);
        setSignInUserSession(payload.signInUserSession);
        onNext();
      }
    },
    ()=>{
      boundAddAlert({
        errorMessage: AuthErrorMessages["error-submitting-one-time-code-message"],
        errorMessageStringId: "Error-while-submitting-one-time-code",
      });
      addAdobeMetric(AdobeEvent.NEXT_BUTTON_CLICKED, PageName.OTP_CONFIRMED, {
        errorMessage: "Error-while-submitting-one-time-code"
      });
    }
  )
  onConfirmOpt && onConfirmOpt();
};

export const onForgotPin = (
  {
    candidateLogin,
    onNext,
  }:{
    candidateLogin: string,
    onNext:Function,
  }
) => {
  const responseTime = Date.now();
  boundGetCandidate(candidateLogin,
    (payload: any)=>{
      boundUpdateCandidate({candidateLogin:candidateLogin});
      boundForgotPin(candidateLogin,()=>{
        onNext();
      });
    },
    ()=>{
      emitEvent(
        {
          event: RumEventType.UI_VALIDATION_ENTER_PIN,
          error: PinError.FORGOT_PIN_ERROR,
          status: false,
          country: getCountryCode(),
          availability: 0,
          path: getPath(),
        }
      );
      boundAddAlert({
        errorMessage: AuthErrorMessages["get-candidate-error-message"],
        errorMessageStringId: "get-candidate-error-message"
      });
    }
  );
}

export const onUpdatePin = (
  {
    candidate,
    otp,
    pin,
    onNext,
  }
: 
  {  
    candidate: Candidate,
    otp: string,
    pin: string,
    onNext: Function,
  }
) => {
  const responseTime = Date.now();
  boundResetPin(
    {
      otp: otp,
      pin: pin,
      user: candidate.candidateLogin
    },
    ()=>{
      onNext();
    },
    ()=>{
      emitEvent(
        {
          event: RumEventType.UI_VALIDATION_ENTER_PIN,
          error: PinError.UPDATE_PIN_ERROR,
          status: false,
          country: getCountryCode(),
          availability: 0,
          path: getPath(),
        }
      );
      boundAddAlert({
        errorMessage: AuthErrorMessages['reset-pin-error-message'],
        errorMessageStringId: "reset-pin-error-message"
      });
    }
  )
};

export const onCheckPhoneNumber = (
  phoneNumber: string,
  phoneCountryCode: string,
  pin: string,
  candidateLogin: string,
  onNext:Function,
) => {
  boundRemoveAlerts();
  const responseTime = Date.now();
  boundUpdatePhone(
    {
      phoneNumber: phoneNumber,
      phoneCountryCode: phoneCountryCode,
      pin: pin,
      candidateLogin:candidateLogin,
    },
    (payload: any)=>{
      // setRegistrationSession(payload.session);
      setConfirmOtpSession(payload.session);
      onNext();
    },
    ()=>{
      boundAddAlert({
        errorMessage: AuthErrorMessages["error-sending-one-time-code-message"],
        errorMessageStringId: "error-sending-one-time-code-message",
      });
    }
  );
};
