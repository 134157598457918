import { isTokenCookieEnabled } from "src/utils/featureFlag/featureFlag";
import { boundAddAlert } from "src/actions/boundActions";

import { AuthErrorMessages } from "src/utils/errorMessages";

declare global {
  interface Window {
    AwsWafIntegration: {
      getToken: () => Promise<string>;
      forceRefreshToken: () => Promise<string>;
    };
  }
}

const WafRetryConfig = {
  MaxRetries: 3,
  DelayMs: 1000,
};

// try to reload WAF script
const reloadWafScript = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const wafChallengeSDKEndpoint = "{{CaptchaApiUrl}}";
    const script = document.createElement("script");
    script.src = wafChallengeSDKEndpoint;
    script.onload = () => {
      resolve();
    };
    script.onerror = () => {
      reject(new Error("Failed to load a WAF SDK."));
    };
    document.body.appendChild(script);
  });
};
export const getWafToken = async () => {
  if (!isTokenCookieEnabled()) return;

  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let wafSDK = window.AwsWafIntegration;

    if (!wafSDK) {
      await reloadWafScript();
      wafSDK = window.AwsWafIntegration;
    }

    if (!wafSDK) {
      boundAddAlert({
        errorMessage: AuthErrorMessages["waf-sdk-integration-not-exist"],
        errorMessageStringId: "waf-sdk-integration-not-exist",
      });

      return;
    }

    for (let i = 1; i <= WafRetryConfig.MaxRetries; i += 1) {
      const token = (await wafSDK.getToken?.()) || (await wafSDK.forceRefreshToken?.());
      if (token) {
        return;
      }
      // don't need to wait for the last retry
      if (i < WafRetryConfig.MaxRetries ) {
        await new Promise((resolve) => setTimeout(resolve, WafRetryConfig.DelayMs * i));
      }
    }

    boundAddAlert({
      errorMessage: AuthErrorMessages["waf-get-token-fails"],
      errorMessageStringId: "waf-get-token-fails",
    });

  } catch {
    boundAddAlert({
      errorMessage: AuthErrorMessages["waf-call-exception"],
      errorMessageStringId: "waf-call-exception",
    });
  }
};