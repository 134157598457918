import { from, Observable, of } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/internal/operators';
import { ofType } from 'redux-observable';
import { getCandidateApi } from '../utils/apis/serviceApi';
import { parseErrorMessage } from '../utils/helper';
import { actionGetCandidateSuccess } from '../actions/actions';
import {
  GET_CANDIDATE,
  GET_CANDIDATE_CALLBACK_SUCCESS,
  GET_CANDIDATE_FAILED,
  GET_CANDIDATE_SUCCESS,
} from '../actions/actionTypes';
import { emitEvent } from 'src/utils/rum';
import { RumEventType } from 'src/utils/constants';

export const getCandidateEpic = (action$: Observable<any>) => {
  return action$.pipe(
    ofType(GET_CANDIDATE),
    flatMap((action: any) =>
      from<Promise<any>>(getCandidateApi(action.payload)).pipe(
        flatMap(async (response) => {
          return response.data;
        }),
        map((x) => {
          // Add candidateLogin into payload
          x.candidateLogin = action.payload;
          return actionGetCandidateSuccess(x, action.callback);
        }),
        catchError((error) => {
          const { response } = error;
          if (action.onError) {
            action.onError(response);
          }

          const parsedMessage = parseErrorMessage(response.data);

          emitEvent({
            event: RumEventType.GET_CANDIDATE,
            status: false,
            error: parsedMessage,
            errorCode: response.status,
            availability: 0,
          });

          return of({
            type: GET_CANDIDATE_FAILED,
            payload: parsedMessage,
          });
        }),
      ),
    ),
  );
};

export const getCandidateSuccessEpic = (action$: Observable<any>) => {
  return action$.pipe(
    ofType(GET_CANDIDATE_SUCCESS),
    map((action) => {
      emitEvent({
        event: RumEventType.GET_CANDIDATE,
        status: true,
        availability: 1,
      });

      if (action.callback) {
        action.callback(action.payload);
      }
      return { type: GET_CANDIDATE_CALLBACK_SUCCESS, payload: null };
    }),
  );
};
