import { Action } from "redux";
import { BannerMessage } from "src/utils/commonTypes";

export enum RedirectBannerActionTypes {
    DISPLAY = 'DISPLAY_REDIRECT_BANNER',
    CLOSE = 'CLOSE_REDIRECT_BANNER'
}

export interface DisplayRedirectBannerAction extends Action {
    type: RedirectBannerActionTypes.DISPLAY;
    payload: BannerMessage;
}

export interface CloseRedirectBannerAction extends Action {
    type: RedirectBannerActionTypes.CLOSE;
}
