import React from 'react';
import { connect } from 'react-redux';
import { getCountryFullName } from 'src/utils/apis/apiHelper';
import { CountryFullName, RegistrationForm } from 'src/utils/commonTypes';
import CAConsentComponent from './CAConsentComponent';
import UKConsentComponent from './UKConsentComponent';
import USConsentComponent from './USConsentComponent';
import MXConsentComponent from './MXConsentComponent';
import { PrivacyNoticeByCountry } from 'src/utils/registrationFrom/constants';

interface ConsentProps {
  registrationForm: RegistrationForm;
  setIsAgreeToDataRetention: Function;
  setIsAgreeToCommunication: Function;
  setIsWhatsAppEnabled: Function;
  consentError: boolean[];
  consentToggleRef1: React.RefObject<HTMLButtonElement>;
  consentToggleRef2: React.RefObject<HTMLButtonElement>;
}

interface MapStateToProps {}

export const Consent = (props: ConsentProps) => {
  const {
    registrationForm,
    setIsAgreeToDataRetention,
    setIsAgreeToCommunication,
    setIsWhatsAppEnabled,
    consentError,
    consentToggleRef1,
    consentToggleRef2,
  } = props;

  let consentComponent = <></>;
  const countryFullName: CountryFullName = getCountryFullName();

  switch (countryFullName) {
    case CountryFullName.UK:
      consentComponent = (
        <UKConsentComponent
          registrationForm={registrationForm}
          setIsAgreeToDataRetention={setIsAgreeToDataRetention}
          setIsAgreeToCommunication={setIsAgreeToCommunication}
          consentError={consentError}
          consentToggleRef1={consentToggleRef1}
          consentToggleRef2={consentToggleRef2}
          privacyNotice={PrivacyNoticeByCountry}
        />
      );
      break;
    case CountryFullName.MX:
      consentComponent = (
        <MXConsentComponent
          registrationForm={registrationForm}
          setIsAgreeToDataRetention={setIsAgreeToDataRetention}
          setIsAgreeToCommunication={setIsAgreeToCommunication}
          setIsWhatsAppEnabled={setIsWhatsAppEnabled}
          consentError={consentError}
          consentToggleRef={consentToggleRef2}
          privacyNotice={PrivacyNoticeByCountry}
        />
      );
      break;
    case CountryFullName.US:
      // US consent doesn't need any action from user
      consentComponent = <USConsentComponent />;
      break;

    case CountryFullName.CA:
      consentComponent = (
        <CAConsentComponent
          registrationForm={registrationForm}
          setIsAgreeToDataRetention={setIsAgreeToDataRetention}
          setIsAgreeToCommunication={setIsAgreeToCommunication}
          consentError={consentError}
          consentToggleRef={consentToggleRef2}
        />
      );
      break;
    default:
      // Return UK as fallback for temp, this is for fix unit testing, need better solution later
      consentComponent = (
        <UKConsentComponent
          registrationForm={registrationForm}
          setIsAgreeToDataRetention={setIsAgreeToDataRetention}
          setIsAgreeToCommunication={setIsAgreeToCommunication}
          consentError={consentError}
          consentToggleRef1={consentToggleRef1}
          consentToggleRef2={consentToggleRef2}
          privacyNotice={PrivacyNoticeByCountry}
        />
      );
      break;
  }

  return consentComponent;
};

const mapStateToProps = (state: MapStateToProps) => {
  return state;
};

export default connect(mapStateToProps)(Consent);
