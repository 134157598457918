import { from, Observable, of } from "rxjs";
import { flatMap, map, catchError } from "rxjs/internal/operators";
import { ofType } from "redux-observable";
import { signInApi } from '../utils/apis/serviceApi';
import { getPath, parseErrorMessage } from "../utils/helper";
import { actionSignInSuccess} from "../actions/actions";
import {
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILED,
  SIGN_IN_CALLBACK_SUCCESS,
} from "../actions/actionTypes";
import {emitEvent} from "src/utils/rum";
import { getCountryCode } from "src/utils/apis/apiHelper";
import { RumEventType } from "src/utils/constants";

export const signInEpic = (action$: Observable<any>) => {
  return action$.pipe(
    ofType(SIGN_IN),
    flatMap((action: any) =>
      from<Promise<any>>(
        signInApi(action.payload)
      ).pipe(
        flatMap(async (response) => {
          return response.data
        }),
        map((x) => {
          emitEvent(
            {
              event: RumEventType.SIGN_IN,
              status: true,
              country: getCountryCode(),
              availability: 1,
              path: getPath()
            }
          );

          return actionSignInSuccess(x, action.callback)
        }),
        catchError((error) => {
            const response = error.response;
            if (action.onError) {
                action.onError(response.data);
            }

            const errorMessage = parseErrorMessage(response.data);

            emitEvent(
              {
                event: RumEventType.SIGN_IN,
                status: false,
                error: errorMessage,
                errorCode: response.status,
                country: getCountryCode(),
                availability: 0,
                path: getPath()
              }
            );

            return of({
                type: SIGN_IN_FAILED,
                payload: parseErrorMessage(response.data),
            });
        })
      )
    )
  );
};

export const signInSuccessEpic = (action$: Observable<any>) => {
    return action$.pipe(
      ofType(SIGN_IN_SUCCESS),
      map((action) => {
        if (action.callback) {
          action.callback(action.payload);
        }
        return { type: SIGN_IN_CALLBACK_SUCCESS, payload: null };
      })
    );
};