import React, { ChangeEvent, ReactNode, useState } from "react";
import { Checkbox, Input, InputWrapper } from "@amzn/stencil-react-components/form";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Label, Text } from "@amzn/stencil-react-components/text";
import isEmpty from "lodash/isEmpty";
import { InputType } from "src/utils/commonTypes";
import { translate as t } from "../../utils/translator";

interface CheckboxControlledTextInputProps {
  hasError?: boolean;
  errorText?: { [key: string]: string } | string;
  infoText?: string;
  tooltipText?: string;
  value: string;
  placeholder?: string;
  optionalText?: string;
  labelText?: any;
  checkboxLabelText?: any;
  onChange?: any;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  id?: string;
  name?: string;
  errorMessage?: string;
  showErrorMessage?: boolean;
  inputType?: string;
  required?: boolean;
  loading?: boolean;
  onBlur?: Function;
  properties?: any;
  maxLength?: number;
  pattern?: string;
  inputMode?: any;
  font?: "primary" | "monospace" | undefined;
  autoComplete?: string;
  disablePaste?: boolean;
  footerComponent?: ReactNode;
  spellCheck?: boolean;
  color?: string;
  isValid?: boolean
}

export interface CheckboxControlledInputEventValue {
  value: string;
  key: string;
  properties: any;
}

const CheckboxControlledInputComponent = ({
  hasError = false,
  errorText = "",
  infoText = undefined,
  tooltipText = undefined,
  labelText = "",
  checkboxLabelText = "",
  id = "text-box",
  value,
  onChange,
  onKeyDown,
  name = "",
  placeholder,
  inputType = "text",
  optionalText,
  required,
  loading = false,
  onBlur,
  properties,
  maxLength,
  pattern,
  inputMode,
  font="primary",
  autoComplete,
  disablePaste,
  footerComponent,
  spellCheck,
  color,
  isValid
}: CheckboxControlledTextInputProps) => {

  const [enableTextbox, toggleTextbox] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("")
  
  const onValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      if (event.target.type == "checkbox"){
        onChange([
                  {key: "middleName", value: ""},
                  {key: "noMiddleNameChecked", value: !enableTextbox}
              ])
        setInputValue("")  
      }
      else {
          onChange([{ value: event.target.value, key: id, properties }]);
          setInputValue(event.target.value)  
      }
    }
  };

  const onTextBlur = (event: ChangeEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur([{ value: event.target.value, key: id, properties }]);
    }
  };

  const getFooterText = () => {
    if (loading) {
      return "Loading";
    }
    return hasError ? typeof errorText === "string" ? errorText : t(errorText.key, errorText.value, errorText?.options) : infoText;

  };

  const onPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    return false;
  };

  return (
    <Col gridGap={"S300"} className="colContainer">
      <InputWrapper
        labelText={labelText}
        id={id}
        tooltipText={tooltipText}
        error={hasError}
        footer={getFooterText()}
        dataTestId={`input-wrapper-${id}`}
        required={isEmpty(optionalText)}
        renderLabel={() => (
          <Row alignItems="center" gridGap={"S300"}>
            <Label fontSize="T200" color="accent2" htmlFor={id}>
              <Row gridGap={8}>
                <Text>
                  {labelText} 
                  {required && <span color="negative" aria-hidden="true"> *</span>}
                </Text>
                {!isEmpty(optionalText) && <Text>{optionalText}</Text>}
              </Row>
            </Label>
          </Row>
        )}
      >
        {inputProps => (
          <Col gridGap={"S300"} className="colContainer">
            <div>
              <Row gridGap={"S300"}>
                <Input
                  {...inputProps}
                  autoComplete={autoComplete}
                  aria-describedby={`${inputProps["aria-describedby"]}`}
                  width="100%"
                  name={name}
                  defaultValue={inputValue}
                  value={inputValue}
                  onChange={onValueChange}
                  dataTestId={`input-test-id-${id}`}
                  type={inputType as InputType}
                  required={enableTextbox}
                  onBlur={onTextBlur}
                  placeholder={placeholder}
                  maxLength={maxLength}
                  disabled={enableTextbox}
                  pattern={pattern}
                  inputMode={inputMode}
                  aria-label={labelText}
                  aria-invalid={hasError}
                  aria-required={required}
                  font={font}
                  onKeyDown={onKeyDown}
                  onPaste={disablePaste ? onPaste : undefined}
                  // unless spellCheck is explicitly set to false, it will be left as default, thus keeping current behavior intact
                  { ...( spellCheck === false ? { spellCheck: false } : {})}
                />
              </Row>
            </div>
            <div>
              <Row gridGap={"S300"}>
                <Checkbox
                  id={`${id}_checkbox`}
                  dataTestId="input-test-id-checkBox"
                  checked={enableTextbox}
                  onChange={(e)=>{
                      const value = e.target.checked;
                      toggleTextbox(value)
                      onValueChange(e)
                    }
                  }
                />
                <Text
                  color={color}
                  fontSize="T200"
                  textAlign="left"
                >
                  {checkboxLabelText
                    ? checkboxLabelText 
                    : ""
                  }
                </Text>
              </Row>
            </div>
          </Col>
        )}
      </InputWrapper>
      {footerComponent}
    </Col>
  );
};

export default CheckboxControlledInputComponent;
