import { Observable, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/internal/operators';
import { ajax, AjaxError } from 'rxjs/ajax';
import { WEB_LAB_ACTION_TYPE, WebLabSendMetrics } from 'src/actions/WebLab/webLabActionTypes';
import { actionSendWebLabMetricFailed, actionSendWebLabMetricSuccess } from 'src/actions/WebLab/webLabActions';
import { getWebLabMetricEndpoint } from 'src/utils/webLab/helper';

export const webLabEpic = (action$: Observable<any>) => {
  return action$.pipe(
    ofType(WEB_LAB_ACTION_TYPE.SEND_METRICS),
    switchMap((action: WebLabSendMetrics) => {
      return ajax({
        url: `${getWebLabMetricEndpoint()}?${action.payload}`,
        method: 'GET',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
      }).pipe(
        map(() => {
          // return result
          return actionSendWebLabMetricSuccess();
        }),

        catchError((error: AjaxError) => {
          // handle error
          /* eslint-disable-next-line no-console */
          console.log(error);
          return of(actionSendWebLabMetricFailed());
        }),
      );
    }),
  );
};
