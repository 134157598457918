import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import { PAGE_ROUTE_AS_JOBS_ACCOUNT, PAGE_ROUTE_REGISTRATION, PAGE_ROUTE_VERIFY_REGISTRATION } from '../pageRoutes';
import {
  countryCodeKeyMap,
  countryCodeMap,
  countryMap,
  createAccountAllowedList,
  errorPageName,
  LOGIN_TYPE,
  LoginError,
  MultiplePhoneCountryCodeCountryList,
  RumEventType,
  SIGN_IN_PAGES,
} from 'src/utils/constants';
import InputComponent from '../Registration/InputComponent';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { H1, Text } from '@amzn/stencil-react-components/text';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import {
  boundGetConfig,
  boundGetCSRF,
  boundRemoveAlerts,
  boundResetCandidate,
  boundUpdateCandidate,
  boundUpdateRegistrationForm,
} from 'src/actions/boundActions';
import { onGetCandidate } from 'src/utils/authService';
import {
  checkIsNumber,
  checkUsernameIsEmail,
  checkUsernameIsPhoneNumber,
  focusFirstInputErrorItem,
  getCountryDropBoxStatus,
  getFromSessionStorage,
  getStartFromAtoZ,
  sanitizeString,
  setCountryCodeSession,
  setSignInAsLoginFlow,
} from 'src/utils/helper';
import PinPage from './PinPage';
import ChooseOtpType from 'src/components/Login/ChooseOtpType';
import ConfirmOtp from 'src/components/Login/ConfirmOtp';
import PhoneLogin from 'src/components/Login/PhoneLogin';
import CheckPhoneNumber from 'src/components/Login/CheckPhoneNumber';
import VerifyPhonePage from 'src/components/Registration/VerifyPhonePage';
import { getCountryCode, getCountryFullName, getStage } from 'src/utils/apis/apiHelper';
import { AuthErrorMessages } from 'src/utils/errorMessages';
import { Link } from '@amzn/stencil-react-components/link';
import { initRegistrationForm } from 'src/reducers/registration.reducer';
import { AlertMessage, CountryFullName } from 'src/utils/commonTypes';
import SelectComponent from 'src/components/Registration/SelectComponent';
import { emitEvent, recordPageView } from 'src/utils/rum';
import { SSOLoginImage, SSOLoginImageBig } from 'src/images';
import Helper from 'src/components/Helper';
import { addAdobeMetric } from 'src/customerTracking/adobeAnalytics';
import { AdobeEvent, PageName } from 'src/customerTracking/adobeAnalytics/types';
import { PhoneCodeByCountry } from 'src/utils/phoneCodes';
import AtoZSSOPage from './AtoZSSO';
import { isAgencyHiringEnabled, isPhysicalStoresEnabled } from 'src/utils/featureFlag/featureFlag';
import { getLoginDetailsApi } from 'src/utils/apis/serviceApi';
import { getDefaultLocale, syncLocaleValue } from 'src/utils/localization/localeHelper';
import { CandidateState } from 'src/reducers/candidate.reducer';
import { AlertState } from 'src/reducers/alert.reducer';
import { Config } from 'src/utils/apis/serviceApiTypes';
import { RouterState } from 'connected-react-router';
import useHVHTranslation from 'src/Hooks/useHVHTranslation';

interface LoginPageProps {
  initPage?: string;
}

interface MapStateToProps {
  router: RouterState;
  config: Config;
  candidate: CandidateState;
  alert: AlertState;
}

export const LoginPage: FC<LoginPageProps & MapStateToProps> = (props) => {
  const history: any = useHistory();
  const { router, config, alert, candidate: candidateProps, initPage } = props;
  const hasAlert = alert?.alert || null;
  const alerts = alert?.alerts || null;
  const candidate = candidateProps.results;
  const countryCode = window.sessionStorage.getItem('countryCode');
  const countryDropBoxStutus = getCountryDropBoxStatus();
  const countryName = countryCode && countryCodeMap.get(countryCode);
  const [countryItem, setCountryItem] = useState<any>(
    countryCode ? { key: `LoginPage-country-drop-box-${countryCode}`, value: countryName } : undefined,
  );
  const [error, setError] = useState<any>({});
  const [countrySelectionError, setCountrySelectionError] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(initPage ? initPage : SIGN_IN_PAGES.LOGIN);

  recordPageView({ pageId: SIGN_IN_PAGES.LOGIN });

  const [referrer] = useState(getFromSessionStorage('referrer'));
  const [pin, setPin] = useState<string>('');
  const [loginType, setLoginType] = useState<string>(LOGIN_TYPE.NONE);

  const goToRegistrationPage = () => {
    history.push(`/${PAGE_ROUTE_REGISTRATION}`);
  };

  const goToVerifyRegistrationPage = () => {
    history.push(`/${PAGE_ROUTE_VERIFY_REGISTRATION}`);
  };

  const [hasFocusedError, setHasFocusedError] = useState(false);
  useEffect(() => {
    !hasFocusedError && focusFirstInputErrorItem(() => setHasFocusedError(true)); // Stop if already focused the error once.
  });

  const [user, setUser] = useState('');
  const [loginMethodInputDisabled, setLoginMethodInputDisabled] = useState<boolean>();

  useEffect(() => {
    emitEvent({
      event: RumEventType.PAGE_LOAD,
      status: true,
      message: 'Mounted Login component',
    });
  }, []);

  useEffect(() => {
    const handleLoginDetails = async () => {
      const candidateLogin = sessionStorage.getItem('loginHint');
      if (candidateLogin) {
        setUser(decodeURIComponent(candidateLogin));
        setLoginMethodInputDisabled(true);
        return;
      }
      setUser(candidate.candidateLogin);

      // The 'loginDetails' contain encrypted user information used to open the PIN setup link, where the emailId will be pre-filled and non-editable for candidate accounts created through data upload.
      if (!isAgencyHiringEnabled()) return;

      const loginDetails = sessionStorage.getItem('loginDetails');
      if (!loginDetails) return;

      const response = await getLoginDetailsApi({ userDetails: loginDetails });
      const { data } = response;
      setUser(data.loginDetails.emailId);
      setLoginMethodInputDisabled(true);
    };
    handleLoginDetails();
  }, []);

  const shouldDisplayStoresJobsLink = () => {
    if (!referrer) return false;
    if (!countryCode) return false;
    // This link goes away when Physical Stores jobs exist
    if (isPhysicalStoresEnabled()) return false;

    const storesLinkAllowedRegions = new Set([CountryFullName.US, CountryFullName.UK]);
    const country = getCountryFullName();

    // If referred by CareerSite and country is allowed
    if (referrer === 'CS' && storesLinkAllowedRegions.has(country)) {
      return true;
    }
    return false;
  };

  const shouldDisplaySignUpLink = () => {
    if (!referrer) return false;

    if (referrer === 'CS' && createAccountAllowedList.includes(referrer.toUpperCase())) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (getStartFromAtoZ()) {
      // when startFromAtoZ search params is set to 1, redirect to start atoz sso flow.
      setCurrentPage(SIGN_IN_PAGES.ATOZ_SSO);
    }
  }, []);

  useEffect(() => {
    boundGetConfig();
    boundGetCSRF();
    addAdobeMetric(AdobeEvent.SIGN_IN, PageName.LOGIN);
    boundUpdateRegistrationForm({ ...initRegistrationForm });
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (hasAlert && alerts.length > 0) {
      alerts.forEach((alert: AlertMessage) => {
        addAdobeMetric(AdobeEvent.PAGE_LOAD, errorPageName[currentPage], {
          errorMessage: alert.errorMessageStringId,
        });
      });
    }
  }, [hasAlert]);

  // willUnmount
  useEffect(
    () => () => {
      boundRemoveAlerts();
    },
    [],
  );

  useEffect(() => {
    if (countryItem) {
      const country = countryItem.value;

      boundUpdateCandidate({ country: country });
      boundGetConfig();
      boundGetCSRF();
    }
  }, [countryItem]);

  const { t } = useHVHTranslation();

  // Add switch statement to handle locale changes based on country name
  const onCountryChange = (data: { key: string; value: { key: string; value: string } }[]) => {
    const countryData = data[0].value;
    setCountryItem(countryData);
    setCountrySelectionError({
      hasError: false,
      message: '',
    });
    const countryCode = countryMap.get(countryData.value);

    // set countryCode in sessionStorage
    countryCode && setCountryCodeSession(countryCode);

    // updating cookies and sessionStorage to the country's default locale
    const defaultLocale = getDefaultLocale(countryCode);
    syncLocaleValue(defaultLocale);
  };

  const onLoginChange = (data: any) => {
    if (isEmpty(countryItem)) {
      setCountrySelectionError({
        hasError: true,
        message: t(
          AuthErrorMessages['empty-country-error-message'].key,
          AuthErrorMessages['empty-country-error-message'].value,
        ),
      });
    }

    const { value } = data[0];
    const user = sanitizeString(value).trim();

    setUser(user);

    setError({
      hasError: false,
      message: '',
    });
  };

  const onCheckCandidate = () => {
    onGetCandidate({
      candidateLoginProp: user,
      onInputPin: () => {
        setCurrentPage(SIGN_IN_PAGES.PIN);
      },
      onPhoneLogin: () => {
        setCurrentPage(SIGN_IN_PAGES.PHONE_LOGIN);
      },
      onVerifyRegistration: () => {
        goToVerifyRegistrationPage();
      },
    });
  };

  const onLoginSubmit = () => {
    setHasFocusedError(false); // Reset error focus for the next form check.
    setSignInAsLoginFlow();

    if (isEmpty(countryItem)) {
      setCountrySelectionError({
        hasError: true,
        message: t(
          AuthErrorMessages['empty-country-error-message'].key,
          AuthErrorMessages['empty-country-error-message'].value,
        ),
      });

      emitEvent({
        event: RumEventType.NO_COUNTRY_SELECTED,
        error: LoginError.EMPTY_COUNTRY_INFO,
        status: false,
      });
    }

    if (isEmpty(user)) {
      emitEvent({
        event: RumEventType.UI_VALIDATION_ENTER_EMAIL_OR_PHONE,
        error: LoginError.EMPTY_LOGIN,
        status: false,
      });

      setError({
        hasError: true,
        message: t(
          AuthErrorMessages['empty-login-email-error-message'].key,
          AuthErrorMessages['empty-login-email-error-message'].value,
        ),
      });
      return;
    }

    // Don't return error if
    // 1. Username is email.
    // 2. Username starts with '+' and is a valid phone number.
    // 3. Username has no '+', current country has single phone country code and (phoneCountryCode+Username) is valid phone number
    // 4. Multiple phone country code and Username contains only digits -> We will direct user to country code selection page.
    const curCode = getCountryCode();
    const phoneCountryCode = PhoneCodeByCountry.get(curCode);
    const isValidPhoneNumberOrEmail: boolean =
      checkUsernameIsEmail(user) ||
      (user.includes('+') && checkUsernameIsPhoneNumber(user)) ||
      (!user.includes('+') &&
        !MultiplePhoneCountryCodeCountryList.includes(curCode) &&
        checkUsernameIsPhoneNumber(phoneCountryCode + user)) ||
      (MultiplePhoneCountryCodeCountryList.includes(curCode) && checkIsNumber(user));

    if (!isValidPhoneNumberOrEmail) {
      emitEvent({
        event: RumEventType.UI_VALIDATION_ENTER_EMAIL_OR_PHONE,
        error: LoginError.INVALID_LOGIN_FORMAT,
        status: false,
      });

      setError({
        hasError: true,
        message: t(
          AuthErrorMessages['invalid-login-error-message'].key,
          AuthErrorMessages['invalid-login-error-message'].value,
        ),
      });
    } else {
      setError({
        hasError: false,
        message: '',
      });

      emitEvent({
        event: RumEventType.UI_VALIDATION_ENTER_EMAIL_OR_PHONE,
        status: true,
      });
      if (!countrySelectionError.hasError) {
        addAdobeMetric(AdobeEvent.NEXT_BUTTON_CLICKED, PageName.LOGIN);
        setLoginType(checkUsernameIsEmail(user) ? LOGIN_TYPE.EMAIL : LOGIN_TYPE.PHONE);
        onCheckCandidate();
      }
    }
  };

  const onCreateCandidate = (event: React.SyntheticEvent) => {
    event.preventDefault();
    boundResetCandidate();
    goToRegistrationPage();
  };

  const signUpLink = () => {
    if (shouldDisplaySignUpLink()) {
      return (
        <Col margin="S200">
          <Text dataTestId="sign-up-account">
            {t('LoginPage-lbl-sign-up-account', "Don't have an account?")}&nbsp;
            <Link
              className="linkHandCursor"
              href="#"
              dataTestId="sign-up-account"
              onClick={onCreateCandidate}
              fontSize="T200"
            >
              {t('LoginPage-lbl-sign-up-here', 'Sign up')}
            </Link>
          </Text>
        </Col>
      );
    }
  };

  const renderStoresJobLink = () => {
    if (shouldDisplayStoresJobsLink()) {
      return (
        <Col margin="S200">
          <Text textAlign="center" dataTestId="label-ASjobsaccount">
            {t('LoginPage-lbl-stores-jobs', 'For Amazon Stores jobs,')}&nbsp;
            <Link
              dataTestId="link-ASjobsaccount"
              href={`${PAGE_ROUTE_AS_JOBS_ACCOUNT}`}
              target="_blank"
              fontSize="T200"
            >
              {t('LoginPage-title-log-in', 'Log in')}
            </Link>
          </Text>
        </Col>
      );
    }
  };

  const srcSetStr = `${SSOLoginImage} 1280w, ${SSOLoginImageBig} 2560w`;
  const LoginPage = (
    <Row width="100%" height="100%">
      <Col>
        <img src={SSOLoginImage} height="100%" alt="Workers" className="login_image" srcSet={srcSetStr} />
      </Col>

      <Col justifyContent="space-between" height="100%" width="100%" alignItems="center">
        <Col gridGap="S300" className="hvhLogin" padding={{ left: '50px', right: '50px', top: '50px' }}>
          <Col className="colContainerTop">
            <H1 fontSize="T400" font="primary" fontWeight="bold" color="black">
              {t('LoginPage-title-log-in', 'Log In')}
            </H1>
            <Text fontSize="T100">
              {t(
                'LoginPage-par-log-in-description',
                'This account is different from the one you use to shop on Amazon.',
              )}
            </Text>
          </Col>
          <Col gridGap="S300" className="colContainer">
            {countryDropBoxStutus === null && (
              <SelectComponent
                onChange={onCountryChange}
                id="country"
                placeholder={''}
                value={countryItem}
                labelText={t('LoginPage-country-drop-box', 'Select your Country')}
                options={countryCodeKeyMap(getStage())}
                required
                hasError={countrySelectionError.hasError}
                errorText={countrySelectionError.message}
              />
            )}
            <InputComponent
              id="login"
              name="login EmailId"
              labelText={t('LoginPage-title-log-in-text-box', 'Email or mobile number')}
              disabled={loginMethodInputDisabled}
              value={user}
              loading={false}
              required
              onChange={onLoginChange}
              hasError={error.hasError}
              errorText={error.message}
              infoText={
                getCountryFullName() === CountryFullName.UK ? 'Input your phone number without leading 0.' : undefined
              }
            />
          </Col>
          <Button dataTestId={`button-continue`} onClick={onLoginSubmit} variant={ButtonVariant.Primary}>
            {t('LoginPage-btn-submit-login', 'Continue')}
          </Button>
          <Row alignItems="center" gridGap={'S300'}>
            {signUpLink()}
          </Row>
          <Row alignItems="center" gridGap={'S300'}>
            {renderStoresJobLink()}
          </Row>
        </Col>
        <Helper />
      </Col>
    </Row>
  );

  const getPage = () => {
    switch (currentPage) {
      case SIGN_IN_PAGES.LOGIN: {
        return LoginPage;
      }
      case SIGN_IN_PAGES.PIN:
        recordPageView({ pageId: SIGN_IN_PAGES.PIN });
        return <PinPage candidate={candidate} goToPage={setCurrentPage} pin={pin} setPin={setPin} />;
      case SIGN_IN_PAGES.OTP_TYPE:
        recordPageView({ pageId: SIGN_IN_PAGES.OTP_TYPE });
        return (
          <ChooseOtpType
            candidate={candidate}
            goToPage={setCurrentPage}
            pin={pin}
            loginType={loginType}
            setLoginType={setLoginType}
          />
        );
      case SIGN_IN_PAGES.CONFIRM_OTP:
        recordPageView({ pageId: SIGN_IN_PAGES.CONFIRM_OTP });
        return (
          <ConfirmOtp
            candidate={candidate}
            goToPage={setCurrentPage}
            loginType={loginType}
            config={config}
            router={router}
            pin={pin}
          />
        );
      case SIGN_IN_PAGES.PHONE_LOGIN:
        recordPageView({ pageId: SIGN_IN_PAGES.PHONE_LOGIN });
        return <PhoneLogin goToPage={setCurrentPage} />;
      case SIGN_IN_PAGES.CHECK_PHONE:
        recordPageView({ pageId: SIGN_IN_PAGES.CHECK_PHONE });
        return <CheckPhoneNumber candidate={candidate} pin={pin} goToPage={setCurrentPage} />;
      case SIGN_IN_PAGES.VERIFY_PHONE:
        recordPageView({ pageId: SIGN_IN_PAGES.VERIFY_PHONE });
        return <VerifyPhonePage pin={pin} goToPage={setCurrentPage} />;
      case SIGN_IN_PAGES.ATOZ_SSO:
        recordPageView({ pageId: SIGN_IN_PAGES.ATOZ_SSO });
        return <AtoZSSOPage />;
      default:
        return LoginPage;
    }
  };

  return (
    <Row justifyContent="center" height="100%">
      {getPage()}
    </Row>
  );
};

interface State {
  testUser?: string;
  initPage?: string;
  router?: any;
  candidate?: any;
  showLocaleSelector?: boolean;
}

const mapStateToProps = (state: MapStateToProps, ownState: State) => {
  return { ...state, ...ownState };
};

export default connect(mapStateToProps)(LoginPage);
