import i18next from 'i18next';
import { getCurrentLocale } from 'src/utils/localization/localeHelper';

const rootStr: string = '.appStrings.CS-';

/*
 * Dynamic component render in contact us page need to use t function from useTranslation hook to detect language change.
 * */
export const mapI18nStringKey = (stringId: string) => {
  const locale = getCurrentLocale();
  return i18next.exists([locale + rootStr + stringId])
    ? locale + rootStr + stringId
    : locale.substring(0, 2) + rootStr + stringId;
};
