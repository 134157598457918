import { AlertMessage, CountryFullName } from "src/utils/commonTypes";
import { MessageBannerType } from "@amzn/stencil-react-components/message-banner";
import { getCountryFullName } from "src/utils/apis/apiHelper";
import {errorMessageCharLimitDefault, errorMessageCharLimitMap} from "src/utils/constants";

export const AuthErrorMessages: { [key: string]: { [key: string]: string }} = {
    "invalid-login-error-message" : {key: "ErrorMessages-invalid-login-error-message", value: "Enter a valid email address or mobile number."},
    "error-submitting-one-time-code-message" : {key: "ErrorMessages-error-submitting-one-time-code-message", value: "Enter a valid verification code. Try resending verification code if the issue continues."},
    "candidate-email-already-exits-in-other-regions-error" : {key: "ErrorMessages-candidate-email-already-exits-in-other-regions-error", value: "This email exists in another region. Please create an account with a different email address."},
    "registration-consent-validation-error" : {key: "ErrorMessages-registration-consent-validation-error", value: "The item above requires your consent."},
    "registration-validation-error" : {key: "ErrorMessages-registration-validation-error", value: "There are items above that require your attention."},
    "empty-form-value-error-message" : {key: "ErrorMessages-empty-form-value-error-message", value: "Some of the field values are missing."},
    "pin-non-numeric-error-message" : {key: "ErrorMessages-pin-non-numeric-error-message", value: "PIN must have exactly 6 numbers, 0-9."},
    "pin-not-match-error-message" : {key: "ErrorMessages-pin-not-match-error-message", value: "Provided pin and re-entered pin are not matched."},
    "hvh-error-commonly-used-pin" : {key: "ErrorMessages-hvh-error-commonly-used-pin", value: "Error setting PIN: For security purposes, you PIN should contain at least 3 unique digits and should not be in a sequence (e.g - 123456, 987654)."},
    "empty-login-email-error-message" : {key: "ErrorMessages-empty-login-email-error-message", value: "Please enter the email you used to register your account."},
    "empty-login-phone-error-message" : {key: "ErrorMessages-empty-login-phone-error-message", value: "Please enter mobile number you used to register your account."},
    "invalid-login-phone-error-message" : {key: "ErrorMessages-invalid-login-phone-error-message", value: "Please enter a valid phone number you used to register your account."},
    "empty-pin-error-message" : {key: "ErrorMessages-empty-pin-error-message", value: "Please enter your personal pin."},
    "error-resending-one-time-code-message" : {key: "ErrorMessages-error-resending-one-time-code-message", value: "Sorry, there is an error while resending the code."},
    "error-sending-one-time-code-message" : {key: "ErrorMessages-error-sending-one-time-code-message", value: "Sorry, there is an error while sending the code."},
    "get-candidate-error-message" : {key: "ErrorMessages-get-candidate-error-message", value: "Sorry, there is an error while getting your account information. Please try again."},
    "reset-pin-error-message" : {key: "ErrorMessages-reset-pin-error-message", value: "Sorry, there is an error while resetting your pin. Please try again."},
    "email-exits-service-message" : {key: "ErrorMessages-email-exits-service-message", value: "Unable to check email exists or not. Please contact support."},
    "email-exits-message" : {key: "ErrorMessages-email-exits-message", value: "This email is already registered. Please provide a different number or login with this email address."},
    "phone-number-exits-message" : {key: "ErrorMessages-phone-number-exits-message", value: "This mobile number is already registered. Please provide a different number or login with this number."},
    "phone-number-exits-service-message" : {key: "ErrorMessages-phone-number-exits-service-message", value: "Unable to check mobile number exists or not. Please contact support."},
    "candidate-email-or-phone-checking-error" : {key: "ErrorMessages-candidate-email-or-phone-checking-error", value: "Unable to check email or mobile number exists or not. Please contact support."},
    "error-create-candidate-message" : {key: "ErrorMessages-error-create-candidate-message", value: "We couldn't create your account. Please try again."},
    "sign-in-candidate-not-found": {key: "ErrorMessages-candidate-not-found", value: "We could not find your account. Enter your email or mobile number again or create a new account."},
    "account-is-locked-message-thirty-minutes": {key: "ErrorMessages-account-is-locked-message-thirty-minutes", value: "Your account is locked, please try again after 30 minutes."},
    "sign-in-unauthorized-error": {key: "ErrorMessages-sign-in-un-authorized-error", value: "Please enter a valid 6-digit pin."},
    "sign-in-internal-server-error": {key: "ErrorMessages-sign-in-internal-server-error", value: "Unable to sign in, please try it later."},
    "one-time-code-error": {key: "ErrorMessages-one-time-code-error", value: "Provided one time code is not valid, please resend the code and try again."},
    "user-not-found-in-session": {key: "ErrorMessages-user-not-found-in-session", value: "Sorry there is an error while confirming verification code, please resend the code and try again."},
    "code-mismatch-in-session": {key: "ErrorMessages-user-not-found-in-session", value: "Sorry there is an error while confirming verification code, please resend the code and try again."},
    "confirm-otp-internal-error": {key: "ErrorMessages-confirm-otp-internal-error", value: "Unable to sign in, please try again."},
    "forgot-pin-limit-exceeded ": {key: "ErrorMessages-forgot-pin-limit-exceeded", value: "Attempt limit exceeded, please try later."},
    "forgot-pin-candidate-not-found": {key: "ErrorMessages-forgot-pin-candidate-not-found", value: "We could not find your account."},
    "sending-forgot-pin-otp-error": {key: "ErrorMessages-sending-forgot-pin-otp-error", value: "Sorry, there is an error while sending the code."},
    "invalid-verification-code-error": {key: "ErrorMessages-invalid-verification-code-error", value: "Invalid verification code, please try again."},
    "updating-pin-session-expired": {key: "ErrorMessages-updating-pin-session-expired", value: "Sorry, the session expired, please try again."},
    "updating-pin-limit-exceeded": {key: "ErrorMessages-updating-pin-limit-exceeded", value: "Sorry, limit exceeded, please try it later."},
    "updating-pin-invalid-parameter": {key: "ErrorMessages-updating-pin-invalid-parameter", value: "Sorry, invalid parameter happened, please try it later."},
    "updating-pin-error": {key: "ErrorMessages-updating-pin-error", value: "Sorry, there is an error to update the pin, please try again."},
    "invalid-redirect-domain-error": {key: "ErrorMessages-invalid-redirect-domain-error", value: "There was an exception while validating redirect url in sign-out."},
    "unauthorized-error": {key: "ErrorMessages-unauthorized-error", value: "Request is unauthorized."},
    "hvh-api-user-name-already-exists": {key: "ErrorMessages-hvh-api-user-name-already-exists", value: "Email address/phone number is already exists. Please enter new email and phone number."},
    "hvh-api-user-name-conflict": {key: "ErrorMessages-hvh-api-user-name-conflict", value: "Please create a new account with a different email address in this region."},
    "error-creating-candidate-error": {key: "ErrorMessages-error-creating-candidate-error", value: "Sorry, there is an error while creating user, Please try again."},
    "candidate-conflict-from-regional-ddb": {key: "ErrorMessages-hvh-api-user-name-conflict", value: "Please create a new account with a different email address in this region."},
    "candidate-unauthorized-error": {key: "ErrorMessages-unauthorized-error", value: "Request is unauthorized."},
    "candidate-not-found": {key: "ErrorMessages-candidate-not-found", value: "We could not find your account. Enter your email or mobile number again or create a new account."},
    "candidate-error-happened": {key: "ErrorMessages-candidate-error-happened", value: "Sorry, There is an error to find your account."},
    "candidate-bbid-conflict-with-sf": {key: "ErrorMessages-hvh-api-user-name-conflict", value: "Please create a new account with a different email address in this region."},
    "candidate-email-checking-error": {key: "ErrorMessages-candidate-email-checking-error", value: "Sorry there is an error while checking the email."},
    "candidate-phone-checking-error": {key: "ErrorMessages-candidate-phone-checking-error", value: "Sorry there is an error while checking the phone number."},
    "verify-email-candidate-not-found-in-session": {key: "ErrorMessages-verify-email-candidate-not-found-in-session", value: "Sorry there is an error while verifying the email, please resend the code and try again."},
    "verify-email-verification-code-mismatch": {key: "ErrorMessages-verify-email-un-authorized-error", value: "Sorry there is an error while verifying the email, please put the correct code."},
    "verify-email-unauthorized-error": {key: "ErrorMessages-verify-email-un-authorized-error", value: "Sorry there is an error while verifying the email, please put the correct code." },
    "verify-email-internal-error": {key: "ErrorMessages-verify-email-internal-error", value: "Sorry there is an error while verifying the email, please try it later."},
    "verify-phone-candidate-not-found-in-session": {key: "ErrorMessages-verify-phone-candidate-not-found-in-session", value: "Sorry there is an error while verifying the phone number, please resend the code and try again."},
    "verify-phone-verification-code-mismatch": {key: "ErrorMessages-verify-phone-un-authorized-error", value: "Sorry there is an error while verifying the phone number, please put the correct code."},
    "verify-phone-unauthorized-error": {key: "ErrorMessages-verify-phone-un-authorized-error", value: "Sorry there is an error while verifying the phone number, please put the correct code."},
    "verify-phone-internal-error": {key: "ErrorMessages-verify-phone-internal-error", value: "Sorry there is an error while verifying the phone number, please try it later."},
    "validate-phone-number-error-message": {key: "ErrorMessages-regexval-enter-valid-mobile", value: "Please enter a valid mobile number."},
    "update-candidate-unauthorized-error": {key: "ErrorMessages-update-candidate-unauthorized-error", value: "Sorry there is an error while updating the phone number."},
    "update-candidate-internal-server-error": {key: "ErrorMessages-update-candidate-unauthorized-error", value: "Sorry there is an error while updating the phone number."},
    "authorize-header-unauthorized-error": {key: "ErrorMessages-authorize-header-unauthorized-error", value: "Request is unauthorized."},
    "unable-to-fetch-client-error": {key: "ErrorMessages-unable-to-fetch-client-error", value: "Unable to fetch client details."},
    "invalid-token-message": {key: "ErrorMessages-invalid-token-message", value: "Your security token has been expired, Please refresh the page and try again."},
    "invalid-jwt-csrf-token-message": {key: "ErrorMessages-invalid-jwt-csrf-token-message", value: "Request is forbidden to provide information. Please refresh your screen and try again."},
    "rate-limit-message": {key: "ErrorMessages-rate-limit-message", value: "You have exceeded the requests limit, Please try after some time."},
    "unable-to-find-the-validations": {key: "ErrorMessages-unable-to-find-the-validations", value: "Unable to find the validations."},
    "hvh-validation-get-candidate-missing-login": {key: "ErrorMessages-hvh-validation-get-candidate-missing-login", value: "Please provide login (email/phoneNumber)."},
    "hvh-create-candidate-error-required-email": {key: "ErrorMessages-hvh-create-candidate-error-required-email", value: "Please provide email."},
    "hvh-create-candidate-error-email-format": {key: "ErrorMessages-hvh-create-candidate-error-email-format", value: "Please provide correct format of email."},
    "hvh-create-candidate-error-required-re-enter-email": {key: "ErrorMessages-hvh-create-candidate-error-required-re-enter-email", value: "Please provide re-enter email."},
    "hvh-email-not-matched-validation-message": {key: "ErrorMessages-hvh-email-not-matched-validation-message", value: "The email addresses do not match each other."},
    "hvh-create-candidate-error-required-phone-number": {key: "ErrorMessages-hvh-create-candidate-error-required-phone-number", value: "Please provide phone number."},
    "hvh-create-candidate-error-required-re-enter-phone": {key: "ErrorMessages-hvh-create-candidate-error-required-re-enter-phone", value: "Please provide re-enter phone number."},
    "hvh-phone-number-not-matched-validation-message": {key: "ErrorMessages-hvh-phone-number-not-matched-validation-message", value: "The phone numbers do not match each other."},
    "hvh-create-candidate-error-required-first-name": {key: "ErrorMessages-hvh-create-candidate-error-required-first-name", value: "Please provide first name."},
    "hvh-create-candidate-error-required-last-name": {key: "ErrorMessages-hvh-create-candidate-error-required-last-name", value: "Please provide last name."},
    "hvh-create-candidate-error-required-phone-country-code": {key: "ErrorMessages-hvh-create-candidate-error-required-phone-country-code", value: "Please provide phone country code."},
    "hvh-create-candidate-error-required-pin": {key: "ErrorMessages-hvh-create-candidate-error-required-pin", value: "Please provide 6 digit pin."},
    "hvh-registration-error-regex-validation-pin": {key: "ErrorMessages-hvh-registration-error-regex-validation-pin", value: "Please enter a valid 6-digit pin."},
    "hvh-registration-pin-validation-message": {key: "ErrorMessages-hvh-registration-pin-validation-message", value: "The PIN does not match."},
    "hvh-confirm-wrong-otp-code": {key: "ErrorMessages-error-submitting-one-time-code-message", value: "Enter a valid verification code. Try resending verification code if the issue continues."},
    "waf-sdk-integration-not-exist": { key: "ErrorMessages-waf-sdk-integration-not-exist", value: "Sorry there is an error while retrieving WAF SDK, please refresh the browser or contact us." },
    "waf-get-token-fails": { key: "ErrorMessages-waf-get-token-fails", value: "Sorry there is an error while retrieving WAF token, please refresh the browser or contact us." },
    "waf-call-exception": { key: "ErrorMessages-waf-call-exception", value: "Sorry there is an exception while calling WAF, please refresh the browser or contact us." },
    "empty-country-error-message" : {key: "ErrorMessages-empty-country-error-message", value: "Please select the country where you registered your account."},
    "DEFAULT": {key: 'ErrorMessages-unknown-error-occurred', value: "An unknown error occurred. Try refreshing the browser."}
};

export const StaticPageErrorMessages: { [key: string]: { [key: string]: string } } = {
    "error-load-image-video" : {key: "ErrorMessages-load-image-video", value:"We couldn't load the image/video."}, // May Need to add "Reload page" text button later in the errorMessage box
};

export const CountryPostCodeNotFoundMessage: { [key: string]: { [key: string]: string } } = {
    [CountryFullName.UK]: {key:"ErrorMessages-appsynclocation-unable-to-find-postcode", value: "Unable to find your postcode."},
    [CountryFullName.US]: {key:"ErrorMessages-appsynclocation-unable-to-find-zipcode", value: "Unable to find your zipcode."},
};

export const AppSyncLocationErrorMessages: { [key: string]: AlertMessage } = {
    "DEFAULT" : {
        errorMessage: {key: "ErrorMessages-appsynclocation-default", value: "Unable to retrieve your location."}
    },
    "PERMISSION_DENIED" : {
        alertType: MessageBannerType.Warning,
        errorTitle: {key: "ErrorMessages-appsynclocation-cannot-get-location-title", value: "We couldn't get your location."},
        errorMessage: {key:"ErrorMessages-appsynclocation-permission-denied", value:"For the best experience, please allow location tracking. Alternatively, you can search for jobs by using a postcode."}
    },
    "POSITION_UNAVAILABLE" : {
        alertType: MessageBannerType.Error,
        errorTitle: {key: "ErrorMessages-appsynclocation-cannot-get-location-title", value: "We couldn't get your location."},
        errorMessage: {key:"ErrorMessages-appsynclocation-try-refreshing-browser", value:"Try refreshing the browser."} // May need to add "Reload page" text button later in the errorMessage box
    },
    "TIMEOUT" : {
        alertType: MessageBannerType.Error,
        errorTitle: {key: "ErrorMessages-appsynclocation-cannot-get-location-title", value: "We couldn't get your location."},
        errorMessage: {key:"ErrorMessages-appsynclocation-try-refreshing-browser", value:"Try refreshing the browser."} // May need to add "Reload page" text button later in the errorMessage box
    },
    "ERR_INTERNAL_LOCATION_SERVICE" : {
        alertType: MessageBannerType.Error,
        errorTitle: {key: "ErrorMessages-appsynclocation-cannot-get-location-title", value: "We couldn't get your location."},
        errorMessage: {key:"ErrorMessages-appsynclocation-try-refreshing-browser", value:"Try refreshing the browser."} // May need to add "Reload page" text button later in the errorMessage box
    }
};

export const AppSyncJobSearchErrorMessages: { [key: string]: { [key: string]: string } } = {
    "DEFAULT": {key:"ErrorMessages-appsyncjobsearch-default", value:"Problem loading page. The server didn't respond in time. Try refreshing the browser."},
    "ERR_RESPONSE_JC_SERVICE" : {key:"ErrorMessages-appsyncjobsearch-default", value:"Problem loading page. The server didn't respond in time. Try refreshing the browser."},
    "ERR_INTERNAL_JC_SERVICE" : {key:"ErrorMessages-appsyncjobsearch-default", value:"Problem loading page. The server didn't respond in time. Try refreshing the browser."},
};

export const AppSyncGetJobApplicationErrorMessages: { [key: string]: { [key: string]: string } } = {
    "DEFAULT": {key:"ErrorMessages-appsyncgetjobapplication-default", value:"Problem loading job applications. Try refreshing the browser."},
    "ERR_INTERNAL_BB_SERVICE": {key:"ErrorMessages-appsyncgetjobapplication-internal", value:"Problem loading job application. The server didn't response in time. Try refreshing the browser."},
    "ERR_INTERNAL_APPLICATION_FUNCTION_LAMBDA": {key:"ErrorMessages-appsyncgetjobapplication-internal", value:"Problem loading job application. The server didn't response in time. Try refreshing the browser."}
}

export const AppSyncJobDetailErrorMessages: { [key: string]: { [key: string]: string } } = {
    "DEFAULT": {key:"ErrorMessages-appsyncjobdetail-default", value:"Internal server issue. The server didn't respond in time. Try refreshing the browser."},
    "ERR_JOBDETAIL_NOT_FOUND" : {key:"ErrorMessages-appsyncjobdetail-msg-job-not-found", value:"This job has either been expired or doesn't exist. You can either return to the previous page, or visit our homepage."},
    "ERR_RESPONSE_JC_SERVICE" : {key:"ErrorMessages-appsyncjobdetail-default", value:"Internal server issue. The server didn't respond in time. Try refreshing the browser."},
    "ERR_INTERNAL_JC_SERVICE" : {key:"ErrorMessages-appsyncjobdetail-default", value:"Internal server issue. The server didn't respond in time. Try refreshing the browser."},
};

export const AppSyncCandidateDetailMessages: { [key: string]: { [key: string]: string } } = {
    "DEFAULT": {key:"ErrorMessages-appsyncjobdetail-default", value:"Internal server issue. The server didn't respond in time. Try refreshing the browser."},
    "Unauthorized" : {key:"ErrorMessages-appsynccandidatedetail-unauthorized", value:"Not Authorized to access this candidate detail."},
    "ERR_INTERNAL_AUTHENTICATION": {key:"ErrorMessages-appsynccandidatedetail-internal-authentication", value:"Not Authorized to access or update this candidate detail."},
    "ERR_RESPONSE_SERVICE" : {key:"ErrorMessages-appsyncjobdetail-default", value:"Internal server issue. The server didn't respond in time. Try refreshing the browser."},
    "SUCCESS": {key:"ErrorMessages-appsyncjobdetail-success-personal-details", value:"Successfully updated personal details."},
    "SUCCESS_UPDATE_CONTACT": {key:"ErrorMessages-appsyncjobdetail-success-contact-info", value:"Successfully updated contact information."}
};

export const AppSyncCommunicationPreferenceMessages: { [key: string]: { [key: string]: string } } = {
    "DEFAULT": {key:"ErrorMessages-appsyncjobdetail-default", value:"Internal server issue. The server didn't respond in time. Try refreshing the browser."},
    "Unauthorized" : {key:"ErrorMessages-appsynccandidatedetail-unauthorized", value:"Not Authorized to access this candidate detail."},
    "ERR_RESPONSE_SERVICE" : {key:"ErrorMessages-appsyncjobdetail-default", value:"Internal server issue. The server didn't respond in time. Try refreshing the browser."},
    "SUCCESS": {key:"ErrorMessages-appsynccommunicationpreferences-success-comm-preferences", value:"Successfully updated communication preference."}
};

export const AppSyncScheduleCardsErrorMessages: { [key: string]: { [key: string]: string } } = {
    "DEFAULT": {key:"ErrorMessages-appsyncschedulecard-default", value:"We couldn't load shift information. Try refreshing the browser."},
    "ERR_RESPONSE_JC_SERVICE" : {key:"ErrorMessages-appsyncschedulecard-default", value:"We couldn't load shift information. Try refreshing the browser."},
    "ERR_INTERNAL_JC_SERVICE" : {key:"ErrorMessages-appsyncschedulecard-default", value:"We couldn't load shift information. Try refreshing the browser."},
};

export const DefaultValidationErrorMessages: { [key: string]: { [key: string]: string } } = {
    firstName: {key: "ErrorMessages-val-first-name-required", value: "First name is required."},
    middleName: {key: "ErrorMessages-val-middle-name-required", value: "Middle name is required. Click the checkbox below if you don't have a middle name"},
    lastName: {key: "ErrorMessages-val-last-name-required", value: "Last name is required."},
    englishFirstName: {key: "ErrorMessages-val-english-first-name-required", value: "English first name is required."},
    englishLastName: {key: "ErrorMessages-val-english-last-name-required", value: "English last name is required."},
    emailId: {key: "ErrorMessages-val-valid-email", value: "Enter a valid email address."},
    phoneNumber: {key: "ErrorMessages-val-valid-phone", value: "Enter a valid phone number."},
    reEnterEmailId: {key: "ErrorMessages-val-reenter-email", value: "Re-enter the email address."},
    reEnterPhoneNumber: {key: "ErrorMessages-val-reenter-phone", value: "Re-enter the mobile number."},
    pin: {key: "ErrorMessages-val-pin-length", value: "PIN must have exactly 6 numbers, 0-9."},
    reEnterPin: {key: "ErrorMessages-val-reenter-pin", value: "Re-enter the pin."},
    dateOfBirth: {key: "ErrorMessages-val-enter-dob", value: "Provide your date of birth"},
    timezone: {key: "ErrorMessages-val-select-timezone", value: "Select a timezone."},
    addressLine1: {key: "ErrorMessages-val-valid-address", value: "Please enter a valid address."},
    city: {key: "ErrorMessages-val-valid-city", value: "Please enter a valid city."},
    state: {key: "ErrorMessages-val-select-state", value: "Select a state."},
    country: {key: "ErrorMessages-val-valid-country", value: "Please enter a valid country."},
    zipcode: {key: "ErrorMessages-val-valid-zipcode", value: "Please enter a valid zipcode."},
    language: {key: "ErrorMessages-preferred-language-required", value: "Please select a preferred language."}
};

const charLimitsByCountry = errorMessageCharLimitMap[getCountryFullName()] || errorMessageCharLimitDefault;
export const DefaultRegexValidationErrorMessages: {
    [key: string]: { [key: string]: string | object };
} = {
    firstName: { key: "ErrorMessages-regexval-enter-valid-first-name", value: `Please enter a valid first name (less than ${charLimitsByCountry.firstName} char).`, options: { charLimit: charLimitsByCountry.firstName } },
    lastName: { key: "ErrorMessages-regexval-enter-valid-last-name", value: `Please enter a valid last name (less than ${charLimitsByCountry.lastName} char).`, options: { charLimit: charLimitsByCountry.lastName } },
    middleName: { key: "ErrorMessages-regexval-enter-valid-middle-name", value: `Must be less than ${charLimitsByCountry.lastName} characters and can contain alphabets and one space. If your name contains any special characters, please exclude them.`, options: { charLimit: charLimitsByCountry.middleName } },
    preferredFirstName: { key: "ErrorMessages-regexval-enter-valid-pref-first-name", value: `Please enter a valid preferred first name (less than ${charLimitsByCountry.preferredFirstName} char).`, options: { charLimit: charLimitsByCountry.preferredFirstName } },
    preferredLastName: { key: "ErrorMessages-regexval-enter-valid-pref-last-name", value: `Please enter a valid preferred last name (less than ${charLimitsByCountry.preferredLastName} char).`, options: { charLimit: charLimitsByCountry.preferredLastName } },
    englishFirstName: { key: "ErrorMessages-regexval-enter-valid-english-first-name", value: `Please enter a valid preferred first name (less than ${charLimitsByCountry.englishFirstName} char).`, options: { charLimit: charLimitsByCountry.englishFirstName } },
    englishLastName: { key: "ErrorMessages-regexval-enter-valid-english-last-name", value: `Please enter a valid preferred last name (less than ${charLimitsByCountry.englishLastName} char).`, options: { charLimit: charLimitsByCountry.englishLastName } },
    emailId: {key: "ErrorMessages-regexval-enter-valid-email", value: "Please enter a valid email address."},
    phoneNumber: {key: "ErrorMessages-regexval-enter-valid-mobile", value: "Please enter a valid mobile number."},
    reEnterEmailId: {key: "ErrorMessages-regexval-enter-valid-email", value: "Please enter a valid email address."},
    reEnterPhoneNumber: {key: "ErrorMessages-regexval-enter-valid-mobile", value: "Please enter a valid mobile number."},
    pin: {key: "ErrorMessages-val-pin-length", value: "PIN must have exactly 6 numbers, 0-9."},
    reEnterPin: {key: "ErrorMessages-val-pin-length", value: "PIN must have exactly 6 numbers, 0-9."},
    addressLine1: {key: "ErrorMessages-regexval-address", value: "Please enter a valid address (less than 30 char)."},
    addressLine2: {key: "ErrorMessages-regexval-address", value: "Please enter a valid address (less than 30 char)."},
    city: {key: "ErrorMessages-regexval-city", value: "Please enter a valid city (less than 30 char)."},
    state: {key: "ErrorMessages-regexval-state", value: "Please enter a valid state."},
    country: {key: "ErrorMessages-val-valid-country", value: "Please enter a valid country."},
    zipcode: {key: "ErrorMessages-val-valid-zipcode", value: "Please enter a valid zipcode."},
    dateOfBirth: {key: "ErrorMessages-regexval-dob", value: "Please enter a valid date."},
    dateOfBirthLimit: {key: "ErrorMessages-regexval-age-limit", value: "You need to be at least 18 years of age to apply to most of our jobs."},
};

export const DefaultMatchValidationErrorMessages: {
    [key: string]: { [key: string]: string };
} = {
    reEnterEmailId: {key:"ErrorMessages-defaultmatchvalidation-emailId", value:"The email addresses didn't match. Try again."},
    reEnterPhoneNumber: {key:"ErrorMessages-defaultmatchvalidation-phonenumber", value:"The phone numbers didn't match. Try again."},
    reEnterPin: {key:"ErrorMessages-defaultmatchvalidation-personalpin", value:"The 6-digit personal pin didn't match. Try again."},
};

export const AuthGetConfigErrorMessages: { [key: string]: { [key: string]: string } } = {
    "DEFAULT": {key:"ErrorMessages-authgetconfig-default", value:"Failed to get authentication configuration. Try refreshing the browser."},
    "Network Error": {key:"ErrorMessages-authgetconfig-network", value:"Failed to get authentication configuration due to network issue. Try refreshing the browser."},
};

export const GetCountryStateConfigErrorMessages: { [key: string]: { [key: string]: string } } = {
    "DEFAULT": {key:"ErrorMessages-getcountrystateconfig-default", value:"Failed to get state list. Try refreshing the browser."},
};

export const AuthCSRFTokenErrorMessages: { [key: string]: { [key: string]: string } } = {
    "DEFAULT": {key:"ErrorMessages-authcsrftoken-default", value:"Token expired. Try refreshing the browser."},
    "Network Error": {key:"ErrorMessages-authcsrftoken-network", value:"Token validation failed due to network issue. Try refreshing the browser."},
    "Request failed with status code 404": {key:"ErrorMessages-authcsrftoken-not-found", value:"Failed to get token. Try refreshing the browser."},
    "invalid-token-message": {key:"ErrorMessages-authcsrftoken-invalid-token", value:"Your security token has been expired, Please refresh the page and try again."}
};

export const AppSyncJobAlertErrorMessages: { [key: string]: { [key: string]: string } } = {
    "DEFAULT": {key:"ErrorMessages-appsyncjobalert-default", value:"Problem while signing up for JobAlert. The server didn't respond in time. Try refreshing the browser."},
    "ERR_RESPONSE_JC_SERVICE" : {key:"ErrorMessages-appsyncjobalert-default", value:"Problem while signing up for JobAlert. The server didn't respond in time. Try refreshing the browser."},
    "ERR_INTERNAL_LOCATION_SERVICE" : {key:"ErrorMessages-appsyncjobalert-default", value:"Problem while signing up for JobAlert. The server didn't respond in time. Try refreshing the browser."},
    "ERR_INVALID_ZIPCODE": {key:"ErrorMessages-appsyncjobalert-invalid-zipcode", value:"Problem while signing up for JobAlert. Please enter a valid zipcode."}
};

export const CopyToClipBoardMessages: { [key: string]: { [key: string]: string } } = {
    "FAILED": {key:"ErrorMessages-copytoclipboard-failed", value:"We couldn't copy the page link. Please try again."},
    "SUCCESS": {key:"ErrorMessages-copytoclipboard-success", value:"Link is copied to clipboard."}
};

export const AppSyncAutoCompleteErrorMessages: { [key: string]: { [key: string]: string } } = {
    "DEFAULT": {key:"ErrorMessages-appsyncautocomplete-default", value:"We couldn't fetch recommendations."}
};

export const AppSynContactUsErrorMessages: { [key: string]: { [key: string]: string } } = {
    "DEFAULT": {key:"ErrorMessages-appsynccontactus-default", value:"Problem contacting support center. The server didn't respond in time. Try refreshing the browser."},
    "Requests rate limit exceeded": {key:"ErrorMessages-appsynccontactus-requests-rate-limit", value:"Sorry. You've sent too many requests in a short period. Please try again later."},
    "TimeoutErrorSMS": {key:"ErrorMessages-appsynccontactus-sms-timeout", value:"Support center is busy now. If you don't receive Text within 5 minutes, please retry or try other contact methods."},
    "TimeoutErrorCall": {key:"ErrorMessages-appsynccontactus-call-timeout", value:"Support center is busy now. If you don't receive Call within 5 minutes, please retry or try other contact methods."}
};

export const AppSyncWithdrawMessages: { [key: string]: { [key: string]: string } } = {
    "DEFAULT": {key:"ErrorMessages-appsyncjobdetail-default", value:"Internal server issue. The server didn't respond in time. Try refreshing the browser."},
    "Unauthorized" : {key:"ErrorMessages-appsyncwithdraw-unauthorized", value:"."},
    "ERR_RESPONSE_SERVICE" : {key:"ErrorMessages-appsyncjobdetail-default", value:"Internal server issue. The server didn't respond in time. Try refreshing the browser."},
    "SUCCESS": {key:"ErrorMessages-appsyncwithdraw-success", value:"Application withdrawn (may take a while to update application list)."}
};