import { from, Observable, of } from "rxjs";
import { flatMap, map, catchError } from "rxjs/internal/operators";
import { ofType } from "redux-observable";
import { getConfigApi, getCountryStateConfig } from '../utils/apis/serviceApi';
import { actionGetConfigSuccess} from "../actions/actions";
import {
  GET_CONFIG,
  GET_CONFIG_FAILED,
} from "../actions/actionTypes";
import { AuthGetConfigErrorMessages, GetCountryStateConfigErrorMessages } from "src/utils/errorMessages";
import { GET_COUNTRY_STATE_CONFIG_TYPE } from "src/actions/ConfigActions/configActionTypes";
import { actionGetCountryStateConfigSuccess } from "src/actions/ConfigActions/configActions";

export const getConfigEpic = (action$: Observable<any>) => {
  return action$.pipe(
    ofType(GET_CONFIG),
    flatMap((action: any) =>
      from<Promise<any>>(
        getConfigApi()
      ).pipe(
        flatMap(async (response) => {
          return response.data
        }),
        map((x) => actionGetConfigSuccess(x, action.callback)),
        catchError((error) => {
            let errorMessage = AuthGetConfigErrorMessages.DEFAULT;

            if(error && error.message) {
                errorMessage =  AuthGetConfigErrorMessages[error.message] || AuthGetConfigErrorMessages.DEFAULT;
            }

            return of({
            type: GET_CONFIG_FAILED,
            payload: {
                errorMessage
            }
          });
        })
      )
    )
  );
};

export const getCountryStateConfigEpic = (action$: Observable<any>) => {
  return action$.pipe(
    ofType(GET_COUNTRY_STATE_CONFIG_TYPE.GET),
    flatMap((action: any) =>
      from<Promise<any>>(
        getCountryStateConfig()
      ).pipe(
        flatMap(async (response) => {
          return response.data
        }),
        map((x) => actionGetCountryStateConfigSuccess(x, action.callback)),
        catchError((error) => {
            let errorMessage = GetCountryStateConfigErrorMessages.DEFAULT;

            if(error && error.message) {
              errorMessage =  GetCountryStateConfigErrorMessages[error.message] || GetCountryStateConfigErrorMessages.DEFAULT;
            }

            return of({
            type: GET_COUNTRY_STATE_CONFIG_TYPE.FAILED,
            payload: {
              errorMessage
            }
          });
        })
      )
    )
  );
};
