import { Action } from 'redux';
import {
  CloseRedirectBannerAction,
  DisplayRedirectBannerAction,
  RedirectBannerActionTypes,
} from 'src/actions/UIActions/UIActionTypes';
import { BannerMessage } from 'src/utils/commonTypes';

export enum LoadingTypes {
  CREATE_CANDIDATE = 'CREATE_CANDIDATE',
  CREATE_CANDIDATE_SUCCESS = 'CREATE_CANDIDATE_SUCCESS',
  CREATE_CANDIDATE_FAILED = 'CREATE_CANDIDATE_FAILED',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
  VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS',
  VERIFY_EMAIL_FAILED = 'VERIFY_EMAIL_FAILED',
  VERIFY_PHONE = 'VERIFY_PHONE',
  VERIFY_PHONE_SUCCESS = 'VERIFY_PHONE_SUCCESS',
  VERIFY_PHONE_FAILED = 'VERIFY_PHONE_FAILED',
  VERIFY_SIGN_IN = 'VERIFY_SIGN_IN',
  VERIFY_SIGN_IN_SUCCESS = 'VERIFY_SIGN_IN_SUCCESS',
  VERIFY_SIGN_IN_FAILED = 'VERIFY_SIGN_IN_FAILED',
  SIGN_IN = 'SIGN_IN',
  SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILED = 'SIGN_IN_FAILED',
  GET_CANDIDATE = 'GET_CANDIDATE',
  GET_CANDIDATE_SUCCESS = 'GET_CANDIDATE_SUCCESS',
  GET_CANDIDATE_FAILED = 'GET_CANDIDATE_FAILED',
  CONFIRM_OTP = 'CONFIRM_OTP',
  CONFIRM_OTP_FAILED = 'CONFIRM_OTP_FAILED',
  CONFIRM_OTP_SUCCESS = 'CONFIRM_OTP_SUCCESS',
  CHECK_STATE = 'CHECK_STATE',
  SIGN_OUT = 'SIGN_OUT',
  SIGN_OUT_FAILED = 'SIGN_OUT_FAILED',
  LOADIND_START = 'LOADIND_START',
  LOADIND_END = 'LOADIND_END',
  UPDATE_PHONE = 'UPDATE_PHONE',
  UPDATE_PHONE_SUCCESS = 'UPDATE_PHONE_SUCCESS',
  UPDATE_PHONE_FAILED = 'UPDATE_PHONE_FAILED',
}

export interface LoadingAction extends Action {
  type:
    | LoadingTypes.CREATE_CANDIDATE
    | LoadingTypes.CREATE_CANDIDATE_FAILED
    | LoadingTypes.CREATE_CANDIDATE_SUCCESS
    | LoadingTypes.VERIFY_EMAIL
    | LoadingTypes.VERIFY_EMAIL_FAILED
    | LoadingTypes.VERIFY_EMAIL_SUCCESS
    | LoadingTypes.VERIFY_PHONE
    | LoadingTypes.VERIFY_PHONE_FAILED
    | LoadingTypes.VERIFY_PHONE_SUCCESS
    | LoadingTypes.VERIFY_SIGN_IN
    | LoadingTypes.VERIFY_SIGN_IN_SUCCESS
    | LoadingTypes.VERIFY_SIGN_IN_FAILED
    | LoadingTypes.SIGN_IN
    | LoadingTypes.SIGN_IN_FAILED
    | LoadingTypes.SIGN_IN_SUCCESS
    | LoadingTypes.GET_CANDIDATE
    | LoadingTypes.GET_CANDIDATE_SUCCESS
    | LoadingTypes.GET_CANDIDATE_FAILED
    | LoadingTypes.CONFIRM_OTP
    | LoadingTypes.CONFIRM_OTP_FAILED
    | LoadingTypes.CONFIRM_OTP_SUCCESS
    | LoadingTypes.CHECK_STATE
    | LoadingTypes.SIGN_OUT
    | LoadingTypes.UPDATE_PHONE
    | LoadingTypes.UPDATE_PHONE_FAILED
    | LoadingTypes.UPDATE_PHONE_SUCCESS
    | LoadingTypes.LOADIND_START
    | LoadingTypes.LOADIND_END;
}

export const actionDisplayRedirectBanner = (payload: BannerMessage): DisplayRedirectBannerAction => {
  return { type: RedirectBannerActionTypes.DISPLAY, payload };
};

export const actionCloseRedirectBanner = (): CloseRedirectBannerAction => {
  return { type: RedirectBannerActionTypes.CLOSE };
};
