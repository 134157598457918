import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PageHeader } from '@amzn/stencil-react-components/page';
import { Col } from '@amzn/stencil-react-components/layout';
import { useBreakpoints } from '@amzn/stencil-react-components/responsive';
import { MessageBanner, MessageBannerLink, MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { generateFocusableItemClasses, getCookieConsent, goToHomePage } from 'src/utils/helper';
import { HVHColors } from 'src/utils/colors';
import { UIState } from 'src/reducers/ui.reducer';
import { ConsentCard } from 'src/components/Navigation/ConsentCard';
import { LogoDarkImage, LogoImage } from 'src/images';
import { AlertState } from 'src/reducers/alert.reducer';
import { isEmailFieldOptional, isStencil2021Theme } from 'src/utils/featureFlag/featureFlag';
import { boundCloseRedirectBanner, boundDisplayRedirectBanner } from 'src/actions/UIActions/boundUIActions';
import { CountryFullName } from 'src/utils/commonTypes';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { WebLabState } from 'src/reducers/weblab.reducer';
import { getCountryFullName } from 'src/utils/apis/apiHelper';
import useHVHTranslation from 'src/Hooks/useHVHTranslation';

const classnames = require('classnames');

interface MapStateToProps {
  ui: UIState;
  alert: AlertState;
  webLab?: WebLabState;
}

interface NavigationProps {}

type NavigationMergedStateProps = NavigationProps & MapStateToProps;

export const Navigation = (props: NavigationMergedStateProps) => {
  const { alert } = props;
  const [isConsentCardOpen, setConsentCardOpenState] = useState(false);
  const [isNavFixed, setIsNavFixed] = useState(false);
  const closeConsentCard = () => setConsentCardOpenState(false);
  const { matches } = useBreakpoints();
  const { t } = useHVHTranslation();

  const renderRedirectBannerMessages = () => {
    if (!alert.showRedirectBanner) return null;

    return alert.redirectBannerMessages.map((message) => (
      <Col gridGap={'S300'} key={`${message}`}>
        <MessageBanner
          titleText={message.title}
          isDismissible
          type={message.bannerType || MessageBannerType.Warning}
          aria-live="polite"
          onDismissed={() => boundCloseRedirectBanner()}
          dismissButtonAltText="Close Canada site redirect banner"
        >
          {message.message}
        </MessageBanner>
      </Col>
    ));
  };

  useEffect(() => {
    if (!getCookieConsent()) {
      setConsentCardOpenState(true);
    }

    if (getCountryFullName() === CountryFullName.US) {
      const customerLocationCountry: HTMLMetaElement | null = document.querySelector('meta[name="location_country"]');
      if (customerLocationCountry?.content === 'CA') {
        boundDisplayRedirectBanner({
          message: (
            <>
              {t(
                'Navigation-canada-redirect-banner-link-text',
                'Seems like you’re visiting the US website from Canada.',
              )}{' '}
              <MessageBannerLink href="https://www.amazondelivers.jobs/canada/">
                {t('Navigation-canada-redirect-banner-link-text', 'Go to Canada website.')}
              </MessageBannerLink>
            </>
          ),
        });
      }
    }
  }, []);

  // Make sure Nav Bar doesn't overlay the Global Alert Banner
  const setNavFixedWhenScroll = () => {
    if (window.scrollY === 0) {
      setIsNavFixed(false);
    } else {
      setIsNavFixed(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', setNavFixedWhenScroll);
    return () => window.removeEventListener('scroll', setNavFixedWhenScroll);
  }, [isNavFixed]);

  const stickyMessageBannerClass = classnames('stickyMessageBanner', { fixed: isNavFixed });

  const renderStickyAlerts = () => {
    if (!alert.showStickyAlerts) return null;
    return (
      <div className={stickyMessageBannerClass}>
        {alert.stickyAlerts.map((alert) => {
          const { errorTitle, errorMessage } = alert;
          const titleText = typeof errorTitle === 'object' ? t(errorTitle.key, errorTitle.value) : errorTitle;
          const messageContent =
            typeof errorMessage === 'object' ? t(errorMessage.key, errorMessage.value) : errorMessage;
          return (
            <MessageBanner
              titleText={titleText}
              isDismissible
              type={alert.alertType || MessageBannerType.Error}
              aria-live="assertive"
              dismissButtonAltText="Close sticky alerts"
              key={`${errorTitle}`}
            >
              {messageContent}
            </MessageBanner>
          );
        })}
      </div>
    );
  };

  return (
    <Col>
      <Col
        backgroundColor={isStencil2021Theme() ? HVHColors.NavBackGround2021 : HVHColors.NavBackGround}
        minHeight={matches.xl ? 'auto' : '56px'}
        style={{ paddingBottom: '0' }}
      >
        {renderRedirectBannerMessages()}
        <PageHeader isFixed={isNavFixed} hasShadow>
          <Col justifyContent="center" padding={`0px ${matches.s || matches.m ? 0 : '8px'}`} tabIndex={-1}>
            {!isEmailFieldOptional() && (
              <Button
                tabIndex={0}
                onClick={goToHomePage}
                dataTestId="navigationLogoLink"
                className={`sidePanelIconLogo ${generateFocusableItemClasses()}`}
                variant={ButtonVariant.Tertiary}
                role="link"
                onKeyDown={(event) => {
                  // noinspection JSDeprecatedSymbols
                  if (event.key === 'Enter' || event.keyCode === 32) {
                    event.preventDefault();
                    goToHomePage();
                  }
                }}
              >
                <img tabIndex={-1} src={isStencil2021Theme() ? LogoDarkImage : LogoImage} alt="Amazon Jobs - Log In" />
              </Button>
            )}
          </Col>
        </PageHeader>
        {renderStickyAlerts()}
        <ConsentCard closeModal={closeConsentCard} isOpen={isConsentCardOpen} />
      </Col>
    </Col>
  );
};

const mapStateToProps = (state: MapStateToProps) => {
  return state;
};

export default connect(mapStateToProps)(Navigation);
