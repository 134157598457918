export enum FeatureFlag {
  theme = 'theme',
  preferredName = 'preferredName',
  englishName = 'englishName',
  useBBSignOut = 'useBBSignOut',
  tokenCookie = 'tokenCookie',
  multiLanguage = 'multiLanguage',
  sfOnDemandSync = 'sfOnDemandSync',
  middleName = 'middleName',
  suffix = 'suffix',
  familyNameFirst = 'familyNameFirst',
  isEmailOptional = 'isEmailOptional',
  rightToLeftText = 'rightToLeftText',
  isAgencyHiring = 'isAgencyHiring',
  physicalStores = 'physicalStores',
  skipPhoneVerification = 'skipPhoneVerification',
}

export interface FeatureFlagConfig {
  theme: string;
  preferredName: boolean;
  preferredNameFullyReady: boolean;
  useBBSignOut: boolean;
  tokenCookie: boolean;
  multiLanguage: boolean;
  sfOnDemandSync: boolean;
  middleName: boolean;
  suffix: boolean;
  familyNameFirst: boolean;
  isEmailOptional: boolean;
  rightToLeftText: boolean;
  isAgencyHiring: boolean;
  physicalStores: boolean;
  skipPhoneVerification: boolean;
}
