import { from, Observable, of } from "rxjs";
import { flatMap, map, catchError } from "rxjs/internal/operators";
import { ofType } from "redux-observable";
import { confirmOtpApi } from '../utils/apis/serviceApi';
import { getPath, parseErrorMessage } from "../utils/helper";
import { actionConfirmOtpSuccess} from "../actions/actions";
import {
  CONFIRM_OTP,
  CONFIRM_OTP_FAILED,
  CONFIRM_OTP_SUCCESS,
  CONFIRM_OTP_CALLBACK_SUCCESS,
} from "../actions/actionTypes";
import { emitEvent } from "src/utils/rum";
import { getCountryCode } from "src/utils/apis/apiHelper";
import { RumEventType } from "src/utils/constants";

export const confirmOtpEpic = (action$: Observable<any>) => {
  return action$.pipe(
    ofType(CONFIRM_OTP),
    flatMap((action: any) =>
      from<Promise<any>>(
        confirmOtpApi(action.payload)
      ).pipe(
        flatMap(async (response) => {
          return response.data
        }),
        map((x) => actionConfirmOtpSuccess(x, action.callback)),
        catchError((error) => {
            const response = error.response;
            if (action.onError) {
                action.onError(response);
            }

            const errorMessage = parseErrorMessage(response.data);

            emitEvent(
              {
                event: RumEventType.CONFIRM_OTP,
                status: false,
                error: errorMessage,
                errorCode: response.status,
                country: getCountryCode(),
                availability: 0,
                path: getPath(),
              }
            );

            return of({
                type: CONFIRM_OTP_FAILED,
                payload: errorMessage,
            });
        })
      )
    )
  );
};

export const confirmOtpSuccessEpic = (action$: Observable<any>) => {
    return action$.pipe(
      ofType(CONFIRM_OTP_SUCCESS),
      map((action) => {
        if (action.callback) {
          action.callback(action.payload);
        }

        return { type: CONFIRM_OTP_CALLBACK_SUCCESS, payload: null };
      })
    );
};