import React from "react";
import { Helmet } from "react-helmet";
import { generateCanonicalURL } from "src/utils/helper";

interface AppSEOHeaderProps {
    isDynamic: boolean;
}

// For better SEO, we want to add canonical urls tag including the URL for the page we do want indexed.
// IMPORTANT: This component should not be used for rendering header.
export const AppSEOHeader = (props: AppSEOHeaderProps) => {
    return <Helmet>
        <link rel="canonical" href={generateCanonicalURL()} />
    </Helmet>
};
