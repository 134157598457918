import { from, Observable, of } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/internal/operators';
import { ofType } from 'redux-observable';
import { confirmOtpApi } from '../utils/apis/serviceApi';
import { parseErrorMessage } from '../utils/helper';
import { actionConfirmOtpSuccess } from '../actions/actions';
import {
  CONFIRM_OTP,
  CONFIRM_OTP_CALLBACK_SUCCESS,
  CONFIRM_OTP_FAILED,
  CONFIRM_OTP_SUCCESS,
} from '../actions/actionTypes';
import { emitEvent } from 'src/utils/rum';
import { RumEventType } from 'src/utils/constants';

export const confirmOtpEpic = (action$: Observable<any>) => {
  return action$.pipe(
    ofType(CONFIRM_OTP),
    flatMap((action: any) =>
      from<Promise<any>>(confirmOtpApi(action.payload)).pipe(
        flatMap(async (response) => {
          return response.data;
        }),
        map((x) => actionConfirmOtpSuccess(x, action.callback)),
        catchError((error) => {
          const { response } = error;
          if (action.onError) {
            action.onError(response);
          }

          const errorMessage = parseErrorMessage(response.data);

          emitEvent({
            event: RumEventType.CONFIRM_OTP,
            status: false,
            error: errorMessage,
            errorCode: response.status,
            availability: 0,
          });

          return of({
            type: CONFIRM_OTP_FAILED,
            payload: errorMessage,
          });
        }),
      ),
    ),
  );
};

export const confirmOtpSuccessEpic = (action$: Observable<any>) => {
  return action$.pipe(
    ofType(CONFIRM_OTP_SUCCESS),
    map((action) => {
      if (action.callback) {
        action.callback(action.payload);
      }

      return { type: CONFIRM_OTP_CALLBACK_SUCCESS, payload: null };
    }),
  );
};
