import {
  WebLabConfig,
  WebLabConfigs,
  WebLabMetricProps,
  WebLabMetricRequest,
  WebLabTreatment,
} from 'src/utils/webLab/types';
import { boundSendWebLabMetrics, boundSetWebLabConfig } from 'src/actions/WebLab/boundWebLabActions';
import { WebLabMetricAPI } from 'src/utils/constants';
import { getCountryFullName, getStage } from 'src/utils/apis/apiHelper';

export const extractWebLabConfig = () => {
  const webLabMeta = document.head.querySelectorAll('meta[name="hvhWebLab"]');
  const webLabConfigs: WebLabConfigs = [];

  webLabMeta.forEach((meta) => {
    const id = meta.getAttribute('id');
    const value = meta.getAttribute('value');

    if (id && value) {
      if (id === 'webLabSessionId') {
        setWebLabSessionId(value);
      } else if (id.startsWith('HVH_CS')) {
        const treatment = meta.getAttribute('treatment');

        const webLabConfig: WebLabConfig = {
          id,
          treatment: getWebLabTreatment(treatment),
          features: value.split(',').map((feature) => feature.trim()),
        };

        webLabConfigs.push(webLabConfig);
      }
    }
  });

  boundSetWebLabConfig(webLabConfigs);
};

export const getWebLabTreatment = (key: string | null) => {
  switch (key) {
    case WebLabTreatment.C:
    case WebLabTreatment.T1:
    case WebLabTreatment.T2:
    case WebLabTreatment.T3:
      return key;

    default:
      return WebLabTreatment.C;
  }
};

export const getTreatmentForFeature = (webLabConfigs: WebLabConfigs | undefined, feature: string): WebLabTreatment => {
  if (!webLabConfigs) {
    return WebLabTreatment.C;
  }

  const webLabConfig = webLabConfigs.find((config) => config.features.includes(feature));

  if (webLabConfig) {
    return getWebLabTreatment(webLabConfig.treatment);
  }

  return WebLabTreatment.C;
};

/**
 * This is a special treatment method for MLS roll out.
 * 1. If there's no treatment fount in meta tag for mlsEnabled, don't do anything.
 * 2. If there's a treatment found for mlsEnabled
 *  2.1. If the treatment is C, set localStorage of mlsEnabled to 'false'
 *  2.2. If the treatment is T1, set localStorage of mlsEnabled to 'true'
 */
export const getTreatmentForFeatureForMLS = (webLabConfigs: WebLabConfigs | undefined, feature: string) => {
  if (!webLabConfigs) {
    return;
  }

  const webLabConfig = webLabConfigs.find((config) => config.features.includes(feature));

  if (webLabConfig) {
    window.localStorage.setItem(
      feature,
      getWebLabTreatment(webLabConfig.treatment) === WebLabTreatment.T1 ? 'true' : 'false',
    );
  }
};

export const setWebLabSessionId = (sessionId: string) => {
  //set sessionId only when sessionId doesn't exist
  !getWebLabSessionId() && window.localStorage.setItem('wlbsid', sessionId);
};

export const setWebLabId = (webLabId: string) => {
  //set webLabId only when it doesn't exist
  !getWebLabId() && window.localStorage.setItem('wlbid', webLabId);
};

export const getWebLabSessionId = () => {
  return window.localStorage.getItem('wlbsid') || null;
};

export const getWebLabId = () => {
  return window.localStorage.getItem('wlbid') || null;
};

export const getWebLabMetricEndpoint = () => {
  const countryFullName = getCountryFullName();
  const stage = getStage();
  return WebLabMetricAPI[countryFullName] && WebLabMetricAPI[countryFullName][stage]
    ? WebLabMetricAPI[countryFullName][stage]
    : 'https://beta-us.devo.jobsatamazon.hvh.a2z.com/weblab-metrics';
};

export const sendWebLabMetric = (metricProps: WebLabMetricProps) => {
  const { webLabConfigs, actionType, jobId, jobType, scheduleId, dataSource } = metricProps;

  if (!webLabConfigs.length) return;

  const requestConfig: Partial<WebLabMetricRequest> = {
    sessionId: getWebLabSessionId(),
    actionType,
    jobId,
    jobType,
    scheduleId,
    dataSource,
    timestamp: Date.now(),
    stage: getStage(),
  };

  let request = parseObjectToQueryString(requestConfig);

  webLabConfigs.forEach((config) => {
    request += `&webLabConfig=${encodeURIComponent(JSON.stringify(config))}`;
  });

  boundSendWebLabMetrics(request);
};

export const parseObjectToQueryString = (obj: { [key: string]: any }): string => {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      const value = typeof obj[p] === 'object' ? JSON.stringify(obj[p]) : obj[p];
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(value));
    }
  return str.join('&');
};
