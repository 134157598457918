import { getCountryFullName, showPhoneNumberInputReminder } from '../apis/apiHelper';
import { CountryCode, CountryFullName, PreferredLanguagesItem } from '../commonTypes';
import { PhoneCodeByCountry } from '../phoneCodes';
import {
  PhoneCodes,
  PhoneCodesByCountry,
  PreferredLanguages,
  REGISTRATION_FIELDS,
  RegistrationFieldCustomPlaceholderKeyMap,
  RegistrationFieldCustomPlaceholderValueMap,
  RegistrationFieldDefaultPlaceholderKeyMap,
  RegistrationFieldDefaultPlaceholderValueMap,
  SuffixOptions,
  TimeZones,
} from './constants';
import { RegistrationFormConfigType } from 'src/utils/registrationFrom/registrationTypes';
import { nameRegexDefault, nameRegexMap } from 'src/utils/constants';

export const getPlaceholderByFieldName = (
  fieldName: REGISTRATION_FIELDS,
  customMap: Map<CountryFullName, any>,
  defaultMap: Map<REGISTRATION_FIELDS, any>,
  countryFullName?: CountryFullName,
) => {
  const country = countryFullName || getCountryFullName();
  const placeholderValueSetByCountry = customMap.get(country);
  return placeholderValueSetByCountry.get(fieldName) || defaultMap.get(fieldName) || '';
};

export const getPhoneCountryCode = (countryFullName?: CountryFullName): string => {
  const countryCode = countryFullName || CountryCode[getCountryFullName()];
  return PhoneCodeByCountry.get(countryCode) || PhoneCodesByCountry[0];
};

export function swapComponents<T>(currentFormConfig: T[], componentIndex1: number, componentIndex2: number): T[] {
  const [index1, index2] = [currentFormConfig[componentIndex1], currentFormConfig[componentIndex2]];
  currentFormConfig[componentIndex1] = index2;
  currentFormConfig[componentIndex2] = index1;
  return currentFormConfig;
}

export const getRegistrationFormConfig = (countryFullName: CountryFullName): RegistrationFormConfigType => {
  const nameRegexByCountry = nameRegexMap[countryFullName] || nameRegexDefault;
  const phoneCodesByCountry = getPhoneCodesByCountry(countryFullName);
  const timeZonesByCountry = getTimeZoneByCountry(countryFullName);
  const preferredLanguageByCountry = getPreferredLanguagesByCountry(countryFullName);

  return {
    Registration: [
      {
        type: 'text',
        hasError: false,
        errorText: { key: 'ErrorMessages-legal-first-name-required', value: 'Please provide legal first name.' },
        placeholder: getPlaceholderByFieldName(
          REGISTRATION_FIELDS.FIRST_NAME,
          RegistrationFieldCustomPlaceholderValueMap,
          RegistrationFieldDefaultPlaceholderValueMap,
          countryFullName,
        ),
        placeholderKey: getPlaceholderByFieldName(
          REGISTRATION_FIELDS.FIRST_NAME,
          RegistrationFieldCustomPlaceholderKeyMap,
          RegistrationFieldDefaultPlaceholderKeyMap,
          countryFullName,
        ),
        optionalText: '',
        labelText: 'Legal first name',
        labelTextKey: 'Constants-lbl-legal-first-name',
        id: 'firstName',
        name: REGISTRATION_FIELDS.FIRST_NAME,
        errorMessage: '',
        showErrorMessage: false,
        inputType: 'text',
        required: true,
        /*
          Any name that contains between 2 and 40 English alphabet.
          Special character allow: whitespace( ), comma(,), period(.), apostrophe('), hyphen(-).
          Example: Mathias d'Arras   Martin Luther King, Jr. Hector Sausage-Hausen

          Note: Only English alphabet is supported for now.
          Reference: https://stackoverflow.com/questions/2385701/regular-expression-for-first-and-last-name
        */
        regex: nameRegexByCountry.firstName,
        autoComplete: 'given-name',
      },
      {
        type: 'checkboxControlledText',
        hasError: false,
        errorText: { key: 'ErrorMessages-legal-middle-name-required', value: 'Please provide legal middle name.' },
        placeholder: getPlaceholderByFieldName(
          REGISTRATION_FIELDS.MIDDLE_NAME,
          RegistrationFieldCustomPlaceholderValueMap,
          RegistrationFieldDefaultPlaceholderValueMap,
          countryFullName,
        ),
        placeholderKey: getPlaceholderByFieldName(
          REGISTRATION_FIELDS.MIDDLE_NAME,
          RegistrationFieldCustomPlaceholderKeyMap,
          RegistrationFieldDefaultPlaceholderKeyMap,
          countryFullName,
        ),
        optionalText: '',
        labelText: 'Legal middle name',
        labelTextKey: 'Constants-lbl-legal-middle-name',
        checkboxLabelText: "I don't have a middle name",
        checkboxLabelTextKey: 'Constants-lbl-no-middle-name',
        id: 'middleName',
        name: REGISTRATION_FIELDS.MIDDLE_NAME,
        errorMessage: '',
        showErrorMessage: false,
        inputType: 'text',
        required: true,
        regex: nameRegexByCountry.middleName,
        autoComplete: 'additional-name',
        isMiddleName: true,
      },
      {
        type: 'text',
        hasError: false,
        errorText: { key: 'ErrorMessages-legal-last-name-required', value: 'Please provide legal last name.' },
        placeholder: getPlaceholderByFieldName(
          REGISTRATION_FIELDS.LAST_NAME,
          RegistrationFieldCustomPlaceholderValueMap,
          RegistrationFieldDefaultPlaceholderValueMap,
          countryFullName,
        ),
        placeholderKey: getPlaceholderByFieldName(
          REGISTRATION_FIELDS.LAST_NAME,
          RegistrationFieldCustomPlaceholderKeyMap,
          RegistrationFieldDefaultPlaceholderKeyMap,
          countryFullName,
        ),
        optionalText: '',
        labelText: 'Legal last name',
        labelTextKey: 'Constants-lbl-legal-last-name',
        id: 'lastName',
        name: REGISTRATION_FIELDS.LAST_NAME,
        errorMessage: '',
        showErrorMessage: false,
        inputType: 'text',
        required: true,
        regex: nameRegexByCountry.lastName,
        autoComplete: 'family-name',
      },
      {
        type: 'select',
        id: 'nameSuffix',
        hasError: false,
        labelText: 'Suffix',
        labelTextKey: 'Constants-lbl-legal-suffix',
        name: REGISTRATION_FIELDS.SUFFIX,
        showErrorMessage: false,
        placeholder: getPlaceholderByFieldName(
          REGISTRATION_FIELDS.SUFFIX,
          RegistrationFieldCustomPlaceholderValueMap,
          RegistrationFieldDefaultPlaceholderValueMap,
          countryFullName,
        ),
        placeholderKey: getPlaceholderByFieldName(
          REGISTRATION_FIELDS.SUFFIX,
          RegistrationFieldCustomPlaceholderKeyMap,
          RegistrationFieldDefaultPlaceholderKeyMap,
          countryFullName,
        ),
        options: SuffixOptions,
        isSuffix: true,
      },
      {
        type: 'text',
        hasError: false,
        errorText: {
          key: 'ErrorMessages-preferred-first-name-required',
          value: 'Please provide preferred first name.',
        },
        placeholder: getPlaceholderByFieldName(
          REGISTRATION_FIELDS.PREFERRED_FIRST_NAME,
          RegistrationFieldCustomPlaceholderValueMap,
          RegistrationFieldDefaultPlaceholderValueMap,
          countryFullName,
        ),
        placeholderKey: getPlaceholderByFieldName(
          REGISTRATION_FIELDS.PREFERRED_FIRST_NAME,
          RegistrationFieldCustomPlaceholderKeyMap,
          RegistrationFieldDefaultPlaceholderKeyMap,
          countryFullName,
        ),
        optionalText: '',
        labelText: 'Preferred first name',
        labelTextKey: 'Constants-lbl-preferred-first-name',
        id: 'preferredFirstName',
        name: REGISTRATION_FIELDS.PREFERRED_FIRST_NAME,
        errorMessage: '',
        showErrorMessage: false,
        inputType: 'text',
        required: false,
        regex: nameRegexByCountry.preferredFirstName,
        autoComplete: 'preferred-firstName',
        isPreferredName: true,
      },
      {
        type: 'text',
        hasError: false,
        errorText: { key: 'ErrorMessages-preferred-last-name-required', value: 'Please provide preferred last name.' },
        placeholder: getPlaceholderByFieldName(
          REGISTRATION_FIELDS.PREFERRED_LAST_NAME,
          RegistrationFieldCustomPlaceholderValueMap,
          RegistrationFieldDefaultPlaceholderValueMap,
          countryFullName,
        ),
        placeholderKey: getPlaceholderByFieldName(
          REGISTRATION_FIELDS.PREFERRED_LAST_NAME,
          RegistrationFieldCustomPlaceholderKeyMap,
          RegistrationFieldDefaultPlaceholderKeyMap,
          countryFullName,
        ),
        optionalText: '',
        labelText: 'Preferred last name',
        labelTextKey: 'Constants-lbl-preferred-last-name',
        id: 'preferredLastName',
        name: REGISTRATION_FIELDS.PREFERRED_LAST_NAME,
        errorMessage: '',
        showErrorMessage: false,
        inputType: 'text',
        required: false,
        regex: nameRegexByCountry.preferredLastName,
        autoComplete: 'preferred-lastName',
        isPreferredName: true,
      },
      {
        type: 'text',
        hasError: false,
        errorText: { key: 'ErrorMessages-english-first-name-required', value: 'Please provide English first name.' },
        placeholder: getPlaceholderByFieldName(
          REGISTRATION_FIELDS.English_FIRST_NAME,
          RegistrationFieldCustomPlaceholderValueMap,
          RegistrationFieldDefaultPlaceholderValueMap,
          countryFullName,
        ),
        placeholderKey: getPlaceholderByFieldName(
          REGISTRATION_FIELDS.English_FIRST_NAME,
          RegistrationFieldCustomPlaceholderKeyMap,
          RegistrationFieldDefaultPlaceholderKeyMap,
          countryFullName,
        ),
        optionalText: '',
        labelText: 'English first name',
        labelTextKey: 'Constants-lbl-english-first-name',
        id: 'englishFirstName',
        name: REGISTRATION_FIELDS.English_FIRST_NAME,
        errorMessage: '',
        showErrorMessage: false,
        inputType: 'text',
        required: true,
        regex: nameRegexByCountry.englishFirstName,
        autoComplete: 'english-firstName',
        isEnglishName: true,
      },
      {
        type: 'text',
        hasError: false,
        errorText: { key: 'ErrorMessages-english-last-name-required', value: 'Please provide English last name.' },
        placeholder: getPlaceholderByFieldName(
          REGISTRATION_FIELDS.English_LAST_NAME,
          RegistrationFieldCustomPlaceholderValueMap,
          RegistrationFieldDefaultPlaceholderValueMap,
          countryFullName,
        ),
        placeholderKey: getPlaceholderByFieldName(
          REGISTRATION_FIELDS.English_LAST_NAME,
          RegistrationFieldCustomPlaceholderKeyMap,
          RegistrationFieldDefaultPlaceholderKeyMap,
          countryFullName,
        ),
        optionalText: '',
        labelText: 'English last name',
        labelTextKey: 'Constants-lbl-english-last-name',
        id: 'englishLastName',
        name: REGISTRATION_FIELDS.English_LAST_NAME,
        errorMessage: '',
        showErrorMessage: false,
        inputType: 'text',
        required: true,
        regex: nameRegexByCountry.englishLastName,
        autoComplete: 'english-lastName',
        isEnglishName: true,
      },
      {
        type: 'email',
        hasError: false,
        errorText: { key: 'ErrorMessages-email-address-required', value: 'Please provide email address.' },
        labelText: 'Email address',
        labelTextKey: 'Constants-lbl-email',
        id: 'emailId',
        name: REGISTRATION_FIELDS.EMAIL,
        errorMessage: '',
        showErrorMessage: false,
        inputType: 'text',
        required: true,
        regex: '^([a-zA-Z0-9_\\-\\.\\+]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$',
        autoComplete: 'email',
        spellCheck: false,
        isEmailOptional: true,
      },
      {
        type: 'email',
        id: 'reEnterEmailId',
        hasError: false,
        errorText: { key: 'ErrorMessages-email-address-required', value: 'Please provide email address.' },
        labelText: 'Re-enter email address',
        labelTextKey: 'Constants-lbl-reenter-email',
        name: REGISTRATION_FIELDS.RE_ENTER_EMAIL,
        errorMessage: '',
        showErrorMessage: false,
        inputType: 'text',
        required: true,
        regex: '^([a-zA-Z0-9_\\-\\.\\+]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$',
        disablePaste: true,
        autoComplete: 'email',
        isEmailOptional: true,
      },
      {
        type: 'tel',
        id: 'phoneNumber',
        hasError: false,
        errorText: { key: 'ErrorMessages-phone-number-required', value: 'Please provide phone number.' },
        labelText: showPhoneNumberInputReminder(countryFullName)
          ? 'Mobile number (Please input your phone number without leading 0.)'
          : 'Mobile number',
        labelTextKey: showPhoneNumberInputReminder(countryFullName)
          ? 'Constants-label-phone-number-without-leading'
          : 'Constants-label-phone-number',
        name: REGISTRATION_FIELDS.PHONE_NUMBER,
        errorMessage: '',
        showErrorMessage: false,
        required: true,
        inputMode: 'tel',
        inputType: 'tel',
        countryCode: phoneCodesByCountry[0],
        options: phoneCodesByCountry,
      },
      {
        type: 'tel',
        id: 'reEnterPhoneNumber',
        hasError: false,
        errorText: { key: 'ErrorMessages-phone-number-required', value: 'Please provide phone number.' },
        labelText: 'Re-enter mobile number',
        labelTextKey: 'Constants-label-reenter-phone-number',
        name: REGISTRATION_FIELDS.RE_ENETER_PHONE_NUMBER,
        errorMessage: '',
        showErrorMessage: false,
        required: true,
        inputMode: 'tel',
        inputType: 'tel',
        countryCode: phoneCodesByCountry[0],
        disablePaste: true,
        options: phoneCodesByCountry,
      },
      {
        type: 'select',
        id: 'language',
        hasError: false,
        errorText: { key: 'ErrorMessages-preferred-language-required', value: 'Please select a preferred language.' },
        labelText: 'Preferred language',
        labelTextKey: 'Constants-lbl-preferred-language',
        name: REGISTRATION_FIELDS.PREFERRED_LANGUAGE,
        errorMessage: '',
        showErrorMessage: false,
        required: true,
        placeholder: getPlaceholderByFieldName(
          REGISTRATION_FIELDS.PREFERRED_LANGUAGE,
          RegistrationFieldCustomPlaceholderValueMap,
          RegistrationFieldDefaultPlaceholderValueMap,
          countryFullName,
        ),
        placeholderKey: getPlaceholderByFieldName(
          REGISTRATION_FIELDS.PREFERRED_LANGUAGE,
          RegistrationFieldCustomPlaceholderKeyMap,
          RegistrationFieldDefaultPlaceholderKeyMap,
          countryFullName,
        ),
        options: preferredLanguageByCountry,
      },
      {
        type: 'select',
        id: 'timezone',
        hasError: false,
        errorText: { key: 'ErrorMessages-timezone-required', value: 'Please provide timezone.' },
        labelText: 'Preferred timezone',
        labelTextKey: 'Constants-lbl-time-zone',
        name: REGISTRATION_FIELDS.PREFERRED_TIMEZONE,
        errorMessage: '',
        showErrorMessage: false,
        required: true,
        placeholder: getPlaceholderByFieldName(
          REGISTRATION_FIELDS.PREFERRED_TIMEZONE,
          RegistrationFieldCustomPlaceholderValueMap,
          RegistrationFieldDefaultPlaceholderValueMap,
          countryFullName,
        ),
        placeholderKey: getPlaceholderByFieldName(
          REGISTRATION_FIELDS.PREFERRED_TIMEZONE,
          RegistrationFieldCustomPlaceholderKeyMap,
          RegistrationFieldDefaultPlaceholderKeyMap,
          countryFullName,
        ),
        options: timeZonesByCountry,
      },
      {
        type: 'password',
        id: 'pin',
        hasError: false,
        errorText: { key: 'ErrorMessages-val-pin-length', value: 'PIN must have exactly 6 numbers, 0-9.' },
        labelText: 'Create personal pin. A 6-digit number you can remember.',
        labelTextKey: 'Constants-lbl-six-digit-pin',
        name: REGISTRATION_FIELDS.PIN,
        errorMessage: '',
        showErrorMessage: false,
        required: true,
        regex: '^[0-9]{6,6}$',
        inputType: 'password',
        pattern: '[0-9]*',
        inputMode: 'numeric',
        maxLength: 6,
        font: 'primary',
        autoComplete: 'new-password',
      },
      {
        type: 'password',
        id: 'reEnterPin',
        hasError: false,
        errorText: { key: 'ErrorMessages-val-pin-length', value: 'PIN must have exactly 6 numbers, 0-9.' },
        labelText: 'Re-enter personal pin',
        labelTextKey: 'Constants-lbl-re-enter-four-digit-pin',
        name: REGISTRATION_FIELDS.RE_ENTER_PIN,
        errorMessage: '',
        showErrorMessage: false,
        required: true,
        regex: '^[0-9]{6,6}$',
        inputType: 'password',
        pattern: '[0-9]*',
        inputMode: 'numeric',
        maxLength: 6,
        font: 'primary',
        autoComplete: 'new-password',
        disablePaste: true,
      },
    ],
    Verify: [
      {
        type: 'email',
        id: 'emailId',
        hasError: false,
        errorText: { key: 'ErrorMessages-email-address-required', value: 'Please provide email address.' },
        labelText: 'Email address',
        labelTextKey: 'Constants-lbl-email',
        name: 'emailId',
        errorMessage: '',
        showErrorMessage: false,
        inputType: 'text',
        disabled: true,
        autoComplete: 'email',
        placeholder: '',
        placeholderKey: '',
        spellCheck: false,
      },
      {
        type: 'tel',
        id: 'phoneNumber',
        hasError: false,
        errorText: { key: 'ErrorMessages-phone-number-required', value: 'Please provide phone number.' },
        labelText: 'Mobile number',
        labelTextKey: 'Constants-label-phone-number',
        name: 'phoneNumber',
        errorMessage: '',
        showErrorMessage: false,
        required: true,
        countryCode: phoneCodesByCountry[0],
        inputMode: 'tel',
        inputType: 'text',
        disabled: false,
        options: phoneCodesByCountry,
        placeholder: '',
        placeholderKey: '',
      },
      {
        type: 'tel',
        id: 'reEnterPhoneNumber',
        hasError: false,
        errorText: { key: 'ErrorMessages-phone-number-required', value: 'Please provide phone number.' },
        labelText: 'Re-enter Mobile number',
        labelTextKey: 'Constants-label-reenter-phone-number',
        name: 'reEnterPhoneNumber',
        errorMessage: '',
        showErrorMessage: false,
        required: true,
        countryCode: phoneCodesByCountry[0],
        inputMode: 'tel',
        inputType: 'text',
        disabled: false,
        disablePaste: true,
        options: phoneCodesByCountry,
        placeholder: '',
        placeholderKey: '',
      },
      {
        type: 'select',
        id: 'language',
        hasError: false,
        errorText: { key: 'ErrorMessages-preferred-language-required', value: 'Please select a preferred language.' },
        labelText: 'Preferred language',
        labelTextKey: 'Constants-lbl-preferred-language',
        name: REGISTRATION_FIELDS.PREFERRED_LANGUAGE,
        errorMessage: '',
        showErrorMessage: false,
        required: true,
        placeholder: getPlaceholderByFieldName(
          REGISTRATION_FIELDS.PREFERRED_LANGUAGE,
          RegistrationFieldCustomPlaceholderValueMap,
          RegistrationFieldDefaultPlaceholderValueMap,
          countryFullName,
        ),
        placeholderKey: getPlaceholderByFieldName(
          REGISTRATION_FIELDS.PREFERRED_LANGUAGE,
          RegistrationFieldCustomPlaceholderKeyMap,
          RegistrationFieldDefaultPlaceholderKeyMap,
          countryFullName,
        ),
        options: preferredLanguageByCountry,
      },
      {
        type: 'password',
        id: 'pin',
        hasError: false,
        errorText: { key: 'ErrorMessages-val-pin-length', value: 'PIN must have exactly 6 numbers, 0-9.' },
        labelText: 'Create personal pin. A 6-digit number you can remember.',
        labelTextKey: 'Constants-lbl-six-digit-pin',
        name: 'pin',
        errorMessage: '',
        showErrorMessage: false,
        required: true,
        regex: '^[0-9]{6,6}$',
        inputType: 'password',
        maxLength: 6,
        pattern: '[0-9]*',
        inputMode: 'decimal',
        autoComplete: 'new-password',
        placeholder: '',
        placeholderKey: '',
      },
      {
        type: 'password',
        id: 'reEnterPin',
        hasError: false,
        errorText: { key: 'ErrorMessages-val-pin-length', value: 'PIN must have exactly 6 numbers, 0-9.' },
        labelText: 'Re-enter personal pin',
        labelTextKey: 'Constants-lbl-re-enter-four-digit-pin',
        name: 'reEnterPin',
        errorMessage: '',
        showErrorMessage: false,
        required: true,
        regex: '^[0-9]{6,6}$',
        inputType: 'password',
        maxLength: 6,
        pattern: '[0-9]*',
        inputMode: 'decimal',
        autoComplete: 'new-password',
        placeholder: '',
        placeholderKey: '',
        disablePaste: true,
      },
    ],
  };
};

export const getPhoneCodesByCountry = (countryFullName?: CountryFullName): string[] => {
  const country = countryFullName || getCountryFullName();
  return PhoneCodes[country] || PhoneCodes[CountryFullName.US];
};

export const getTimeZoneByCountry = (countryFullName: CountryFullName): { key: string; value: string }[] => {
  return TimeZones[countryFullName || getCountryFullName()] || TimeZones[CountryFullName.US];
};

export const getPreferredLanguagesByCountry = (countryFullName: CountryFullName): PreferredLanguagesItem[] => {
  return PreferredLanguages[countryFullName || getCountryFullName()];
};
