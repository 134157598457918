export const PAGE_ROUTE_LOGIN: string = "login";
export const PAGE_ROUTE_JOB_SEARCH: string = "jobSearch";
export const PAGE_ROUTE_HIRING_PROCESS: string = "hiring-process";
export const PAGE_ROUTE_WAREHOUSE_OPERATIVE: string = "warehouse-operative";
export const PAGE_ROUTE_SORTATION_OPERATIVE: string = "sortation-operative";
export const PAGE_ROUTE_BENEFITS: string = "benefits";
export const PAGE_ROUTE_CULTURE: string = "culture";
export const PAGE_ROUTE_LEADERSHIP_PRINCIPLES: string = "leadership-principles";
export const PAGE_ROUTE_FAQ: string = "faq";
export const PAGE_ROUTE_FAQS: string = "faqs";
export const CONTACT_US_URL = "https://www.amazondelivers.jobs/contactus";
export const PAGE_ROUTE_CONTACT_US: string = "contact-us"
export const PAGE_ROUTE_JOB_DETAIL: string = "jobDetail";
export const PAGE_ROUTE_REGISTRATION: string = "registration";
export const PAGE_ROUTE_VERIFY_EMAIL: string = "verifyEmail";
export const PAGE_ROUTE_VERIFY_PHONE: string = "verifyPhone";
export const PAGE_ROUTE_VERIFY_REGISTRATION: string = "verifyRegistration";
export const PAGE_ROUTE_FORGOT_PIN: string = "forgotPin";
export const PAGE_ROUTE_LOGOUT: string = "logout";
export const PAGE_ROUTE_JOB_ALERT: string = 'jobAlert';
export const PAGE_ROUTE_MY_ACCOUNT: string = 'myAccount';
export const PAGE_ROUTE_PERSONAL_DETAIL: string = 'personalDetail';
export const PAGE_ROUTE_CONTACT_INFORMATION: string = 'contactInformation';
export const PAGE_ROUTE_COMMUNICATION_PREFERENCES: string = 'communicationPreferences';
export const PAGE_ROUTE_MY_APPLICATIONS: string = 'myApplications';
export const PAGE_ROUTE_WITHDRAW_APPLICATION = "withdrawApplication";
export const PAGE_ROUTE_AS_JOBS_ACCOUNT: string = "https://amazon.tal.net/vx/lang-en-GB/mobile-0/appcentre-1/brand-4/xf-a94b85d0dd40/candidate/application";
export const ROUTER_LOCATION_CHANGE = "@@router/LOCATION_CHANGE";

export enum US_PAGE_ROUTE {
  FAQ = "faq"
}

export enum JP_PAGE_ROUTE {
  FAQ = "hiring-process/faq",
}

export enum BR_PAGE_ROUTE {
  FAQ = "perguntas-frequentes",
}

export const getPath = (path: string) => {
  return `/${path}`;
};
