import {
  CandidateDetail,
  CountryFullName,
  phoneCountryCode,
} from "../utils/commonTypes";
import { PhoneCodesByCountry } from "src/utils/registrationFrom/constants";
import { getLocale } from "src/utils/apis/apiHelper";
import {
  GET_CANDIDATE_DETAIL_TYPE,
  UPDATE_CANDIDATE_DETAIL_TYPE
} from "src/actions/CandidateDetailActions/candidateDetailActionTypes";

export const initAddress = {
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  country: "{{CountryFullName}}" as CountryFullName,
  zipcode: "",
  countryCode: "",
}

export const initAdditionalBackgroundInfo = {
  address: initAddress
}

export const initCandidateDetail: CandidateDetail = {
  candidateId: "",
  candidateSFId: "",
  firstName: "",
  middleName: "",
  lastName: "",
  preferredFirstName: "",
  preferredLastName: "",
  nameSuffix: "",
  emailId: "",
  phoneNumber: "",
  phoneCountryCode: PhoneCodesByCountry[0] as phoneCountryCode,
  locale: getLocale({}),
  language: "",
  additionalBackgroundInfo: initAdditionalBackgroundInfo
};

export interface CandidateDetailState {
  loading: boolean
  results: CandidateDetail
}

export const initCandidateDetailState: CandidateDetailState  = {
  results: initCandidateDetail,
  loading: false,
}


export default function candidateDetailReducer(
  state: CandidateDetailState = initCandidateDetailState,
  action: any
) {
  switch (action.type) {
    case GET_CANDIDATE_DETAIL_TYPE.GET:
      return {
        ...state,
        loading: true,
      };
    case GET_CANDIDATE_DETAIL_TYPE.SUCCESS:
      return {
        ...state,
        results: action.payload,
        loading: false,
      };
    case GET_CANDIDATE_DETAIL_TYPE.FAILED:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_CANDIDATE_DETAIL_TYPE.UPDATE:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CANDIDATE_DETAIL_TYPE.SUCCESS:
      return {
        ...state,
        results: action.payload,
        loading: false,
      };
    case UPDATE_CANDIDATE_DETAIL_TYPE.FAILED:
      return {
        ...state,
        loading: false,
      };
    case GET_CANDIDATE_DETAIL_TYPE.RESET:
      return {
        ...state,
        results: initCandidateDetail,
      }
    default:
      return state;
  }
}
  