import {
  UPDATE_CANDIDATE,
  RESET_CANDIDATE,
  GET_CANDIDATE_SUCCESS,
  GET_CANDIDATE,
  GET_CANDIDATE_FAILED,
  VERIFY_SIGN_IN_SUCCESS
} from "../actions/actionTypes";
import {
  Candidate,
  CountryFullName,
  BBRegion, phoneCountryCode,
} from "../utils/commonTypes";
import { PhoneCodesByCountry } from "src/utils/registrationFrom/constants";

export const initCandidate: Candidate = {
  candidateLogin: "",
  isEmailVerified: false,
  isPhoneVerified: false,
  phoneNumber: "",
  emailId: "",
  firstName: "",
  middleName: "",
  lastName: "",
  nameSuffix: "",
  phoneCountryCode: PhoneCodesByCountry[0] as phoneCountryCode,
  isAgreeToCommunication: undefined,
  pin: "",
  isAgreeToDataRetention: undefined,
  country: "{{CountryFullName}}" as CountryFullName,
  bbRegion: "{{BBRegion}}" as BBRegion
};

export default function candidateReducer(
  state = {
    results: initCandidate,
    loading: false,
    nextToken: null
  },
  action: any
) {
  const curResults = state.results ? state.results : {};
  switch (action.type) {
    case GET_CANDIDATE:
      return {
        ...state,
        loading: true,
      };
    case GET_CANDIDATE_SUCCESS:
      return {
        ...state,
        results: action.payload,
        NextToken: action.payload.NextToken,
        loading: false,
      };
    case GET_CANDIDATE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case VERIFY_SIGN_IN_SUCCESS:
    case UPDATE_CANDIDATE:
      return {
        ...state,
        results: {
          ...curResults,
          ...action.payload,
        },
      };
    case RESET_CANDIDATE:
      return {
        ...state,
        results: initCandidate,
      }
    default:
      return state;
  }
}
