import { from, Observable, of } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/internal/operators';
import { ofType } from 'redux-observable';
import { verifySignInApi } from '../utils/apis/serviceApi';
import { VerifySignInErrorResponse, VerifySignInSuccessResponse } from 'src/utils/apis/serviceApiTypes';
import { actionVerifySignInFail, actionVerifySignInSuccess } from '../actions/actions';
import { VERIFY_SIGN_IN } from '../actions/actionTypes';
import { emitEvent } from 'src/utils/rum';
import { RumEventType } from 'src/utils/constants';

export const verifySignInEpic = (action$: Observable<any>) => {
  return action$.pipe(
    ofType(VERIFY_SIGN_IN),
    flatMap((action: any) =>
      from<Promise<any>>(verifySignInApi(action.payload)).pipe(
        flatMap(async (response) => {
          return response.data;
        }),
        map((data: VerifySignInSuccessResponse) => {
          if (action.onSuccess) {
            action.onSuccess();
          }

          emitEvent({
            event: RumEventType.VERIFY_SIGN_IN,
            status: true,
            availability: 1,
          });

          return actionVerifySignInSuccess({
            phoneNumber: data.phoneNumber,
            emailId: data.emailId,
          });
        }),
        catchError((error) => {
          const errorResponse: VerifySignInErrorResponse = error.response.data;

          emitEvent({
            event: RumEventType.VERIFY_SIGN_IN,
            status: false,
            error: errorResponse.errorMessageStringId,
            errorCode: error.response.status,
            availability: 0,
          });

          return of(
            actionVerifySignInFail({
              errorMessage: errorResponse.errorMessage,
              errorMessageStringId: errorResponse.errorMessageStringId,
            }),
          );
        }),
      ),
    ),
  );
};
