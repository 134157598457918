import React from 'react';
import {getCountryCode} from 'src/utils/apis/apiHelper';
import {constructPrivacyNoticeUri, constructContactUsUri, getEeoLawUrl} from 'src/utils/helper';
import { translate as t } from "../utils/translator"
import {connect} from "react-redux";

const disclaimerText = 'Amazon is committed to a diverse and inclusive workplace. Amazon is an equal opportunity employer and does not discriminate on the basis of race, national origin, gender, gender identity, sexual orientation, disability, age, or other legally protected status.';
export const Footer = (props: any) => {
  const copyrightTextYear = `© 1996-${new Date().getFullYear()} `;
  const copyrightText = `Amazon.com, Inc. or its affiliates`;

  return (
    <footer className='footer'>
        <div className="footer-links">
          <a key={1} href= {constructContactUsUri()} target="_blank" rel="noopener noreferrer">
            {t("Footer-contact-us", "Contact Us")}
          </a>
          {getCountryCode() === "US" && <a key={2} href= {getEeoLawUrl()} target="_blank" rel="noopener noreferrer">
            {t("Footer-EEO-law", "EEO Law")}
          </a>}
          <a key={3} href= {constructPrivacyNoticeUri()} target="_blank" rel="noopener noreferrer">
            {t("Footer-privacy-notice", "Privacy Notice")}
          </a>
        </div>
        <p className="footer-text">{t("Footer-disclaimer-text", disclaimerText)}</p>
        <p className="footer-text">{copyrightTextYear + t("Footer-copyright-text", copyrightText)}</p>
    </footer>
  );
}

interface Props {
}
const mapStateToProps = (state: any, ownPros: Props) => {
  return { ...state, ...ownPros };
};
export default connect(mapStateToProps)(Footer);

