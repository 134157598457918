import { CountryStateConfigs } from "src/utils/apis/serviceApiTypes";
import { AlertMessage } from "src/utils/commonTypes";
import { GetCountryStateConfigAction, GetCountryStateConfigFailedAction, GetCountryStateConfigSuccessAction, GET_COUNTRY_STATE_CONFIG_TYPE } from "./configActionTypes";

export const actionGetCountryStateConfig = (): GetCountryStateConfigAction => {
    return { type: GET_COUNTRY_STATE_CONFIG_TYPE.GET }
};

export const actionGetCountryStateConfigSuccess = (
    payload: CountryStateConfigs,
    callback?: Function,
): GetCountryStateConfigSuccessAction => {
    return { type: GET_COUNTRY_STATE_CONFIG_TYPE.SUCCESS, payload, callback }
};

export const actionGetCountryStateConfigFailed = (
    payload: AlertMessage
): GetCountryStateConfigFailedAction => {
    return { type: GET_COUNTRY_STATE_CONFIG_TYPE.FAILED, payload }
};
