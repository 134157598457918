import React, { ChangeEvent, ReactNode } from 'react';
import { Input, InputWrapper } from '@amzn/stencil-react-components/form';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { Label, Text } from '@amzn/stencil-react-components/text';
import isEmpty from 'lodash/isEmpty';
import { InputType } from 'src/utils/commonTypes';
import useHVHTranslation from 'src/Hooks/useHVHTranslation';

interface TextInputProps {
  hasError?: boolean;
  errorText?: { [key: string]: string } | string;
  infoText?: string;
  tooltipText?: string;
  value: string;
  placeholder?: string;
  optionalText?: string;
  labelText?: any;
  onChange?: any;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  id?: string;
  name?: string;
  errorMessage?: string;
  showErrorMessage?: boolean;
  inputType?: string;
  required?: boolean;
  loading?: boolean;
  onBlur?: Function;
  properties?: any;
  maxLength?: number;
  disabled?: boolean;
  pattern?: string;
  inputMode?: any;
  font?: 'primary' | 'monospace' | undefined;
  autoComplete?: string;
  disablePaste?: boolean;
  footerComponent?: ReactNode;
  spellCheck?: boolean;
}

export interface InputEventValue {
  value: string;
  key: string;
  properties: any;
}

const InputComponent = ({
  hasError = false,
  errorText = '',
  infoText = undefined,
  tooltipText = undefined,
  labelText = '',
  id = 'text-box',
  value,
  onChange,
  onKeyDown,
  name = '',
  placeholder,
  inputType = 'text',
  optionalText,
  required,
  loading = false,
  onBlur,
  properties,
  maxLength,
  disabled,
  pattern,
  inputMode,
  font = 'primary',
  autoComplete,
  disablePaste,
  footerComponent,
  spellCheck,
}: TextInputProps) => {
  const { t } = useHVHTranslation();
  const onValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange([{ value: event.target.value, key: id, properties }]);
    }
  };

  const onTextBlur = (event: ChangeEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur([{ value: event.target.value, key: id, properties }]);
    }
  };

  const getFooterText = () => {
    if (loading) {
      return 'Loading';
    }
    return hasError
      ? typeof errorText === 'string'
        ? errorText
        : t(errorText.key, errorText.value, errorText?.options)
      : infoText;
  };

  const onPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    return false;
  };

  return (
    <Col gridGap={'S300'} className="colContainer">
      <InputWrapper
        labelText={labelText}
        id={id}
        tooltipText={tooltipText}
        error={hasError}
        footer={getFooterText()}
        dataTestId={`input-wrapper-${id}`}
        required={isEmpty(optionalText)}
        renderLabel={() => (
          <Row alignItems="center" gridGap={'S300'}>
            <Label fontSize="T200" color="accent2" htmlFor={id}>
              <Row gridGap={8}>
                <Text>
                  {labelText}
                  {required && (
                    <span color="negative" aria-hidden="true">
                      {' '}
                      *
                    </span>
                  )}
                </Text>
                {!isEmpty(optionalText) && <Text>{optionalText}</Text>}
              </Row>
            </Label>
          </Row>
        )}
      >
        {(inputProps) => (
          <Row gridGap={'S300'}>
            <Input
              {...inputProps}
              autoComplete={autoComplete}
              aria-describedby={`${inputProps['aria-describedby']}`}
              width="100%"
              name={name}
              defaultValue={value}
              onChange={onValueChange}
              dataTestId={`input-test-id-${id}`}
              type={inputType as InputType}
              required={required}
              onBlur={onTextBlur}
              placeholder={placeholder}
              maxLength={maxLength}
              disabled={disabled}
              pattern={pattern}
              inputMode={inputMode}
              aria-label={labelText}
              aria-invalid={hasError}
              aria-required={required}
              font={font}
              onKeyDown={onKeyDown}
              onPaste={disablePaste ? onPaste : undefined}
              // unless spellCheck is explicitly set to false, it will be left as default, thus keeping current behavior intact
              {...(spellCheck === false ? { spellCheck: false } : {})}
            />
          </Row>
        )}
      </InputWrapper>
      {footerComponent}
    </Col>
  );
};

export default InputComponent;
