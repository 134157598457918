import i18next, { StringMap, TOptions } from "i18next";
import { getCurrentLocale } from "src/utils/localization/localeHelper";

const rootStr: string = ".appStrings.CS-";
const locale = getCurrentLocale();

/**
 * Get translated strings.
 * @param stringId
 * @param defaultString
 */
export const translate = (stringId: string, defaultString: string, options?: TOptions<StringMap> | string) => {
  //You can pass an array of keys to lookup.
  //The last one in the list will end up being the default value being displayed if no keys are found.
  const stringKey = i18next.exists([locale + rootStr + stringId]) ? locale + rootStr + stringId : locale.substring(0, 2) + rootStr + stringId;
  const translation = i18next.isInitialized && i18next.exists([stringKey])
      ? i18next.t([stringKey], options)
      : defaultString;
  return translation;
};

/**
 * Get translated strings with format.
 * @param stringId
 * @param defaultString
 */
export const translateWithFormat = (
  stringId: string,
  defaultString: string,
  formatArgs: any = {}
) => {
  //You can pass an array of keys to lookup.
  //The last one in the list will end up being the default value being displayed if no keys are found.
  const stringKey = i18next.exists([locale + rootStr + stringId]) ? locale + rootStr + stringId : locale.substring(0, 2) + rootStr + stringId;
  const translation = i18next.isInitialized && i18next.exists([stringKey])
    ? i18next.t([stringKey], formatArgs)
    : defaultString;
  return translation;
};

/*
* Dynamic component render in contact us page need to use t function from useTranslation hook to detect language change.
* */
export const mapI18nStringKey = (stringId: string) => {
  const locale = getCurrentLocale();
  return i18next.exists([locale + rootStr + stringId]) ? locale + rootStr + stringId : locale.substring(0, 2) + rootStr + stringId;
};
