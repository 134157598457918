import { from, Observable, of } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/internal/operators';
import { ofType } from 'redux-observable';
import { signInApi } from '../utils/apis/serviceApi';
import { parseErrorMessage } from '../utils/helper';
import { actionSignInSuccess } from '../actions/actions';
import { SIGN_IN, SIGN_IN_CALLBACK_SUCCESS, SIGN_IN_FAILED, SIGN_IN_SUCCESS } from '../actions/actionTypes';
import { emitEvent } from 'src/utils/rum';
import { RumEventType } from 'src/utils/constants';

export const signInEpic = (action$: Observable<any>) => {
  return action$.pipe(
    ofType(SIGN_IN),
    flatMap((action: any) =>
      from<Promise<any>>(signInApi(action.payload)).pipe(
        flatMap(async (response) => {
          return response.data;
        }),
        map((x) => {
          emitEvent({
            event: RumEventType.SIGN_IN,
            status: true,
            availability: 1,
          });

          return actionSignInSuccess(x, action.callback);
        }),
        catchError((error) => {
          const { response } = error;
          if (action.onError) {
            action.onError(response.data);
          }

          const errorMessage = parseErrorMessage(response.data);

          emitEvent({
            event: RumEventType.SIGN_IN,
            status: false,
            error: errorMessage,
            errorCode: response.status,
            availability: 0,
          });

          return of({
            type: SIGN_IN_FAILED,
            payload: parseErrorMessage(response.data),
          });
        }),
      ),
    ),
  );
};

export const signInSuccessEpic = (action$: Observable<any>) => {
  return action$.pipe(
    ofType(SIGN_IN_SUCCESS),
    map((action) => {
      if (action.callback) {
        action.callback(action.payload);
      }
      return { type: SIGN_IN_CALLBACK_SUCCESS, payload: null };
    }),
  );
};
