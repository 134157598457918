import { from, Observable, of } from 'rxjs';
import { flatMap, map, catchError } from 'rxjs/internal/operators';
import { ofType } from 'redux-observable';
import { createCandidateApi } from '../utils/apis/serviceApi';
import { createAppSyncEpicError, parseErrorMessage } from '../utils/helper';
import { actionCreateCandidateSuccess } from '../actions/actions';
import {
  CREATE_CANDIDATE,
  CREATE_CANDIDATE_FAILED,
  CREATE_CANDIDATE_SUCCESS,
  CREATE_CANDIDATE_CALLBACK_SUCCESS,
} from '../actions/actionTypes';
import { addAdobeMetric } from 'src/customerTracking/adobeAnalytics';
import { AdobeEvent, PageName } from 'src/customerTracking/adobeAnalytics/types';

export const createCandidateEpic = (action$: Observable<any>) => {
  return action$.pipe(
    ofType(CREATE_CANDIDATE),
    flatMap((action: any) =>
      from<Promise<any>>(createCandidateApi(action.payload)).pipe(
        flatMap(async (response) => {
          if (!response.data) throw createAppSyncEpicError('CREATE_CANDIDATE_FAILED');
          return response.data;
        }),
        map((x) => {
          // send metrics when using preferred name
          const { request } = action.payload;
          if (request && (request.preferredFirstName || request.preferredLastName)) {
            addAdobeMetric(AdobeEvent.PREFERRED_NAME, PageName.REGISTER);
          }
          return actionCreateCandidateSuccess(x, action.callback);
        }),
        catchError((error) => {
          const { response } = error;
          if (action.onError) {
            action.onError(response);
          }
          return of({
            type: CREATE_CANDIDATE_FAILED,
            payload: parseErrorMessage(response.data),
          });
        }),
      ),
    ),
  );
};

export const createCandidateSuccessEpic = (action$: Observable<any>) => {
  return action$.pipe(
    ofType(CREATE_CANDIDATE_SUCCESS),
    map((action) => {
      if (action.callback) {
        action.callback(action.payload);
      }
      return { type: CREATE_CANDIDATE_CALLBACK_SUCCESS, payload: null };
    }),
  );
};
