import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { SIGN_IN_PAGES } from "../../utils/constants";
import { IconArrowLeft } from "@amzn/stencil-react-components/icons";
import PhoneNumberInputComponent from "../Registration/PhoneNumberInputComponent";
import { translate as t } from "../../utils/translator";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { H1, Text } from "@amzn/stencil-react-components/text";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { boundRemoveAlerts, } from "../../actions/boundActions";
import { onGetCandidateWithPhoneNumber } from "../../utils/authService";
import { AuthErrorMessages } from "src/utils/errorMessages";
import {cleanPhoneNumberFormat, getPhoneCodesByCountry} from "src/utils/helper";
import { isPossiblePhoneNumber } from "libphonenumber-js/mobile";
import {addAdobeMetric} from "src/customerTracking/adobeAnalytics";
import {AdobeEvent, PageName} from "src/customerTracking/adobeAnalytics/types";

export const PhoneLogin = (props: any) => {
  const candidate = props.candidate? props.candidate.results : null;
  const [login, setLogin] = useState(
    {
      phoneNumber: candidate? candidate.candidateLogin : null,
      phoneCountryCode: getPhoneCodesByCountry()[0],
    }
  );
  const [error, setError] = useState<any>({});

  useEffect(() => {
    !candidate &&  goToLoginPage();
    // document.title = "Amazon Jobs - Login with phone number";
    addAdobeMetric(AdobeEvent.PAGE_LOAD, PageName.CONFIRM_PHONE_NUMBER);
  }, []);

  const onLoginChange = (targets:[]) => {
    const currentLogin = login;
    const newLogin: any = {...currentLogin};
    targets.forEach((target:{key: string, value:any})=>{
      newLogin[target.key] = target.value;
    });
    setLogin(newLogin);
    setError({
      hasError: false,
      message: "",
    });
  };

  const onLoginSubmit = () => {
    props.onNextTest && props.onNextTest();

    if (isEmpty(login.phoneNumber)) {
      setError({
        hasError: true,
        message: t(AuthErrorMessages["empty-login-phone-error-message"].key, AuthErrorMessages["empty-login-phone-error-message"].value),
      });
    } else if (!isPossiblePhoneNumber(login.phoneCountryCode + login.phoneNumber)) {
      setError({
        hasError: true,
        message: t(AuthErrorMessages["invalid-login-phone-error-message"].key, AuthErrorMessages["invalid-login-phone-error-message"].value),
      });
    } else {
      onGetCandidateWithPhoneNumber(
        {
          phoneNumberWithPhoneCountryCode:`${login.phoneCountryCode}${cleanPhoneNumberFormat(login.phoneNumber)}`,
          onNext
        });
    }
  };

  const onNext = () => {
    props.goToPage(SIGN_IN_PAGES.PIN);
  };

  const goToLoginPage = () => {
    boundRemoveAlerts();
    props.goToPage(SIGN_IN_PAGES.LOGIN);
  };

  return (
    <Col 
      gridGap="S300"
      className="hvhLogin"
      padding={{left:'S200',right:'S200'}}
    >
      <Col className="colContainerTop">
        <Row gridGap={"S300"} justifyContent="space-between">
          <Button 
            dataTestId="button-back"
            onClick={goToLoginPage} variant={ButtonVariant.Tertiary}>
            <Row gridGap={"S300"} alignItems="center">
              <IconArrowLeft aria-hidden={true} />
              {t("PhoneLogin-lbl-return-to-login-button", "back")}
            </Row>
          </Button>
        </Row>
        <H1 fontSize="T400" font="primary" fontWeight="regular" color="black">
          {t("PhoneLogin-title-phone-country-code", "Confirm your country code")}
        </H1>
        <Text fontSize="T100">
          {t(
            "PhoneLogin-lbl-verify-country-code-description",
            "Please verify the country code and mobile number"
          )}
        </Text>
        <Row gridGap="S300" alignItems="center">
          <PhoneNumberInputComponent
            labelText={t("PhoneLogin-lbl-mobile-number", "Mobile number")}
            name="phoneNumber"
            hasError={error.hasError}
            errorText={error.message}
            key={`phone_login_component`}
            onChange={onLoginChange}
            placeholder={t("PhoneLogin-placeholder-phone-number", "phone number...")}
            type={"text"}
            id={"phoneNumber"}
            value={login.phoneNumber}
            countryCode={login.phoneCountryCode}
            required={true}
            options={getPhoneCodesByCountry()}
          />
        </Row>
      </Col>
      <Button 
        dataTestId="button-continue"
        onClick={onLoginSubmit} variant={ButtonVariant.Primary}>
        {t("PhoneLogin-btn-submit-login", "Continue")}
      </Button>
    </Col>
  );
};

interface Props {
  goToPage: Function;
  onNextTest?: Function;
  router?: any;
  candidate?: any;
}

const mapStateToProps = (state: any, ownProps: Props) => {
  return {...state, ...ownProps};
};

export default connect(mapStateToProps)(PhoneLogin);
