import { Action } from "redux";
import { GetCommunicationPreferenceRequest, UpdateCommunicationPreferenceRequest } from "src/utils/apis/appSyncAPI/types";
import { AlertMessage, CommunicationPreference } from "src/utils/commonTypes";

export enum GET_COMMUNICATION_PREFERENCE_TYPE {
    GET = 'GET_COMMUNICATION_PREFERENCE',
    SUCCESS = 'GET_COMMUNICATION_PREFERENCE_SUCCESS',
    FAILED = 'GET_COMMUNICATION_PREFERENCE_FAILED',
    RESET = 'RESET_GET_COMMUNICATION_PREFERENCE',
}

export enum UPDATE_COMMUNICATION_PREFERENCE_TYPE {
    UPDATE = 'UPDATE_COMMUNICATION_PREFERENCE',
    SUCCESS = 'UPDATE_COMMUNICATION_PREFERENCE_SUCCESS',
    FAILED = 'UPDATE_COMMUNICATION_PREFERENCE_FAILED'
}

export interface GetCommunicationPreferenceAction extends Action {
    type: GET_COMMUNICATION_PREFERENCE_TYPE.GET;
    payload: GetCommunicationPreferenceRequest;
}

export interface GetCommunicationPreferenceSuccessAction extends Action {
    type: GET_COMMUNICATION_PREFERENCE_TYPE.SUCCESS;
    payload: CommunicationPreference;
}

export interface GetCommunicationPreferenceFailedAction extends Action {
    type: GET_COMMUNICATION_PREFERENCE_TYPE.FAILED;
    payload: AlertMessage;
}

export interface ResetCommunicationPreferenceAction extends Action {
    type: GET_COMMUNICATION_PREFERENCE_TYPE.RESET;
    callback?: Function;
}

export interface UpdateCommunicationPreferenceAction extends Action {
    type: UPDATE_COMMUNICATION_PREFERENCE_TYPE.UPDATE;
    payload: UpdateCommunicationPreferenceRequest;
    callback?: Function;
}

export interface UpdateCommunicationPreferenceSuccessAction extends Action {
    type: UPDATE_COMMUNICATION_PREFERENCE_TYPE.SUCCESS;
    payload: CommunicationPreference;
}

export interface UpdateCommunicationPreferenceFailedAction extends Action {
    type: UPDATE_COMMUNICATION_PREFERENCE_TYPE.FAILED;
    payload: AlertMessage;
}


