export const PAGE_ROUTE_LOGIN: string = 'login';
export const PAGE_ROUTE_JOB_SEARCH: string = 'jobSearch';
export const PAGE_ROUTE_FAQ: string = 'faq';
export const PAGE_ROUTE_FAQS: string = 'faqs';
export const PAGE_ROUTE_CONTACT_US: string = 'contact-us';
export const PAGE_ROUTE_REGISTRATION: string = 'registration';
export const PAGE_ROUTE_VERIFY_EMAIL: string = 'verifyEmail';
export const PAGE_ROUTE_VERIFY_PHONE: string = 'verifyPhone';
export const PAGE_ROUTE_VERIFY_REGISTRATION: string = 'verifyRegistration';
export const PAGE_ROUTE_FORGOT_PIN: string = 'forgotPin';
export const PAGE_ROUTE_LOGOUT: string = 'logout';
export const PAGE_ROUTE_AS_JOBS_ACCOUNT: string =
  'https://amazon.tal.net/vx/lang-en-GB/mobile-0/appcentre-1/brand-4/xf-a94b85d0dd40/candidate/application';
export const ROUTER_LOCATION_CHANGE = '@@router/LOCATION_CHANGE';
export const PAGE_ROUTE_SELECT_COUNTRY: string = 'selectCountry';

export enum US_PAGE_ROUTE {
  FAQ = 'faq',
}

export enum JP_PAGE_ROUTE {
  FAQ = 'hiring-process/faq',
}

export enum BR_PAGE_ROUTE {
  FAQ = 'perguntas-frequentes',
}

export const getPath = (path: string) => {
  return `/${path}`;
};
