import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { PAGE_ROUTE_LOGIN } from '../pageRoutes';
import { errorPageName, FORGOT_PIN_PAGES } from '../../utils/constants';
import { IconArrowLeft } from '@amzn/stencil-react-components/icons';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { Text, H1 } from '@amzn/stencil-react-components/text';
import InputComponent from 'src/components/Registration/InputComponent';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import isEmpty from 'lodash/isEmpty';
import { checkUsernameIsEmail, checkUsernameIsPhoneNumber } from 'src/utils/helper';
import { boundRemoveAlerts, boundGetConfig, boundGetCSRF } from 'src/actions/boundActions';
import { onForgotPin } from 'src/utils/authService';
import ResetPin from './ResetPin';
import { AuthErrorMessages } from 'src/utils/errorMessages';
import { addAdobeMetric } from 'src/customerTracking/adobeAnalytics';
import { AdobeEvent, PageName } from 'src/customerTracking/adobeAnalytics/types';
import { AlertMessage } from 'src/utils/commonTypes';
import { isEmailFieldOptional } from 'src/utils/featureFlag/featureFlag';
import useHVHTranslation from 'src/Hooks/useHVHTranslation';

export const ForgotPin = (props: any) => {
  const { t } = useHVHTranslation();
  const [candidateLogin, setCandidateLogin] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(props.initPage ? props.initPage : FORGOT_PIN_PAGES.FORGOT_PIN);
  const [error, setError] = useState<any>({});
  const history: any = useHistory();
  const hasAlert = props.alert?.alert || null;
  const alerts = props.alert?.alerts || null;
  const candidate = props.candidate.results;
  useEffect(() => {
    addAdobeMetric(AdobeEvent.PAGE_LOAD, PageName.FORGOT_PIN);

    boundGetConfig();
    boundGetCSRF();
  }, []);

  useEffect(() => {
    if (hasAlert && alerts.length > 0) {
      alerts.forEach((alert: AlertMessage) => {
        addAdobeMetric(AdobeEvent.PAGE_LOAD, errorPageName[currentPage], {
          errorMessage: alert.errorMessageStringId,
        });
      });
    }
  }, [hasAlert]);
  // willUnmount
  useEffect(
    () => () => {
      boundRemoveAlerts();
    },
    [],
  );

  const onLoginChange = (data: any) => {
    setCandidateLogin(data[0].value);
    setError({
      hasError: false,
      message: '',
    });
  };

  const goToLoginPage = () => {
    boundRemoveAlerts();
    history.push(`/${PAGE_ROUTE_LOGIN}`);
  };

  const onNext = () => {
    addAdobeMetric(AdobeEvent.NEXT_BUTTON_CLICKED, PageName.FORGOT_PIN);
    setCurrentPage(FORGOT_PIN_PAGES.RESET_PIN);
  };

  const imgSrc = isEmailFieldOptional()
    ? 'https://m.media-amazon.com/images/G/01/HVH-CandidateApplication/jobs/illustration-phone.svg'
    : 'https://m.media-amazon.com/images/G/01/HVH-CandidateApplication/jobs/illustration-email.svg';
  const inputLabel = isEmailFieldOptional()
    ? t('ForgotPin-text-phone-number', 'Please provide the phone number')
    : t('ForgotPin-text-Email-address', 'Please provide the email address');
  const inputName = isEmailFieldOptional() ? 'PhoneNumber' : 'EmailAddress';
  const altText = isEmailFieldOptional() ? 'phone' : 'email';
  const errorMessage = isEmailFieldOptional()
    ? t(
        AuthErrorMessages['empty-login-phone-error-message'].key,
        AuthErrorMessages['empty-login-phone-error-message'].value,
      )
    : t(
        AuthErrorMessages['empty-login-email-error-message'].key,
        AuthErrorMessages['empty-login-email-error-message'].value,
      );
  const checkUserName = isEmailFieldOptional()
    ? checkUsernameIsPhoneNumber(candidateLogin)
    : checkUsernameIsEmail(candidateLogin);

  const onNextClick = () => {
    boundRemoveAlerts();
    props.onNextTest && props.onNextTest();
    if (isEmpty(candidateLogin) || !checkUserName) {
      setError({
        hasError: true,
        message: errorMessage,
      });
    } else {
      onForgotPin({ candidateLogin: candidateLogin, onNext });
    }
  };

  const getPage = () => {
    switch (currentPage) {
      case FORGOT_PIN_PAGES.FORGOT_PIN:
        return ForgotPinPage;
      case FORGOT_PIN_PAGES.RESET_PIN:
        return <ResetPin candidate={candidate} goToPage={setCurrentPage} />;
      default:
        return ForgotPinPage;
    }
  };

  const ForgotPinPage = (
    <Col gridGap="S300" className="hvhLogin" padding={{ left: 'S200', right: 'S200' }}>
      <Col gridGap="S300" className="colContainerTop">
        <Row>
          <Button dataTestId="button-back" onClick={goToLoginPage} variant={ButtonVariant.Tertiary}>
            <Row alignItems="center">
              <IconArrowLeft aria-hidden />
              <Text>{t('ForgotPin-lbl-Return-to-login-btn', 'Login')}</Text>
            </Row>
          </Button>
        </Row>
        <img src={imgSrc} className="image-icon" alt={altText} aria-hidden="true" role="presentation" tabIndex={-1} />
        <H1 fontSize="T400" fontWeight="regular" color="black">
          {t('ForgotPin-title-Forgot-pin', 'Forgot personal pin?')}
        </H1>
        <InputComponent
          id="forgot"
          labelText={inputLabel}
          name={inputName}
          value={candidateLogin}
          loading={false}
          onChange={onLoginChange}
          hasError={error.hasError}
          errorText={error.message}
        />
        <Button dataTestId="button-continue" onClick={onNextClick} variant={ButtonVariant.Primary}>
          {t('ForgotPin-label-Continue-btn', 'Continue')}
        </Button>
      </Col>
    </Col>
  );

  return <Row justifyContent="center">{getPage()}</Row>;
};

interface Props {
  onNextTest?: Function;
  initPage?: string;
  candidate?: any;
  goToPage?: Function;
}

const mapStateToProps = (state: any, ownPros: Props) => {
  return { ...state, ...ownPros };
};

export default connect(mapStateToProps)(ForgotPin);
