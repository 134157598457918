import React, { useEffect, useState } from 'react';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { Text } from '@amzn/stencil-react-components/text';
import { IconRefresh } from '@amzn/stencil-react-components/icons';
import { onResendOtp } from '../../utils/authService';
import isBoolean from 'lodash/isBoolean';
import isNumber from 'lodash/isNumber';
import useHVHTranslation from 'src/Hooks/useHVHTranslation';

interface Props {
  resetOtpTextBox?: Function;
  disableResendButtonOverride?: boolean;
  candidate: any;
  waitTime?: number;
  setIntervalCallback?: Function;
  loginType: string;
  pin: string;
}

const ResendOtp: React.FC<Props> = ({
  resetOtpTextBox,
  disableResendButtonOverride,
  candidate,
  waitTime = 60,
  setIntervalCallback,
  loginType,
  pin,
}) => {
  const [disableResendButton, setDisableResendButton] = useState<boolean>(
    isBoolean(disableResendButtonOverride) ? disableResendButtonOverride : true,
  );
  const { t } = useHVHTranslation();
  const [seconds, setSeconds] = useState<number>(waitTime);

  const enableResendButton = () => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds < 1) {
          setDisableResendButton(false);
          clearInterval(interval);
          return isNumber(waitTime) ? waitTime : 60;
        }
        return prevSeconds - 1;
      });
      setIntervalCallback?.();
    }, 1000);

    return interval;
  };

  const onResendOtpClick = () => {
    setDisableResendButton(true);
    resetOtpTextBox?.();
    onResendOtp({ candidate, loginType, pin });
    enableResendButton();
  };

  useEffect(() => {
    const interval = enableResendButton();
    return () => clearInterval(interval);
  }, []); // Run once on mount

  return (
    <Col gridGap="S300" justifyContent="flex-start">
      {!disableResendButton ? (
        <Row>
          <Button
            onClick={onResendOtpClick}
            className="resendOtpButton"
            dataTestId="button-test-id-resendOtpButton"
            variant={ButtonVariant.Tertiary}
          >
            <Row gridGap={'S300'} alignItems="center">
              <IconRefresh aria-hidden />
              <Text>{t('ResendOtpComponent-btn-resend-label', 'Resend verification code')}</Text>
            </Row>
          </Button>
        </Row>
      ) : (
        <Text fontSize="T100">
          {t('ResendOtpComponent-lbl-resend-code', 'You can resend verification code in {seconds} seconds', {
            seconds: `${seconds}`,
          })}
        </Text>
      )}
    </Col>
  );
};

export default ResendOtp;
