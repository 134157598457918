import React, { ChangeEvent, useEffect } from 'react';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { H1, Text } from '@amzn/stencil-react-components/text';
import { DetailedRadio } from '@amzn/stencil-react-components/form';
import { Link } from '@amzn/stencil-react-components/link';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { IconArrowLeft } from '@amzn/stencil-react-components/icons';
import { Candidate } from 'src/utils/commonTypes';
import isEmpty from 'lodash/isEmpty';
import { boundRemoveAlerts, boundUpdateCandidate } from 'src/actions/boundActions';
import { LOGIN_TYPE, SIGN_IN_PAGES } from 'src/utils/constants';
import { onSignIn } from 'src/utils/authService';
import { useHistory } from 'react-router';
import { PAGE_ROUTE_VERIFY_EMAIL, PAGE_ROUTE_VERIFY_PHONE } from 'src/components/pageRoutes';
import { getCountryFullName } from 'src/utils/apis/apiHelper';
import { addAdobeMetric } from 'src/customerTracking/adobeAnalytics';
import { AdobeEvent, PageName } from 'src/customerTracking/adobeAnalytics/types';
import { isEmailFieldOptional, skipPhoneVerification } from 'src/utils/featureFlag/featureFlag';
import { getLoginHelpPage } from 'src/utils/helper';
import useHVHTranslation from 'src/Hooks/useHVHTranslation';

interface ChooseOtpTypeProps {
  candidate: Candidate | null;
  pin: string;
  goToPage: Function;
  loginType: string;
  setLoginType: Function;
  onNextTest?: Function;
  router?: any;
  testUser?: any;
}

export const ChooseOtpType = ({
  candidate,
  pin,
  goToPage,
  loginType,
  setLoginType,
  onNextTest,
}: ChooseOtpTypeProps) => {
  const history: any = useHistory();
  const { t } = useHVHTranslation();

  useEffect(() => {
    if (isEmailFieldOptional()) {
      //When email is optional default login type is PHONE and if phone is not verified we will force customer to verify phone
      if (candidate && candidate.isPhoneVerified) {
        setLoginType(LOGIN_TYPE.PHONE);
      } else if (candidate && !candidate.isPhoneVerified) {
        boundUpdateCandidate({ pin: pin });
        onSubmitChooseLoginType({ loginTypeOverride: LOGIN_TYPE.PHONE });
      }
    } else {
      // Select email as default if only email is verified
      if (candidate && !candidate.isPhoneVerified && candidate.isEmailVerified) {
        setLoginType(LOGIN_TYPE.EMAIL);
      } else if (candidate && !candidate.isEmailVerified) {
        // Based on issue listed in https://quip-amazon.com/dnErA9ifvkMR/Issues-of-CS-login,
        // we will force customer to verify email if email is not verified. (Even if customer had phone verified already)

        boundUpdateCandidate({ pin: pin });
        onSubmitChooseLoginType({ loginTypeOverride: LOGIN_TYPE.EMAIL });
      }
    }
    addAdobeMetric(AdobeEvent.PAGE_LOAD, PageName.OTP_OPTION_CHOICE, {
      phoneOptionExists: candidate?.isPhoneVerified,
      emailOptionExists: candidate?.isEmailVerified,
    });
  }, []);

  useEffect(() => {
    if (isEmpty(candidate) || !candidate) {
      goToPage(SIGN_IN_PAGES.LOGIN);
    }
  }, [candidate, history]);

  const goToLoginPage = () => {
    boundRemoveAlerts();
    goToPage(SIGN_IN_PAGES.LOGIN);
  };

  const onChooseLoginTypeSelected = (e: ChangeEvent<HTMLInputElement>) => {
    addAdobeMetric(AdobeEvent.SEND_VERIFICATION_CODE, PageName.OTP_OPTION_CHOICE, {
      verificationOptionChosen: e.target.value,
    });
    setLoginType(e.target.value);
  };

  const onSubmitChooseLoginType = ({ loginTypeOverride }: any) => {
    onNextTest && onNextTest();
    onSignIn({
      user: candidate ? candidate.candidateLogin : '',
      pin,
      loginType: loginTypeOverride ? loginTypeOverride : loginType,
      onNext,
      onPinError,
    });
  };

  const onPinError = () => {
    // Go back to pin page
    goToPage(SIGN_IN_PAGES.PIN);
  };

  const goToVerifyEmailPage = () => {
    history.push(`/${PAGE_ROUTE_VERIFY_EMAIL}`);
  };

  const goToVerifyPhonePage = () => {
    history.push(`/${PAGE_ROUTE_VERIFY_PHONE}`);
  };

  const onNext = () => {
    if (isEmailFieldOptional()) {
      if (candidate && !candidate.isPhoneVerified) {
        goToVerifyPhonePage();
        return;
      }
    } else {
      if (candidate && !candidate.isEmailVerified && candidate.isBBUser) {
        goToVerifyEmailPage();
        return;
      }
    }
    goToPage(SIGN_IN_PAGES.CONFIRM_OTP);
  };

  return (
    <Col gridGap="S300" className="hvhLogin" padding={{ left: 'S200', right: 'S200' }}>
      <Col gridGap="S300" className="colContainerTop">
        <Row>
          <Button dataTestId="button-back" onClick={goToLoginPage} variant={ButtonVariant.Tertiary}>
            <Row alignItems="center">
              <IconArrowLeft aria-hidden />
              <Text>{t('ChooseOtpType-lbl-back-button', 'back')}</Text>
            </Row>
          </Button>
        </Row>
        <H1 fontSize="T400" fontWeight="regular">
          {t('ChooseOtpType-title-choose-login-with', 'Where should we send your verification code?')}
        </H1>
        <Col gridGap="S300">
          {(loginType === LOGIN_TYPE.PHONE || !skipPhoneVerification()) && candidate && candidate.isPhoneVerified && (
            <DetailedRadio
              titleText={t('ChooseOtpType-lbl-choose-phone-radio', 'Send verification code to {phone}', {
                phone: candidate.phoneNumber,
              })}
              defaultChecked={loginType === LOGIN_TYPE.PHONE}
              name="loginType"
              value={LOGIN_TYPE.PHONE}
              checked={loginType === LOGIN_TYPE.PHONE}
              onChange={onChooseLoginTypeSelected}
            />
          )}
          {candidate && candidate.isEmailVerified && (
            <DetailedRadio
              titleText={t('ChooseOtpType-lbl-choose-email-radio', 'Email verification code to {email}', {
                email: candidate.emailId,
              })}
              defaultChecked={isEmailFieldOptional() ? loginType === LOGIN_TYPE.PHONE : loginType === LOGIN_TYPE.EMAIL}
              name="loginType"
              value={isEmailFieldOptional() ? LOGIN_TYPE.PHONE : LOGIN_TYPE.EMAIL}
              checked={isEmailFieldOptional() ? loginType === LOGIN_TYPE.PHONE : loginType === LOGIN_TYPE.EMAIL}
              onChange={onChooseLoginTypeSelected}
            />
          )}
        </Col>
        <Link dataTestId="link-needHelp" href={`${getLoginHelpPage(getCountryFullName())}`} target="_blank">
          {t('ChooseOtpType-lbl-need-help-login', 'Need help to login?')}
        </Link>
        <Button
          dataTestId="button-submit"
          onClick={onSubmitChooseLoginType}
          variant={ButtonVariant.Primary}
          aria-describedby="send_code_hint"
          disabled={loginType !== LOGIN_TYPE.EMAIL && loginType !== LOGIN_TYPE.PHONE}
        >
          {t('ChooseOtpType-lbl-send-code-button', 'Send verification code')}
        </Button>
        {candidate && candidate.isPhoneVerified && (
          <Text fontSize="T100" textAlign="center" color="accent2" id="send_code_hint">
            {t('ChooseOtpType-lbl-carrier-charges-info-message', 'Your carrier may charge for SMS messages.')}
          </Text>
        )}
      </Col>
    </Col>
  );
};

export default ChooseOtpType;
