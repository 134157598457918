import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import moment from "moment";
import {
  boundAddAlert,
  boundCreateCandidate,
  boundLoadingEnd,
  boundLoadingStart,
  boundRemoveAlerts,
  boundUpdateCandidate,
} from "src/actions/boundActions";
import {getCountryCode, getCountryFullName,} from "src/utils/apis/apiHelper";
import {
    checkCandidateEmailExistsApi,
    checkCandidatePhoneExitsApi,
    checkCandidateExistsApi,
} from "src/utils/apis/serviceApi";
import {
  Candidate,
  CandidateDetail,
  CountryFullName,
  FullCountryBBRegion,
  Language,
  RumSensitiveInfoEnabledCountry
} from "src/utils/commonTypes";
import {ExistingCandidateError, PinError, USER_SIGN_UP_TYPE} from "src/utils/constants";
import {
  AuthErrorMessages,
  DefaultMatchValidationErrorMessages,
  DefaultRegexValidationErrorMessages,
  DefaultValidationErrorMessages,
} from "src/utils/errorMessages";
import { cleanPhoneNumberFormat, isPhoneNumberValid, setConfirmOtpSession } from "src/utils/helper";
import {
  TimeZonesJP,
  TimeZonesUK,
  requiredRegistrationFieldsEvent,
  requiredRegistrationFieldsError,
  regexRegistrationFieldsError,
  reEnterStringError
} from "src/utils/registrationFrom/constants";
import { getPreferredLocaleFromCountryAndLanguage } from "./myAccount/helper";
import {emitEvent} from "src/utils/rum";
import {getPath} from "src/utils/helper";
import { addAdobeMetric } from "src/customerTracking/adobeAnalytics";
import { AdobeEvent, PageName } from "src/customerTracking/adobeAnalytics/types";

/**
 * check if pin is sequencyNumber, which belongs to commonly used pin
 * ex:
 * "123456" or "654321"
 * @param input personal pin
 */
export const checkIfSequentialNumber = (input: string) => {
  const sequencePatternAsc = "01234567890"; // to match circular sequence as well.
  const sequencePatternDes = "09876543210"; // to match circular sequence as well.

  if (
    sequencePatternDes.indexOf(input) === -1 &&
    sequencePatternAsc.indexOf(input) === -1
  ) {
    return false;
  } return true;
};

/**
 * check if pin is repeated number, which belongs to commonly used pin (aaaaaa, ababab, abcabc)
 * ex:
 * "121212", "123123", "111111"
 * @param input personal pin
 */
export const checkIfRepeatedNumber = (input: string) => {
  const regex = new RegExp("\\b(\\d+)\\1+\\b");
  return regex.test(input) ? true : false;
};

/**
 * check if pin is half repeated number, which belongs to commonly used pin (aaabbb)
 * ex:
 * "000111"
 * @param input personal pin
 */
export const checkIfHalfRepeatedNumber = (input: string) => {
  const regex = new RegExp("^([0-9])\\1{2}([0-9])\\2{2}");
  return regex.test(input) ? true : false;
};

export const checkEmailExits = async (component: any, candidateObject: any) => {
  try {
    const response = await checkCandidateEmailExistsApi(
      { email: candidateObject.emailId.toLowerCase() }
    );
    const { data } = response;
    if (data.isCandidateExits) {
      component.hasError = true;
      component.errorText = AuthErrorMessages["email-exits-message"];
      const countryCode = getCountryCode();
      if (RumSensitiveInfoEnabledCountry.includes(countryCode)) {
        emitEvent(
          {
            event: requiredRegistrationFieldsEvent[component.id],
            error: ExistingCandidateError.EMAIL_ALREADY_EXISTS,
            status: false,
            country: countryCode,
            path: getPath(),
            candidateInput: candidateObject.emailId,
          }
        );
      }
      else {
        emitEvent(
          {
            event: requiredRegistrationFieldsEvent[component.id],
            error: ExistingCandidateError.EMAIL_ALREADY_EXISTS,
            status: false,
            country: countryCode,
            path: getPath(),
          }
        );
      }
    }
  } catch (ex: any) {
    component.hasError = true;
    component.errorText = ex?.response?.data.errorMessageStringId
      ? AuthErrorMessages[ex?.response?.data.errorMessageStringId]
      : ex?.response?.data.errorMessage || AuthErrorMessages["email-exits-service-message"];
  }
};

export const checkPhoneNumberExits = async (
  component: any,
  candidateObject: Candidate | CandidateDetail
) => {
  try {
    const response = await checkCandidatePhoneExitsApi(
      { phoneNumber: candidateObject.phoneCountryCode + candidateObject.phoneNumber }
    );
    const { data } = response;
    if (data.isCandidateExits) {
      component.hasError = true;
      component.errorText = AuthErrorMessages["phone-number-exits-message"];
      const countryCode = getCountryCode();
      if (RumSensitiveInfoEnabledCountry.includes(countryCode)) {
        emitEvent(
          {
            event: requiredRegistrationFieldsEvent[component.id],
            error: ExistingCandidateError.PHONE_NUMBER_ALREADY_EXISTS,
            status: false,
            country: countryCode,
            path: getPath(),
            candidateInput: candidateObject.phoneNumber,
          }
        );
      }
      else {
        emitEvent(
          {
            event: requiredRegistrationFieldsEvent[component.id],
            error: ExistingCandidateError.PHONE_NUMBER_ALREADY_EXISTS,
            status: false,
            country: countryCode,
            path: getPath(),
          }
        );
      }
    }
  } catch (ex: any) {
    component.hasError = true;
    component.errorText = (ex?.response?.data.errorMessageStringId && AuthErrorMessages[ex?.response?.data.errorMessageStringId]) ||
      (ex?.response?.data.errorMessage || AuthErrorMessages["phone-number-exits-service-message"]);
  }
};


// TODO: Replace checkPhoneNumberExits and checkEmailExits with check_duplicate. Refer: https://sim.amazon.com/issues/T-RAVENGUARD-1048
export const check_duplicate = async (emailComponent: any, phoneComponent:any, candidateObject: any) => {
  try {
    const response = await checkCandidateExistsApi(
        {
          email: candidateObject.emailId.toLowerCase(),
          phoneNumber: candidateObject.phoneCountryCode + candidateObject.phoneNumber
        }
    );
    const {data} = response;
      if (data.isCandidateExits) {
          emailComponent.hasError = true;
          emailComponent.errorText = AuthErrorMessages["email-exits-message"];
          const countryCode = getCountryCode();
          if (RumSensitiveInfoEnabledCountry.includes(countryCode)) {
              emitEvent(
                  {
                      event: requiredRegistrationFieldsEvent[emailComponent.id],
                      error: ExistingCandidateError.EMAIL_ALREADY_EXISTS,
                      status: false,
                      country: countryCode,
                      path: getPath(),
                      candidateInput: candidateObject.emailId,
                  }
              );
          }
          else {
              emitEvent(
                  {
                      event: requiredRegistrationFieldsEvent[emailComponent.id],
                      error: ExistingCandidateError.EMAIL_ALREADY_EXISTS,
                      status: false,
                      country: countryCode,
                      path: getPath(),
                  }
              );
          }
      }
      if (data.isCandidatePhoneExists) {
          phoneComponent.hasError = true;
          phoneComponent.errorText = AuthErrorMessages["phone-number-exits-message"];
          const countryCode = getCountryCode();
          if (RumSensitiveInfoEnabledCountry.includes(countryCode)) {
              emitEvent(
                  {
                      event: requiredRegistrationFieldsEvent[phoneComponent.id],
                      error: ExistingCandidateError.PHONE_NUMBER_ALREADY_EXISTS,
                      status: false,
                      country: countryCode,
                      path: getPath(),
                      candidateInput: candidateObject.phoneNumber,
                  }
              );
          }
          else {
              emitEvent(
                  {
                      event: requiredRegistrationFieldsEvent[phoneComponent.id],
                      error: ExistingCandidateError.PHONE_NUMBER_ALREADY_EXISTS,
                      status: false,
                      country: countryCode,
                      path: getPath(),
                  }
              );
          }
      }
  } catch (ex: any) {
      if(!ex.response.data.componentId){
          if(ex.response.data.componentId === "emailId"){
              emailComponent.hasError = true;
              emailComponent.errorText = ex?.response?.data.errorMessageStringId
                  ? AuthErrorMessages[ex?.response?.data.errorMessageStringId]
                  : ex?.response?.data.errorMessage || AuthErrorMessages["email-exits-service-message"];
          }
          if(ex.response.data.componentId === "phoneNumber"){
              phoneComponent.hasError = true;
              phoneComponent.errorText = (ex?.response?.data.errorMessageStringId && AuthErrorMessages[ex?.response?.data.errorMessageStringId]) ||
                  (ex?.response?.data.errorMessage || AuthErrorMessages["phone-number-exits-service-message"]);
          }
      }
      else{
          emailComponent.hasError = true;
          phoneComponent.hasError = true;
          emailComponent.errorText = ex?.response?.data.errorMessageStringId
              ? AuthErrorMessages[ex?.response?.data.errorMessageStringId]
              : ex?.response?.data.errorMessage || AuthErrorMessages["candidate-email-or-phone-checking-error"];
          phoneComponent.errorText = (ex?.response?.data.errorMessageStringId && AuthErrorMessages[ex?.response?.data.errorMessageStringId]) ||
              (ex?.response?.data.errorMessage || AuthErrorMessages["candidate-email-or-phone-checking-error"]);
      }
  }
}

export const validateRequiredFields = (component: any, candidateObject: any) => {
  if (component.id == "middleName"){
    // this is validated separately due to the extra checkbox 
    return;
  }

  if (component.required && isEmpty(candidateObject[component.id])) {
    component.hasError = true;
    component.errorText = DefaultValidationErrorMessages[component.id];
    emitEvent(
      {
        event: requiredRegistrationFieldsEvent[component.id],
        error: requiredRegistrationFieldsError[component.id],
        status: false,
        country: getCountryCode(),
        path: getPath(),
      }
    );
  }
};

export function validateMiddleName(component: any, candidateObject: any) {
  if(candidateObject[component.id].length == 0 && candidateObject['noMiddleNameChecked'] === true) {
    return;
  }

  if (candidateObject[component.id].length > 0) {
    const regex = new RegExp(component.regex);
    if (!regex.test(candidateObject[component.id])) {
      component.hasError = true;
      component.errorText = DefaultRegexValidationErrorMessages[component.id];
      const countryCode = getCountryCode();
      if (RumSensitiveInfoEnabledCountry.includes(countryCode)) {
        emitRumMetric(requiredRegistrationFieldsEvent[component.id], regexRegistrationFieldsError[component.id], { candidateInput: candidateObject[component.id] })
      }
      else {
        emitRumMetric(requiredRegistrationFieldsEvent[component.id], regexRegistrationFieldsError[component.id])      
      }
    }
    return;
  }
  
  component.hasError = true;
  component.errorText = DefaultValidationErrorMessages[component.id];
  addAdobeMetric(AdobeEvent.MIDDLE_NAME_ERROR, PageName.REGISTER)
  emitRumMetric(requiredRegistrationFieldsEvent[component.id],requiredRegistrationFieldsError[component.id])
  return;
}

export const emitRumMetric = (event: any, error: any, params?: any) => {
  emitEvent(
    {
      event: event,
      error: error,
      status: false,
      country: getCountryCode(),
      path: getPath(),
      ...params
    }
  );

}

export const validateRegexValues = (component: any, candidateObject: any) => {
  if (component.id == "middleName"){
    // this is validated separately due to the extra checkbox 
    return;
  }

  if (!component.hasError && ! isEmpty(component.regex)) {
    const regex = new RegExp(component.regex);
    // check if optional input is empty or doesn't exist
    // If so, we skip regex test
    const isValidOptional = !candidateObject[component.id] && !component.required;

    if (!regex.test(candidateObject[component.id]) && !isValidOptional) {
      // console.log("regex", component.id, candidateObject);
      component.hasError = true;
      component.errorText = DefaultRegexValidationErrorMessages[component.id];
      const countryCode = getCountryCode();
      if (RumSensitiveInfoEnabledCountry.includes(countryCode) || (component.type != 'email' && component.type != 'tel')) {
        emitEvent(
          {
            event: requiredRegistrationFieldsEvent[component.id],
            error: regexRegistrationFieldsError[component.id],
            status: false,
            country: countryCode,
            path: getPath(),
            candidateInput: candidateObject[component.id],
          }
        );
      }
      else {
        emitEvent(
          {
            event: requiredRegistrationFieldsEvent[component.id],
            error: regexRegistrationFieldsError[component.id],
            status: false,
            country: countryCode,
            path: getPath(),
          }
        );
      }
    }
  }
};

export const validatePhoneNumber = (
  component: any,
  candidateObject: any,
) => {
  if (!component.hasError && component.type === "tel") {
    const phoneNumber = `${candidateObject.phoneCountryCode}${candidateObject[component.id]}`;

    if (!isPhoneNumberValid(phoneNumber)) {
      component.hasError = true;
      component.errorText = DefaultRegexValidationErrorMessages[component.id];
      const countryCode = getCountryCode();
      if (RumSensitiveInfoEnabledCountry.includes(countryCode)) {
        emitEvent(
          {
            event: requiredRegistrationFieldsEvent[component.id],
            error: regexRegistrationFieldsError[component.id],
            status: false,
            country: countryCode,
            path: getPath(),
            candidateInput: candidateObject[component.id],
          }
        );
      } else {
        emitEvent(
          {
            event: requiredRegistrationFieldsEvent[component.id],
            error: regexRegistrationFieldsError[component.id],
            status: false,
            country: countryCode,
            path: getPath(),
          }
        );
      }
    }
  }
};

export const isValidDateOfBirth = (date: string) => {
  const age = moment().diff(date, "years", true);

  return age >= 18 && age <=100;
};

export const validateDateOfBirth = (
  component: any,
  candidateObject: any,
) => {
  if (!component.hasError && component.id === "dateOfBirth") {
    if (!isValidDateOfBirth(candidateObject.dateOfBirth)) {
      component.hasError = true;
      component.errorText = DefaultRegexValidationErrorMessages[`${component.id}Limit`];
    }
  }
};

export const validateStringIsSame = (
  component: any,
  candidateObject: any,
  currentKey: string,
  validationCheckingKey: string
) => {
  if (
    !isEqual(
      candidateObject[currentKey],
      candidateObject[validationCheckingKey]
    )
  ) {
    component.hasError = true;
    component.errorText = DefaultMatchValidationErrorMessages[component.id];
    emitEvent(
      {
        event: requiredRegistrationFieldsEvent[component.id],
        error: reEnterStringError[component.id],
        status: false,
        country: getCountryCode(),
        path: getPath(),
      }
    );
  }
};

export const validatePinRequirementSatisfied = (
  component: any,
  candidateObject: any
) => {
  if (
    checkIfSequentialNumber(candidateObject.pin) ||
    checkIfRepeatedNumber(candidateObject.pin) ||
    checkIfHalfRepeatedNumber(candidateObject.pin)
  ) {
    component.hasError = true;
    component.errorText = AuthErrorMessages["hvh-error-commonly-used-pin"];
    emitEvent(
      {
        event: requiredRegistrationFieldsEvent[component.id],
        error: PinError.INVALID_PIN,
        status: false,
        country: getCountryCode(),
        path: getPath(),
        candidateInput: candidateObject.pin,
      }
    );
  }
};

export const resetErrorInForm = (currentFormConfig: any[]) => {
  const resetedForm = cloneDeep(currentFormConfig);
  for (const component of resetedForm) {
    component.hasError = false;
    component.errorText = null;
  }
  return resetedForm;
};

export const formatSelectValues = (registrationForm: any) => {
  registrationForm.language = typeof registrationForm.language === "string" ? registrationForm.language : registrationForm.language.value;
  registrationForm.timezone = typeof registrationForm.timezone === "string" ? registrationForm.timezone : registrationForm.timezone["value"];
  return registrationForm;
};

const setFormConsent = (registrationForm: any, formConsent: (boolean | undefined)[]) => {
  registrationForm.isAgreeToDataRetention = formConsent[0];
  registrationForm.isAgreeToCommunication = formConsent[1];
  registrationForm.isWhatsAppEnabled = formConsent[2];
  return registrationForm;
};

interface CreateCandidateProps {
  registrationForm: any;
  formConfig: any[];
  formConsent: (boolean | undefined)[];
  signUpType: string;
  onCheckForm: Function;
  onCheckConsent: Function;
  onCheckOverall: Function;
  onNext: Function;
}

export const onCreateCandidate = async ({
  registrationForm,
  formConfig,
  formConsent,
  signUpType,
  onCheckForm,
  onCheckConsent,
  onCheckOverall,
  onNext,
}: CreateCandidateProps) => {
  // On RegistrationPage, it will pass Redux Store registration obj.
  // This store obj got mutated by formatSelectValues function. CloneDeep to avoid mutation.
  let candidateObject = cloneDeep(registrationForm);

  boundRemoveAlerts();
  boundLoadingStart();
  addAdobeMetric(AdobeEvent.NEXT_BUTTON_CLICKED, PageName.REGISTER);
  const config = resetErrorInForm(formConfig);
  const errorFields: any = {};
  const newConfig: any[] = [];
  for (const component of config) {
    if (!isEmpty(component)) {
      /**
       * Validate required fields, if there is an error and update the state.
       */
      validateRequiredFields(component, candidateObject);

      /**
       * Validate Middle Name field separately as it has a checkbox
       */
      if (component.type == 'checkboxControlledText') {
        validateMiddleName(component, candidateObject)
      }

      /**
       * Validate the input format using RegEx.
       */
      validateRegexValues(component, candidateObject);

      /**
       * validate pin match
       */
      if (!component.hasError && component.id === "reEnterPin") {
        validateStringIsSame(component, candidateObject, "reEnterPin", "pin");
      }

      /**
       * check commonly used PINs
       */
      if (!component.hasError && component.id === "pin") {
        validatePinRequirementSatisfied(component, candidateObject);
      }

      /**
       * validate phoneNumber
       */
      validatePhoneNumber(component, candidateObject);

      if (
        !component.hasError &&
        component.id === "reEnterPhoneNumber"
      ) {
        validateStringIsSame(
          component,
          candidateObject,
          "reEnterPhoneNumber",
          "phoneNumber"
        );
      }

      /**
       * validate Email
       */
      if (!component.hasError && component.id === "reEnterEmailId") {
        validateStringIsSame(
          component,
          candidateObject,
          "reEnterEmailId",
          "emailId"
        );
      }

      /** check email for duplicate */
      if (
        !component.hasError &&
        signUpType === USER_SIGN_UP_TYPE.CREATE &&
        component.id === "emailId"
      ) {
        await checkEmailExits(component, candidateObject);
      }

      /** check mobile for duplicate */
      if (
        !component.hasError &&
        signUpType === USER_SIGN_UP_TYPE.CREATE &&
        component.id === "phoneNumber"
      ) {
        await checkPhoneNumberExits(component, candidateObject);
      }

      if (component.hasError) {
        errorFields[component.id] = true;
      }
      newConfig.push(component);
    }
  }

  onCheckForm(newConfig);

  const countryFullName = getCountryFullName();

  // Set timezone to automatically be default value for UK
  if (countryFullName === CountryFullName.UK ) {
    candidateObject.timezone = TimeZonesUK[0].value;
  } else if (countryFullName === CountryFullName.JP ) {
    candidateObject.timezone = TimeZonesJP[0].value;
    candidateObject.language = Language.JP;
  }
  candidateObject.bbRegion = FullCountryBBRegion[countryFullName];

  if ( countryFullName === CountryFullName.MX ) {
    // override IsAgreeToCommunication to true
    formConsent[1] = true;
  }
  if (countryFullName === CountryFullName.US) {
    // override IsAgreeToCommunication to true in US, and ignore isAgreeToDataRetention
    formConsent[1] = true;
  } else if (countryFullName === CountryFullName.CA) {
    onCheckConsent([false, formConsent[1] === undefined]);
  } else if (countryFullName === CountryFullName.MX) {
    onCheckConsent([false, true, formConsent[2] === undefined]);
  } else {
    // override isAgreeToCommunication to true as UK already set it to true in initRegistrationForm
    formConsent[1] = true;
    // Show consent error messages if customer didn't select any consent.
    onCheckConsent([formConsent[0] === undefined, false]);
  }

  const consentRule = () => {
    if (countryFullName === CountryFullName.US) {
      // only check IsAgreeToCommunication for US
      return formConsent[1] === true;
    } else if (countryFullName === CountryFullName.MX) {
      // check IsAgreeToCommunication and IsWhatsAppEnabled for MX
      return (formConsent[1] !== undefined && formConsent[2] !== undefined);
    } else if (countryFullName === CountryFullName.CA) {
      // only check IsAgreeToCommunication for CA
      return formConsent[1] !== undefined;
    }

    // for other countries, only need to check whether isAgreeToDataRetention is set
    return (formConsent[0] !== undefined);

  };

  if (isEmpty(errorFields) && consentRule()) {
    onCheckOverall(false);
    // console.log("everything is valid");

    // Reformat Select field values to strings
    candidateObject = formatSelectValues(candidateObject);

    const { language } = candidateObject;
    const locale = getPreferredLocaleFromCountryAndLanguage(language);

    candidateObject = setFormConsent(candidateObject, formConsent);
    const country = candidateObject?.country === CountryFullName.DEVO ? CountryFullName.UK : candidateObject.country;

    let isAgencyUser: string | boolean | null = window.sessionStorage.getItem(
      "isAgencyUser"
    );
    if (isAgencyUser && isAgencyUser === "true") {
      isAgencyUser = true;
    }

    boundCreateCandidate(
      {
        request: {
          ...candidateObject,
          emailId: candidateObject.emailId.toLowerCase(),
          reEnterEmailId: candidateObject.reEnterEmailId.toLowerCase(),
          phoneNumber: cleanPhoneNumberFormat(candidateObject.phoneNumber),
          reEnterPhoneNumber: cleanPhoneNumberFormat(candidateObject.reEnterPhoneNumber),
          locale,
          isAgencyUser,
          country
        },
        type: signUpType
      },
      (payload: any) => {
        // setRegistrationSession(payload.session);
        setConfirmOtpSession(payload.session);
        boundUpdateCandidate({
          ...candidateObject,
          candidateLogin: signUpType === USER_SIGN_UP_TYPE.CREATE? candidateObject.emailId.toLowerCase() : candidateObject.candidateLogin.toLowerCase(),
          emailId: candidateObject.emailId.toLowerCase(),
          phoneNumber: cleanPhoneNumberFormat(candidateObject.phoneNumber),
          reEnterPhoneNumber: cleanPhoneNumberFormat(candidateObject.reEnterPhoneNumber),
          locale,
          isAgencyUser
        });

        onNext();
      },
      () => {
        boundAddAlert({
          errorMessage: AuthErrorMessages["error-create-candidate-message"],
          errorMessageStringId: "error-create-candidate-message"
        });
        boundLoadingEnd();
      }
    );
  } else {
    onCheckOverall(true);
    boundLoadingEnd();
  }
};

