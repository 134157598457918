import React, {useState} from "react";
import { Checkbox } from '@amzn/stencil-react-components/form';
import { Col, Spacer, Row } from "@amzn/stencil-react-components/layout";
import { Text } from "@amzn/stencil-react-components/text";

interface CheckboxComponentProps {
    onChange: Function
    labelText?: string
    alignItems?: string
    color?: string
}

export const CheckboxComponent = ({
    onChange,
    labelText,
    alignItems,
    color,
}:CheckboxComponentProps) => {
    const [showPin, setShowPin] = useState<boolean>(false);
    return(
        <Col className="colContainer">
            <Row className="rowContainer" alignItems={alignItems} gridGap="S300">
                <div className="checkbox">
                <Checkbox
                    id="show-pin-checkbox"
                    dataTestId="input-test-id-checkBox"
                    checked={showPin}
                    onChange={(e)=>{
                        const value = e.target.checked;
                        setShowPin(value)
                        onChange(value)
                    }}
                    
                />
                </div>
                <Text
                    color={color}
                    fontSize="T200"
                    textAlign="left"
                >
                    {labelText
                        ? labelText 
                        : ""
                    }
                </Text>
            </Row>
        </Col>
    )
}
