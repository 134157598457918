import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { PAGE_ROUTE_LOGIN } from '../pageRoutes';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { H1, Text } from '@amzn/stencil-react-components/text';
import { Input } from '@amzn/stencil-react-components/form';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { IconCheckCircleFill, IconCloseCircleFill } from '@amzn/stencil-react-components/icons';
import isBoolean from 'lodash/isBoolean';
import isNil from 'lodash/isNil';
import { LOGIN_TYPE, REGISTRATION_PAGES } from 'src/utils/constants';
import { onVerifyPhone } from 'src/utils/authService';
import ResendOtp from './ResendOtpComponent';
import SkipPhoneVerification from './SkipPhoneVerficationComponent';
import { isEmailFieldOptional, isStencil2021Theme } from 'src/utils/featureFlag/featureFlag';
import { CommonColors, HVHColors } from 'src/utils/colors';
import { handleRedirection } from 'src/utils/helper';
import { recordPageView } from 'src/utils/rum';
import { addAdobeMetric } from 'src/customerTracking/adobeAnalytics';
import { AdobeEvent, PageName } from 'src/customerTracking/adobeAnalytics/types';
import useHVHTranslation from 'src/Hooks/useHVHTranslation';

export const VerifyPhonePage = (props: any) => {
  const history: any = useHistory();
  const { t } = useHVHTranslation();
  const [otp, setOtp] = useState<string>('');
  const [isInvaildToVerify, setInvaildToVerify] = useState<boolean>(false);
  const [isPhoneVerified, setPhoneVerified] = useState<boolean>(
    isBoolean(props.isPhoneVerifiedOverride) ? props.isPhoneVerifiedOverride : false,
  );
  const goToLoginPage = () => {
    props.redirectToLoginTest && props.redirectToLoginTest();
    history && history.push(`/${PAGE_ROUTE_LOGIN}`);
  };

  const currentCandidate = props.candidate.results;

  useEffect(() => {
    if (currentCandidate && currentCandidate.isPhoneVerified) {
      // Skip validation since phone already verified
      PhoneVerificationComplete();
    } else if (currentCandidate && !currentCandidate.isPhoneVerified && currentCandidate.candidateLogin) {
      // From verify flow
      setInvaildToVerify(false);
    } else if (
      !currentCandidate.isPhoneVerified &&
      props.registration.emailId &&
      props.registration.emailId.length >= 0
    ) {
      // From registration flow
      setInvaildToVerify(false);
    } else {
      !props.redirectOverride && goToLoginPage();
    }
    // document.title = "Amazon Jobs - Phone verification";
  }, []);

  useEffect(() => {
    if (isNil(currentCandidate)) {
      setInvaildToVerify(true);
    }
  }, [currentCandidate]);

  const onOtpChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.length <= 6) {
      setOtp(value);
    }
    if (value.length === 6) {
      onVerifyPhone(currentCandidate, value, setPhoneVerified, props.onVerifyPhone);
    }
  };

  const resetOtp = () => {
    setOtp('');
  };

  const PhoneVerificationComplete = () => {
    addAdobeMetric(AdobeEvent.NEXT_BUTTON_CLICKED, PageName.VERIFY_PHONE);
    props.onPhoneVerificationCompleteTest && props.onPhoneVerificationCompleteTest();
    handleRedirection();
  };

  recordPageView({ pageId: REGISTRATION_PAGES.VERIFY_PHONE });
  return (
    <Row justifyContent="center">
      {isInvaildToVerify ? (
        <Col gridGap="S300" className="hvhRegistration">
          <img
            src="https://m.media-amazon.com/images/G/01/HVH-CandidateApplication/jobs/illustration-phone.svg"
            className="image-icon"
            alt="phone"
            aria-hidden="true"
            role="presentation"
            tabIndex={-1}
          />
          <Text fontSize="T400" color="accent1">
            <Row gridGap="S300">
              <IconCloseCircleFill aria-hidden color="negative" />
              <Text>
                {t(
                  'VerifyPhonePage-lbl-verification-failed-login-prompt',
                  'Phone verification is failed. Please login to verify your phone number.',
                )}
              </Text>
            </Row>
          </Text>
        </Col>
      ) : (
        <Col gridGap="S300" className="hvhRegistration">
          <Col className="colContainerTop">
            <img
              src="https://m.media-amazon.com/images/G/01/HVH-CandidateApplication/jobs/illustration-phone.svg"
              className="image-icon"
              alt="phone"
              aria-hidden="true"
              role="presentation"
              tabIndex={-1}
            />
          </Col>
          <Col className="colContainer">
            <H1 fontSize="T400" color="accent1" fontWeight="regular" textAlign="left">
              {t(
                'VerifyPhonePage-title-verify-phone',
                "Great! Now, let's verify your mobile number so you can use it to log in next time.",
              )}
            </H1>
          </Col>
          <Col className="colContainer">
            <Text fontSize="T100" color="accent1" textAlign="left">
              {t(
                'VerifyPhonePage-desc-verify-phone',
                `Enter the verification code sent to {phoneCountryCode}{phoneNumber}. Look for a text message that says "Your Amazon Jobs verification code". It will expire in 3 minutes.`,
                {
                  phoneCountryCode: currentCandidate.phoneCountryCode,
                  phoneNumber: currentCandidate.phoneNumber,
                },
              )}
            </Text>
          </Col>
          <Col gridGap="S300" className="colContainer">
            <Row gridGap="S300" alignItems="center" className="rowContainer">
              <Input
                dataTestId="input-test-id-phoneOtp"
                value={otp}
                onChange={onOtpChange}
                width="100%"
                maxLength={6}
                aria-label="verificationCode"
              />
              <IconCheckCircleFill
                aria-hidden
                margin="0px 0px 0px 8px"
                color={isStencil2021Theme() ? CommonColors.Blue70 : HVHColors.BlueLagoon}
                display={isPhoneVerified ? 'block' : 'none'}
              />
            </Row>
            {!isPhoneVerified && (
              <>
                <Col gridGap="S300" className="colContainer" alignItems="center">
                  <ResendOtp
                    candidate={currentCandidate}
                    resetOtpTextBox={resetOtp}
                    waitTime={60}
                    loginType={LOGIN_TYPE.PHONE}
                    pin={currentCandidate.pin ? currentCandidate.pin : props.pin}
                  />
                </Col>
                {!isEmailFieldOptional() && (
                  <Col gridGap="S300" className="colContainer" alignItems="center">
                    <SkipPhoneVerification onSkipPhoneVerification={PhoneVerificationComplete} />
                  </Col>
                )}
              </>
            )}
            {isPhoneVerified && (
              <Col gridGap="S300" className="colContainer" alignItems="center">
                <Text textAlign="center" color="positive">
                  {t('VerifyPhonePage-lbl-phone-verify-success-message', 'Your account has been verified!')}
                </Text>
                <Button
                  dataTestId="button-test-id-phoneVerificationComplete"
                  onClick={PhoneVerificationComplete}
                  variant={ButtonVariant.Primary}
                >
                  {t('VerifyPhonePage-btn-continue-label', 'Continue')}
                </Button>
              </Col>
            )}
          </Col>
        </Col>
      )}
    </Row>
  );
};

interface Props {
  goToPage?: Function;
  onVerifyPhone?: Function;
  redirectOverride?: boolean;
  redirectToLoginTest?: Function;
  isPhoneVerifiedOverride?: boolean;
  onPhoneVerificationCompleteTest?: Function;
  router?: any;
  pin?: any;
  candidate?: any;
  registration?: any;
}

const mapStateToProps = (state: any, ownProps?: Props) => {
  return { ...state, ...ownProps };
};

export default connect(mapStateToProps)(VerifyPhonePage);
