import { from, Observable, of } from "rxjs";
import { flatMap, map, catchError } from "rxjs/internal/operators";
import { ofType } from "redux-observable";
import { verifySignInApi } from '../utils/apis/serviceApi';
import { VerifySignInSuccessResponse, VerifySignInErrorResponse } from 'src/utils/apis/serviceApiTypes';
import { actionVerifySignInSuccess, actionVerifySignInFail } from "../actions/actions";
import { VERIFY_SIGN_IN } from "../actions/actionTypes";
import { emitEvent } from "src/utils/rum";
import { getCountryCode } from "src/utils/apis/apiHelper";
import { RumEventType } from "src/utils/constants";
import { getPath } from "src/utils/helper";

export const verifySignInEpic = (action$: Observable<any>) => {
    return action$.pipe(
        ofType(VERIFY_SIGN_IN),
        flatMap((action: any) =>
            from<Promise<any>>(
                verifySignInApi(action.payload)
            ).pipe(
                flatMap(async (response) => {
                    return response.data
                }),
                map((data: VerifySignInSuccessResponse) => {
                    if (action.onSuccess) {
                        action.onSuccess();
                    }

                    emitEvent(
                        {
                            event: RumEventType.VERIFY_SIGN_IN,
                            status: true,
                            country: getCountryCode(),
                            availability: 1,
                            path: getPath()
                        }
                    );

                    return actionVerifySignInSuccess({
                        phoneNumber: data.phoneNumber,
                        emailId: data.emailId
                    })
                }),
                catchError((error) => {
                    const errorResponse: VerifySignInErrorResponse = error.response.data;

                    emitEvent(
                        {
                            event: RumEventType.VERIFY_SIGN_IN,
                            status: false,
                            country: getCountryCode(),
                            error: errorResponse.errorMessageStringId,
                            errorCode: error.response.status,
                            availability: 0,
                            path: getPath()
                          }
                    );

                    return of(actionVerifySignInFail({ errorMessage: errorResponse.errorMessage, errorMessageStringId: errorResponse.errorMessageStringId }))
                })
            )
        )
    );
};