import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import alertReducer from './alert.reducer';
import configReducer from './config.reducer';
import uiReducer from './ui.reducer';
import registrationReducer from './registration.reducer';
import candidateReducer from './candidate.reducer';
import webLabReducer from 'src/reducers/weblab.reducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const alertPersistConfig = {
  key: 'alert',
  storage,
  whitelist: ['showGlobalAlerts', 'globalAlerts', 'showJobAlertSuccess'],
};

const createRootReducer = (history: any) =>
  combineReducers({
    config: configReducer,
    router: connectRouter(history),
    alert: persistReducer(alertPersistConfig, alertReducer),
    ui: uiReducer,
    registration: registrationReducer,
    candidate: candidateReducer,
    webLab: webLabReducer,
  });

export default createRootReducer;
