import { from, Observable, of } from 'rxjs';
import { flatMap, map, catchError } from 'rxjs/internal/operators';
import { ofType } from 'redux-observable';
import { verifyEmailApi } from '../utils/apis/serviceApi';
import { parseErrorMessage } from '../utils/helper';
import { actionVerifyEmailSuccess } from '../actions/actions';
import {
  VERIFY_EMAIL,
  VERIFY_EMAIL_FAILED,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_CALLBACK_SUCCESS,
} from '../actions/actionTypes';

export const verifyEmailEpic = (action$: Observable<any>) => {
  return action$.pipe(
    ofType(VERIFY_EMAIL),
    flatMap((action: any) =>
      from<Promise<any>>(verifyEmailApi(action.payload)).pipe(
        flatMap(async (response) => {
          return response.data;
        }),
        map((x) => actionVerifyEmailSuccess(x, action.callback)),
        catchError((error) => {
          const { response } = error;
          if (action.onError) {
            action.onError(response);
          }
          return of({
            type: VERIFY_EMAIL_FAILED,
            payload: parseErrorMessage(response.data),
          });
        }),
      ),
    ),
  );
};

export const verifyEmailSuccessEpic = (action$: Observable<any>) => {
  return action$.pipe(
    ofType(VERIFY_EMAIL_SUCCESS),
    map((action) => {
      if (action.callback) {
        action.callback(action.payload);
      }
      return { type: VERIFY_EMAIL_CALLBACK_SUCCESS, payload: null };
    }),
  );
};
