import {
  CHECK_AUTH,
  CHECK_AUTH_FAILED,
  CHECK_AUTH_SUCCESS,
} from "../actions/actionTypes";
import { CheckAuth } from "src/utils/commonTypes";

export interface AuthState {
  loading: boolean
  results: {
    checkAuth: CheckAuth
  }
}

export const initAuthState: AuthState = {
  loading: false,
  results: { checkAuth: {}}
}

export default function authReducer(
  state = initAuthState,
  action: any
) {
  const curResults = state.results ? state.results : {};
  switch (action.type) {
    case CHECK_AUTH:
      return {
        ...state,
        loading: true,
      };
    case CHECK_AUTH_SUCCESS:
      return {
        ...state,
        results: {
          ...curResults,
          checkAuth: action.payload
        },
        loading: false,
      };
    case CHECK_AUTH_FAILED:
      return {
        ...state,
        results:{
          checkAuth:{isValid: false},
        },
        loading: false,
      };
    default:
      return state;
  }
}
