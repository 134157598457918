import React, { useEffect, useState } from 'react';

import { Row } from '@amzn/stencil-react-components/layout';
import { Spinner } from '@amzn/stencil-react-components/spinner';
import { getAccessToken, getSsoCode, handleRedirection, setAccessToken } from 'src/utils/helper';
import { getStage } from 'src/utils/apis/apiHelper';
import { RumEventType, StageToAtoZSSOCodeEndpoint } from 'src/utils/constants';
import { getSSOTokenWithCode } from 'src/utils/apis/serviceApi';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { isInitialized } from 'src/i18n';
import { boundHideAlerts } from 'src/actions/boundActions';
import { emitEvent } from 'src/utils/rum';

const AtoZSSOPage = () => {
  const [error, setError] = useState('');

  useEffect(() => {
    // Emitting RUM events
    emitEvent({
      event: RumEventType.PAGE_LOAD,
      status: true,
      message: 'Mounted AtoZ Account match component',
    });
  }, []);

  useEffect(() => {
    async function runSso() {
      // When the redirection above happens, Safari will force the requests in flight to throw network errors.
      // We don't care about the errors since we are redirecting to idprism, but the error banner will show up
      // for a second, which is confusing to the user. Therefore, we manually hide all alerts after redirection call.
      boundHideAlerts();
      const code = getSsoCode();
      if (!code) {
        const url = StageToAtoZSSOCodeEndpoint[getStage()];
        emitEvent({
          event: RumEventType.ATOZ_LOGIN_REDIRECT_TO_A2Z_CUPID,
          status: true,
          message: `AtoZ Account match: no ssoCode, redirecting to atoz cupid. Url: ${url}`,
        });
        // fetch sso code if sso code not found in session storage
        window.location.assign(url);
      } else if (!getAccessToken()) {
        // otherwise use sso code to fetch access token, persist access token and then redirect to CS
        try {
          const {
            data: { access_token },
          } = await getSSOTokenWithCode(code);
          emitEvent({
            event: RumEventType.ATOZ_LOGIN_GET_ACCESS_TOKEN_SUCCESS,
            status: true,
            message: `AtoZ Account match: successfully got accessCode with ssoCode. Setting accessCode and redirecting to CS`,
          });
          setAccessToken(access_token);
          handleRedirection();
        } catch (e: any) {
          setError(e ? String(e) : 'Unknown error when fetching SSO token');
          emitEvent({
            event: RumEventType.ATOZ_LOGIN_GET_ACCESS_TOKEN_FAILED,
            error: 'Unknown error when fetching SSO token',
            errorCode: e?.code,
            status: false,
            message: `AtoZ Account match: successfully and failed to get accessCode with ssoCode with error: ${e}`,
          });
        }
      }
    }
    if (isInitialized) {
      // currently App.tsx is being rendered twice (before and after i18n is initialized) during a single page load. The sso logic should only be run once per page load.
      runSso();
    }
  }, []);
  return (
    <Row height="100%" justifyContent="center" padding="S300">
      {error ? <MessageBanner type={MessageBannerType.Error}>{error}</MessageBanner> : <Spinner />}
    </Row>
  );
};

export default AtoZSSOPage;
