import { Action } from "redux";
import { WebLabConfigs } from "src/utils/webLab/types";

export enum WEB_LAB_ACTION_TYPE {
    SET = "SET_WEB_LAB_CONFIG",
    CLEAR = 'CLEAR_WEB_LAB_CONFIG',
    SEND_METRICS = 'SEND_METRICS',
    SEND_METRICS_SUCCESS = 'SEND_METRICS_SUCCESS',
    SEND_METRICS_FAILED = 'SEND_METRICS_FAILED'
}

export interface WebLabSetConfig extends Action {
    type: WEB_LAB_ACTION_TYPE.SET;
    payload: WebLabConfigs;
}

export interface WebLabClearConfig extends Action {
    type: WEB_LAB_ACTION_TYPE.CLEAR;
}

export interface WebLabSendMetrics extends Action {
    type: WEB_LAB_ACTION_TYPE.SEND_METRICS,
    payload: string
}

export interface WebLabSendMetricSuccess extends Action {
    type: WEB_LAB_ACTION_TYPE.SEND_METRICS_SUCCESS
}

export interface WebLabSendMetricFailed extends Action {
    type: WEB_LAB_ACTION_TYPE.SEND_METRICS_FAILED
}

export type WebLabActions =
    WebLabSetConfig |
    WebLabClearConfig |
    WebLabSendMetrics |
    WebLabSendMetricSuccess |
    WebLabSendMetricFailed;
