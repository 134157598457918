export const ADD_ALERT = 'ADD_ALERT';
export const ADD_STICKY_ALERTS = 'ADD_STICKY_ALERTS';
export const CREATE_CANDIDATE = 'CREATE_CANDIDATE';
export const CREATE_CANDIDATE_SUCCESS = 'CREATE_CANDIDATE_SUCCESS';
export const CREATE_CANDIDATE_FAILED = 'CREATE_CANDIDATE_FAILED';
export const CREATE_CANDIDATE_CALLBACK_SUCCESS = 'CREATE_CANDIDATE_CALLBACK_SUCCESS';
export const GET_CONFIG = 'GET_CONFIG';
export const GET_CONFIG_FAILED = 'GET_CONFIG_FAILED';
export const GET_CONFIG_SUCCESS = 'GET_CONFIG_SUCCESS';
export const GET_CANDIDATE = 'GET_CANDIDATE';
export const GET_CANDIDATE_FAILED = 'GET_CANDIDATE_FAILED';
export const GET_CANDIDATE_SUCCESS = 'GET_CANDIDATE_SUCCESS';
export const GET_CANDIDATE_CALLBACK_SUCCESS = 'GET_CANDIDATE_CALLBACK_SUCCESS';
export const REMOVE_ALERTS = 'REMOVE_ALERTS';
export const REMOVE_STICKY_ALERTS = 'REMOVE_STICKY_ALERTS';
export const DISMISS_GLOBAL_ALERTS = 'DISMISS_GLOBAL_ALERTS';
export const HIDE_ALERTS = 'HIDE_ALERTS';
export const RESET_CANDIDATE = 'RESET_CANDIDATE';
export const VERIFY_SIGN_IN = 'VERIFY_SIGN_IN';
export const VERIFY_SIGN_IN_SUCCESS = 'VERIFY_SIGN_IN_SUCCESS';
export const VERIFY_SIGN_IN_FAILED = 'VERIFY_SIGN_IN_FAILED';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_FAILED = 'SIGN_IN_FAILED';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_CALLBACK_SUCCESS = 'SIGN_IN_CALLBACK_SUCCESS';
export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_OUT_FAILED = 'SIGN_OUT_FAILED';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_CALLBACK_SUCCESS = 'SIGN_OUT_CALLBACK_SUCCESS';
export const UPDATE_CANDIDATE = 'UPDATE_CANDIDATE';
export const UPDATE_REGISTRATION_FORM = 'UPDATE_REGISTRATION_FORM';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAILED = 'VERIFY_EMAIL_FAILED';
export const VERIFY_EMAIL_CALLBACK_SUCCESS = 'VERIFY_EMAIL_CALLBACK_SUCCESS';
export const VERIFY_PHONE = 'VERIFY_PHONE';
export const VERIFY_PHONE_SUCCESS = 'VERIFY_PHONE_SUCCESS';
export const VERIFY_PHONE_FAILED = 'VERIFY_PHONE_FAILED';
export const VERIFY_PHONE_CALLBACK_SUCCESS = 'VERIFY_PHONE_CALLBACK_SUCCESS';
export const CONFIRM_OTP = 'CONFIRM_OTP';
export const CONFIRM_OTP_FAILED = 'CONFIRM_OTP_FAILED';
export const CONFIRM_OTP_SUCCESS = 'CONFIRM_OTP_SUCCESS';
export const CONFIRM_OTP_CALLBACK_SUCCESS = 'CONFIRM_OTP_CALLBACK_SUCCESS';
export const FORGOT_PIN = 'FORGOT_PIN';
export const FORGOT_PIN_SUCCESS = 'FORGOT_PIN_SUCCESS';
export const FORGOT_PIN_FAILED = 'FORGOT_PIN_FAILED';
export const FORGOT_PIN_CALLBACK_SUCCESS = 'FORGOT_PIN_CALLBACK_SUCCESS';
export const RESET_PIN = 'RESET_PIN';
export const RESET_PIN_SUCCESS = 'RESET_PIN_SUCCESS';
export const RESET_PIN_FAILED = 'RESET_PIN_FAILED';
export const RESET_PIN_CALLBACK_SUCCESS = 'RESET_PIN_CALLBACK_SUCCESS';
export const CHECK_STATE = 'CHECK_STATE';
export const LOADIND_START = 'LOADIND_START';
export const LOADIND_END = 'LOADIND_END';
export const UPDATE_PHONE = 'UPDATE_PHONE';
export const UPDATE_PHONE_SUCCESS = 'UPDATE_PHONE_SUCCESS';
export const UPDATE_PHONE_FAILED = 'UPDATE_PHONE_FAILED';
export const UPDATE_PHONE_CALLBACK_SUCCESS = 'UPDATE_PHONE_CALLBACK_SUCCESS';
export const GET_CSRF = 'GET_CSRF';
export const GET_CSRF_SUCCESS = 'GET_CSRF_SUCCESS';
export const GET_CSRF_FAILED = 'GET_CSRF_FAILED';
export const RESET_REQUEST = 'RESET_REQUEST';
