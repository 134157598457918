import { Address, CountryFullName } from '../commonTypes';
import { getDefaultLocale } from '../localization/localeHelper';
import { PreferredLanguages } from '../registrationFrom/constants';
import { getCountryFullName } from 'src/utils/apis/apiHelper';

export const getLanguageForPreferredLocale = (
  language: string | { key: string; value: string } | undefined,
): string => {
  if (!language) {
    return '';
  }

  if (typeof language === 'string') {
    return language;
  }

  return language.value;
};

export const getCountry = (address: Address) => {
  if (!address.country) {
    return '';
  }

  return address.country;
};

export const getPreferredLocaleFromCountryAndLanguage = (language: string, countryFullName?: CountryFullName) => {
  const languageListByCountry = PreferredLanguages[countryFullName || getCountryFullName()];
  const languageSelected = languageListByCountry.find((lang) => lang.value === language);

  return languageSelected?.locale || getDefaultLocale();
};
