import { getCountryFullName, getLocale } from "src/utils/apis/apiHelper";
import { TimeZonesByCountry } from "src/utils/registrationFrom/constants";
import {
  UPDATE_REGISTRATION_FORM,
} from "../actions/actionTypes";
import {
  RegistrationForm,
  CountryFullName,
  BBRegion,
} from "../utils/commonTypes";
import { getPhoneCountryCode } from "src/utils/registrationFrom/registrationHelper";

export const initRegistrationForm: RegistrationForm = {
  isActive: false,
  emailId: "",
  firstName: "",
  middleName: "",
  noMiddleNameChecked: false,
  nameSuffix: "",
  isAgencyUser: false,
  isAgreeToCommunication: getCountryFullName() === CountryFullName.UK ? true : undefined,
  language: "",
  lastName: "",
  locale: getLocale({}),
  phoneCountryCode: getPhoneCountryCode(),
  phoneNumber: "",
  pin: "",
  reEnterEmailId: "",
  reEnterPhoneNumber: "",
  reEnterPin: "",
  timezone: TimeZonesByCountry[0] as any,
  isAgreeToDataRetention: undefined,
  isWhatsAppEnabled: undefined,
  country: getCountryFullName(),
  bbRegion: "{{BBRegion}}" as BBRegion
};

export default function updateRegistrationFormReducer(
  state = initRegistrationForm,
  action: any
) {
  switch (action.type) {
    case UPDATE_REGISTRATION_FORM:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
