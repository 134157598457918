export enum AdobeEvent {
    PAGE_LOAD = "page load",
    CREATE_ACCOUNT = "create account",
    SIGN_IN = "sign in",
    PREFERRED_NAME = "use preferred name",
    SELECT_HELP_TOPIC = "select help topic",
    SELECT_CONTACT_CHANNEL = "select contact channel",
    SELECT_FAQ = "select FAQ",
    LANGUAGE_SELECTOR = "language selector",
    NEXT_BUTTON_CLICKED = "next button clicked",
    SEND_VERIFICATION_CODE = "send verification code",
    BUTTON_CLICK_UPDATE = "button click update",
    MIDDLE_NAME_ERROR = "middleName error"
}

export const UNSPECIFIED: UNSPECIFIED_TYPE = "unspecified";


export enum PageName {
  CONTACT_US = "contact us",
  LOCALE_SWITCH = "locale switch",
  HOMEPAGE = "Homepage",
  REGISTER = "register",
  LOGIN = "login",
  FORGOT_PIN = "forgot personal pin",
  GLOBAL_CONTACT_US = "global contact us",
  PERSONAL_PIN_ENTRY = "personal pin entry",
  INCORRECT_OTP = "incorrect otp",
  CONFIRM_PHONE_NUMBER = "confirm phone number",
  OTP_OPTION_CHOICE = "otp option choice",
  OTP_CONFIRMED = "otp confirmed",
  ENTER_OTP = "enter otp",
  RESET_PERSONAL_PIN = "reset personal pin",
  PIN_RESET_ERROR = "pin reset error",
  PIN_RESET_SUCCESS = "pin reset success",
  INVALID_EMAIL = "invalid email",
  VERIFY_EMAIL = "verify email",
  VERIFY_PHONE = "verify phone"
}

export enum PageRenderType {
    DYNAMIC = "dynamic",
    STATIC = "static"
}

export enum UserLogInStatus {
    AUTHENTICATED = "logged-in",
    UNAUTHENTICATED = "unauthenticated"
}

export interface PageInfo {
    name: PageName;
    path: string;
    type?: string;
    renderType: PageRenderType;
    application: string;
    locale?: string;
    ittk?: string | UNSPECIFIED_TYPE;
}

export interface CandidateInfo {
    bbCandidateId: string | UNSPECIFIED_TYPE;
    status: UserLogInStatus;
    language?: string | UNSPECIFIED_TYPE;
    country?: string | UNSPECIFIED_TYPE;
    adobeSessionId?: string;
}

export interface CandidateOverrideInfo {
    language?: string | UNSPECIFIED_TYPE;
    country?: string | UNSPECIFIED_TYPE;
    bbCandidateId?: string | UNSPECIFIED_TYPE;
    sfCandidateId?: string | UNSPECIFIED_TYPE;
    CID?: string | UNSPECIFIED_TYPE;
}

export interface OverrideProps {
    page?: Partial<PageInfo>;
    candidate?: CandidateOverrideInfo;
}

export interface CampaignInfo {
    cmpid?: string;
}

export interface GeoClusterInfo {
    ID: string;
    name: string;
    region: string;
    zone: string;
    description: string;
    locationCode: string;
}

export interface ContactUsQuestionInfo {
    position: string | UNSPECIFIED_TYPE;
    helpTopic: string | UNSPECIFIED_TYPE;
}

export interface ContactUsContactInfo {
    channel: string;
}

export interface ContactUsFAQInfo {
    index: number;
    questionAnswer: string;
}

export interface AdobeMetricType {
    event: AdobeEvent;
    page: PageInfo;
    environment: string;
    countryCode: string;
    candidate: CandidateInfo;
    campaign: CampaignInfo;
    timestamp: string;
}

export interface MetricProps {
    geocluster?: GeoClusterInfo;
    question?: ContactUsQuestionInfo;
    contact?: ContactUsContactInfo;
    faq?: ContactUsFAQInfo;
    errorMessage?: string;
    phoneOptionExists?: boolean;
    emailOptionExists?: boolean;
    verificationOptionChosen?: string;
}

export type UNSPECIFIED_TYPE = "unspecified";
