import React from 'react';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import useHVHTranslation from 'src/Hooks/useHVHTranslation';

const USConsent = () => {
  const { t } = useHVHTranslation();
  return (
    <Col className="colContainerTop">
      <Row className="rowContainer" gridGap="S300">
        <Text color="accent1" fontSize="T200" textAlign="left">
          {t(
            'USConsentComponent-paragraph-contact-consent',
            'By providing your phone number(s), you agree to receive calls, text messages, and prerecorded/artificial voice messages, including from an automatic telephone dialing system, from or on behalf of Amazon to your phone number(s) about any matters. To opt out, you can change your contact preferences on the Edit Profile or My Personal Information section on the job portal.',
          )}
        </Text>
      </Row>
    </Col>
  );
};

export default USConsent;
