import { CountryStateConfigs, CountryStateListItem } from './apis/serviceApiTypes';
import {
  BasedUrlMapByCountryCode,
  CountryCodes,
  CSDomainByCountryFullName,
  EeoLawUrlByLocale,
  FlowType,
  loginHelpPageRoute,
  pathUrlLocaleRegex,
  PrivacyNoticeCountryCodeToLanguageMap,
  PrivacyNoticeLocaletoLanguageMap,
  PrivacyNoticeRegionMap,
  RumEventType,
  SignInOriginal,
} from 'src/utils/constants';
import {
  AppSyncEpicError,
  CountryFullName,
  GeoInfo,
  PhoneNumberInput,
  RegistrationForm,
  UpdateFromTarget,
} from 'src/utils/commonTypes';
import { GraphQLError } from 'graphql';
import { boundResetRequest } from 'src/actions/boundActions';
import { isMultiLanguageEnabled, isStencil2021Theme } from 'src/utils/featureFlag/featureFlag';
import { getAtoZDomain, getCountryCode, getCountryFullName, getCSDomain, getStage } from 'src/utils/apis/apiHelper';
import { CountryCode, isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import find from 'lodash/find';
import {
  PAGE_ROUTE_FORGOT_PIN,
  PAGE_ROUTE_LOGIN,
  PAGE_ROUTE_REGISTRATION,
  PAGE_ROUTE_VERIFY_EMAIL,
  PAGE_ROUTE_VERIFY_PHONE,
  PAGE_ROUTE_VERIFY_REGISTRATION,
} from 'src/components/pageRoutes';
import { getCurrentLocale, getDefaultLocale, getLocaleConfig } from 'src/utils/localization/localeHelper';
import { WebLabName } from 'src/utils/webLab/constants';
import { confirmLoginApi } from './apis/serviceApi';
import { PrivacyNoticeMap } from 'src/utils/registrationFrom/constants';
import Cookies from 'js-cookie';
import * as url from 'url';
import {
  cleanSearchParamValue,
  parseSearchParamFromHash,
  parseSearchParamFromLocationSearch,
} from 'src/utils/urlUtils';
import urlcat from 'urlcat';
import { emitEvent } from 'src/utils/rum';

const classnames = require('classnames');

export const composeFilter = (filter: any = []) => {
  const filterKeys = Object.keys(filter);
  return filterKeys.map((key: any) => `${filter[key].key}${filter[key].value}`).join('');
};

export const parseErrorMessage = (error: any) => {
  const parsedError = error;

  // Example to compose the error message
  // Will refactor for real case later
  if (error && error.name === 'CredentialsError') {
    parsedError.responseText = 'Please (re)authenticate or reload your browser.';
  }

  return parsedError;
};

export const getBrowserLocation = (callback: Function, errorCallback: Function, timeout?: number) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (result: GeolocationPosition) => {
        callback(result);
      },
      (error: GeolocationPositionError) => {
        errorCallback(error);
      },
      { timeout },
    );
  } else {
    const error = new Error('Browser geolocation is not supported.');
    errorCallback(error);
  }
};

export const getBrowserLocationPromise = (timeout?: number): Promise<GeolocationPosition> => {
  return new Promise<GeolocationPosition>((resolve, reject) => {
    getBrowserLocation(resolve, reject, timeout);
  });
};

export const setSessionToken = (sessionToken: string | null) => {
  const token: string = sessionToken ? sessionToken : '';
  localStorage.setItem('sessionToken', token);
};

export const getSessionToken = () => {
  let token: string = '';
  if (localStorage.getItem('sessionToken')) {
    token = localStorage.getItem('sessionToken') as string;
  }
  return token;
};

export const setConfirmOtpSession = (confirmOtpSession: string | null) => {
  const session: string = confirmOtpSession ? confirmOtpSession : '';
  localStorage.setItem('confirmOtpSession', session);
};

export const getConfirmOtpSession = () => {
  return localStorage.getItem('confirmOtpSession');
};

export const setSignInUserSession = (signInUserSession: any) => {
  window.sessionStorage.setItem('signInUserSession', JSON.stringify(signInUserSession));
  setAccessToken(signInUserSession && signInUserSession.accessToken ? signInUserSession.accessToken : '');
  setIdToken(signInUserSession && signInUserSession.idToken ? signInUserSession.idToken : '');
  setRefreshToken(signInUserSession && signInUserSession.refreshToken ? signInUserSession.refreshToken : '');
};

export const setCountryCodeSession = (countryCode: string) => {
  window.sessionStorage.setItem('countryCode', countryCode.toUpperCase());
};

export const getCountryDropBoxStatus = () => {
  return window.sessionStorage.getItem('notDisplayCountry');
};

export const getAccessToken = () => {
  return window.sessionStorage.getItem('accessToken') || '';
};

export const setAccessToken = (accessToken: string) => {
  window.sessionStorage.setItem('accessToken', accessToken);
};

export const getRefreshToken = () => {
  return window.sessionStorage.getItem('refreshToken') || '';
};

export const setRefreshToken = (refreshToken: string) => {
  window.sessionStorage.setItem('refreshToken', refreshToken);
};

export const getIdToken = () => {
  return window.sessionStorage.getItem('idToken') as string;
};

export const setIdToken = (idToken: string) => {
  window.sessionStorage.setItem('idToken', idToken);
};

export const getStartFromAtoZ = () => {
  return window.sessionStorage.getItem('startFromAtoZ') === '1';
};

export const getSsoCode = () => {
  return window.sessionStorage.getItem('ssoCode');
};

export const setSsoCode = (code: string) => {
  window.sessionStorage.setItem('ssoCode', code);
};

export const persistSsoCodeFromSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const isSsoResponse = searchParams.get('atozSsoResponse') === '1';
  if (isSsoResponse) {
    const code = searchParams.get('code');
    if (code) {
      setSsoCode(cleanSearchParamValue(code));
    }
  }
};

// Clicking on amazonjobs icon should direct candidates back to careersite homepage if the candidate were redirected from CS
// otherwise, stay on on the current Page

export const goToHomePage = () => {
  const referrer = getFromSessionStorage('referrer');
  let destinationpPage = window.location.href;

  if (referrer === 'CS') {
    destinationpPage = getBaseUrlFromRedirectUrl();
    const locale = getFromSessionStorage('locale');
    const localeConfig = getLocaleConfig();
    const localeSettings: { [key: string]: Object } = localeConfig.locales;
    if (locale && locale in localeSettings && locale !== getDefaultLocale()) {
      destinationpPage += `/${locale}/#/`;
    }
  }
  window.location.href = destinationpPage;
};

export const getBaseUrlFromRedirectUrl = (withLocale?: boolean) => {
  const defaultUrl = 'https://hiring.amazon.com';

  const redirectUrl = decodeURIComponent(getRedirectUrlFromSessionStorage() || '');

  if (redirectUrl) {
    return new URL(redirectUrl).origin;
  }

  const countryCode = getFromSessionStorage('countryCode') as string;

  let careersiteUrl = countryCode ? BasedUrlMapByCountryCode[countryCode] || defaultUrl : defaultUrl;
  const locale = getCurrentLocale();
  if (withLocale && locale && locale !== getDefaultLocale()) {
    careersiteUrl += `/${locale}`;
  }

  return careersiteUrl;
};

export const getEeoLawUrl = () => {
  const curLocale = (getFromSessionStorage('locale') as string) || 'en-US';
  return EeoLawUrlByLocale[curLocale];
};

export const constructContactUsUri = () => {
  const defaultUrl = 'https://hiring.amazon.com';

  const countryCode = getFromSessionStorage('countryCode') as string;

  const baseUrl = BasedUrlMapByCountryCode[countryCode] ?? defaultUrl;
  const locale = getCurrentLocale();
  const path = 'contact-us';
  return locale === getDefaultLocale() ? `${baseUrl}/${path}` : `${baseUrl}/${locale}/${path}`;
};

export const constructPrivacyNoticeUri = () => {
  const privacyNotice = PrivacyNoticeMap[getCountryFullName()];
  if (privacyNotice) return privacyNotice.urlLink;

  const baseUrl = getBaseUrlFromRedirectUrl();
  const countryCode = getCountryCode();
  const locale = getCurrentLocale();

  let path;

  switch (countryCode) {
    case 'MX':
    case 'CR':
    case 'CO':
      path = 'aviso-de-privacidad';
      break;
    case 'BR':
      path = 'aviso-de-privacidade';
      break;
    default:
      path = 'privacy-notice';
  }

  return locale === getDefaultLocale() ? `${baseUrl}/${path}` : `${baseUrl}/${locale}/${path}`;
};

export const getLoginHelpPage = (countryName: CountryFullName): string => {
  const stage = getStage();
  const locale = getCurrentLocale();
  const csDomain =
    CSDomainByCountryFullName[countryName] && CSDomainByCountryFullName[countryName][stage]
      ? CSDomainByCountryFullName[countryName][stage]
      : CSDomainByCountryFullName[CountryFullName.US] && CSDomainByCountryFullName[CountryFullName.US]['beta'];

  const helpPage = loginHelpPageRoute[countryName] || loginHelpPageRoute[CountryFullName.US];
  return locale === getDefaultLocale() ? `${csDomain}/${helpPage}#` : `${csDomain}/${locale}/${helpPage}#`;
};

export const deriveRegionAndLanguage = (
  locale: string,
  countryCode: string,
  defaultRegion: string,
  defaultLanguage: string,
) => {
  if (locale) {
    return extractFromLocale(locale, defaultRegion, defaultLanguage);
  }

  if (countryCode) {
    return {
      region: PrivacyNoticeRegionMap[countryCode] || defaultRegion,
      language: PrivacyNoticeCountryCodeToLanguageMap[countryCode] || defaultLanguage,
    };
  }

  return {
    region: defaultRegion,
    language: defaultLanguage,
  };
};

export const extractFromLocale = (locale: string, defaultRegion: string, defaultLanguage: string) => {
  const [languageCode, country] = locale.split('-');
  if (!languageCode || !country) {
    return {
      region: defaultRegion,
      language: defaultLanguage,
    };
  }

  return {
    region: PrivacyNoticeRegionMap[country] || defaultRegion,
    language: PrivacyNoticeLocaletoLanguageMap[languageCode] || defaultLanguage,
  };
};

export const registrationSessionDataExists = (): boolean => {
  return Boolean(
    window.sessionStorage.getItem('countryCode') &&
      window.sessionStorage.getItem('redirectUrl') &&
      window.sessionStorage.getItem('referrer'),
  );
};

export const setRegistrationSession = (registrationSession: string) => {
  const session: string = registrationSession ? registrationSession : '';
  window.localStorage.setItem('registrationSession', session);
};

export const getRegistrationSession = () => {
  return window.localStorage.getItem('registrationSession');
};

export const removeSessionToken = () => {
  window.localStorage.removeItem('sessionToken');
};

export const removeAccessIdToken = () => {
  window.localStorage.removeItem('accessToken');
  window.localStorage.removeItem('idToken');
};

export const setUserId = (userId: string) => {
  window.localStorage.setItem('userId', userId);
};

export const getUserId = () => {
  return window.localStorage.getItem('userId');
};

export const removeUserId = () => {
  window.localStorage.removeItem('userId');
};

export const setBbCandidateId = (bbCandidateId: string) => {
  window.localStorage.setItem('bbCandidateId', bbCandidateId);
};

export const getBbCandidateId = () => {
  return window.localStorage.getItem('bbCandidateId');
};

export const removeBbCandidateId = () => {
  window.localStorage.removeItem('bbCandidateId');
};

export const setRedirectUrl = (redirectURL: string) => {
  window.localStorage.setItem('redirectURL', redirectURL);
};

export const getRedirectUrl = () => {
  return window.localStorage.getItem('redirectURL') as string;
};

export const removeRedirectUrl = () => {
  window.localStorage.removeItem('redirectURL');
};

export const setRedirectUrlToSessionStorage = (redirectURL: string) => {
  window.sessionStorage.setItem('redirectURL', redirectURL);
};

export const removeRedirectUrlToSessionStorage = () => {
  window.sessionStorage.removeItem('redirectURL');
};

export const getRedirectUrlFromSessionStorage = () => {
  return window.sessionStorage.getItem('redirectURL') || (window.sessionStorage.getItem('redirectUrl') as string);
};

export const getCanaryFromSessionStorage = () => {
  return window.sessionStorage.getItem('canary') || (window.sessionStorage.getItem('iscanary') as string);
};

export const setToSessionStorage = (key: string, value: string) => {
  window.sessionStorage.setItem(key, value);
};

export const getFromSessionStorage = (key: string): string => {
  return window.sessionStorage.getItem(key) || '';
};

export const constructRedirectURLAndRedirect = (redirectURL: string) => {
  if (redirectURL.endsWith('/')) {
    redirectURL = redirectURL.substring(0, redirectURL.length - 1);
  }

  const startFromAtoZ = getStartFromAtoZ();
  // This parameter is used by CS team as the redirect Url to other components such as SF, BB, ASH
  const destinationUrl = getFromSessionStorage('destinationUrl');

  const finalRedirectUrl = urlcat(redirectURL, {
    accessToken: getAccessToken(),
    idToken: getIdToken(),
    refreshToken: getRefreshToken(),
    redirectUrl: destinationUrl,
    startFromAtoZ: startFromAtoZ ? 1 : undefined,
    locale: getFromSessionStorage('locale'),
  });

  const isValidUrl = isValidRedirectUrl(finalRedirectUrl);

  // Emit RUM Events
  emitEvent({
    event: RumEventType.FUNCTION_EXECUTION,
    status: true,
    message: `constructRedirectURLAndRedirect: successfully created redirectUrl: ${finalRedirectUrl} and isValid: ${isValidUrl}`,
  });

  // clear sessionStorage after validating URL. Because url validation depends on session data
  window.sessionStorage.clear();

  // if url is valid, redirect to redirectUrl. otherwise reload the page and don't redirect at all
  isValidUrl ? window.location.assign(finalRedirectUrl) : window.location.reload();
};

export const handleRedirection = () => {
  const redirectURL = getRedirectUrlFromSessionStorage();
  // Emitting RUM Events
  emitEvent({
    event: RumEventType.FUNCTION_EXECUTION,
    status: true,
    message: `handleRedirection: triggering constructRedirectURLAndRedirect with redirectUrl: ${redirectURL}`,
  });

  if (redirectURL) {
    constructRedirectURLAndRedirect(decodeURIComponent(redirectURL));
  } else if (sessionStorage.getItem('loginHint')) {
    confirmLoginApi();
  } else {
    // redirect to CS home page if candidate opened this page directly
    constructRedirectURLAndRedirect(getCSAuthReturnUrl());
  }
};

export const getCSAuthReturnUrl = () => {
  const CSEndpoint = getCSDomain();
  return `${CSEndpoint}/app#/auth-return`;
};

export const setCheckAuthParams = () => {
  const queryParamStringFromURLFor3rdParty = getQueryParamStringFromURLFor3rdParty();
  if (
    queryParamStringFromURLFor3rdParty &&
    queryParamStringFromURLFor3rdParty.length &&
    queryParamStringFromURLFor3rdParty.length > 0
  )
    window.localStorage.setItem('checkAuthParams', queryParamStringFromURLFor3rdParty);
};

export const getCheckAuthParams = () => {
  return window.localStorage.getItem('checkAuthParams');
};

export const removeCheckAuthParams = () => {
  window.localStorage.removeItem('checkAuthParams');
};

export const checkUsernameIsPhoneNumber = (userName: string) => {
  return isPossiblePhoneNumber(userName);
};

export const setCookieConsent = (state: boolean) => {
  window.localStorage.setItem('cookieConsent', JSON.stringify(state));
};

export const getCookieConsent = () => {
  const cookieConsent = window.localStorage.getItem('cookieConsent');

  if (cookieConsent) {
    try {
      const consent = JSON.parse(cookieConsent);

      if (consent && typeof consent === 'boolean') {
        return consent;
      }

      removeCookieConsent();
      return false;
    } catch (e) {
      removeCookieConsent();
      return false;
    }
  }

  return false;
};

export const removeCookieConsent = () => {
  window.localStorage.removeItem('cookieConsent');
};

export const removeGeoInfo = () => {
  window.localStorage.removeItem('geoInfo');
};

/**
 * check entered user name is email
 * @param userName string user name entered by user
 */
export const checkUsernameIsEmail = (userName: string): boolean => {
  const regex = new RegExp(
    // eslint-disable-next-line
    /^([a-zA-Z0-9_\-\.\+]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
  );
  return regex.test(userName);
};

export const checkIsNumber = (input: string): boolean => {
  const reg = new RegExp('^[0-9]+$');

  return reg.test(input);
};

export const sanitizeString = (text: string) => {
  return text ? decodeURIComponent(encodeURIComponent(text).replace('%E2%80%AA', '').replace('%E2%80%AC', '')) : '';
};

export const cleanPhoneNumberFormat = (phoneNumber: string): string => {
  return phoneNumber ? phoneNumber.replace(/[^+\d]+/g, '') : '';
};

// Phone number validation logic borrowed from AuthPortal
//  https://code.amazon.com/packages/HVHAuthenticationPortalWebsite/blobs/a4e0bb0a7a13628f2d3dd859abcacdb83297860f/--/src/utils/utilities.ts#L149-L162
export const isPhoneNumberValid = (phoneNumber: string): boolean => {
  return isValidPhoneNumber(phoneNumber);
};

/**
 * This is a helper method to help constructing redirect path when navigating from
 * static page to React pages.
 * @param pathname string pathname of the destination route.
 *
 * Example:
 * Current location is https://domain/some/path
 */
export const routeToAppPageWithPath = (pathname: string) => {
  window.location.assign(`/#/${pathname}`);
};

/**
 * This is a helper method to help constructing redirect path when navigating from
 * dynamic page back to static page.
 * @param pathname string pathname of the destination route.
 *
 * Example:
 * If current location is https://domain/app/#/path, calling this method will direct user
 * to https://domain/path.
 */
export const routeToRootPageWithPath = (pathname: string) => {
  const localePath = shouldAppendLocale(pathname) ? `/${getCurrentLocale()}` : '';
  window.location.assign(`${localePath}/${pathname}${window.location.search}`);
};

export const shouldAppendLocale = (pathName: string) => {
  const locale = getCurrentLocale();
  const defaultLocale = getDefaultLocale();

  return isMultiLanguageEnabled()
    ? pathUrlLocaleRegex.test(pathName) && locale && defaultLocale && locale !== defaultLocale
    : false;
};

export const parsePathNameFromHash = () => {
  const hashURL = window.location.hash.split('#')[1];

  if (!hashURL) return '';

  return hashURL.split('?')[0];
};

export const getFilterCountries = (): CountryCodes[] => {
  const domainName = window.location.hostname;

  switch (domainName) {
    // TODO: Add cases for actual domains.
    case 'localhost':
      return [CountryCodes.US, CountryCodes.CA, CountryCodes.UK];
    default:
      return [CountryCodes.US, CountryCodes.CA, CountryCodes.UK, CountryCodes.IN];
  }
};

export const truncateString = (str: string, num: number) => {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str;
  }
  // Return str truncated with '...' concatenated to the end of str.
  return str.slice(0, num) + '...';
};

export const purgeReduxPersist = async () => {
  // Clear persisted request redux state from localStorage.
  boundResetRequest();
};

// Purge persisted redux store (stored in localeStorage) if document.referrer is not dynamic page and
// the page load is not a refresh or backward.
// This is to make sure navigate back from static to dynamic page will not lose filter context.
// Refresh and backward will also not trigger purging redux context.
export const clearReduxPersistOnload = () => {
  const indexPath = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'devo' ? '/index.html' : '';
  const params = parseSearchParamFromHash(window.location.hash);
  const { fromVanity } = params;

  if (
    document.referrer !== `${window.location.origin}/app${indexPath}` &&
    isPageRenderedThroughNavigate() &&
    !fromVanity
  ) {
    purgeReduxPersist();
  }
};

// window.performance.navigation.type === 0 will check if current page is not a 'reload' or 'backward'. This method is deprecated.
// window.performance.getEntriesByType("navigation")[0].type === 'navigate' is a replacement to the method above.
// Both the methods above are used to check if user is performing a refresh.
const isPageRenderedThroughNavigate = (): boolean => {
  return (
    (!!window.performance.navigation && window.performance.navigation.type === 0) ||
    (!!window.performance.getEntriesByType('navigation').length &&
      (window.performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming).type === 'navigate')
  );
};

export const createAppSyncEpicError = (errorMessage: string): AppSyncEpicError => {
  const graphQlError = new GraphQLError(errorMessage);
  return { errors: [graphQlError] };
};

export const isStaticPage = () => {
  const pathName = window.location.pathname;
  const isNotCsStaticPage = !pathName.includes('/app/') && pathName !== '/app';
  const isNotBBFlow = !pathName.includes('/application/') && pathName !== '/application';
  const isNotAshFlow = !pathName.includes('/selfservice') && !pathName.includes('/checklist/');

  return isNotCsStaticPage && isNotBBFlow && isNotAshFlow;
};

export const getSingleQueryParamFromURL = (key: string): string | null => {
  const urlHashParams = parseSearchParamFromHash(window.location.hash);
  const searchParams = new URLSearchParams(window.location.search);

  return searchParams.has(key) ? searchParams.get(key) : urlHashParams[key] ? urlHashParams[key] : null;
};

export const getAdobeSessionId = () => {
  return Cookies.get('adobe-session-id') || '';
};

export const getAllQueryParamsFromURL = (): { [key: string]: string } => {
  const urlHashParams = parseSearchParamFromHash(window.location.hash);
  const urlSearchParams = parseSearchParamFromLocationSearch();

  return {
    ...urlHashParams,
    ...urlSearchParams,
  };
};

export const getQueryParamStringFromURL = (): string => {
  const allQueryParams = getAllQueryParamsFromURL();
  let queryString = '';

  // These keys are already added to job apply URL generation when needed, exclude them to prevent duplication.
  const excludedKeyList = [
    'page',
    'jobId',
    'CS',
    'token',
    'locale',
    'isapply',
    'reqid',
    'scheduleId',
    'requisitionId',
    'language',
    'recommended',
    'fromVanity',
    'redirectUrl',
  ];

  Object.keys(allQueryParams).forEach((key) => {
    if (!excludedKeyList.includes(key)) {
      queryString += `&${key}=${allQueryParams[key] || ''}`;
    }
  });

  return queryString;
};

export const getQueryParamStringFromURLFor3rdParty = (notationOverride?: string): string => {
  const allQueryParams = getAllQueryParamsFromURL();
  let queryString = '';

  // These keys are 3rd Party params we allowed to pass with redirectUrl after login.
  const includedKeyList = [
    'cmpid',
    'ccuid',
    'ccid',
    'etd',
    'piq_uuid',
    'pandocampaignid',
    'pandocandidateid',
    'piq_source',
    'ikey',
    'akey',
    'tid',
  ];

  Object.keys(allQueryParams).forEach((key) => {
    if (includedKeyList.includes(key)) {
      queryString += `&${key}=${allQueryParams[key] || ''}`;
    }
  });

  if (notationOverride) {
    return `${notationOverride}${queryString.substring(1)}`;
  } else {
    return queryString;
  }
};

export const insert3rdPartyQueryParamIntoURL = (url: string): string => {
  if (!getQueryParamStringFromURLFor3rdParty()) {
    return url;
  }

  const [domain, ...rest] = url.split('?');

  return `${domain}${getQueryParamStringFromURLFor3rdParty('?')}${rest.length ? `&${rest.join('?')}` : ''}`;
};

export const getSearchParamWithExclusion = (exclusionList: string[]): string => {
  const searchParams = parseSearchParamFromLocationSearch();
  let queryString = '';

  Object.keys(searchParams).forEach((key) => {
    if (!exclusionList.includes(key.toLowerCase())) {
      queryString += `&${key}=${searchParams[key] || ''}`;
    }
  });

  if (queryString.length) {
    queryString = `?${queryString.substring(1)}`;
  }

  return queryString;
};

export const parseCountryFullNameToCountryCode = (countryFullName: string): CountryCodes => {
  switch (countryFullName) {
    case CountryFullName.MX:
    case CountryFullName.US:
      return CountryCodes.US;
    //TODO: Add more case when onboarded with other countries
    default:
      return CountryCodes.US;
  }
};

export const isValidString = (input: string) => {
  if (typeof input !== 'string') {
    return false;
  }

  if (input === 'null' || input === '' || input === ' ') {
    return false;
  }

  return true;
};

export const generateFocusableItemClasses = () => {
  return classnames('focusableItem', { 'stencil-2021': isStencil2021Theme() });
};

export const generateFocusableItemClassesForRegistration = () => {
  return classnames('focusableItem', 'linkHandCursor', { 'stencil-2021': isStencil2021Theme() });
};

export const focusFirstInputErrorItem = (callback?: Function) => {
  // Select first element
  const inputItemsWithError: HTMLElement | null = document.querySelector(
    'input[aria-invalid]:not([aria-invalid="false"])',
  );
  const buttonItemsWithError: HTMLElement | null = document.querySelector(
    'button[aria-invalid]:not([aria-invalid="false"])',
  );
  if (inputItemsWithError || buttonItemsWithError) {
    inputItemsWithError && inputItemsWithError.focus();
    buttonItemsWithError && buttonItemsWithError.focus();
    callback && callback();
    return true;
  } else {
    return false;
  }
};

export const setGeoInfoShownValue = (geoInfo: GeoInfo) => {
  geoInfo.shownValue = geoInfo.postalCode
    ? geoInfo.postalCode
    : geoInfo.label
      ? geoInfo.label
      : `${geoInfo.municipality}, ${geoInfo.region}`;
};

export const isBBApplicationFlow = () => {
  return window.location.pathname.includes('application');
};

export const updateRegistrationFormOrPhoneNumberInput = (
  form: RegistrationForm | PhoneNumberInput,
  targets: UpdateFromTarget[],
  setForm: Function,
) => {
  const newForm: any = { ...form };

  targets.forEach((target: UpdateFromTarget) => {
    let targetValue = target.value;

    if (typeof targetValue === 'string' || targetValue instanceof String) {
      targetValue = targetValue.trim();
    }

    newForm[target.key] = targetValue;
  });

  setForm(newForm);
};

/*
isPossiblePhoneNumber and isValidPhoneNumber allow US phone number with 12 digits starts with duplicate 1, for example:
+1 1 669 123 0000. This causes our user not been reached out by contact us call and sms.
*/
export const hvhIsValidUSPhoneNumber = (phoneNumber: string, countryCode: CountryCode) => {
  const phoneNumberDigits = phoneNumber.replace(/[ +()-]/g, '');

  return !(countryCode === 'US' && phoneNumberDigits.length > 11);
};
/*
We receive lots of UK spam call
*/
export const hvhIsValidUKPhoneNumber = (phoneNumber: string) => {
  const phoneNumberPrefixBlockList = new Set<string>(['448']);
  const phoneNumberDigits = phoneNumber.replace(/[ +()-]/g, '');

  return !phoneNumberPrefixBlockList.has(phoneNumberDigits.substring(0, 3));
};

export const getCountryStateList = (
  countryStateConfigs: CountryStateConfigs,
  currentCountryFullName: CountryFullName,
) => {
  const countryStateList: string[] = [];
  let currentCountry: any = {};

  currentCountry = find(countryStateConfigs, { name: currentCountryFullName });

  currentCountry.states.forEach((state: CountryStateListItem) => {
    countryStateList.push(state.name);
  });

  return countryStateList.sort();
};

// Specify canonical URL for all pages so that campid and other unrelated tags in url will not create new record in SEO.
// Detailed Requirement: https://quip-amazon.com/fGqVAfmCKOVk/Amazon-US-UK-Jobs-Websites-Canonical-Specifications
export const generateCanonicalURL = () => {
  const domainName = window.location.hostname;
  const pathName = window.location.pathname.replace(/\/+$/, '');
  const protocol = `http${domainName === 'localhost' ? '' : 's'}://`;
  const canonicalUrl = `${protocol}${domainName}${pathName}`;

  return encodeURI(canonicalUrl);
};

export const shouldAppendRedirectURL = () => {
  const redirectBlockList = [
    `/${PAGE_ROUTE_REGISTRATION}`,
    `/${PAGE_ROUTE_LOGIN}`,
    `/${PAGE_ROUTE_FORGOT_PIN}`,
    `/${PAGE_ROUTE_VERIFY_PHONE}`,
    `/${PAGE_ROUTE_VERIFY_EMAIL}`,
    `/${PAGE_ROUTE_VERIFY_REGISTRATION}`,
  ];

  return !redirectBlockList.includes(parsePathNameFromHash());
};

export const displayMultiLanguageFeature = () => {
  return isMultiLanguageEnabled() && localStorage.getItem(WebLabName.MLS_Enabled) === 'true';
};

export const getPath = () => {
  return window.location.href.split('#')[1].substring(1);
};

export const setSignInAsLoginFlow = () => {
  localStorage.setItem(FlowType.SIGN_IN_ORIGINAL, SignInOriginal.SIGN_IN);
};

export const setRegistrationAsLoginFlow = () => {
  localStorage.setItem(FlowType.SIGN_IN_ORIGINAL, SignInOriginal.REGISTRATION);
};

export const getSignInOriginal = () => {
  return localStorage.getItem(FlowType.SIGN_IN_ORIGINAL);
};

export const isSalesforceDomain = (url: string): boolean => {
  if (!url) {
    return false;
  }

  try {
    const newUrl = new URL(url);
    return ['force.com', 'my.site.com'].some((domain) => newUrl.origin.includes(domain));
  } catch (e) {
    return false;
  }
};

export const isValidRedirectUrl = (checkRedirectUrl?: string): boolean => {
  const redirectUrl = checkRedirectUrl ?? getRedirectUrlFromSessionStorage();
  // if access from bookmark
  if (!redirectUrl) return true;

  try {
    const decodedRedirectUrl = new URL(decodeURIComponent(redirectUrl));

    if (
      !['http:', 'https:'].includes(decodedRedirectUrl.protocol) ||
      decodedRedirectUrl.href.toLowerCase().startsWith('javascript')
    ) {
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }

  const redirectUrl_ = url.parse(decodeURIComponent(redirectUrl));
  const careersiteDomain = getCSDomain();
  const careersiteDomain_ = url.parse(decodeURIComponent(careersiteDomain));
  const a2zdomain_ = url.parse(decodeURIComponent(getAtoZDomain()));

  switch (redirectUrl_.hostname) {
    case careersiteDomain_.hostname:
    case a2zdomain_.hostname:
    case 'localhost':
      return true;
    default:
      return false;
  }
};

export const getIsCanaryFromQueryString = (): boolean => {
  // To bypass auth waf rule in localhost
  if (process.env.NODE_ENV === 'development') return true;

  // remove comments if necessary
  // const urlHashParams = new URLSearchParams(window.location.href);
  // const urlSeachParams = new URLSearchParams(window.location.search);
  //
  // return Boolean(urlSeachParams.get('canary') || urlHashParams.get('canary'));

  return getCanaryFromSessionStorage() === 'true';
};
