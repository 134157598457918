import { GET_CONFIG_SUCCESS } from '../actions/actionTypes';
import { Config } from '../utils/apis/serviceApiTypes';
import { getCountryFullName } from 'src/utils/apis/apiHelper';
import { GET_COUNTRY_STATE_CONFIG_TYPE } from 'src/actions/ConfigActions/configActionTypes';
import { getCountryStateList } from 'src/utils/helper';

export const initConfig: Config = {
  analytics: {
    federateIdentity: 'us-west-2:6053c154-f40b-4ba1-be7a-3dac4e5b1d3c',
    streamName: 'HVHAuthPortalAnalyticsBetaTest',
  },
  features: {},
  region: 'eu-west-1',
  stage: '{{Stage}}',
  countryStateList: [],
};

export default function configReducer(state = initConfig, action: any): Config {
  switch (action.type) {
    case GET_CONFIG_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case GET_COUNTRY_STATE_CONFIG_TYPE.SUCCESS:
      return {
        ...state,
        countryStateList: getCountryStateList(action.payload, getCountryFullName()),
      };
    default:
      return state;
  }
}
