import { Action } from "redux";
import { CountryStateConfigs } from "src/utils/apis/serviceApiTypes";
import { AlertMessage } from "src/utils/commonTypes";

export enum GET_COUNTRY_STATE_CONFIG_TYPE {
    GET = 'GET_COUNTRY_STATE_CONFIG',
    SUCCESS = 'GET_COUNTRY_STATE_CONFIG_SUCCESS',
    FAILED = 'GET_COUNTRY_STATE_CONFIG_FAILED'
}

export type CountryStateConfigAction = GetCountryStateConfigAction
    | GetCountryStateConfigSuccessAction
    | GetCountryStateConfigFailedAction
    
export interface GetCountryStateConfigAction extends Action {
    type: GET_COUNTRY_STATE_CONFIG_TYPE.GET;
}

export interface GetCountryStateConfigSuccessAction extends Action {
    type: GET_COUNTRY_STATE_CONFIG_TYPE.SUCCESS;
    payload: CountryStateConfigs;
    callback?: Function;
}

export interface GetCountryStateConfigFailedAction extends Action {
    type: GET_COUNTRY_STATE_CONFIG_TYPE.FAILED;
    payload: AlertMessage;
}
