import { AwsRum } from 'aws-rum-web';
import { getStage } from './apis/apiHelper';
import { RumConfig } from './constants';
import { PageAttributes } from 'aws-rum-web/dist/cjs/sessions/PageManager';
import { getFromSessionStorage, getPath, getSignInOriginal, getStartFromAtoZ } from './helper';
import { SESSION_STORAGE_ITEMS } from 'src/utils/sessionStorageConstant';
import { getCurrentLocale } from 'src/utils/localization/localeHelper';
import { maskSensitiveTokensFromLog } from 'src/utils/urlUtils';

let rumClient: AwsRum | undefined;

export const initRum = (): void => {
  try {
    const stage = getStage();

    const rumConfig = RumConfig[stage];

    if (rumConfig) {
      rumClient = new AwsRum(
        rumConfig.applicationId,
        rumConfig.applicationVersion,
        rumConfig.applicationRegion,
        rumConfig.config,
      );
    } else {
      /* eslint-disable-next-line no-console */
      console.log('RUM config not found for stage: ' + stage);
    }
  } catch (error) {
    /* eslint-disable-next-line no-console */
    console.error('Error initializing RUM client', error);
  }
};

export const emitEvent = (eventAttributes: {
  event: string;
  status: boolean;
  userLogin?: string;
  error?: string;
  errorCode?: string;
  availability?: number;
  candidateInput?: string;
  message?: string;
}): void => {
  if (!rumClient) {
    /* eslint-disable-next-line no-console */
    console.log('Rum client not initialized');
    return;
  }

  const { DESTINATION_URL, REDIRECT_URL, SSO_CODE, LOCALE, COUNTRY_CODE } = SESSION_STORAGE_ITEMS;

  rumClient.recordEvent('hvh-auth-custom-event', {
    flowType: getSignInOriginal(),
    redirectUrl: getFromSessionStorage(REDIRECT_URL),
    destinationUrl: getFromSessionStorage(DESTINATION_URL),
    locale: getFromSessionStorage(LOCALE),
    ssoCode: getFromSessionStorage(SSO_CODE),
    country: getFromSessionStorage(COUNTRY_CODE),
    startFromAtoZ: getStartFromAtoZ(),
    href: window.location.href,
    currentLocale: getCurrentLocale(),
    userAgent: navigator?.userAgent,
    path: getPath(),
    ...eventAttributes,
    message: eventAttributes.message && maskSensitiveTokensFromLog(`Auth-RUM-Logs => ${eventAttributes.message}`),
  });
};

export const recordPageView = (attributes: PageAttributes): void => {
  if (!rumClient) {
    /* eslint-disable-next-line no-console */
    console.log('Rum client not initialized');
    return;
  }

  rumClient.recordPageView(attributes);
};
