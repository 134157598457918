import { WebLabConfigs } from "src/utils/webLab/types";
import { WEB_LAB_ACTION_TYPE, WebLabActions } from "src/actions/WebLab/webLabActionTypes";

export interface WebLabState {
    config: WebLabConfigs
}

export const initWebLabState: WebLabState = {
    config: []
};

export default function WebLabReducer(state: WebLabState = initWebLabState, action: WebLabActions ) {

    switch (action.type ) {
        case WEB_LAB_ACTION_TYPE.SET:
            return {
                ...state,
                config: action.payload
            }
        case WEB_LAB_ACTION_TYPE.CLEAR:
            return {
                ...state,
                config: []
            }
        default:
            return state;
    }
}
