import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { loadScript } from '../utils/captcha';

export function AWSWAFCaptchaModal() {
  useEffect(() => loadScript());

  return createPortal(
    <div className="captcha-overlay" id="captchaModalOverlay" data-test-id="captchaModalOverlay">
      <div className="captcha-modal" id="captchaModal" data-test-id="captchaModal">
        <div id="captchaForm" data-test-id="captchaForm" />
      </div>
    </div>,
    document.body,
  );
}
