import {
  Locale,
  Language,Stage, RunEnabledRegion, RumConfigType
} from "src/utils/commonTypes";
import { CountryFullName } from "src/utils/commonTypes";
import pL from "js-regex-pl";
import {BR_PAGE_ROUTE, JP_PAGE_ROUTE, PAGE_ROUTE_CONTACT_US, PAGE_ROUTE_FAQ, PAGE_ROUTE_FAQS, US_PAGE_ROUTE} from "src/components/pageRoutes";
import {PageName} from "src/customerTracking/adobeAnalytics/types";
import {getStage} from "src/utils/apis/apiHelper";
// AWS location service uses Alpha-3 code as country codes.
export enum CountryCodes {
  US = "USA",
  UK = "GBR",
  CA = "CAN",
  IN = "IND",
  JP = "JPN",
  BR = "BRA",
  CO = "COL",
  CR = "CRI",
  MX = "MEX",
  EG = "EGY",
  JO = "JOR",
  SA = "KSA",
  AE = "UAE",
  DEV = "DEV",
}

export const THREE_LETTER_TO_TWO_LETTER_COUNTRY_CODE_MAP: { [key: string]: string } = {
  USA: 'US',
  MEX: 'MX',
  CAN: 'CA',
  JPN: 'JP',
  PHL: 'PH',
  IRL: 'IE',
  IND: 'IN',
  ZAF: 'ZA',
  GBR: 'GB',
  BRA: 'BR',
  COL: 'CO',
  EGY: 'EG',
  JOR: 'JO',
  CRI: 'CR',
  KSA: 'SA',
  UAE: 'AE'
};

export const PrivacyNoticeRegionMap: { [key: string]: string } = {
  JP: 'jp',
  US: 'us',
  UK: 'eu',
  CA: 'canada',
  MX: 'mexico'
};

export const PrivacyNoticeLocaletoLanguageMap: { [key: string]: string } = {
  en: 'en',
  es: 'es',
  ja: 'jp',
  fr: 'fr',
};

export const PrivacyNoticeCountryCodeToLanguageMap: { [key: string]: string } = {
  US: 'en',
  MX: 'es',
  JP: 'jp',
  CA: 'en',
  UK: 'en'
};

export const BasedUrlMapByCountryCode: { [key: string]: string } = {
  US: 'https://hiring.amazon.com',
  MX: 'https://trabaja.amazon.com.mx',
  JP: 'https://shigoto.amazon.co.jp',
  CA: 'https://hiring.amazon.ca',
  UK: 'https://www.jobsatamazon.co.uk',
  BR: 'https://trabalho.amazon.com.br',
  CO: 'https://trabaja.amazon.co',
  CR: 'https://trabaja.amazon.cr',
  EG: 'https://jobs.amazon.eg',
  JO: 'https://jobs.amazon.jo',
  SA: '', //TODO: Add Base URL
  AE: '' // TODO: Add Base URL
};

export const EeoLawUrlByLocale: { [key: string]: string } = {
  [Locale.US]: 'https://www.eeoc.gov/sites/default/files/2022-10/EEOC_KnowYourRights_screen_reader_10_20.pdf',
  [Locale.ES_US]: 'https://www.eeoc.gov/es/conozca-sus-derechos-la-discriminacion-en-el-lugar-de-trabajo-es-ilegal'
};

export const Constants = {
  ServiceEndPoints:{
    GET_JOBS_GQL:'http://dev-dsk-fanliang-2a-8d729e37.us-west-2.amazon.com:8080/graphql',
    GET_JOBS_AREST: 'https://job-container.beta.auth.associate.amazondelivers.jobs/search/searchjobcards',
    GET_JOB_DETAIL_JSON: 'https://job-container.beta.auth.associate.amazondelivers.jobs/search/jobdetailjson',
    GET_JOB_DETAIL_REST: 'https://job-container.beta.auth.associate.amazondelivers.jobs/search/jobdetail',
    GET_CMS_FOOTER: '/amabot-rest?page=common&res=footer',
    SF_AUTH_CALLBACK_BB_AUTH: 'http://csdev-amazon.cs61.force.com/services/authcallback/BB_auth',
  },
  AdobeAnalyticsEndPints: {
    beta: "https://assets.adobedtm.com/launch-EN2c38d445ba884f1ca9901284481b2c7b-staging.min.js",
    gamma: "https://assets.adobedtm.com/launch-EN2c38d445ba884f1ca9901284481b2c7b-staging.min.js",
    prod: "https://assets.adobedtm.com/launch-EN6bc43e7eee5146beaa43d868366aabe6.min.js",
  },
  ImageLinks:{
    EMAIL_ICON: "https://m.media-amazon.com/images/G/01/HVH-CandidateApplication/jobs/illustration-email.svg",
    PHONE_ICON: "https://m.media-amazon.com/images/G/01/HVH-CandidateApplication/jobs/illustration-phone.svg",
  }
};

export enum WidgetPositions {
  HEADER = 'header',
  CONTENT = 'content',
  FOOTER = 'footer',
  VANITY_URL= 'vanity_url',
  JOB_ALERT = 'jobAlert',
  CONTACT_US = 'contact-us',
  APPLICATION_DASHBOARD = 'application-dashboard'
}

export const createAccountAllowedList = ['SF', 'CS'];

export const countryMap = new Map<string, string>([
  ["Canada", "CA"],
  ["Mexico", "MX"],
  ["Japan", "JP"],
  //["United Kingdom", "UK"],
  ["United States", "US"],
  ["Brazil", "BR"],
  ["Colombia", "CO"],
  ["Costa Rica", "CR"],
  ["Egypt", "EG"],
  ["Jordan", "JO"],
  ["Kingdom of Saudi Arabia", "SA"],
  ["United Arab Emirates", "AE"],
]);

export const countryCodeKeyMap = (stage: Stage) => { 
  let countryCodeKeys = [
    { key: "LoginPage-country-drop-box-MX", value: "Mexico" },
    { key: "LoginPage-country-drop-box-JP", value: "Japan" },
    { key: "LoginPage-country-drop-box-US", value: "United States" },
    { key: "LoginPage-country-drop-box-CA", value: "Canada" },
    { key: "LoginPage-country-drop-box-BR", value: "Brazil" },
    { key: "LoginPage-country-drop-box-CO", value: "Colombia" },
    { key: "LoginPage-country-drop-box-CR", value: "Costa Rica" },
    { key: "LoginPage-country-drop-box-EG", value: "Egypt" },
    { key: "LoginPage-country-drop-box-JO", value: "Jordan" },
  ];
  //TODO: Move above in countryCodeKeys once we have base URL
  if (stage !== Stage.PreProd && stage !== Stage.Prod) {
    countryCodeKeys = [
      ...countryCodeKeys,
      { key: "LoginPage-country-drop-box-SA", value: "Kingdom of Saudi Arabia" },
      { key: "LoginPage-country-drop-box-AE", value: "United Arab Emirates" }
    ];
  }

  // Preprod should mimic Prod
  if (stage !== Stage.PreProd && stage !== Stage.Prod ) {
    countryCodeKeys = [
      ...countryCodeKeys,
    ];
  }
  return countryCodeKeys;
}

export const localeMap = new Map<string, string>([
  ["en-CA", "Canada"],
  ["fr-CA", "Canada"],
  ["es-MX", "Mexico"],
  //["en-GB","United Kingdom"],
  ["en-US", "United States"],
  ["es-US", "United States"],
  ["ja-JP", "Japan"],
  ["pt-BR", "Brazil"],
  ["es-CR", "Costa Rica"],
  ["es-CO", "Colombia"],
  ["en-EG", "Egypt"],
  ["en-JO", "Jordan"],
]);

export const countryCodeMap = new Map<string, string>([
  ["MX", "Mexico"],
  //["UK", "United Kingdom"],
  ["US", "United States"],
  ["JP", "Japan"],
  ["CA", "Canada"],
  ["BR", "Brazil"],
  ["CO", "Colombia"],
  ["CR", "Costa Rica"],
  ["EG", "Egypt"],
  ["JO", "Jordan"],
  ["SA", "Kingdom of Saudi Arabia"],
  ["AE", "United Arab Emirates"]
]);

export enum USER_SIGN_UP_TYPE {
  VERIFY = "Verify",
  CREATE = "Create",
}

export enum SIGN_IN_PAGES {
  LOGIN = "LOGIN",
  PIN = "PIN",
  OTP_TYPE = "OTP_TYPE",
  CONFIRM_OTP = "CONFIRM_OTP",
  PHONE_LOGIN = "PHONE_LOGIN",
  CHECK_PHONE = "CHECK_PHONE",
  VERIFY_PHONE = "VERIFY_PHONE",
  VERIFY_REGISTRATION = "VERIFY_REGISTRATION",
  ATOZ_SSO = "ATOZ_SSO"
}

export enum REGISTRATION_PAGES {
  REGISTRATION = "REGISTRATION",
  VERIFY_EMAIL = "VERIFY_EMAIL",
  VERIFY_PHONE = "VERIFY_PHONE",
}

export enum FORGOT_PIN_PAGES {
  FORGOT_PIN = "FORGOT_PIN",
  RESET_PIN = "PIN",
}

export enum LOGIN_TYPE {
  EMAIL = "email",
  PHONE = "phone",
  NONE = "",
}

export enum StartDateOptionValue {
  One_TO_TWO_WEEKS = 'One_TO_TWO_WEEKS',
  TWO_TO_THREE_WEEKS = 'TWO_TO_THREE_WEEKS',
  MORE_THAN_THREE_WEEK = 'MORE_THAN_THREE_WEEK',
}

export interface StartDateOptionType {
  value: StartDateOptionValue | null;
  shownValue: string;
}

export const CSAuthServiceEndPointsUS: { [key: string]: string } = {
  "beta" : "https://beta.auth.associate.amazondelivers.jobs",
  "gamma" : "https://gamma.auth.associate.amazondelivers.jobs",
  "preprod" : "https://preprod.auth.associate.amazondelivers.jobs",
  "prod" : "https://auth.associate.amazondelivers.jobs",
};

export const CSAuthServiceEndPointsCloudFrontUS: { [key: string]: string } = {
  "beta" : "https://beta-us.devo.jobsatamazon.hvh.a2z.com/authorize",
  "gamma" : "https://gamma-us.devo.jobsatamazon.hvh.a2z.com/authorize",
  "preprod" : "https://preprod.auth.associate.amazondelivers.jobs",
  "prod" : "https://auth.associate.amazondelivers.jobs",
};

export const CSAuthServiceEndPointsUK: { [key: string]: string } = {
  "beta" : "https://eu-west-1.beta.auth.associate.amazondelivers.jobs",
  "gamma" : "https://eu-west-1.gamma.auth.associate.amazondelivers.jobs",
  "preprod" : "https://eu-west-1.auth.associate.amazondelivers.jobs",
  "prod" : "https://eu-west-1.auth.associate.amazondelivers.jobs",
};

// TODO: Update raw endpoint
export const CSAuthServiceEndPointsMX: { [key: string]: string } = {
  "beta" : "https://us-east-1.beta.auth.associate.amazondelivers.jobs",
  "gamma" : "https://us-east-1.gamma.auth.associate.amazondelivers.jobs",
  "preprod" : "https://us-east-1.preprod.auth.associate.amazondelivers.jobs",
  "prod" : "https://us-east-1.auth.associate.amazondelivers.jobs",
};

export const CSAuthServiceEndPointsCloudFront: { [key: string]: string } = {
  "beta" : "https://beta.auth.hvh.a2z.com",
  "gamma" : "https://gamma.auth.hvh.a2z.com",
  "preprod" : "https://preprod.auth.hvh.a2z.com",
  "prod" : "https://auth.hiring.amazon.com",
}
export const CSAuthServiceEndPointsCloudFrontTemp: { [key: string]: string } = {
  "beta" : "https://beta.auth.hvh.a2z.com",
  "gamma" : "https://gamma.auth.hvh.a2z.com",
  "preprod" : "",
  "prod" : "",
}

export const CSAuthServiceEndPointsByCountryFullName: { [key: string]: any } = {
  [CountryFullName.UK] : CSAuthServiceEndPointsCloudFront,
  [CountryFullName.MX] : CSAuthServiceEndPointsCloudFront,
  [CountryFullName.US] : CSAuthServiceEndPointsCloudFront,
  [CountryFullName.CA] : CSAuthServiceEndPointsCloudFront,
  [CountryFullName.JP] : CSAuthServiceEndPointsCloudFront,
  [CountryFullName.EG] : CSAuthServiceEndPointsCloudFront,
  [CountryFullName.JO] : CSAuthServiceEndPointsCloudFront,
  [CountryFullName.SA] : CSAuthServiceEndPointsCloudFrontTemp, //TODO: Replace with CSAuthServiceEndPointsCloudFront when ready for prod
  [CountryFullName.AE] : CSAuthServiceEndPointsCloudFrontTemp, //TODO: Replace with CSAuthServiceEndPointsCloudFront when ready for prod
  //TODO: Update endpoints for SA and AE
};

export const CSIntegrationEndPoints: { [key: string]: string } = {
  "test" : "https://beta.devo.jobsatamazon.hvh.a2z.com",
  "devo" : "https://beta.devo.jobsatamazon.hvh.a2z.com"
};

// TODO: Update raw endpoint
export const BBSignOutEndPointsMX: { [key: string]: string } = {
  "beta" : "https://beta-mx.devo.jobsatamazon.hvh.a2z.com/application/mx/?page=sflogout",
  "gamma" : "https://gamma-mx.devo.jobsatamazon.hvh.a2z.com/application/mx/?page=sflogout",
  "preprod": "https://mx.preprod.jobsatamazon.hvh.a2z.com/application/mx/?page=sflogout",
  "prod" : "https://hiring.amazon.com/application/mx/?page=sflogout",
};

export const BBSignOutEndPointsNA: { [key: string]: string } = {
  "beta" : "https://beta-us.devo.jobsatamazon.hvh.a2z.com/application/?page=sflogout",
  "gamma" : "https://gamma-us.devo.jobsatamazon.hvh.a2z.com/application/?page=sflogout",
  "preprod": "https://us.preprod.jobsatamazon.hvh.a2z.com/application/?page=sflogout",
  "prod" : "https://hiring.amazon.com/application/?page=sflogout",
};

export const BBApplicationSignOutEndPointsEU: { [key: string]: string } = {
  "beta" : "https://application.beta.devo.jobsatamazon.hvh.a2z.com/?page=sflogout",
  "gamma" : "https://application.gamma.devo.jobsatamazon.hvh.a2z.com/?page=sflogout",
  "preprod" : "https://application.jobsatamazon.co.uk/?page=sflogout",
  "prod" : "https://application.jobsatamazon.co.uk/?page=sflogout",
};

export const BBSignOutEndPointsByCountryFullName: { [key: string]: any } = {
  [CountryFullName.UK] : BBApplicationSignOutEndPointsEU,
  [CountryFullName.US] : BBSignOutEndPointsNA,
  [CountryFullName.MX] : BBSignOutEndPointsMX,
};

export const BBUKEndpoint: { [key: string]: string } = {
  "test" : "https://beta.emea.associate.amazondelivers.jobs",
  "devo" : "https://beta.emea.associate.amazondelivers.jobs",
  "beta" : "https://beta.emea.associate.amazondelivers.jobs",
  "gamma" : "https://gamma.emea.associate.amazondelivers.jobs",
  "preprod" : "https://emea.associate.amazondelivers.jobs",
  "prod" : "https://emea.associate.amazondelivers.jobs",
};

export const BBUKApplicationEndpoint: { [key: string]: string } = {
  "test" : "https://application.beta.devo.jobsatamazon.hvh.a2z.com",
  "devo" : "https://application.beta.devo.jobsatamazon.hvh.a2z.com",
  "beta" : "https://application.beta.devo.jobsatamazon.hvh.a2z.com",
  "gamma" : "https://application.gamma.devo.jobsatamazon.hvh.a2z.com",
  "preprod" : "https://application.jobsatamazon.co.uk",
  "prod" : "https://application.jobsatamazon.co.uk",
};

// TODO: Update BB US Endpoint
export const BBUSEndpoint: { [key: string]: string } = {
  "test" : "https://beta.associate.amazondelivers.jobs",
  "devo" : "https://beta.associate.amazondelivers.jobs",
  "beta" : "https://beta.associate.amazondelivers.jobs",
  "gamma" : "https://gamma.associate.amazondelivers.jobs",
  "preprod": "https://preprod.associate.amazondelivers.jobs",
  "prod" : "https://associate.amazondelivers.jobs",
};

export const BBUSUnifiedEndpoint: { [key: string]: string} = {
  "beta" : "https://beta-us.devo.jobsatamazon.hvh.a2z.com/application",
  "gamma" : "https://gamma-us.devo.jobsatamazon.hvh.a2z.com/application",
  "preprod": "https://us.preprod.jobsatamazon.hvh.a2z.com/application",
  "prod" : "https://hiring.amazon.com/application",
};

// TODO: Update raw endpoint
export const BBMXUnifiedEndpoint: { [key: string]: string} = {
  "beta" : "https://beta-mx.devo.jobsatamazon.hvh.a2z.com/application/mx",
  "gamma" : "https://gamma-mx.devo.jobsatamazon.hvh.a2z.com/application/mx",
  "preprod": "https://mx.preprod.jobsatamazon.hvh.a2z.com/application/mx",
  "prod" : "https://hiring.amazon.com/application/mx",
};

export const UnifiedDomainEnabled: { [key: string]: boolean } = {
  "beta" : true,
  "gamma" : true,
  "preprod": true,
  "prod" : true,
};

export const ASHUSEndpoint: { [key: string]: string } = {
  "test" : "https://beta.pdx.checklist.associate.amazondelivers.jobs",
  "devo" : "https://beta.pdx.checklist.associate.amazondelivers.jobs",
  "beta" : "https://beta.pdx.checklist.associate.amazondelivers.jobs",
  "gamma" : "https://gamma.iad.checklist.associate.amazondelivers.jobs",
  "preprod": "https://preprod.iad.checklist.associate.amazondelivers.jobs",
  "prod" : "https://checklist.associate.amazondelivers.jobs",
};

export const SFCountry: { [key: string]: string } = {
  "test" : "gbr",
  "devo" : "gbr",
  "beta" : "gbr",
  "gamma" : "gbr",
  "preprod" : "gbr",
  "prod" : "gbr",
};

export const StageToLocaleUK: { [key: string]: string } = {
  "test" : Locale.UK,
  "devo" : Locale.UK,
  "beta" : Locale.UK,
  "gamma" : Locale.UK,
  "preprod" : Locale.UK,
  "prod" : Locale.UK,
};

export const StageToLocaleUS: { [key: string]: string } = {
  "test" : Locale.US,
  "devo" : Locale.US,
  "beta" : Locale.US,
  "gamma" : Locale.US,
  "preprod" : Locale.US,
  "prod" : Locale.US,
};

export const StageToLocaleMX: { [key: string]: string } = {
  "test" : Locale.ES_MX,
  "devo" : Locale.ES_MX,
  "beta" : Locale.ES_MX,
  "gamma" : Locale.ES_MX,
  "preprod" : Locale.ES_MX,
  "prod" : Locale.ES_MX,
};

export const StageToLocaleJP: { [key: string]: string } = {
  "test" : Locale.JP,
  "devo" : Locale.JP,
  "beta" : Locale.JP,
  "gamma" : Locale.JP,
  "preprod" : Locale.JP,
  "prod" : Locale.JP,
};

export const StageToLocaleCO: { [key: string]: string } = {
  "test": Locale.CO,
  "devo": Locale.CO,
  "beta": Locale.CO,
  "gamma": Locale.CO,
  "preprod": Locale.CO,
  "prod": Locale.CO,
};
 
export const StageToLocaleCR: { [key: string]: string } = {
  "test": Locale.CR,
  "devo": Locale.CR,
  "beta": Locale.CR,
  "gamma": Locale.CR,
  "preprod": Locale.CR,
  "prod": Locale.CR,
};
 
export const StageToLocaleBR: { [key: string]: string } = {
  "test": Locale.BR,
  "devo": Locale.BR,
  "beta": Locale.BR,
  "gamma": Locale.BR,
  "preprod": Locale.BR,
  "prod": Locale.BR,
};

export const StageToLocaleEG: { [key: string]: string } = {
  "test": Locale.EG,
  "devo": Locale.EG,
  "beta": Locale.EG,
  "gamma": Locale.EG,
  "preprod": Locale.EG,
  "prod": Locale.EG,
};

export const StageToLocaleJO: { [key: string]: string } = {
  "test": Locale.JO,
  "devo": Locale.JO,
  "beta": Locale.JO,
  "gamma": Locale.JO,
  "preprod": Locale.JO,
  "prod": Locale.JO,
};

export const StageToLocaleSA: { [key: string]: string } = {
  "test": Locale.SA,
  "devo": Locale.SA,
  "beta": Locale.SA,
  "gamma": Locale.SA,
  "preprod": Locale.SA,
  "prod": Locale.SA,
};

export const StageToLocaleAE: { [key: string]: string } = {
  "test": Locale.AE,
  "devo": Locale.AE,
  "beta": Locale.AE,
  "gamma": Locale.AE,
  "preprod": Locale.AE,
  "prod": Locale.AE,
};
 

export const StageToLocaleByCountryFullName: { [key: string]: any } = {
  [CountryFullName.UK] : StageToLocaleUK,
  [CountryFullName.US] : StageToLocaleUS,
  [CountryFullName.MX] : StageToLocaleMX,
  [CountryFullName.JP] : StageToLocaleJP,
  [CountryFullName.CO]: StageToLocaleCO,
  [CountryFullName.CR]: StageToLocaleCR,
  [CountryFullName.BR]: StageToLocaleBR,
  [CountryFullName.EG]: StageToLocaleEG,
  [CountryFullName.JO]: StageToLocaleJO,
  [CountryFullName.SA]: StageToLocaleSA,
  [CountryFullName.AE]: StageToLocaleAE,
};

export const StageToLanguageUK: { [key: string]: string } = {
  "test" : Language.UK,
  "devo" : Language.UK,
  "beta" : Language.UK,
  "gamma" : Language.UK,
  "preprod" : Language.UK,
  "prod" : Language.UK,
};

export const StageToLanguageUS: { [key: string]: string } = {
  "test" : Language.US,
  "devo" : Language.US,
  "beta" : Language.US,
  "gamma" : Language.US,
  "preprod" : Language.US,
  "prod" : Language.US,
};

export const StageToLanguageMX: { [key: string]: string } = {
  "test" : Language.MX,
  "devo" : Language.MX,
  "beta" : Language.MX,
  "gamma" : Language.MX,
  "preprod" : Language.MX,
  "prod" : Language.MX,
};

export const StageToLanguageJP: { [key: string]: string } = {
  "test" : Language.JP,
  "devo" : Language.JP,
  "beta" : Language.JP,
  "gamma" : Language.JP,
  "preprod" : Language.JP,
  "prod" : Language.JP,
};

export const StageToLanguageCO: { [key: string]: string } = {
  "test": Language.CO,
  "devo": Language.CO,
  "beta": Language.CO,
  "gamma": Language.CO,
  "preprod": Language.CO,
  "prod": Language.CO,
};
 
export const StageToLanguageCR: { [key: string]: string } = {
  "test": Language.CR,
  "devo": Language.CR,
  "beta": Language.CR,
  "gamma": Language.CR,
  "preprod": Language.CR,
  "prod": Language.CR,
};
 
export const StageToLanguageBR: { [key: string]: string } = {
  "test": Language.BR,
  "devo": Language.BR,
  "beta": Language.BR,
  "gamma": Language.BR,
  "preprod": Language.BR,
  "prod": Language.BR,
};

export const StageToLanguageEG: { [key: string]: string } = {
  "test": Language.EG,
  "devo": Language.EG,
  "beta": Language.EG,
  "gamma": Language.EG,
  "preprod": Language.EG,
  "prod": Language.EG,
};

export const StageToLanguageJO: { [key: string]: string } = {
  "test": Language.JO,
  "devo": Language.JO,
  "beta": Language.JO,
  "gamma": Language.JO,
  "preprod": Language.JO,
  "prod": Language.JO,
};

export const StageToLanguageAE: { [key: string]: string } = {
  "test": Language.AE,
  "devo": Language.AE,
  "beta": Language.AE,
  "gamma": Language.AE,
  "preprod": Language.AE,
  "prod": Language.AE,
};

export const StageToLanguageSA: { [key: string]: string } = {
  "test": Language.SA,
  "devo": Language.SA,
  "beta": Language.SA,
  "gamma": Language.SA,
  "preprod": Language.SA,
  "prod": Language.SA,
};

export const StageToAtoZSSOCodeEndpoint = {
  "test": 'https://hvh-atoz-sso-gamma.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=2u6tog419nc35jn25eu2tmle8l&response_type=code&scope=openid&redirect_uri=http://localhost:4321?atozSsoResponse=1',
  "devo": 'https://hvh-atoz-sso-beta.auth.us-west-2.amazoncognito.com/oauth2/authorize?client_id=74jik9164n0boiag8hg4napt&response_type=code&scope=openid&redirect_uri=https://beta.auth.hvh.a2z.com?atozSsoResponse=1',
  "beta": 'https://hvh-atoz-sso-beta.auth.us-west-2.amazoncognito.com/oauth2/authorize?client_id=74jik9164n0boiag8hg4napt&response_type=code&scope=openid&redirect_uri=https://beta.auth.hvh.a2z.com?atozSsoResponse=1',
  "gamma": 'https://hvh-atoz-sso-gamma.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=2u6tog419nc35jn25eu2tmle8l&response_type=code&scope=openid&redirect_uri=https://gamma.auth.hvh.a2z.com?atozSsoResponse=1',
  "preprod": 'https://hvh-atoz-sso-preprod.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=4hf41995ecahgv0hco0opmasa8&response_type=code&scope=openid&redirect_uri=https://preprod.auth.hvh.a2z.com?atozSsoResponse=1',
  "prod": 'https://hvh-atoz-sso-prod.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=hq152b6e3c34dbcm4thpesa14&response_type=code&scope=openid&redirect_uri=https://auth.hiring.amazon.com?atozSsoResponse=1',
}

export const StageToAtoZSSOTokenEndpoint = {
  "test": 'https://gamma.auth.hvh.a2z.com/sso-api/fetchAccessToken?grant_type=authorization_code&client_id=2u6tog419nc35jn25eu2tmle8l&redirect_uri=http://localhost:4321?atozSsoResponse=1',
  "devo": '/sso-api/fetchAccessToken?grant_type=authorization_code&client_id=74jik9164n0boiag8hg4napt&redirect_uri=https%3A%2F%beta.auth.hvh.a2z.com%3FatozSsoResponse%3D1',
  "beta": '/sso-api/fetchAccessToken?grant_type=authorization_code&client_id=74jik9164n0boiag8hg4napt&redirect_uri=https%3A%2F%beta.auth.hvh.a2z.com%3FatozSsoResponse%3D1',
  "gamma": '/sso-api/fetchAccessToken?grant_type=authorization_code&client_id=2u6tog419nc35jn25eu2tmle8l&redirect_uri=https%3A%2F%2Fgamma.auth.hvh.a2z.com%3FatozSsoResponse%3D1',
  "preprod": '/sso-api/fetchAccessToken?grant_type=authorization_code&client_id=4hf41995ecahgv0hco0opmasa8&redirect_uri=https%3A%2F%preprod.auth.hvh.a2z.com%3FatozSsoResponse%3D1',
  "prod": '/sso-api/fetchAccessToken?grant_type=authorization_code&client_id=hq152b6e3c34dbcm4thpesa14&redirect_uri=https%3A%2F%2Fauth.hiring.amazon.com%3FatozSsoResponse%3D1',
}

export const StageToLanguageByCountryFullName: { [key: string]: any } = {
  [CountryFullName.UK] : StageToLanguageUK,
  [CountryFullName.US] : StageToLanguageUS,
  [CountryFullName.MX] : StageToLanguageMX,
  [CountryFullName.JP] : StageToLanguageJP,
  [CountryFullName.CR]: StageToLanguageCR,
  [CountryFullName.BR]: StageToLanguageBR,
  [CountryFullName.CO]: StageToLanguageCO,
  [CountryFullName.EG]: StageToLanguageEG,
  [CountryFullName.JO]: StageToLanguageJO,
  [CountryFullName.AE]: StageToLanguageAE,
  [CountryFullName.SA]: StageToLanguageSA,
};

// There are only a few supported employment types in Google and they are case sensitive.
// https://developers.google.com/search/docs/data-types/job-posting#job-posting-definition

export const WebLabMetricApiUS: { [key: string]: string } = {
  "beta" : "https://beta-us.devo.jobsatamazon.hvh.a2z.com/weblab-metrics",
  "gamma" : "https://gamma-us.devo.jobsatamazon.hvh.a2z.com/weblab-metrics",
  "preprod" : "https://us.preprod.jobsatamazon.hvh.a2z.com/weblab-metrics",
  "prod" : "https://hiring.amazon.com/weblab-metrics",
}

export const WebLabMetricApiUK: { [key: string]: string } = {
  "beta" : "https://beta.devo.jobsatamazon.hvh.a2z.com/weblab-metrics",
  "gamma" : "https://gamma.devo.jobsatamazon.hvh.a2z.com/weblab-metrics",
  "preprod" : "https://uk.preprod.jobsatamazon.hvh.a2z.com/weblab-metrics",
  "prod" : "https://www.jobsatamazon.co.uk/weblab-metrics",
}

export const WebLabMetricAPI: { [key: string]: any } = {
  [CountryFullName.UK] : WebLabMetricApiUK,
  [CountryFullName.US] : WebLabMetricApiUS,
  [CountryFullName.MX] : WebLabMetricApiUS,
};

export const CSDomainsUS :{ [key: string]: string }  = {
  "beta": "https://beta-us.devo.jobsatamazon.hvh.a2z.com",
  "gamma": "https://gamma-us.devo.jobsatamazon.hvh.a2z.com",
  "preprod": "https://us.preprod.jobsatamazon.hvh.a2z.com",
  "prod": "https://hiring.amazon.com",
};

export const CSDomainsUK: { [key: string]: string }  = {
  "beta": "https://beta.devo.jobsatamazon.hvh.a2z.com",
  "gamma": "https://gamma.devo.jobsatamazon.hvh.a2z.com",
  "preprod": "https://uk.preprod.jobsatamazon.hvh.a2z.com",
  "prod": "https://www.jobsatamazon.co.uk",
};

export const CSDomainsMX: { [key: string]: string }  = {
  "beta": "https://beta-mx.devo.jobsatamazon.hvh.a2z.com",
  "gamma": "https://gamma-mx.devo.jobsatamazon.hvh.a2z.com",
  "preprod": "https://mx.preprod.jobsatamazon.hvh.a2z.com",
  "prod": "https://trabaja.amazon.com.mx",
};

export const CSDomainsCA: { [key: string]: string }  = {
  "beta": "https://beta-ca.devo.jobsatamazon.hvh.a2z.com",
  "gamma": "https://gamma-ca.devo.jobsatamazon.hvh.a2z.com",
  "preprod": "https://ca.preprod.jobsatamazon.hvh.a2z.com",
  "prod": "https://hiring.amazon.ca",
};

export const CSDomainsJP: { [key: string]: string }  = {
  "beta": "https://beta-jp.devo.jobsatamazon.hvh.a2z.com",
  "gamma": "https://gamma-jp.devo.jobsatamazon.hvh.a2z.com",
  "preprod": "https://jp.preprod.jobsatamazon.hvh.a2z.com",
  "prod": "https://shigoto.amazon.co.jp",
};

export const CSDomainsBR: { [key: string]: string }  = {
  "beta": "https://beta-br.devo.jobsatamazon.hvh.a2z.com",
  "gamma": "https://gamma-br.devo.jobsatamazon.hvh.a2z.com",
  "preprod": "https://br.preprod.jobsatamazon.hvh.a2z.com",
  "prod": "https://trabalho.amazon.com.br",
};

export const CSDomainsCR: { [key: string]: string }  = {
  "beta": "https://beta-cr.devo.jobsatamazon.hvh.a2z.com",
  "gamma": "https://gamma-cr.devo.jobsatamazon.hvh.a2z.com",
  "preprod": "https://cr.preprod.jobsatamazon.hvh.a2z.com",
  "prod": "https://trabaja.amazon.cr",
};

export const CSDomainsCO: { [key: string]: string }  = {
  "beta": "https://beta-co.devo.jobsatamazon.hvh.a2z.com",
  "gamma": "https://gamma-co.devo.jobsatamazon.hvh.a2z.com",
  "preprod": "https://co.preprod.jobsatamazon.hvh.a2z.com",
  "prod": "https://trabaja.amazon.co",
};

export const CSDomainsEG: { [key: string]: string }  = {
  "beta": "https://beta-eg-cs.devo.jobsatamazon.hvh.a2z.com",
  "gamma": "https://gamma-eg-cs.devo.jobsatamazon.hvh.a2z.com",
  "preprod": "https://eg-cs.preprod.jobsatamazon.hvh.a2z.com",
  "prod": "https://jobs.amazon.eg",
};

export const CSDomainsJO: { [key: string]: string }  = {
  "beta": "https://beta-jo.devo.jobsatamazon.hvh.a2z.com",
  "gamma": "https://gamma-jo.devo.jobsatamazon.hvh.a2z.com",
  "preprod": "https://jo.preprod.jobsatamazon.hvh.a2z.com",
  "prod": "https://jobs.amazon.jo",
};

// TODO: Update CS endpoints
export const CSDomainsAE: { [key: string]: string }  = {
  "beta": "",
  "gamma": "",
  "preprod": "",
  "prod": "",
};

export const CSDomainsSA: { [key: string]: string }  = {
  "beta": "",
  "gamma": "",
  "preprod": "",
  "prod": "",
};

export const CSDomainByCountryFullName: { [key: string]: any } = {
  [CountryFullName.UK] : CSDomainsUK,
  [CountryFullName.US] : CSDomainsUS,
  [CountryFullName.MX] : CSDomainsMX,
  [CountryFullName.CA] : CSDomainsCA,
  [CountryFullName.JP] : CSDomainsJP,
  [CountryFullName.BR] : CSDomainsBR,
  [CountryFullName.CR] : CSDomainsCR,
  [CountryFullName.CO] : CSDomainsCO,
  [CountryFullName.EG] : CSDomainsEG,
  [CountryFullName.JO] : CSDomainsJO,
  //TODO: Update CS endpoints for SA and AE
};

export const MultiplePhoneCountryCodeCountryList = [
  "UK"
];


export const AuthClientIdUS: { [key: string]: string } = {
  "beta" : "91a70a90-c7cf-11ea-9411-536524081e5f",
  "gamma" : "844ac420-f49c-11eb-93e9-977ccfbd7e6c"
};

export const AuthClientIdMX: { [key: string]: string } = {
};

export const AuthClientIdUK: { [key: string]: string } = {
};

export const AuthClientIdByCountryFullName: { [key: string]: any } = {
  [CountryFullName.UK] : AuthClientIdUK,
  [CountryFullName.US] :AuthClientIdUS,
  [CountryFullName.MX] :AuthClientIdMX,
};

export const AuthSecretIdUS: { [key: string]: string } = {
  "beta" : "$2b$10$mCOO9rWsXWBiT/rB7JdDk.bOoVZ91q6zWbaUMVMcNYf3LddX2wh5B",
  "gamma" : "$2b$10$hv59lZbg7chMNsY8aWcGR.K4fwbsc.8QRqVB/gghEkaGAbYzX7iCa"
};

export const AuthSecretIdUK: { [key: string]: string } = {
};

export const AuthSecretIdMX: { [key: string]: string } = {
};

export const AuthSecretIdByCountryFullName: { [key: string]: any } = {
  [CountryFullName.UK] : AuthSecretIdUK,
  [CountryFullName.US] : AuthSecretIdUS,
  [CountryFullName.MX] : AuthSecretIdMX,
};

export default Constants

export const errorMessageCharLimitDefault = {
  firstName: "40",
  lastName: "80",
  middleName: "30",
  preferredFirstName: "30",
  preferredLastName: "30",
  englishFirstName: "40",
  englishLastName: "80",
};
export const errorMessageCharLimitMap:{ [key: string]: any } = {
  [CountryFullName.MX]: {
    firstName: "50",
    lastName: "50",
    middleName: "50",
    preferredFirstName: "50",
    preferredLastName: "50",
    englishFirstName: "50",
    englishLastName: "50",
  },
  [CountryFullName.JP]: {
    firstName: "50",
    lastName: "100",
    middleName: "20",
    preferredFirstName: "20",
    preferredLastName: "20",
    englishFirstName: "40",
    englishLastName: "80",
  },
};

export const nameRegexDefault = {
  firstName: `^[\\${pL} ,.'-]{2,40}$`,
  lastName: `^[\\${pL} ,.'-]{2,80}$`,
  // allow strings with characters and up to one space followed by characters.
  // (?=.{2,30}$) is a positive lookahead assertion to ensure that the whole
  // string is less than 30 characters. 
  middleName: `^(?=.{1,30}$)[\\${pL}]+(?: [a-zA-Z]+)?$`,
  preferredFirstName: `^[\\${pL} ,.'-]{2,30}$`,
  preferredLastName: `^[\\${pL} ,.'-]{2,30}$`,
  englishFirstName: `^[\\${pL} ,.'-]{2,40}$`,
  englishLastName: `^[\\${pL} ,.'-]{2,80}$`,
};

// refer to https://gist.github.com/ryanmcgrath/982242
const JapaneseNameCharSet = `[\u3040-\u309F]|[\u30A0-\u30FF]|[\u4E00-\u9FAF]`;
export const nameRegexMap:{ [key: string]: any } = {
  [CountryFullName.MX]: {
    firstName: `^[\\${pL} ,.'-]{2,50}$`,
    lastName: `^[\\${pL} ,.'-]{2,50}$`,
    middleName: `^[\\${pL} ,.'-]{2,50}$`,
    preferredFirstName: `^[\\${pL} ,.'-]{2,50}$`,
    preferredLastName: `^[\\${pL} ,.'-]{2,50}$`,
    englishFirstName: `^[\\${pL} ,.'-]{2,50}$`,
    englishLastName: `^[\\${pL} ,.'-]{2,50}$`,
  },
  [CountryFullName.JP]: {
    firstName: `^(${JapaneseNameCharSet}){1,50}$`,
    lastName: `^(${JapaneseNameCharSet}){1,100}$`,
    middleName: `^[\\${pL} ,.'-]{1,20}$`,
    preferredFirstName: `^[\\${pL} ,.'-]{1,20}$`,
    preferredLastName: `^[\\${pL} ,.'-]{1,20}$`,
    englishFirstName: `^[A-Za-z' -]{2,40}$`,
    englishLastName: `^[A-Za-z' -]{2,80}$`,
  },
};

export const pathUrlLocaleRegex =
    new RegExp("^(?!www\\.|(?:http|ftp)s?:\\/\\/|[A-Za-z]:\\\\|\\/\\/|(\\/app#)|(\\/selfservice\\/)|(\\/checklist\\/)|(\\/application\\/)|(app#)|(selfservice\\/)|(checklist\\/)|(application\\/)).*");


export const loginHelpPageRoute: { [key: string]: string } = {
  [CountryFullName.US]: US_PAGE_ROUTE.FAQ,
  [CountryFullName.MX]: PAGE_ROUTE_CONTACT_US,
  [CountryFullName.UK]: PAGE_ROUTE_FAQ,
  [CountryFullName.CA]: PAGE_ROUTE_FAQ,
  [CountryFullName.JP]: JP_PAGE_ROUTE.FAQ,
  [CountryFullName.BR]: BR_PAGE_ROUTE.FAQ,
  [CountryFullName.CO]: PAGE_ROUTE_FAQS,
  [CountryFullName.CR]: PAGE_ROUTE_FAQS,
  [CountryFullName.EG]: PAGE_ROUTE_FAQS,
  [CountryFullName.JO]: PAGE_ROUTE_FAQS,
  [CountryFullName.SA]: PAGE_ROUTE_FAQS,
  [CountryFullName.AE]: PAGE_ROUTE_FAQS,
  [CountryFullName.DEVO]: PAGE_ROUTE_FAQ
};

export const PhoneNumberReminderCountryList = [CountryFullName.UK, CountryFullName.JP];

export const RumConfig: Record<string, RumConfigType> = {
  [Stage.Beta]: {
    config: {
      sessionSampleRate: 1,
      guestRoleArn: "arn:aws:iam::723097128080:role/CloudwatchRUMStack-Beta-us-east-1-Role",
      identityPoolId: "us-east-1:8ea4fb13-d5c7-42bf-9f8a-5a48e0dc9511",
      endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
      telemetries: ["errors","http","performance"],
      allowCookies: true,
      enableXRay: true,
      sessionEventLimit: 0,
      disableAutoPageView: true,
    },
    applicationId: "1db0e39b-a0d8-4e05-9b7b-6c4f91b14ff8",
    applicationVersion: "1.0.0",
    applicationRegion: RunEnabledRegion.US_EAST_1
  },
  [Stage.Gamma]: {
    config: {
      sessionSampleRate: 1,
      guestRoleArn: "arn:aws:iam::667103799397:role/CloudwatchRUMStack-Gamma-us-east-1-Role",
      identityPoolId: "us-east-1:f5a9775a-bb54-4bd3-92a8-eef19f26e29d",
      endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
      telemetries: ["errors","http","performance"],
      allowCookies: true,
      enableXRay: true,
      sessionEventLimit: 0,
      disableAutoPageView: true,
    },
    applicationId: "68f797a6-3aab-4fa7-8fc5-2ec9b73efccf",
    applicationVersion: "1.0.0",
    applicationRegion: RunEnabledRegion.US_EAST_1
  },
  [Stage.PreProd]: {
    config: {
      sessionSampleRate: 1,
      guestRoleArn: "arn:aws:iam::306104229072:role/CloudwatchRUMStack-Preprod-us-east-1-Role",
      identityPoolId: "us-east-1:9b71ccbd-9968-45de-bfd8-db293bb46aec",
      endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
      telemetries: ["errors","http","performance"],
      allowCookies: true,
      enableXRay: true,
      sessionEventLimit: 0,
      disableAutoPageView: true,
    },
    applicationId: "6da67aa3-3b57-4cf1-917b-57258c58fee5",
    applicationVersion: "1.0.0",
    applicationRegion: RunEnabledRegion.US_EAST_1
  },
  [Stage.Prod]: {
    config: {
      sessionSampleRate: 1,
      guestRoleArn: "arn:aws:iam::793060240266:role/CloudwatchRUMStack-Prod-us-east-1-Role",
      identityPoolId: "us-east-1:1205a44f-549b-4073-934a-dfff2fc666da",
      endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
      telemetries: ["errors","http","performance"],
      allowCookies: true,
      enableXRay: true,
      sessionEventLimit: 0,
      disableAutoPageView: true,
    },
    applicationId: "edcc8bdd-3790-43d9-b0ee-e1366b8244c5",
    applicationVersion: "1.0.0",
    applicationRegion: RunEnabledRegion.US_EAST_1
  }
};

export enum RumEventType {
  UI_VALIDATION_ENTER_EMAIL_OR_PHONE = "ui-validation-enter-email-or-phone",
  UI_VALIDATION_ENTER_PIN = "ui-validation-enter-pin",
  CONFIRM_OTP = "confirm-otp",
  RESEND_OTP = "resend-otp",
  GET_CANDIDATE = "get-candidate",
  SIGN_IN = "sign-in",
  VERIFY_SIGN_IN = "verify-sign-in",
  VERIFY_EMAIL = "verify-email",
  VERIFY_PHONE = "verify-phone",
  NO_COUNTRY_SELECTED = "no-country-selected",
  NO_LANGUAGE_SELECTED = "no-language-selected",
  NO_TIMEZONE_SELECTED = "no-timezone-selected",
  UI_VALIDATION_ENTER_NAME = "ui-validation-enter-name",
}

export enum LoginError {
  EMPTY_LOGIN = "empty-login",
  INVALID_LOGIN_FORMAT = "invalid-login-format",
  EMPTY_COUNTRY_INFO = "empty-country-info",
}


export enum RegistrationError {
  EMPTY_FIRST_NAME = "empty-first-name",
  EMPTY_MIDDLE_NAME = "empty-middle-name",
  EMPTY_LAST_NAME = "empty-last-name",
  EMPTY_ENGLISH_FIRST_NAME = "empty-english-first-name",
  EMPTY_ENGLISH_LAST_NAME = "empty-english-last-name",
  EMPTY_EMAIL = "empty-email",
  EMPTY_REENTER_EMAIL = "empty-reenter-email",
  EMPTY_PHONE_NUMBER = "empty-phone-number",
  EMPTY_REENTER_PHONE_NUMBER = "empty-reenter-phone-number",
  EMPTY_LANGUAGE = "empty-language",
  EMPTY_TIMEZONE = "empty-timezone",
  EMPTY_PIN = "empty-pin",
  EMPTY_REENTER_PIN = "empty-reenter-pin"
}


export enum RegistrationRegexError {
  INVALID_FIRST_NAME = "invalid-first-name",
  INVALID_MIDDLE_NAME = "invalid-middle-name",
  INVALID_LAST_NAME = "invalid-last-name",
  INVALID_ENGLISH_FIRST_NAME = "invalid-english-first-name",
  INVALID_ENGLISH_LAST_NAME = "invalid-english-last-name",
  INVALID_EMAIL = "invalid-email",
  INVALID_REENTER_EMAIL = "invalid-reenter-email",
  INVALID_PHONE_NUMBER = "invalid-phone-number",
  INVALID_REENTER_PHONE_NUMBER = "invalid-reenter-phone-number",
  INVALID_LANGUAGE = "invalid-language",
  INVALID_TIMEZONE = "invalid-timezone",
  INVALID_PIN = "invalid-pin",
  INVALID_REENTER_PIN = "invalid-reenter-pin"
}

export enum StringMatchError {
  EMAIL_NOT_EQUAL = "email-not-equal",
  PHONE_NUMBER_NOT_EQUAL = "phone-number-not-equal",
  PIN_NOT_EQUAL = "pin-not-equal",
}

export enum PinError {
  EMPTY_PIN = "empty-pin",
  NON_NUMERIC_PIN = "non-numeric-pin",
  INVALID_PIN = "invalid-pin",
  FORGOT_PIN_ERROR = "forgot-pin-error",
  UPDATE_PIN_ERROR = "update-pin-error",
}

export enum ExistingCandidateError {
  EMAIL_ALREADY_EXISTS = "email-already-exists",
  PHONE_NUMBER_ALREADY_EXISTS = "phone-number-already-exists",
}

export enum OtpError {
  INVALID_OTP = "invalid-otp",
  RESEND_OTP_ERROR = "failed-resend-otp",
  NO_CANDIDATE_FOR_OTP = "no-candidate-for-otp",
}

export enum VerificationError {
  FAILED_EMAIL_VERIFY = "failed-email-verify",
  FAILED_PHONE_VERIFY = "failed-phone-verify",
  VERIFY_EMAIL_ERROR = "verify-phone-error",
  VERIFY_PHONE_ERROR = "verify-phone-error",
}

export enum FlowType {
  SIGN_IN_ORIGINAL = "sign-in-original"
}

export enum SignInOriginal {
  SIGN_IN = "sign-in",
  REGISTRATION = "registration"
}

export const errorPageName: {[key: string]: PageName} = {
  [SIGN_IN_PAGES.LOGIN]: PageName.PERSONAL_PIN_ENTRY,
  [SIGN_IN_PAGES.PIN]: PageName.PERSONAL_PIN_ENTRY,
  [SIGN_IN_PAGES.CONFIRM_OTP]: PageName.INCORRECT_OTP,
  [FORGOT_PIN_PAGES.FORGOT_PIN]: PageName.INVALID_EMAIL,
  [FORGOT_PIN_PAGES.RESET_PIN]: PageName.PIN_RESET_ERROR,
};

export const AtoZDomains: { [key: string]: string } = {
  "beta": "https://hiring.integ.amazon.work",
  "gamma": "https://hiring.integ.amazon.work",
  "preprod": "",
  "prod": ""
}
