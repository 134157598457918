import { from, Observable, of } from "rxjs";
import { flatMap, map, catchError } from "rxjs/internal/operators";
import { ofType } from "redux-observable";
import { updatePinApi } from '../utils/apis/serviceApi';
import { parseErrorMessage } from "../utils/helper";
import { actionResetPinSuccess} from "../actions/actions";
import {
  RESET_PIN,
  RESET_PIN_SUCCESS,
  RESET_PIN_FAILED,
  RESET_PIN_CALLBACK_SUCCESS,
} from "../actions/actionTypes";

export const resetPinEpic = (action$: Observable<any>) => {
  return action$.pipe(
    ofType(RESET_PIN),
    flatMap((action: any) =>
      from<Promise<any>>(
        updatePinApi(action.payload)
      ).pipe(
        flatMap(async (response) => {
          return response.data
        }),
        map((x) => actionResetPinSuccess(x, action.callback)),
        catchError((error) => {
            const response = error.response;
            if (action.onError) {
                action.onError(response);
            }
            return of({
                type: RESET_PIN_FAILED,
                payload: parseErrorMessage(response.data),
            });
        })
      )
    )
  );
};

export const resetPinSuccessEpic = (action$: Observable<any>) => {
    return action$.pipe(
      ofType(RESET_PIN_SUCCESS),
      map((action) => {
        if (action.callback) {
          action.callback(action.payload);
        }
        return { type: RESET_PIN_CALLBACK_SUCCESS, payload: null };
      })
    );
};