import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { generateCanonicalURL } from 'src/utils/helper';

interface AppSEOHeaderProps {}

// For better SEO, we want to add canonical urls tag including the URL for the page we do want indexed.
// IMPORTANT: This component should not be used for rendering header.
// @typescript-eslint/no-unused-vars
export const AppSEOHeader: FC<AppSEOHeaderProps> = () => {
  return (
    <Helmet>
      <link rel="canonical" href={generateCanonicalURL()} />
    </Helmet>
  );
};
