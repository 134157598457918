const geoInfoResponseItems = `
country
lat
lng
postalCode
label
municipality
region
subRegion
addressNumber
`;

const jobCardsResponse =
`nextToken
jobCards {
    jobId
    language
    dataSource
    requisitionType
    jobTitle
    jobType
    employmentType
    city
    state
    postalCode
    locationName
    totalPayRateMin
    totalPayRateMax
    tagLine
    bannerText
    image
    jobPreviewVideo
    distance
    featuredJob
    bonusJob
    bonusPay
    scheduleCount
    currencyCode
    geoClusterDescription
    surgePay
    jobTypeL10N
    employmentTypeL10N
    bonusPayL10N
    surgePayL10N
    totalPayRateMinL10N
    totalPayRateMaxL10N
    distanceL10N
}`;

const jobDetailResponse =
`
agencyName
jobId
language
dataSource
requisitionType
jobIdNumber
jobTitle
jobType
employmentType
fullAddress
country
city
state
postalCode
totalPayRateMin
totalPayRateMax
currencyCode
tagLine
image
jobPreviewVideo
distance
featuredJob
bonusJob
jobDescription
jobQualification
careerPortalURL
blackbirdPortalURL
postingStatus
uiPath
siteId
locationDescription
locationName
jobBannerText
geoClusterId
geoClusterName
geoClusterRegion
geoClusterZone
geoClusterDescription
locationCode
complianceText
requiredLanguage
`;

const candidateDetailResponse =
`
candidateId
candidateSFId
firstName
middleName
lastName
preferredFirstName
preferredLastName
nameSuffix
emailId
phoneNumber
phoneCountryCode
locale
additionalBackgroundInfo{
    address{
        addressLine1
        addressLine2
        city
        state
        country
        zipcode
        countryCode
    }
    dateOfBirth
}
timezone
language
`;

const communicationPreferenceResponse = 
`
candidateId
isConsent
isEmailEnabled
isSMSEnabled
`

const scheduleCardsResponse =
`
nextToken
scheduleCards {
    address
    basePay
    bonusSchedule
    city
    currencyCode
    dataSource
    distance
    employmentType
    externalJobTitle
    featuredSchedule
    firstDayOnSite
    hoursPerWeek
    image
    jobId
    jobPreviewVideo
    language
    postalCode
    priorityRank
    scheduleBannerText
    scheduleId
    scheduleText
    scheduleType
    signOnBonus
    state
    surgePay
    tagLine
    geoClusterId
    geoClusterName
    siteId
    scheduleBusinessCategory
    totalPayRate
    financeWeekStartDate
    laborDemandAvailableCount
    scheduleBusinessCategoryL10N
    firstDayOnSiteL10N
    financeWeekStartDateL10N
    scheduleTypeL10N
    employmentTypeL10N
    basePayL10N
    signOnBonusL10N
    totalPayRateL10N
    distanceL10N
    requiredLanguage
}
`;

const jobAlertSignupResponse =
`
subscriber {
    email
    phoneNumber
    firstName
    lastName
    locale
    longitude
    latitude
    country
    zipCode
    }
filters
`;

const queryApplicationsByBBCandidateIdResponse =
`
active
submitted
applicationId
candidateId
sfCandidateId
workflowName
step
subStep
lastModificationDate
continueApplicationLink
changeShiftLink
startDateConfirmed
sfShift {
    shiftCode
    shiftDescription
    daysOfTheWeek
    startTime
    endTime
    basePayRate
    firstDayOnSite
    reportTime
}
scheduleInfo {
    scheduleId
    language
    dataSource
    geoClusterId
    geoClusterName
    currencyCode
    address
    city
    state
    postalCode
    basePay
    signOnBonus
    surgePay
    shiftDifferential
    employmentType
    externalJobTitle
    tagLine
    image
    financeWeekStartDate
    scheduleBannerText
    scheduleType
    scheduleText
    hoursPerWeek
    firstDayOnSite
    startTime
}
jobDetail {
    jobId
    language
    dataSource
    requisitionType
    jobIdNumber
    jobTitle
    jobType
    employmentType
    fullAddress
    country
    city
    state
    postalCode
    totalPayRateMin
    totalPayRateMax
    currencyCode
    tagLine
    image
    distance
    featuredJob
    bonusJob
    jobQualification
    postingStatus
    agencyName
    geoClusterId
    geoClusterName
    geoClusterRegion
    geoClusterZone
    geoClusterDescription
    locationCode
    locationDescription
    locationName
    jobBannerText
    complianceText
}
stepper{
    stepOrder
    active
    completed
    showAshButton
    continueButtonText
    continueButtonTextTranslationId
    withdrawn
    showAshButton
    applicationCompleted
    messageCode
    hideWithdrawButton
}
`;

const withApplicationsResponse =
`
error
statusCode
`

export const getJobDetailQuery =
    `query getJobDetail($getJobDetailRequest: GetJobDetailRequest!) {
        getJobDetail(getJobDetailRequest: $getJobDetailRequest) {
            ${jobDetailResponse}
        }
    }`;

export const getCandidateDetailQuery =
    `query queryCandidate($bbCandidateId: String!){
        queryCandidate(bbCandidateId: $bbCandidateId) {
            ${candidateDetailResponse}
        }
    }`;

export const getApplicationsByBBCandidateIdQuery =
    `query queryApplicationsByBBCandidateId($locale: String!, $bbCandidateId: String!) {
        queryApplicationsByBBCandidateId(locale: $locale, bbCandidateId: $bbCandidateId) {
            ${queryApplicationsByBBCandidateIdResponse}
        }
    }`

export const withdrawApplicationsQuery =
    `mutation MyMutation ($input: withdrawApplicationsInput!) {
        withdrawApplications(input: $input){
            ${withApplicationsResponse}
        }
    }`

export const updateCandidateDetailQuery =
    `mutation updateCandidate($input: CandidateInput!) {
        updateCandidate(input: $input){
            candidateId
        }
    }`;

export const getCommunicationPreferenceQuery =
    `query queryCommunicationPreference($bbCandidateId: String!){
        queryCommunicationPreference(bbCandidateId: $bbCandidateId) {
            ${communicationPreferenceResponse}
        }
    }`;

export const updateCommunicationPreferenceQuery =
    `mutation queryCommunicationPreference($input: CommunicationPreferenceInput!){
        updateCommunicationPreference(input: $input){
            candidateId
        }
    }`;

export const searchJobCardsByLocationQuery =
    `query searchJobCardsByLocation($searchJobRequest: SearchJobRequest!) {
        searchJobCardsByLocation(searchJobRequest: $searchJobRequest) {
            ${jobCardsResponse}
        }
    }`;

export const queryGeoInfoByAddressQuery =
    `query queryGeoInfoByAddress($geoAddressQueryRequest: GeoAddressQueryRequest!) {
        queryGeoInfoByAddress(geoAddressQueryRequest: $geoAddressQueryRequest) {
            ${geoInfoResponseItems}
        }
    }`;

export const queryGeoInfoByLocationQuery =
    `query queryGeoInfoByLocation($geoLocationQueryRequest: GeoLocationQueryRequest!) {
        queryGeoInfoByLocation(geoLocationQueryRequest: $geoLocationQueryRequest) {
            ${geoInfoResponseItems}
        }
    }`;

export const searchScheduleCardsQuery =
    `query searchScheduleCards($searchScheduleRequest: SearchScheduleRequest!) {
        searchScheduleCards(searchScheduleRequest: $searchScheduleRequest) {
            ${scheduleCardsResponse}
        }
    }`;

export const getAutoCompleteHintQuery =
    `query getAutoCompleteHint($autoCompleteRequest: AutoCompleteRequest!){
        getAutoCompleteHint(autoCompleteRequest: $autoCompleteRequest){
            hints
        }
    }`;

export const jobAlertSignupQuery =
    `mutation createSubscription($jobAlertSignupRequest: CreateSubscriptionInput!) {
        createJobAlertSubscription(input: $jobAlertSignupRequest) {
            ${jobAlertSignupResponse}
        }
    }`;

export const contactUsCallQuery =
    `mutation contactUsCall($contactUsCallRequest: ContactUsCallInput!){
        contactUsCall(input: $contactUsCallRequest)
    }`;

export const contactUsSMSQuery =
    `mutation contactUsSMS($contactUsSMSRequest: ContactUsSMSInput!){
        contactUsSMS(input: $contactUsSMSRequest)
    }`;

export const contactUsEmailQuery =
    `mutation contactUsEmail($contactUsEmailRequest: ContactUsEmailActionInput!){
        contactUsEmail(input: $contactUsEmailRequest)
    }`;