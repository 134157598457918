import React, { useState } from 'react';
import { Checkbox } from '@amzn/stencil-react-components/form';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';

interface CheckboxComponentProps {
  onChange: Function;
  id: string;
  labelText?: string;
  alignItems?: string;
  color?: string;
}

export const CheckboxComponent = ({ onChange, labelText, alignItems, color, id }: CheckboxComponentProps) => {
  const [showMiddleName, setShowMiddleName] = useState<boolean>(false);
  return (
    <Col className="colContainer">
      <Row className="rowContainer" alignItems={alignItems} gridGap="S300">
        <div className="checkbox">
          <Checkbox
            id={id}
            dataTestId="input-test-id-checkBox"
            checked={showMiddleName}
            onChange={(e) => {
              const value = e.target.checked;
              setShowMiddleName(value);
              onChange(value);
            }}
          />
        </div>
        <Text color={color} fontSize="T200" textAlign="left">
          {labelText ? labelText : ''}
        </Text>
      </Row>
    </Col>
  );
};
