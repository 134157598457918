import { getCountryFullName } from "../apis/apiHelper";
import { CountryCode, CountryFullName } from "../commonTypes";
import { PhoneCodeByCountry } from "../phoneCodes";
import { PhoneCodesByCountry, RegistartionFieldName } from "./constants";

export const getPlaceholderByFieldName = (fieldName: RegistartionFieldName, customMap: Map<CountryFullName, any>, defaultMap: Map<RegistartionFieldName, any>) => {
  const country = getCountryFullName();
  const placeholderValueSetByCountry = customMap.get(country);
  return placeholderValueSetByCountry.get(fieldName) || defaultMap.get(fieldName) || "";
};

export const getPhoneCountryCode = (): string => {
  const countryCode = CountryCode[getCountryFullName()];
  return PhoneCodeByCountry.get(countryCode) || PhoneCodesByCountry[0];
};

export function swapComponents<T>(currentFormConfig: T[], componentIndex1: number, componentIndex2: number): T[] {
  const [index1, index2] = [
    currentFormConfig[componentIndex1],
    currentFormConfig[componentIndex2]
  ];
  currentFormConfig[componentIndex1] = index2;
  currentFormConfig[componentIndex2] = index1;
  return currentFormConfig;
}
