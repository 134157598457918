import { AwsRum } from 'aws-rum-web';
import { getStage } from './apis/apiHelper';
import { RumConfig } from './constants';
import {PageAttributes} from "aws-rum-web/dist/cjs/sessions/PageManager";
import { getSignInOriginal } from './helper';

let rumClient: AwsRum | undefined;

export const initRum = (): void => {
    try {
        const stage = getStage();

        const rumConfig = RumConfig[stage];

        if (rumConfig) {
            rumClient = new AwsRum(
                rumConfig.applicationId, 
                rumConfig.applicationVersion, 
                rumConfig.applicationRegion, 
                rumConfig.config,
            );
        } else {
            console.log("RUM config not found for stage: " + stage);
        }
    } catch (error) {
        console.error('Error initializing RUM client', error);
    }
}

export const emitEvent = (eventAttributes: {
    event: string,
    status: boolean,
    country: string,
    path: string,
    userLogin?: string,
    error?: string,
    errorCode?: string,
    availability?: number,
    candidateInput?: string,
  }): void => {
    if (!rumClient) {
        console.log("Rum client not initialized");
        return;
    }
    
    rumClient.recordEvent("hvh-auth-custom-event", {
        ...eventAttributes,
        flowType: getSignInOriginal()
    });
};

export const recordPageView = (attributes: PageAttributes): void => {
    if (!rumClient) {
        console.log("Rum client not initialized");
        return;
    }

    rumClient.recordPageView(attributes);
};