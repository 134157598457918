import React from "react";
import { Modal, ModalContent, ModalStyle } from "@amzn/stencil-react-components/modal";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { P } from "@amzn/stencil-react-components/text";
import { setCookieConsent } from "src/utils/helper";
import { Link } from '@amzn/stencil-react-components/link';
import { translate as t } from "src/utils/translator";

interface ConsentCardProps {
    closeModal: () => void;
    isOpen: boolean;
}

export const ConsentCard = (props: ConsentCardProps) => {
    const { closeModal, isOpen } = props;

    return <Modal
        isOpen={isOpen}
        close={closeModal}
        style={ModalStyle.BottomSheet}
        shouldCloseOnClickOutside={false}
        dataTestId="consentModal"
    >
        <ModalContent
            titleText=""
            maxWidth="100%"
        >
            <Col
                gridGap={16}
                justifyContent="center"
            >
                <Row justifyContent="center">
                    <P>
                        {t('ConsentCard-main-text', `We use cookies and other tracking technologies to assist with navigation,
                        improve our products and services, assist with our marketing efforts, and provide
                        content from third parties, in accordance with our`)} <Link
                        href="https://www.amazon.jobs/en/privacy_page?region_id=region_eu"
                        target="_blank">{t('ConsentCard-privacy-policy-link', 'privacy policy')}</Link>.
                    </P>
                </Row>
                <Row justifyContent="center">
                    <Button
                        variant={ButtonVariant.Primary}
                        onClick={() => {
                            setCookieConsent(true);
                            closeModal();
                        }}
                    >{t('ConsentCard-accept-consent-btn-txt', 'I consent')}</Button>
                </Row>
            </Col>
        </ModalContent>
    </Modal>
};
