import React from 'react';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { Text } from '@amzn/stencil-react-components/text';
import { ModalContent, RenderModalFunction, WithModal } from '@amzn/stencil-react-components/modal';
import useHVHTranslation from 'src/Hooks/useHVHTranslation';

interface SkipPhoneVerification {
  onSkipPhoneVerification: Function;
}

const SkipPhoneVerification = (props: SkipPhoneVerification) => {
  const { t } = useHVHTranslation();
  const onSkipPhoneVerificationClick = (modalClose: Function) => {
    // Analytics.logCounterMetrics({
    //   name: ANALYTICS_COUNTER_METRICS.SKIP_LOGIN_WITH_PHONE,
    //   page: ANALYTICS_PAGE_NAMES.PHONE_VERIFICATION_PAGE,
    // });
    props.onSkipPhoneVerification();
    modalClose();
  };

  const renderModal: RenderModalFunction = ({ close }) => (
    <ModalContent
      titleText={t(
        'SkipPhoneVerificationComponent-title-skip-phone-verification-modal',
        'Skip mobile number verification?',
      )}
    >
      <Col gridGap="S300">
        <Text fontSize="T200">
          {t(
            'SkipPhoneVerificationComponent-lbl-skip-phone-verification-message-1',
            'You will not be able to login using your mobile number, but you can still login using your email address.',
          )}
        </Text>
        <Text fontSize="T200">
          {t(
            'SkipPhoneVerificationComponent-lbl-skip-phone-verification-message-2',
            'You may still receive text messages about your appointments during the hiring process.',
          )}
        </Text>
        <Button dataTestId="button-test-id-closeSkipPhoneModal" onClick={close} variant={ButtonVariant.Primary}>
          {t('SkipPhoneVerificationComponent-btn-skip-phone-verification-back-button-text', 'Back')}
        </Button>
        <Button dataTestId="button-test-id-onSkipPhoneModal" onClick={() => onSkipPhoneVerificationClick(close)}>
          {t(
            'SkipPhoneVerificationComponent-btn-skip-phone-verification-continue-button-text',
            'Continue without verifying',
          )}
        </Button>
      </Col>
    </ModalContent>
  );

  return (
    <WithModal renderModal={renderModal}>
      {({ open }) => (
        <Row gridGap="S300">
          <Button dataTestId="button-test-id-openSkipPhoneModal" variant={ButtonVariant.Tertiary} onClick={open}>
            <Text>
              {t(
                'SkipPhoneVerificationComponent-btn-skip-phone-verification-button-label',
                'Skip mobile number verification',
              )}
            </Text>
          </Button>
        </Row>
      )}
    </WithModal>
  );
};

export default SkipPhoneVerification;
