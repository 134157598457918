import { Action } from 'redux';
import { CountryStateConfigs } from 'src/utils/apis/serviceApiTypes';

export enum GET_COUNTRY_STATE_CONFIG_TYPE {
  GET = 'GET_COUNTRY_STATE_CONFIG',
  SUCCESS = 'GET_COUNTRY_STATE_CONFIG_SUCCESS',
  FAILED = 'GET_COUNTRY_STATE_CONFIG_FAILED',
}

export interface GetCountryStateConfigSuccessAction extends Action {
  type: GET_COUNTRY_STATE_CONFIG_TYPE.SUCCESS;
  payload: CountryStateConfigs;
  callback?: Function;
}
