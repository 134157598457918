import {
    GetCommunicationPreferenceRequest, UpdateCommunicationPreferenceRequest
} from "src/utils/apis/appSyncAPI/types";
import { AlertMessage, CommunicationPreference } from "src/utils/commonTypes";
import {
    GetCommunicationPreferenceAction,
    GetCommunicationPreferenceFailedAction,
    GetCommunicationPreferenceSuccessAction,
    GET_COMMUNICATION_PREFERENCE_TYPE,
    ResetCommunicationPreferenceAction,
    UpdateCommunicationPreferenceAction,
    UpdateCommunicationPreferenceFailedAction,
    UpdateCommunicationPreferenceSuccessAction,
    UPDATE_COMMUNICATION_PREFERENCE_TYPE
} from "./communicationPreferenceActionTypes";

export const actionGetCommunicationPreference = (
    payload: GetCommunicationPreferenceRequest
): GetCommunicationPreferenceAction => {
    return { type: GET_COMMUNICATION_PREFERENCE_TYPE.GET, payload }
};

export const actionGetCommunicationPreferenceSuccess = (
    payload: CommunicationPreference
): GetCommunicationPreferenceSuccessAction => {
    return { type: GET_COMMUNICATION_PREFERENCE_TYPE.SUCCESS, payload }
};

export const actionGetCommunicationPreferenceFailed = (
    payload: AlertMessage
): GetCommunicationPreferenceFailedAction => {
    return { type: GET_COMMUNICATION_PREFERENCE_TYPE.FAILED, payload }
};

export const actionResetCommunicationPreference = (
    callback?: Function
): ResetCommunicationPreferenceAction => {
    return { type: GET_COMMUNICATION_PREFERENCE_TYPE.RESET, callback }
};

export const actionUpdateCommunicationPreference = (
    payload: UpdateCommunicationPreferenceRequest,
    callback?: Function
): UpdateCommunicationPreferenceAction => {
    return { type: UPDATE_COMMUNICATION_PREFERENCE_TYPE.UPDATE, payload, callback }
};

export const actionUpdateCommunicationPreferenceSuccess = (
    payload: CommunicationPreference
): UpdateCommunicationPreferenceSuccessAction => {
    return { type: UPDATE_COMMUNICATION_PREFERENCE_TYPE.SUCCESS, payload }
};

export const actionUpdateCommunicationPreferenceFailed = (
    payload: AlertMessage
): UpdateCommunicationPreferenceFailedAction => {
    return { type: UPDATE_COMMUNICATION_PREFERENCE_TYPE.FAILED, payload }
};