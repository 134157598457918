import { from, Observable, of } from "rxjs";
import { flatMap, map, catchError } from "rxjs/internal/operators";
import { ofType } from "redux-observable";
import { getSessionTokenApi } from '../utils/apis/serviceApi';
import { actionGetCSRFSuccess} from "../actions/actions";
import {
  GET_CSRF,
  GET_CSRF_FAILED,
} from "../actions/actionTypes";
import {
    AuthCSRFTokenErrorMessages,
} from "src/utils/errorMessages";
import { setSessionToken } from "src/utils/helper";

export const getCSRFEpic = (action$: Observable<any>) => {
  return action$.pipe(
    ofType(GET_CSRF),
    flatMap((action: any) =>
      from<Promise<any>>(
        getSessionTokenApi()
      ).pipe(
        flatMap(async (response) => {
          setSessionToken(response.data.token);
          if (action.callback) {
            action.callback();
          }
          return response.data
        }),
        map((x) => actionGetCSRFSuccess(x, action.callback)),
        catchError((error) => {
            let errorMessage = AuthCSRFTokenErrorMessages.DEFAULT;

            if(error && error.message) {
                errorMessage =  AuthCSRFTokenErrorMessages[error.message] || AuthCSRFTokenErrorMessages.DEFAULT;
            }

            return of({
            type: GET_CSRF_FAILED,
            payload: {
                errorMessage
            }
          });
        })
      )
    )
  );
};
