import {
    GetCandidateDetailRequest, UpdateCandidateDetailRequest
} from "src/utils/apis/appSyncAPI/types";
import { AlertMessage, CandidateDetail } from "src/utils/commonTypes";
import {
    GET_CANDIDATE_DETAIL_TYPE,
    GetCandidateDetailAction,
    GetCandidateDetailSuccessAction,
    GetCandidateDetailFailedAction,
    ResetCandidateDetailAction,
    UpdateCandidateDetailAction,
    UPDATE_CANDIDATE_DETAIL_TYPE,
    UpdateCandidateDetailFailedAction,
    UpdateCandidateDetailSuccessAction,
} from "src/actions/CandidateDetailActions/candidateDetailActionTypes";

export const actionGetCandidateDetail = (
    payload: GetCandidateDetailRequest,
    onSuccess?: Function,
    onError?: Function,
): GetCandidateDetailAction => {
    return { type: GET_CANDIDATE_DETAIL_TYPE.GET, payload, onSuccess, onError }
};

export const actionGetCandidateDetailSuccess = (
    payload: CandidateDetail
): GetCandidateDetailSuccessAction => {
    return { type: GET_CANDIDATE_DETAIL_TYPE.SUCCESS, payload }
};

export const actionGetCandidateDetailFailed = (
    payload: AlertMessage
): GetCandidateDetailFailedAction => {
    return { type: GET_CANDIDATE_DETAIL_TYPE.FAILED, payload }
};

export const actionResetCandidateDetail = (
    callback?: Function
): ResetCandidateDetailAction => {
    return { type: GET_CANDIDATE_DETAIL_TYPE.RESET, callback }
};

export const actionUpdateCandidateDetail = (
    payload: UpdateCandidateDetailRequest,
    onSuccess?: Function,
    onError?: Function,
): UpdateCandidateDetailAction => {
    return { type: UPDATE_CANDIDATE_DETAIL_TYPE.UPDATE, payload, onSuccess, onError }
};

export const actionUpdateCandidateDetailSuccess = (
    payload: CandidateDetail,
    callback?: Function
): UpdateCandidateDetailSuccessAction => {
    return { type: UPDATE_CANDIDATE_DETAIL_TYPE.SUCCESS, payload, callback }
};

export const actionUpdateCandidateDetailFailed = (
    payload: AlertMessage
): UpdateCandidateDetailFailedAction => {
    return { type: UPDATE_CANDIDATE_DETAIL_TYPE.FAILED, payload }
};

