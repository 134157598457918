import './polyfills';
import './sizmekTracker';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import './index.scss';
import './images/favicon.ico';
import { Store } from 'redux';
import DirectAccessError from 'src/components/DirectAccessError';
import { clearReduxPersistOnload, isValidRedirectUrl, persistSsoCodeFromSearchParams } from 'src/utils/helper';
import { loadWAFEnv } from 'src/utils/captcha';
import { initRum } from 'src/utils/rum';
import { extractAndSaveSearchParamsFromHash } from 'src/utils/urlUtils';

// This is to bypass ts error "Property 'dataLayerArray' does not exist on type 'Window & typeof globalThis'."
// window.dataLayerArray is introduced by Adobe Analytics script.
declare global {
  interface Window {
    dataLayerArray: any; // Adobe Analytics
    acDataLayer: any; // AppCast tracker
    dataLayer: any; // Google Pixel
    reduxStoreCS: Store;
    AWS_WAF_ENV: {
      JSAPI_URL: string;
      CAPTCHA_API_KEY: string;
    };
    AwsWafCaptcha: {
      renderCaptcha: Function;
    };
  }
}

window.acDataLayer = window.acDataLayer || [];
loadWAFEnv();

clearReduxPersistOnload();
initRum();

// Get URL parameters from the hash portion (#), store them in browser session storage, and clean up the URL
extractAndSaveSearchParamsFromHash();

// When sso code is returned from redirect_url, the url cannot contain # symbol.
// We need to persist the code before redirecting to the login page
persistSsoCodeFromSearchParams();

const root = document.getElementById('root');

if (!isValidRedirectUrl()) {
  ReactDOM.render(<DirectAccessError />, root);
} else {
  ReactDOM.render(<App />, root);
}
