import { combineEpics } from "redux-observable";
import {
  getConfigEpic,
  getCountryStateConfigEpic,
}from "./getConfig.epic";
import {
  getCandidateEpic,
  getCandidateSuccessEpic,
} from "./getCandidate.epic";
import {
  createCandidateEpic,
  createCandidateSuccessEpic,
} from "./createCandidate.epic";
import {
  verifyEmailEpic,
  verifyEmailSuccessEpic,
} from "./verifyEmail.epic";
import {
  verifyPhoneEpic,
  verifyPhoneSuccessEpic,
} from "./verfyPhone.epic";
import {
  signInEpic,
  signInSuccessEpic,
} from "./signIn.epic";
import {
  verifySignInEpic
} from "./verifySignIn.epic";
import {
  signOutEpic,
  signOutSuccessEpic,
} from "./signOut.epic";
import {
  confirmOtpEpic,
  confirmOtpSuccessEpic,
} from "./confirmOtp.epic";
import {
  forgotPinEpic,
  forgotPinSuccessEpic,
} from "./forgotPin.epic";
import {
  resetPinEpic,
  resetPinSuccessEpic,
} from "./resetPin.epic";
import {
  updatePhoneEpic,
  updatePhoneSuccessEpic,
} from "./updatePhone.epic";
import { getCSRFEpic } from "src/epics/getCSRF.epic";
import { webLabEpic } from "src/epics/webLab.epic";
import { getCandidateDetailEpic, updateCandidateDetailEpic } from "src/epics/candidateDetail.epic";
import { getCommunicationPreferenceEpic, updateCommunicationPreferenceEpic } from "./communicationPreference.epic";

const rootEpic = combineEpics(
    getConfigEpic,
    getCandidateEpic,
    getCandidateSuccessEpic,
    createCandidateEpic,
    createCandidateSuccessEpic,
    verifyEmailEpic,
    verifyEmailSuccessEpic,
    verifyPhoneEpic,
    verifyPhoneSuccessEpic,
    verifySignInEpic,
    signInEpic,
    signInSuccessEpic,
    signOutEpic,
    signOutSuccessEpic,
    confirmOtpEpic,
    confirmOtpSuccessEpic,
    forgotPinEpic,
    forgotPinSuccessEpic,
    resetPinEpic,
    resetPinSuccessEpic,
    updatePhoneEpic,
    updatePhoneSuccessEpic,
    getCSRFEpic,
    webLabEpic,
    getCandidateDetailEpic,
    updateCandidateDetailEpic,
    getCommunicationPreferenceEpic,
    updateCommunicationPreferenceEpic,
    getCountryStateConfigEpic
);

export default rootEpic;
