export interface WebLabConfig {
  id: string;
  treatment: WebLabTreatment;
  features: string[];
}

export type WebLabConfigs = WebLabConfig[];

export enum WebLabTreatment {
  C = 'C',
  T1 = 'T1',
  T2 = 'T2',
  T3 = 'T3',
}

export interface WebLabMetricProps {
  webLabConfigs: WebLabConfigs;
  actionType: WebLabActionType;
  jobType?: string | null;
  jobId?: string | null;
  scheduleId?: string | null;
  dataSource?: string | null;
}

export enum WebLabActionType {
  APPLY = 'Apply',
  VIEW = 'View',
}

export interface WebLabMetricRequest extends WebLabMetricProps {
  sessionId: string | null;
  timestamp: number;
  stage: string;
}
