import { from, Observable, of } from "rxjs";
import { ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/internal/operators";
import { getCandidateDetail, updateCandidateDetail} from "src/utils/apis/appSyncAPI/appSyncApi";
import { GetCandidateDetailResponseGQL } from "src/utils/apis/appSyncAPI/types";
import {
    AppSyncCandidateDetailMessages
} from "src/utils/errorMessages";
import { createAppSyncEpicError } from "src/utils/helper";
import { AppSyncEpicError } from "src/utils/commonTypes";
import { GetCandidateDetailAction, GET_CANDIDATE_DETAIL_TYPE, UpdateCandidateDetailAction, UPDATE_CANDIDATE_DETAIL_TYPE } from "src/actions/CandidateDetailActions/candidateDetailActionTypes";
import { actionGetCandidateDetailFailed, actionGetCandidateDetailSuccess, actionUpdateCandidateDetailFailed, actionUpdateCandidateDetailSuccess } from "src/actions/CandidateDetailActions/candidateDetailActions";
import { initAdditionalBackgroundInfo, initAddress } from "src/reducers/candidateDetail.reducer";
import { getCountryFullName } from "src/utils/apis/apiHelper";

export const getCandidateDetailEpic = (action$: Observable<any>) => {
    return action$.pipe(
        ofType(GET_CANDIDATE_DETAIL_TYPE.GET),
        switchMap((action: GetCandidateDetailAction) =>
            from(getCandidateDetail({ bbCandidateId: action.payload.bbCandidateId, accessToken: action.payload.accessToken}))
                .pipe(
                    switchMap(async (response) => {
                        if(!response.data) throw createAppSyncEpicError('ERR_RESPONSE_SERVICE');
                        return response.data
                    }),
                    map((data: GetCandidateDetailResponseGQL) => {
                        const candidateDetail = data.queryCandidate;
                        if(!candidateDetail.additionalBackgroundInfo){
                            candidateDetail.additionalBackgroundInfo = initAdditionalBackgroundInfo;
                        } else if(candidateDetail.additionalBackgroundInfo && !candidateDetail.additionalBackgroundInfo.address) {
                            candidateDetail.additionalBackgroundInfo.address = initAddress
                        }
                        candidateDetail.additionalBackgroundInfo.address.country = candidateDetail.additionalBackgroundInfo.address.country || getCountryFullName();
                        candidateDetail.phoneNumber = candidateDetail.phoneNumber.replace(candidateDetail.phoneCountryCode, "");    
                        if (action.onSuccess) action.onSuccess();                   
                        return actionGetCandidateDetailSuccess(candidateDetail);
                    }),
                    catchError((error: AppSyncEpicError) => {
                        if (action.onError) action.onError();
                        const errorCode = error.errors ? error.errors[0].message : 'DEFAULT';
                        return of(actionGetCandidateDetailFailed({
                            errorMessage: AppSyncCandidateDetailMessages[errorCode] || AppSyncCandidateDetailMessages.DEFAULT
                        }));
                    })
                )
        )
    )
};

export const updateCandidateDetailEpic = (action$: Observable<any>) => {
    return action$.pipe(
        ofType(UPDATE_CANDIDATE_DETAIL_TYPE.UPDATE),
        switchMap((action: UpdateCandidateDetailAction) =>
            from(updateCandidateDetail({...action.payload}))
                .pipe(
                    switchMap(async (response) => {
                        if(!response.data) throw createAppSyncEpicError('ERR_RESPONSE_SERVICE');
                        return response.data
                    }),
                    map(() => {
                        if (action.onSuccess) action.onSuccess();

                        const newCandidateDetail = action.payload.newCandidateDetail;
                        return actionUpdateCandidateDetailSuccess(newCandidateDetail);
                    }),
                    catchError((error: AppSyncEpicError) => {
                        if (action.onError) action.onError();
                        const errorCode = error.errors ? error.errors[0].message : 'DEFAULT';
                        return of(actionUpdateCandidateDetailFailed({
                            errorMessage: AppSyncCandidateDetailMessages[errorCode] || AppSyncCandidateDetailMessages.DEFAULT
                        }));
                    })
                )
        )
    )
};
