import { from, Observable, of } from 'rxjs';
import { flatMap, map, catchError } from 'rxjs/internal/operators';
import { ofType } from 'redux-observable';
import { forgotPinApi } from '../utils/apis/serviceApi';
import { parseErrorMessage } from '../utils/helper';
import { actionForgotPinSuccess } from '../actions/actions';
import { FORGOT_PIN, FORGOT_PIN_SUCCESS, FORGOT_PIN_FAILED, FORGOT_PIN_CALLBACK_SUCCESS } from '../actions/actionTypes';

export const forgotPinEpic = (action$: Observable<any>) => {
  return action$.pipe(
    ofType(FORGOT_PIN),
    flatMap((action: any) =>
      from<Promise<any>>(forgotPinApi(action.payload)).pipe(
        flatMap(async (response) => {
          return response.data;
        }),
        map((x) => actionForgotPinSuccess(x, action.callback)),
        catchError((error) => {
          const { response } = error;
          if (action.onError) {
            action.onError(response);
          }
          return of({
            type: FORGOT_PIN_FAILED,
            payload: parseErrorMessage(response.data),
          });
        }),
      ),
    ),
  );
};

export const forgotPinSuccessEpic = (action$: Observable<any>) => {
  return action$.pipe(
    ofType(FORGOT_PIN_SUCCESS),
    map((action) => {
      if (action.callback) {
        action.callback(action.payload);
      }
      return { type: FORGOT_PIN_CALLBACK_SUCCESS, payload: null };
    }),
  );
};
