
export enum CommonColors {
    White = "#FFFFFF",
    AthensGray = "#E8EAEE",
    Blue80 = "#1659a9",
    Blue70 = "#1768C9",
    Blue20 = "#AFD7F7",
    Blue10 = "#D7EBFB",
    Blue05 = "#EBF5FD",
    Neutral100 = "#161E2D",
    Neutral90 = "#232f3e",
    Neutral70 = "#56616C",
    Neutral50 = "#8B96A3",
    Neutral30 = "#B9C0C8",
    Neutral20 = "#D1D5DA",
    Neutral10 = "#E8EAEE",
    Neutral05 = "#F1F4F7",
    Neutral0 = "#ffffff",
    OldPrimary70 = "#007486",
    OldNeutral70 = "#5c7274",
    OldNeutral50 = "#799294",
    OldPrimaryTint = '#EEF5F6',
    Red70 = '#bb2929',
    Yellow05 = "#fff9e6"
}

export enum HVHColors {
    NavBackGround = "#232F3E",
    NavBackGround2021 = "#FFFFFF",
    MainContentBackground = "#F1F4F7",
    SidePanelHeaderBackground = "#0379AD",
    SidePanelHeaderBackground2021 = "#1768c9",
    White = "#FFFFFF",
    BlueLagoon = "#007486",
    AlertFillYellow = "#CC7A00"
}
