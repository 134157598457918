import { getCountryFullName } from 'src/utils/apis/apiHelper';
import {
  CountryFullName,
  Language,
  Locale,
  PreferredLanguagesList,
  PreferredLanguagesMap,
  PrivacyNotice,
} from 'src/utils/commonTypes';
import { EUPhoneCodes } from 'src/utils/phoneCodes';
import { RegistrationError, RegistrationRegexError, RumEventType, StringMatchError } from '../constants';

export const TimeZonesUK = [
  { key: 'Constants-timezone-uk-europe-london', value: '(GMT+00:00) Greenwich Mean Time (Europe/London)' },
];

export const TimeZonesUS = [
  {
    key: 'Constants-timezone-us-america-indiana-indianapolis',
    value: '(GMT-05:00) Eastern Standard Time (America/Indiana/Indianapolis)',
  },
  { key: 'Constants-timezone-us-america-newyork', value: '(GMT-05:00) Eastern Standard Time (America/New_York)' },
  { key: 'Constants-timezone-us-america-chicago', value: '(GMT-06:00) Central Standard Time (America/Chicago)' },
  { key: 'Constants-timezone-us-america-denver', value: '(GMT-07:00) Mountain Standard Time (America/Denver)' },
  { key: 'Constants-timezone-us-america-phoenix', value: '(GMT-07:00) Mountain Standard Time (America/Phoenix)' },
  { key: 'Constants-timezone-us-america-losangeles', value: '(GMT-08:00) Pacific Standard Time (America/Los_Angeles)' },
  { key: 'Constants-timezone-us-america-anchorage', value: '(GMT-09:00) Alaska Standard Time (America/Anchorage)' },
  { key: 'Constants-timezone-us-america-adak', value: '(GMT-10:00) Hawaii-Aleutian Standard Time (America/Adak)' },
  {
    key: 'Constants-timezone-us-pacific-honolulu',
    value: '(GMT-10:00) Hawaii-Aleutian Standard Time (Pacific/Honolulu)',
  },
];

export const TimeZonesCA = [
  { key: 'Constants-timezone-us-america-stjohns', value: '(GMT-03:30) Newfoundland Standard Time (America/St_Johns)' },
  { key: 'Constants-timezone-us-america-halifax', value: '(GMT-04:00) Atlantic Standard Time (America/Halifax)' },
  { key: 'Constants-timezone-us-america-newyork', value: '(GMT-05:00) Eastern Standard Time (America/New_York)' },
  { key: 'Constants-timezone-us-america-chicago', value: '(GMT-06:00) Central Standard Time (America/Chicago)' },
  { key: 'Constants-timezone-us-america-denver', value: '(GMT-07:00) Mountain Standard Time (America/Denver)' },
  { key: 'Constants-timezone-us-america-losangeles', value: '(GMT-08:00) Pacific Standard Time (America/Los_Angeles)' },
];

export const TimeZonesMX = [
  {
    key: 'Constants-timezone-us-america-mexico-city',
    value: '(GMT-06:00) Central Standard Time (America/Mexico_City)',
  },
  { key: 'Constants-timezone-us-america-tijuana', value: '(GMT-08:00) Pacific Standard Time (America/Tijuana)' },
];

export const TimeZonesJP = [
  { key: 'Constants-timezone-jp-asia-tokyo', value: '(GMT+09:00) Japan Standard Time (Asia/Tokyo)' },
];

export const TimeZonesCO = [
  { key: 'Constants-timezone-co-america-bogota', value: '(GMT-05:00) Colombia Time (America/Bogota)' },
];

export const TimeZonesCR = [
  { key: 'Constants-timezone-us-america-chicago', value: '(GMT-06:00) Central Standard Time (America/Chicago)' },
  { key: 'Constants-timezone-us-america-elsalvador', value: '(GMT-06:00) Central Standard Time (America/El_Salvador)' },
  { key: 'Constants-timezone-us-america-mexicocity', value: '(GMT-06:00) Central Standard Time (America/Mexico_City)' },
];
export const TimeZonesBR = [
  { key: 'Constants-timezone-us-america-saopaulo', value: '(GMT-02:00) Brasilia Summer Time (America/Sao_Paulo)' },
];

export const TimeZonesEG = [
  { key: 'Constants-timezone-dz-africa-algiers', value: '(GMT+01:00) Central European Time (Africa/Algiers)' },
  { key: 'Constants-timezone-eg-africa-cairo', value: '(GMT+02:00) Eastern European Time (Africa/Cairo)' },
  { key: 'Constants-timezone-sa-asia-riyadh', value: '(GMT+03:00) Arabian Standard Time (Asia/Riyadh)' },
];

export const TimeZonesJO = [
  { key: 'Constants-timezone-eg-africa-cairo', value: '(GMT+02:00) Eastern European Time (Africa/Cairo)' },
  { key: 'Constants-timezone-il-asia-jerusalem', value: '(GMT+02:00) Israel Standard Time (Asia/Jerusalem)' },
  { key: 'Constants-timezone-sa-asia-riyadh', value: '(GMT+03:00) Arabian Standard Time (Asia/Riyadh)' },
];
//TODO: add timezones for SA and AE

const euPhoneCodes = EUPhoneCodes.map((phone) => phone.dial_code);
export const PhoneCodesUK = euPhoneCodes;

export const PhoneCodesUS = ['+1'];

export const PhoneCodesMX = ['+52'];

export const PhoneCodesJP = ['+81'];

export const PhoneCodesIN = ['+91'];

export const PhoneCodesBR = ['+55'];

export const PhoneCodesCR = ['+506'];

export const PhoneCodesCO = ['+57'];

export const PhoneCodesEG = ['+20'];

export const PhoneCodesJO = ['+962'];

export const PreferredLanguagesUS: PreferredLanguagesList = [
  { key: 'Constants-language-us-english', value: Language.US, locale: Locale.US },
  { key: 'Constants-language-us-spanish', value: Language.ES, locale: Locale.ES_US },
];

export const PreferredLanguagesCA: PreferredLanguagesList = [
  { key: 'Constants-language-us-english', value: Language.US, locale: Locale.EN_CA },
  { key: 'Constants-language-ca-french', value: Language.FR_CA, locale: Locale.FR_CA },
];

export const PreferredLanguagesUK: PreferredLanguagesList = [
  { key: 'Constants-language-uk-english', value: Language.UK, locale: Locale.UK },
];

export const PreferredLanguagesMX: PreferredLanguagesList = [
  { key: 'Constants-language-mx-spanish', value: Language.MX, locale: Locale.ES_MX },
];

export const PreferredLanguagesJP: PreferredLanguagesList = [
  { key: 'Constants-language-jp-japanese', value: Language.JP, locale: Locale.JP },
];

export const PreferredLanguagesCO: PreferredLanguagesList = [
  { key: 'Constants-language-co-spanish', value: Language.CO, locale: Locale.CO },
  { key: 'Constants-language-br-portuguese', value: Language.BR, locale: Locale.PT_CO },
  { key: 'Constants-language-us-english', value: Language.US, locale: Locale.EN_CO },
];

export const PreferredLanguagesCR: PreferredLanguagesList = [
  { key: 'Constants-language-cr-spanish', value: Language.CR, locale: Locale.CR },
  { key: 'Constants-language-us-english', value: Language.US, locale: Locale.EN_CR },
];

export const PreferredLanguagesBR: PreferredLanguagesList = [
  { key: 'Constants-language-br-portuguese', value: Language.BR, locale: Locale.BR },
];

export const PreferredLanguagesEG: PreferredLanguagesList = [
  { key: 'Constants-language-eg-english', value: Language.EG, locale: Locale.EG },
  // TODO: Enable when Arabic is ready to launch
  // { key: "Constants-language-eg-arabic", value: Language.AR, locale: Locale.AR_EG },
];

export const PreferredLanguagesJO: PreferredLanguagesList = [
  { key: 'Constants-language-jo-english', value: Language.JO, locale: Locale.JO },
];

export const PreferredLanguagesSA: PreferredLanguagesList = [
  { key: 'Constants-language-sa-english', value: Language.SA, locale: Locale.SA },
  { key: 'Constants-language-sa-arabic', value: Language.AR, locale: Locale.AR_SA },
];

export const PreferredLanguagesAE: PreferredLanguagesList = [
  { key: 'Constants-language-ae-english', value: Language.AE, locale: Locale.AE },
  { key: 'Constants-language-ae-arabic', value: Language.AR, locale: Locale.AR_AE },
];

export const PhoneCodes: { [countryCode: string]: string[] } = {
  // We treat countryFullName = 'development' as US.
  [CountryFullName.DEVO]: PhoneCodesUS,
  [CountryFullName.UK]: PhoneCodesUK,
  [CountryFullName.US]: PhoneCodesUS,
  [CountryFullName.CA]: PhoneCodesUS,
  [CountryFullName.MX]: PhoneCodesMX,
  [CountryFullName.JP]: PhoneCodesJP,
  [CountryFullName.CO]: PhoneCodesCO,
  [CountryFullName.CR]: PhoneCodesCR,
  [CountryFullName.BR]: PhoneCodesBR,
  [CountryFullName.EG]: PhoneCodesEG,
  [CountryFullName.JO]: PhoneCodesJO,
  [CountryFullName.SA]: PhoneCodesEG, //TODO: Update PhoneCodes when available
  [CountryFullName.AE]: PhoneCodesEG, //TODO: Update PhoneCodes when available
};

export const TimeZones: { [countryCode: string]: { key: string; value: string }[] } = {
  // We treat countryFullName = 'development' as US.
  [CountryFullName.DEVO]: TimeZonesUS,
  [CountryFullName.UK]: TimeZonesUK,
  [CountryFullName.US]: TimeZonesUS,
  [CountryFullName.CA]: TimeZonesCA,
  [CountryFullName.MX]: TimeZonesMX,
  [CountryFullName.JP]: TimeZonesJP,
  [CountryFullName.CO]: TimeZonesCO,
  [CountryFullName.CR]: TimeZonesCR,
  [CountryFullName.BR]: TimeZonesBR,
  [CountryFullName.EG]: TimeZonesEG,
  [CountryFullName.JO]: TimeZonesJO,
  [CountryFullName.SA]: TimeZonesEG, //TODO: Update TimeZones when available
  [CountryFullName.AE]: TimeZonesEG, //TODO: Update TimeZones when available
};

export const PreferredLanguages: PreferredLanguagesMap = {
  [CountryFullName.DEVO]: PreferredLanguagesUK,
  [CountryFullName.UK]: PreferredLanguagesUK,
  [CountryFullName.US]: PreferredLanguagesUS,
  [CountryFullName.CA]: PreferredLanguagesCA,
  [CountryFullName.MX]: PreferredLanguagesMX,
  [CountryFullName.JP]: PreferredLanguagesJP,
  [CountryFullName.CR]: PreferredLanguagesCR,
  [CountryFullName.CO]: PreferredLanguagesCO,
  [CountryFullName.BR]: PreferredLanguagesBR,
  [CountryFullName.EG]: PreferredLanguagesEG,
  [CountryFullName.JO]: PreferredLanguagesJO,
  [CountryFullName.AE]: PreferredLanguagesAE,
  [CountryFullName.SA]: PreferredLanguagesSA,
};

export const TimeZonesByCountry = TimeZones[getCountryFullName()]
  ? TimeZones[getCountryFullName()]
  : TimeZones[CountryFullName.UK];

export const PhoneCodesByCountry = PhoneCodes[getCountryFullName()]
  ? PhoneCodes[getCountryFullName()]
  : PhoneCodes[CountryFullName.UK];

export const SuffixOptions: any = ['None', 'Jr', 'Sr', 'II', 'III', 'IV', 'V'];

export const PrivacyNoticeEU: PrivacyNotice = {
  key: 'Constants-Privacy-Notice-label-eu',
  text: 'UK/EU Candidate Privacy Notice',
  urlLink: 'https://www.amazon.jobs/en/privacy_page?region_id=region_eu',
};

export const PrivacyNoticeAsiaPacific: PrivacyNotice = {
  key: 'Constants-Privacy-Notice-label-ap',
  text: 'Asia Pacific (APAC) Candidate Privacy Notice',
  urlLink: 'https://www.amazon.jobs/en/privacy_page?region_id=region_asia',
};

export const PrivacyNoticeAsiaJapan: PrivacyNotice = {
  key: 'JPConsentComponent-link-privacy-notice',
  text: 'Candidate Privacy Notice',
  urlLink: 'https://amazon.jobs/jp/privacy_page?region_id=region_jp&language_id=language_jp',
};

export const PrivacyNoticeBrazil: PrivacyNotice = {
  key: 'Constants-Privacy-Notice-label-brazil',
  text: 'Amazon Brazil Employment Candidate Privacy Notice',
  urlLink: 'https://trabalho.amazon.com.br/aviso-de-privacidade',
};

export const PrivacyNoticeGlobal: PrivacyNotice = {
  key: 'Constants-Privacy-Notice-label-global',
  text: 'Global Candidate Privacy Notice',
  urlLink: 'https://www.amazon.jobs/en/privacy_page?region_id=region_global',
};

export const PrivacyNoticeMexico: PrivacyNotice = {
  key: 'Constants-Privacy-Notice-label-mexico',
  text: 'Aviso de Privacidad',
  urlLink: 'https://trabaja.amazon.com.mx/aviso-de-privacidad#/',
};

export const PrivacyNoticeEgypt: PrivacyNotice = {
  key: 'Constants-Privacy-Notice-label-global',
  text: 'Global Candidate Privacy Notice',
  urlLink: 'http://jobs.amazon.eg/privacy-notice',
};

export const PrivacyNoticeJordan: PrivacyNotice = {
  key: 'Constants-Privacy-Notice-label-global',
  text: 'Global Candidate Privacy Notice',
  urlLink: 'http://jobs.amazon.jo/privacy-notice',
};

export const PrivacyNoticeMap: { [countryCode: string]: PrivacyNotice } = {
  [CountryFullName.DEVO]: PrivacyNoticeGlobal,
  [CountryFullName.UK]: PrivacyNoticeEU,
  [CountryFullName.JP]: PrivacyNoticeAsiaJapan,
  [CountryFullName.BR]: PrivacyNoticeBrazil,
  [CountryFullName.MX]: PrivacyNoticeMexico,
  [CountryFullName.EG]: PrivacyNoticeEgypt,
  [CountryFullName.JO]: PrivacyNoticeJordan,
};

export const PrivacyNoticeByCountry = PrivacyNoticeMap[getCountryFullName()]
  ? PrivacyNoticeMap[getCountryFullName()]
  : PrivacyNoticeGlobal;
export const ContactInformationFooterComponent = 'ContactInformationFooterComponent';

export enum REGISTRATION_FIELDS {
  FIRST_NAME = 'firstName',
  MIDDLE_NAME = 'middleName',
  LAST_NAME = 'lastName',
  SUFFIX = 'nameSuffix',
  PREFERRED_FIRST_NAME = 'preferredFirstName',
  PREFERRED_LAST_NAME = 'preferredLastName',
  English_FIRST_NAME = 'englishFirstName',
  English_LAST_NAME = 'englishLastName',
  EMAIL = 'emailId',
  RE_ENTER_EMAIL = 'reEnterEmailId',
  PHONE_NUMBER = 'phoneNumber',
  RE_ENETER_PHONE_NUMBER = 'reEnterPhoneNumber',
  PREFERRED_LANGUAGE = 'language',
  PREFERRED_TIMEZONE = 'timezone',
  PIN = 'pin',
  RE_ENTER_PIN = 'reEnterPin',
}

type StateSelectOption = {
  displayValue: string;
  value: string;
};

const MXStatesSelectOptions: StateSelectOption[] = [
  { displayValue: 'Aguascalientes', value: 'Aguascalientes' },
  { displayValue: 'Baja California', value: 'Baja California Norte' },
  { displayValue: 'Baja California Sur', value: 'Baja California Sur' },
  { displayValue: 'Campeche', value: 'Campeche' },
  { displayValue: 'Chiapas', value: 'Chiapas' },
  { displayValue: 'Chihuahua', value: 'Chihuahua' },
  { displayValue: 'Coahuila', value: 'Coahuila' },
  { displayValue: 'Colima', value: 'Colima' },
  { displayValue: 'Ciudad de México', value: 'Federal District' },
  { displayValue: 'Durango', value: 'Durango' },
  { displayValue: 'Estado de México', value: 'Estado de Mexico' },
  { displayValue: 'Guanajuato', value: 'Guanajuato' },
  { displayValue: 'Guerrero', value: 'Guerrero' },
  { displayValue: 'Hidalgo', value: 'Hidalgo' },
  { displayValue: 'Jalisco', value: 'Jalisco' },
  { displayValue: 'Michoacán', value: 'Michoacan' },
  { displayValue: 'Morelos', value: 'Morelos' },
  { displayValue: 'Nayarit', value: 'Nayarit' },
  { displayValue: 'Nuevo León', value: 'Nuevo León' },
  { displayValue: 'Oaxaca', value: 'Oaxaca' },
  { displayValue: 'Puebla', value: 'Puebla' },
  { displayValue: 'Querétaro', value: 'Queretaro' },
  { displayValue: 'Quintana Roo', value: 'Quintana Roo' },
  { displayValue: 'San Luis Potosí', value: 'San Luis Potosí' },
  { displayValue: 'Sinaloa', value: 'Sinaloa' },
  { displayValue: 'Sonora', value: 'Sonora' },
  { displayValue: 'Tabasco', value: 'Tabasco' },
  { displayValue: 'Tamaulipas', value: 'Tamaulipas' },
  { displayValue: 'Tlaxcala', value: 'Tlaxcala' },
  { displayValue: 'Veracruz', value: 'Veracruz' },
  { displayValue: 'Yucatán', value: 'Yucatan' },
  { displayValue: 'Zacatecas', value: 'Zacatecas' },
];

// For Country not specified here, will fetch https://m.media-amazon.com/images/G/01/HVH-Kirin/config/US/state-country-list.json during runtime
export const StatesSelectOptions: { [CountryFullName: string]: StateSelectOption[] } = {
  [CountryFullName.MX]: MXStatesSelectOptions,
};

export const RegistrationFieldCustomPlaceholderValueMap = new Map<CountryFullName, Map<REGISTRATION_FIELDS, string>>([
  [
    CountryFullName.UK,
    new Map<REGISTRATION_FIELDS, string>([
      [REGISTRATION_FIELDS.FIRST_NAME, 'First name'],
      [REGISTRATION_FIELDS.LAST_NAME, 'Last name'],
      [REGISTRATION_FIELDS.PREFERRED_FIRST_NAME, 'If provided, this is displayed on your badge'],
      [REGISTRATION_FIELDS.PREFERRED_LAST_NAME, 'If provided, this is displayed on your badge'],
    ]),
  ],
  [
    CountryFullName.US,
    new Map<REGISTRATION_FIELDS, string>([
      [REGISTRATION_FIELDS.FIRST_NAME, 'First name'],
      [REGISTRATION_FIELDS.MIDDLE_NAME, 'Middle name'],
      [REGISTRATION_FIELDS.LAST_NAME, 'Last name'],
      [REGISTRATION_FIELDS.PREFERRED_FIRST_NAME, 'If provided, this is displayed on your badge'],
      [REGISTRATION_FIELDS.PREFERRED_LAST_NAME, 'If provided, this is displayed on your badge'],
      [REGISTRATION_FIELDS.SUFFIX, 'Suffix'],
    ]),
  ],
  [
    CountryFullName.CA,
    new Map<REGISTRATION_FIELDS, string>([
      [REGISTRATION_FIELDS.FIRST_NAME, 'First name'],
      [REGISTRATION_FIELDS.LAST_NAME, 'Last name'],
      [REGISTRATION_FIELDS.PREFERRED_FIRST_NAME, 'If provided, this is displayed on your badge'],
      [REGISTRATION_FIELDS.PREFERRED_LAST_NAME, 'If provided, this is displayed on your badge'],
    ]),
  ],
  [
    CountryFullName.MX,
    new Map<REGISTRATION_FIELDS, string>([
      [REGISTRATION_FIELDS.FIRST_NAME, 'Ex: Maddy Michelle'],
      [REGISTRATION_FIELDS.LAST_NAME, 'Ex: Gonzales Reiman'],
      [REGISTRATION_FIELDS.PREFERRED_FIRST_NAME, 'If provided, this is displayed on your badge'],
      [REGISTRATION_FIELDS.PREFERRED_LAST_NAME, 'If provided, this is displayed on your badge'],
    ]),
  ],
  [
    CountryFullName.JP,
    new Map<REGISTRATION_FIELDS, string>([
      [REGISTRATION_FIELDS.FIRST_NAME, 'First name'],
      [REGISTRATION_FIELDS.LAST_NAME, 'Last name'],
      [REGISTRATION_FIELDS.PREFERRED_FIRST_NAME, 'If provided, this is displayed on your badge'],
      [REGISTRATION_FIELDS.PREFERRED_LAST_NAME, 'If provided, this is displayed on your badge'],
    ]),
  ],
  [
    CountryFullName.CO,
    new Map<REGISTRATION_FIELDS, string>([
      [REGISTRATION_FIELDS.FIRST_NAME, 'First name'],
      [REGISTRATION_FIELDS.LAST_NAME, 'Last name'],
      [REGISTRATION_FIELDS.PREFERRED_FIRST_NAME, 'If provided, this is displayed on your badge'],
      [REGISTRATION_FIELDS.PREFERRED_LAST_NAME, 'If provided, this is displayed on your badge'],
    ]),
  ],
  [
    CountryFullName.CR,
    new Map<REGISTRATION_FIELDS, string>([
      [REGISTRATION_FIELDS.FIRST_NAME, 'First name'],
      [REGISTRATION_FIELDS.LAST_NAME, 'Last name'],
      [REGISTRATION_FIELDS.PREFERRED_FIRST_NAME, 'If provided, this is displayed on your badge'],
      [REGISTRATION_FIELDS.PREFERRED_LAST_NAME, 'If provided, this is displayed on your badge'],
    ]),
  ],
  [
    CountryFullName.BR,
    new Map<REGISTRATION_FIELDS, string>([
      [REGISTRATION_FIELDS.FIRST_NAME, 'First name'],
      [REGISTRATION_FIELDS.LAST_NAME, 'Last name'],
      [REGISTRATION_FIELDS.PREFERRED_FIRST_NAME, 'If provided, this is displayed on your badge'],
      [REGISTRATION_FIELDS.PREFERRED_LAST_NAME, 'If provided, this is displayed on your badge'],
    ]),
  ],
  [
    CountryFullName.EG,
    new Map<REGISTRATION_FIELDS, string>([
      [REGISTRATION_FIELDS.FIRST_NAME, 'First name'],
      [REGISTRATION_FIELDS.LAST_NAME, 'Last name'],
      [REGISTRATION_FIELDS.PREFERRED_FIRST_NAME, 'If provided, this is displayed on your badge'],
      [REGISTRATION_FIELDS.PREFERRED_LAST_NAME, 'If provided, this is displayed on your badge'],
    ]),
  ],
  [
    CountryFullName.JO,
    new Map<REGISTRATION_FIELDS, string>([
      [REGISTRATION_FIELDS.FIRST_NAME, 'First name'],
      [REGISTRATION_FIELDS.LAST_NAME, 'Last name'],
      [REGISTRATION_FIELDS.PREFERRED_FIRST_NAME, 'If provided, this is displayed on your badge'],
      [REGISTRATION_FIELDS.PREFERRED_LAST_NAME, 'If provided, this is displayed on your badge'],
    ]),
  ],
  [
    CountryFullName.SA,
    new Map<REGISTRATION_FIELDS, string>([
      [REGISTRATION_FIELDS.FIRST_NAME, 'First name'],
      [REGISTRATION_FIELDS.LAST_NAME, 'Last name'],
      [REGISTRATION_FIELDS.MIDDLE_NAME, 'Middle name'],
      [REGISTRATION_FIELDS.PREFERRED_FIRST_NAME, 'If provided, this is displayed on your badge'],
      [REGISTRATION_FIELDS.PREFERRED_LAST_NAME, 'If provided, this is displayed on your badge'],
    ]),
  ],
  [
    CountryFullName.AE,
    new Map<REGISTRATION_FIELDS, string>([
      [REGISTRATION_FIELDS.FIRST_NAME, 'First name'],
      [REGISTRATION_FIELDS.LAST_NAME, 'Last name'],
      [REGISTRATION_FIELDS.MIDDLE_NAME, 'Middle name'],
      [REGISTRATION_FIELDS.PREFERRED_FIRST_NAME, 'If provided, this is displayed on your badge'],
      [REGISTRATION_FIELDS.PREFERRED_LAST_NAME, 'If provided, this is displayed on your badge'],
    ]),
  ],
  [
    CountryFullName.DEVO,
    new Map<REGISTRATION_FIELDS, string>([
      [REGISTRATION_FIELDS.FIRST_NAME, 'First name'],
      [REGISTRATION_FIELDS.LAST_NAME, 'Last name'],
      [REGISTRATION_FIELDS.PREFERRED_FIRST_NAME, 'If provided, this is displayed on your badge'],
      [REGISTRATION_FIELDS.PREFERRED_LAST_NAME, 'If provided, this is displayed on your badge'],
    ]),
  ],
]);

export const RegistrationFieldDefaultPlaceholderValueMap = new Map<REGISTRATION_FIELDS, string>([
  [REGISTRATION_FIELDS.FIRST_NAME, 'This is what appears on your ID'],
  [REGISTRATION_FIELDS.LAST_NAME, 'This is what appears on your ID'],
  [REGISTRATION_FIELDS.PREFERRED_LANGUAGE, 'Select preferred language'],
  [REGISTRATION_FIELDS.PREFERRED_TIMEZONE, 'Select preferred timezone'],
]);

export const RegistrationFieldCustomPlaceholderKeyMap = new Map<CountryFullName, Map<REGISTRATION_FIELDS, string>>([
  [
    CountryFullName.UK,
    new Map<REGISTRATION_FIELDS, string>([
      [REGISTRATION_FIELDS.FIRST_NAME, 'Constants-placeholder-first-name'],
      [REGISTRATION_FIELDS.LAST_NAME, 'Constants-placeholder-last-name'],
      [REGISTRATION_FIELDS.PREFERRED_FIRST_NAME, 'Constants-placeholder-preferred-firstname'],
      [REGISTRATION_FIELDS.PREFERRED_LAST_NAME, 'Constants-placeholder-preferred-lastname'],
    ]),
  ],
  [
    CountryFullName.US,
    new Map<REGISTRATION_FIELDS, string>([
      [REGISTRATION_FIELDS.FIRST_NAME, 'Constants-placeholder-first-name'],
      [REGISTRATION_FIELDS.MIDDLE_NAME, 'Constants-placeholder-middle-name'],
      [REGISTRATION_FIELDS.LAST_NAME, 'Constants-placeholder-last-name'],
      [REGISTRATION_FIELDS.PREFERRED_FIRST_NAME, 'Constants-placeholder-preferred-firstname'],
      [REGISTRATION_FIELDS.PREFERRED_LAST_NAME, 'Constants-placeholder-preferred-lastname'],
      [REGISTRATION_FIELDS.SUFFIX, 'Constants-placeholder-suffix'],
    ]),
  ],
  [
    CountryFullName.CA,
    new Map<REGISTRATION_FIELDS, string>([
      [REGISTRATION_FIELDS.FIRST_NAME, 'Constants-placeholder-first-name'],
      [REGISTRATION_FIELDS.LAST_NAME, 'Constants-placeholder-last-name'],
      [REGISTRATION_FIELDS.PREFERRED_FIRST_NAME, 'Constants-placeholder-preferred-firstname'],
      [REGISTRATION_FIELDS.PREFERRED_LAST_NAME, 'Constants-placeholder-preferred-lastname'],
    ]),
  ],
  [
    CountryFullName.MX,
    new Map<REGISTRATION_FIELDS, string>([
      [REGISTRATION_FIELDS.FIRST_NAME, 'Constants-placeholder-mx-first-name'],
      [REGISTRATION_FIELDS.LAST_NAME, 'Constants-placeholder-mx-last-name'],
      [REGISTRATION_FIELDS.PREFERRED_FIRST_NAME, 'Constants-placeholder-preferred-firstname'],
      [REGISTRATION_FIELDS.PREFERRED_LAST_NAME, 'Constants-placeholder-preferred-lastname'],
    ]),
  ],
  [
    CountryFullName.JP,
    new Map<REGISTRATION_FIELDS, string>([
      [REGISTRATION_FIELDS.FIRST_NAME, 'Constants-placeholder-first-name'],
      [REGISTRATION_FIELDS.LAST_NAME, 'Constants-placeholder-last-name'],
      [REGISTRATION_FIELDS.PREFERRED_FIRST_NAME, 'Constants-placeholder-preferred-firstname'],
      [REGISTRATION_FIELDS.PREFERRED_LAST_NAME, 'Constants-placeholder-preferred-lastname'],
    ]),
  ],
  [
    CountryFullName.CO,
    new Map<REGISTRATION_FIELDS, string>([
      [REGISTRATION_FIELDS.FIRST_NAME, 'Constants-placeholder-first-name'],
      [REGISTRATION_FIELDS.LAST_NAME, 'Constants-placeholder-last-name'],
      [REGISTRATION_FIELDS.PREFERRED_FIRST_NAME, 'Constants-placeholder-preferred-firstname'],
      [REGISTRATION_FIELDS.PREFERRED_LAST_NAME, 'Constants-placeholder-preferred-lastname'],
    ]),
  ],
  [
    CountryFullName.CR,
    new Map<REGISTRATION_FIELDS, string>([
      [REGISTRATION_FIELDS.FIRST_NAME, 'Constants-placeholder-first-name'],
      [REGISTRATION_FIELDS.LAST_NAME, 'Constants-placeholder-last-name'],
      [REGISTRATION_FIELDS.PREFERRED_FIRST_NAME, 'Constants-placeholder-preferred-firstname'],
      [REGISTRATION_FIELDS.PREFERRED_LAST_NAME, 'Constants-placeholder-preferred-lastname'],
    ]),
  ],
  [
    CountryFullName.BR,
    new Map<REGISTRATION_FIELDS, string>([
      [REGISTRATION_FIELDS.FIRST_NAME, 'Constants-placeholder-first-name'],
      [REGISTRATION_FIELDS.LAST_NAME, 'Constants-placeholder-last-name'],
      [REGISTRATION_FIELDS.PREFERRED_FIRST_NAME, 'Constants-placeholder-preferred-firstname'],
      [REGISTRATION_FIELDS.PREFERRED_LAST_NAME, 'Constants-placeholder-preferred-lastname'],
    ]),
  ],
  [
    CountryFullName.EG,
    new Map<REGISTRATION_FIELDS, string>([
      [REGISTRATION_FIELDS.FIRST_NAME, 'Constants-placeholder-first-name'],
      [REGISTRATION_FIELDS.LAST_NAME, 'Constants-placeholder-last-name'],
      [REGISTRATION_FIELDS.PREFERRED_FIRST_NAME, 'Constants-placeholder-preferred-firstname'],
      [REGISTRATION_FIELDS.PREFERRED_LAST_NAME, 'Constants-placeholder-preferred-lastname'],
    ]),
  ],
  [
    CountryFullName.JO,
    new Map<REGISTRATION_FIELDS, string>([
      [REGISTRATION_FIELDS.FIRST_NAME, 'Constants-placeholder-first-name'],
      [REGISTRATION_FIELDS.LAST_NAME, 'Constants-placeholder-last-name'],
      [REGISTRATION_FIELDS.PREFERRED_FIRST_NAME, 'Constants-placeholder-preferred-firstname'],
      [REGISTRATION_FIELDS.PREFERRED_LAST_NAME, 'Constants-placeholder-preferred-lastname'],
    ]),
  ],
  [
    CountryFullName.SA,
    new Map<REGISTRATION_FIELDS, string>([
      [REGISTRATION_FIELDS.FIRST_NAME, 'Constants-placeholder-first-name'],
      [REGISTRATION_FIELDS.MIDDLE_NAME, 'Constants-placeholder-middle-name'],
      [REGISTRATION_FIELDS.LAST_NAME, 'Constants-placeholder-last-name'],
      [REGISTRATION_FIELDS.PREFERRED_FIRST_NAME, 'Constants-placeholder-preferred-firstname'],
      [REGISTRATION_FIELDS.PREFERRED_LAST_NAME, 'Constants-placeholder-preferred-lastname'],
    ]),
  ],
  [
    CountryFullName.AE,
    new Map<REGISTRATION_FIELDS, string>([
      [REGISTRATION_FIELDS.FIRST_NAME, 'Constants-placeholder-first-name'],
      [REGISTRATION_FIELDS.MIDDLE_NAME, 'Constants-placeholder-middle-name'],
      [REGISTRATION_FIELDS.LAST_NAME, 'Constants-placeholder-last-name'],
      [REGISTRATION_FIELDS.PREFERRED_FIRST_NAME, 'Constants-placeholder-preferred-firstname'],
      [REGISTRATION_FIELDS.PREFERRED_LAST_NAME, 'Constants-placeholder-preferred-lastname'],
    ]),
  ],
  [
    CountryFullName.DEVO,
    new Map<REGISTRATION_FIELDS, string>([
      [REGISTRATION_FIELDS.FIRST_NAME, 'Constants-placeholder-first-name'],
      [REGISTRATION_FIELDS.LAST_NAME, 'Constants-placeholder-last-name'],
      [REGISTRATION_FIELDS.PREFERRED_FIRST_NAME, 'Constants-placeholder-preferred-firstname'],
      [REGISTRATION_FIELDS.PREFERRED_LAST_NAME, 'Constants-placeholder-preferred-lastname'],
    ]),
  ],
]);

export const RegistrationFieldDefaultPlaceholderKeyMap = new Map<REGISTRATION_FIELDS, string>([
  [REGISTRATION_FIELDS.FIRST_NAME, 'Constants-placeholder-first-name'],
  [REGISTRATION_FIELDS.LAST_NAME, 'Constants-placeholder-last-name'],
  [REGISTRATION_FIELDS.PREFERRED_LANGUAGE, 'Constants-placeholder-preferred-language'],
  [REGISTRATION_FIELDS.PREFERRED_TIMEZONE, 'Constants-placeholder-preferred-timezone'],
]);

export const requiredRegistrationFieldsEvent: { [key: string]: string } = {
  firstName: RumEventType.UI_VALIDATION_ENTER_NAME,
  lastName: RumEventType.UI_VALIDATION_ENTER_NAME,
  middleName: RumEventType.UI_VALIDATION_ENTER_NAME,
  englishFirstName: RumEventType.UI_VALIDATION_ENTER_NAME,
  englishLastName: RumEventType.UI_VALIDATION_ENTER_NAME,
  emailId: RumEventType.UI_VALIDATION_ENTER_EMAIL_OR_PHONE,
  reEnterEmailId: RumEventType.UI_VALIDATION_ENTER_EMAIL_OR_PHONE,
  phoneNumber: RumEventType.UI_VALIDATION_ENTER_EMAIL_OR_PHONE,
  reEnterPhoneNumber: RumEventType.UI_VALIDATION_ENTER_EMAIL_OR_PHONE,
  language: RumEventType.NO_LANGUAGE_SELECTED,
  timezone: RumEventType.NO_TIMEZONE_SELECTED,
  pin: RumEventType.UI_VALIDATION_ENTER_PIN,
  reEnterPin: RumEventType.UI_VALIDATION_ENTER_PIN,
};

export const requiredRegistrationFieldsError: { [key: string]: string } = {
  firstName: RegistrationError.EMPTY_FIRST_NAME,
  middleName: RegistrationError.EMPTY_MIDDLE_NAME,
  lastName: RegistrationError.EMPTY_LAST_NAME,
  englishFirstName: RegistrationError.EMPTY_ENGLISH_FIRST_NAME,
  englishLastName: RegistrationError.EMPTY_ENGLISH_LAST_NAME,
  emailId: RegistrationError.EMPTY_EMAIL,
  reEnterEmailId: RegistrationError.EMPTY_REENTER_EMAIL,
  phoneNumber: RegistrationError.EMPTY_PHONE_NUMBER,
  reEnterPhoneNumber: RegistrationError.EMPTY_REENTER_PHONE_NUMBER,
  language: RegistrationError.EMPTY_LANGUAGE,
  timezone: RegistrationError.EMPTY_TIMEZONE,
  pin: RegistrationError.EMPTY_PIN,
  reEnterPin: RegistrationError.EMPTY_REENTER_PIN,
};

export const regexRegistrationFieldsError: { [key: string]: string } = {
  firstName: RegistrationRegexError.INVALID_FIRST_NAME,
  middleName: RegistrationRegexError.INVALID_MIDDLE_NAME,
  lastName: RegistrationRegexError.INVALID_LAST_NAME,
  englishFirstName: RegistrationRegexError.INVALID_ENGLISH_FIRST_NAME,
  englishLastName: RegistrationRegexError.INVALID_ENGLISH_LAST_NAME,
  emailId: RegistrationRegexError.INVALID_EMAIL,
  reEnterEmailId: RegistrationRegexError.INVALID_REENTER_EMAIL,
  phoneNumber: RegistrationRegexError.INVALID_PHONE_NUMBER,
  reEnterPhoneNumber: RegistrationRegexError.INVALID_REENTER_PHONE_NUMBER,
  language: RegistrationRegexError.INVALID_LANGUAGE,
  timezone: RegistrationRegexError.INVALID_TIMEZONE,
  pin: RegistrationRegexError.INVALID_PIN,
  reEnterPin: RegistrationRegexError.INVALID_REENTER_PIN,
};

export const reEnterStringError: { [key: string]: string } = {
  reEnterEmailId: StringMatchError.EMAIL_NOT_EQUAL,
  reEnterPhoneNumber: StringMatchError.PHONE_NUMBER_NOT_EQUAL,
  reEnterPin: StringMatchError.PIN_NOT_EQUAL,
};
