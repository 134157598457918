import { WebLabConfigs, WebLabMetricRequest } from "src/utils/webLab/types";
import {
    WEB_LAB_ACTION_TYPE,
    WebLabClearConfig, WebLabSendMetricFailed,
    WebLabSendMetrics, WebLabSendMetricSuccess,
    WebLabSetConfig
} from "src/actions/WebLab/webLabActionTypes";

export const actionSetWebLabConfig = (payload: WebLabConfigs): WebLabSetConfig => {
    return {
        type: WEB_LAB_ACTION_TYPE.SET,
        payload
    }
}

export const actionClearWebLabConfig = (): WebLabClearConfig => {
    return {
        type: WEB_LAB_ACTION_TYPE.CLEAR
    }
}

export const actionSendWebLabMetrics = (payload: string): WebLabSendMetrics => {
    return {
        type: WEB_LAB_ACTION_TYPE.SEND_METRICS,
        payload
    };
}

export const actionSendWebLabMetricSuccess = (): WebLabSendMetricSuccess => {
    return { type: WEB_LAB_ACTION_TYPE.SEND_METRICS_SUCCESS };
}

export const actionSendWebLabMetricFailed = (): WebLabSendMetricFailed => {
    return { type: WEB_LAB_ACTION_TYPE.SEND_METRICS_FAILED };
}
