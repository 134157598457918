import React, { useEffect } from "react";
import { connect } from "react-redux";
import { onSignOut } from "src/utils/authService";
import { useHistory } from "react-router";
import {
  PAGE_ROUTE_LOGIN
} from "../pageRoutes";
import queryString from "query-string";
import { boundGetCSRF } from "src/actions/boundActions";

export const LogoutPage = (props: any) => {
  const history: any = useHistory();
  const { router} = props;
  const { location } = router;
  const { search } = location;

  useEffect(() => {
    boundGetCSRF(()=>{
      const urlParams = queryString.parse(search);
      const redirectUrl = urlParams.redirectUrl? decodeURIComponent(urlParams.redirectUrl as string) : undefined;
      onSignOut(redirectUrl, ()=>{}, goToLoginPage);
    });
  }, []);
  
  const goToLoginPage = () => {
    history.push(`/${PAGE_ROUTE_LOGIN}`);
  };
  return <div></div>;
};

const mapStateToProps = (state: any) => {
  return state;
};

export default connect(mapStateToProps)(LogoutPage);
