import { useTranslation } from 'react-i18next';
import { mapI18nStringKey } from '../utils/translator';

const useHVHTranslation = () => {
  const { t } = useTranslation();

  const translate = (key: string, defaultString: string, formatArgs?: Object) =>
    t(mapI18nStringKey(key), defaultString, formatArgs);

  return { t: translate };
};

export default useHVHTranslation;
