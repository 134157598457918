import React from 'react';
import useHVHTranslation from 'src/Hooks/useHVHTranslation';

const DirectAccessError = () => {
  const { t } = useHVHTranslation();

  return (
    <div className="DirectAccessErrorContainer">
      <h2 className="DirectAccessErrorTitle">{t('DirectAccessError-title', 'Oops!')} 🛑</h2>
      <p className="DirectAccessErrorMessage">
        {t(
          'DirectAccessError-body',
          `It seems you've accessed this page from wrong link. For the best experience and to ensure proper functionality,
                please close this page and revisit the link or process that that brought you here previously.`,
        )}
      </p>
      <p className="DirectAccessErrorForbidden">{t('DirectAccessError-forbidden', 'Forbidden (403)')}</p>
    </div>
  );
};

export default DirectAccessError;
