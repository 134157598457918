import { CommunicationPreference } from "../utils/commonTypes";
import {
  GET_COMMUNICATION_PREFERENCE_TYPE,
  UPDATE_COMMUNICATION_PREFERENCE_TYPE
} from "src/actions/CommunicationPreferenceActions/communicationPreferenceActionTypes";

export const initCommunicationPreference: CommunicationPreference = {
  candidateId: "",
  isConsent: false,
  isEmailEnabled: false,
  isSMSEnabled: false,
};

export interface CommunicationPreferenceState {
  loading: boolean
  results: CommunicationPreference
}

export const initCommunicationPreferenceState: CommunicationPreferenceState  = {
  results: initCommunicationPreference,
  loading: false,
}

export default function communicationPreferenceReducer(
  state: CommunicationPreferenceState = initCommunicationPreferenceState,
  action: any
) {
  switch (action.type) {
    case GET_COMMUNICATION_PREFERENCE_TYPE.GET:
      return {
        ...state,
        loading: true,
      };
    case GET_COMMUNICATION_PREFERENCE_TYPE.SUCCESS:
      return {
        ...state,
        results: action.payload,
        loading: false,
      };
    case GET_COMMUNICATION_PREFERENCE_TYPE.FAILED:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_COMMUNICATION_PREFERENCE_TYPE.UPDATE:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_COMMUNICATION_PREFERENCE_TYPE.SUCCESS:
      return {
        ...state,
        results: action.payload,
        loading: false,
      };
    case UPDATE_COMMUNICATION_PREFERENCE_TYPE.FAILED:
      return {
        ...state,
        loading: false,
      };
    case GET_COMMUNICATION_PREFERENCE_TYPE.RESET:
      return {
        ...state,
        results: initCommunicationPreference,
      }
    default:
      return state;
  }
}
