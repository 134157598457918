import {
    AdobeEvent,
    AdobeMetricType,
    MetricProps,
    OverrideProps,
    PageName,
    PageRenderType, UNSPECIFIED,
    UserLogInStatus
} from "src/customerTracking/adobeAnalytics/types";
import {
    getAdobeSessionId,
    getFromSessionStorage,
    getUserId,
    parsePathNameFromHash
} from "src/utils/helper";
import isBoolean from "lodash/isBoolean";
import store from "src/store/store";
import { getCurrentLocale } from "src/utils/localization/localeHelper";
import {getCountryCode} from "src/utils/apis/apiHelper";

const pushLayer = (metric: AdobeMetricType) => {
    if (process.env.NODE_ENV === "development") {
        console.log(metric);
    }

    window.dataLayerArray = window.dataLayerArray || [];
    window.dataLayerArray.push(metric);
};

export const addMetric = (
    event: string,
    props?: any
): void => {
    const metric: AdobeMetricType = {
        event,
        ...props,
    };

    pushLayer(metric);
};

export const addAdobeMetric = (event: AdobeEvent, pageName: PageName, props?: MetricProps, overrideProps?: OverrideProps) => {
    const auth = store.getState().auth;
    const pagePath = parsePathNameFromHash();
    const ittk = getFromSessionStorage("ittk");
    const userId = getUserId();
    const isTokenValid = isBoolean(auth.results.checkAuth.isValid)? auth.results.checkAuth.isValid : false;
    const bbCandidateId = auth.results.checkAuth.candidateId;
    const cmpid = getFromSessionStorage("cmpid");
    const adobeSessionId = getAdobeSessionId();
    const adobeMetric: AdobeMetricType = {
        event,
        page: {
            name: pageName,
            path: pagePath,
            ittk: ittk || UNSPECIFIED,
            renderType: PageRenderType.STATIC,
            application: 'AuthSSO',
            locale: getCurrentLocale() || UNSPECIFIED,
            ...overrideProps?.page
        },
        environment: '{{Stage}}',
        countryCode: getCountryCode(),
        candidate: {
            adobeSessionId: adobeSessionId,
            bbCandidateId: !!bbCandidateId ? bbCandidateId : UNSPECIFIED,
            status: !!userId || isTokenValid ? UserLogInStatus.AUTHENTICATED : UserLogInStatus.UNAUTHENTICATED,
            ...overrideProps?.candidate
        },
        campaign: {
            ...(!!cmpid && { cmpid })
        },
        timestamp: new Date().toISOString().slice(0, 19).replace('T', ' '),
        ...props
    };

    pushLayer(adobeMetric);
};
