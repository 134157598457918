import React, { useState, ChangeEvent } from "react";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import {
  Input,
  InputWrapper,
  Select,
} from "@amzn/stencil-react-components/form";
import { Label } from "@amzn/stencil-react-components/text";
import { InputType } from "src/utils/commonTypes";
import { translate as t } from "../../utils/translator";

interface PhoneNumberProps {
  hasError?: boolean;
  errorText?: { [key: string]: string } | string;
  infoText?: string;
  tooltipText?: string;
  value: string;
  placeholder?: string;
  optionalText?: string;
  labelText?: any;
  dataKey?: string;
  onChange?: Function;
  id?: string;
  name?: string;
  errorMessage?: string;
  showErrorMessage?: boolean;
  required?: boolean;
  countryCode?: string;
  disablePaste?: boolean;
  type?: string;
  options: any;
  disabled?: boolean;
}

const PhoneNumberInputComponent = ({
  hasError = false,
  errorText = "",
  infoText = undefined,
  tooltipText = undefined,
  labelText = "",
  id = "phone-number",
  value,
  onChange,
  name = "",
  placeholder,
  type = "text",
  required,
  countryCode,
  disablePaste,
  options=[],
  disabled,
}:PhoneNumberProps) => {

  const onChangeCountryCode = (value: any) => {
    if (onChange) {
      onChange([{
        value,
        key: "phoneCountryCode",
        selectedCountryCode: value,
      }]);
    }
  };

  const onValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange([{
        value: event.target.value.replace(/ /g, ''),
        key: id
      }]);
    }
  };

  const onPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    return false;
  };

  return (
    <Col
      gridGap={"S300"}
      className="colContainer"
    >
      <InputWrapper
        labelText={labelText}
        id={id}
        tooltipText={tooltipText}
        error={hasError}
        footer={hasError ? typeof errorText === 'string' ? errorText : t(errorText.key, errorText.value)  : infoText}
        dataTestId={`input-wrapper-${id}`}
        renderLabel={() => (
          <Row alignItems="center" gridGap={"S300"}>
            <Label color="accent2" id={`label-${id}`}>
                {labelText}
                {required && <span color="negative" aria-hidden="true"> *</span>}
              </Label>
            <Label
                id={`${id}-country-code`}
                display="none"
            >{`${labelText} country code`}</Label>
          </Row>
        )}
      >
        {inputProps => (
          <Row gridGap={"S300"} role="group" aria-labelledby={`label-${id}`}>
            <Select
              options={options}
              width={128}
              id={`country-code-select-${id}`}
              defaultValue={countryCode}
              onChange={onChangeCountryCode}
              dataTestId={`phone-number-select-${id}`}
              value={countryCode}
              aria-describedby={`${id}-country-code`}
              labelId={`${id}-country-code`}
            />
            <Input
              {...inputProps}
              
              aria-label={labelText}
              aria-invalid={hasError}
              autoComplete="tel"
              aria-describedby={`${inputProps["aria-describedby"]}`}
              width="95%"
              name={name}
              id={`phone-number-text-${id}`}
              defaultValue={value}
              onChange={onValueChange}
              onBlur={onValueChange}
              dataTestId={`input-test-id-${id}`}
              type={type as InputType }
              placeholder={placeholder}
              onPaste={disablePaste ? onPaste : undefined}
              disabled={disabled}
            />
          </Row>
        )}
      </InputWrapper>
    </Col>
  )
};

export default PhoneNumberInputComponent
