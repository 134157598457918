import React from "react";
import { Col } from "@amzn/stencil-react-components/layout";
import {
  MessageBanner,
  MessageBannerType,
} from "@amzn/stencil-react-components/message-banner";
import { connect } from "react-redux";
import { AlertMessage } from "src/utils/commonTypes";
import { AuthErrorMessages } from "src/utils/errorMessages";
import { translate as t } from "src/utils/translator";

export const ErrorMessage = (props: any) => {
  const {alert, alerts, displayAlerts} = props.alert;

  const setErrorMessage = (alert: AlertMessage) => {
    let message = t(AuthErrorMessages.DEFAULT.key, AuthErrorMessages.DEFAULT.value);

    if (alert.errorMessageStringId && AuthErrorMessages[alert.errorMessageStringId]) {
      return message = t(AuthErrorMessages[alert.errorMessageStringId].key, AuthErrorMessages[alert.errorMessageStringId].value);
    }
    if (typeof alert.errorMessage === 'object' && alert.errorMessage['key'] && alert.errorMessage['value']) {
      return message = t(alert.errorMessage['key'], alert.errorMessage['value']);
    }
    if (typeof alert.errorMessage === 'string') {
      return message = alert.errorMessage;
    }

    return message;
  };

  const errorMessages = alerts.map((alert: AlertMessage, index: number)=> {
    const { errorTitle } = alert;

    const titleText = typeof errorTitle === "object" ? t(errorTitle.key, errorTitle.value) : errorTitle;

    return <Col gridGap={"S300"} key={`${index}`}>
      <MessageBanner
          isDismissible
          type={alert.alertType || MessageBannerType.Error}
          titleText={titleText}
          aria-live="assertive"
          dismissButtonAltText="Close banner"
      >
        {setErrorMessage(alert)}
      </MessageBanner>
    </Col>
  });

  return alert && alerts.length > 0 && displayAlerts
    ?  <Col gridGap={1}>{errorMessages}</Col>
    : null;
};

const mapStateToProps = (state: any) => {
  return state;
};

export default connect(mapStateToProps)(ErrorMessage);
