import { Action } from "redux";
import { GetCandidateDetailRequest, UpdateCandidateDetailRequest } from "src/utils/apis/appSyncAPI/types";
import { AlertMessage, CandidateDetail } from "src/utils/commonTypes";

export enum GET_CANDIDATE_DETAIL_TYPE {
    GET = 'GET_CANDIDATE_DETAIL',
    SUCCESS = 'GET_CANDIDATE_DETAIL_SUCCESS',
    FAILED = 'GET_CANDIDATE_DETAIL_FAILED',
    RESET = 'RESET_CANDIDATE_DETAIL',
}

export enum UPDATE_CANDIDATE_DETAIL_TYPE {
    UPDATE = 'UPDATE_CANDIDATE_DETAIL',
    SUCCESS = 'UPDATE_CANDIDATE_DETAIL_SUCCESS',
    FAILED = 'UPDATE_CANDIDATE_DETAIL_FAILED'
}

export interface GetCandidateDetailAction extends Action {
    type: GET_CANDIDATE_DETAIL_TYPE.GET;
    payload: GetCandidateDetailRequest;
    onSuccess?: Function;
    onError?: Function;
}

export interface GetCandidateDetailSuccessAction extends Action {
    type: GET_CANDIDATE_DETAIL_TYPE.SUCCESS;
    payload: CandidateDetail;
}

export interface GetCandidateDetailFailedAction extends Action {
    type: GET_CANDIDATE_DETAIL_TYPE.FAILED;
    payload: AlertMessage;
}

export interface ResetCandidateDetailAction extends Action {
    type: GET_CANDIDATE_DETAIL_TYPE.RESET;
    callback?: Function;
}

export interface UpdateCandidateDetailAction extends Action {
    type: UPDATE_CANDIDATE_DETAIL_TYPE.UPDATE;
    payload: UpdateCandidateDetailRequest;
    onSuccess?: Function;
    onError?: Function;
}

export interface UpdateCandidateDetailSuccessAction extends Action {
    type: UPDATE_CANDIDATE_DETAIL_TYPE.SUCCESS;
    payload: CandidateDetail;
    callback?: Function;
}

export interface UpdateCandidateDetailFailedAction extends Action {
    type: UPDATE_CANDIDATE_DETAIL_TYPE.FAILED;
    payload: AlertMessage;
}


