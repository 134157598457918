import React from "react";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import { connect } from "react-redux";
import {translate as t} from "src/utils/translator";

/**
 * Loader Placeholder.
 * Components to on loading.
 * @param loading boolean loading.
 */
export const LoadingComponent = (props:any) => {
  return props.ui.isLoading ? (
    <div className="loadingBackground">
      <div className="loadingWrapper">
        <Spinner dataTestId="spinner" size={SpinnerSize.Medium} loadingText={t("Common-lbl-loading", "Loading") + '...'} />
      </div>
    </div>
  ) : (
    <span data-testid="no-spinner" />
  );
};

const mapStateToProps = (state: any) => {
  return state;
};

export default connect(mapStateToProps)(LoadingComponent);
