import React, { useEffect, useState } from 'react';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { H1, Text } from '@amzn/stencil-react-components/text';
import PhoneNumberInputComponent from '../Registration/PhoneNumberInputComponent';
import { IconArrowLeft } from '@amzn/stencil-react-components/icons';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { SIGN_IN_PAGES } from '../../utils/constants';
import isEmpty from 'lodash/isEmpty';
import { Candidate } from 'src/utils/commonTypes';
import { checkUsernameIsPhoneNumber } from '../../utils/helper';
import { onCheckPhoneNumber } from '../../utils/authService';
import { AuthErrorMessages } from 'src/utils/errorMessages';
import { PhoneCodesByCountry } from 'src/utils/registrationFrom/constants';
import useHVHTranslation from 'src/Hooks/useHVHTranslation';

interface CheckPhoneNumberProps {
  candidate: Candidate;
  goToPage: Function;
  pin: string;
  onNextTest?: Function;
  router?: any;
}

export const CheckPhoneNumber = ({ candidate, goToPage, pin, onNextTest }: CheckPhoneNumberProps) => {
  const { t } = useHVHTranslation();
  const [login, setLogin] = useState({
    phoneNumber: candidate.phoneNumber,
    phoneCountryCode: candidate.phoneCountryCode,
  });
  const [error, setError] = useState<any>({});

  useEffect(() => {
    if (!candidate) {
      goToPage(SIGN_IN_PAGES.LOGIN);
    }
    // document.title = "Amazon Jobs - Phone verification";
  }, []);

  const onLoginChange = (targets: []) => {
    const currentLogin = login;
    const newLogin: any = { ...currentLogin };
    targets.forEach((target: { key: string; value: any }) => {
      newLogin[target.key] = target.value;
    });
    setLogin(newLogin);
    setError({
      hasError: false,
      message: '',
    });
  };

  const onNext = () => {
    goToPage(SIGN_IN_PAGES.VERIFY_PHONE);
  };

  const onLoginSubmit = () => {
    onNextTest && onNextTest();
    if (isEmpty(login.phoneNumber)) {
      setError({
        hasError: true,
        message: t(
          AuthErrorMessages['empty-login-phone-error-message'].key,
          AuthErrorMessages['empty-login-phone-error-message'].value,
        ),
      });
    } else if (!checkUsernameIsPhoneNumber(login.phoneCountryCode + login.phoneNumber)) {
      setError({
        hasError: true,
        message: t('ErrorMessages-validate-phone-number-error-message', 'Please enter a valid mobile number'),
      });
    } else {
      onCheckPhoneNumber(login.phoneNumber, login.phoneCountryCode, pin, candidate.candidateLogin, onNext);
    }
  };

  const goBackToConfirmOtpPage = () => {
    goToPage(SIGN_IN_PAGES.CONFIRM_OTP);
  };

  return (
    <Col gridGap="S300" className="hvhLogin" padding={{ left: 'S200', right: 'S200' }}>
      <Col gridGap="S300" className="colContainerTop">
        <Row gridGap="S300" justifyContent="space-between">
          <Button dataTestId="button-back-otp" onClick={goBackToConfirmOtpPage} variant={ButtonVariant.Tertiary}>
            <Row gridGap="S300" alignItems="center">
              <IconArrowLeft aria-hidden />
              {t('CheckPointNumber-lbl-return-to-login-button', 'back')}
            </Row>
          </Button>
        </Row>
        <img
          src="https://m.media-amazon.com/images/G/01/HVH-CandidateApplication/jobs/illustration-phone.svg"
          className="image-icon"
          alt="phone"
          aria-hidden="true"
          role="presentation"
          tabIndex={-1}
        />
        <H1 fontSize="T400" fontWeight="regular" color="accent1">
          {t('CheckPhoneNumber-title-check-phone-number-page', 'We need to verify your mobile number.')}
        </H1>
        <Text fontSize="T100">
          {t(
            'CheckPhoneNumber-lbl-check-phone-number-description',
            'Please enter your complete phone number to verify and login',
          )}
        </Text>
        <PhoneNumberInputComponent
          id="phoneNumber"
          labelText={t('CheckPhoneNumber-lbl-mobile-number', 'Mobile number')}
          name="phoneNumber"
          value={login.phoneNumber}
          countryCode={login.phoneCountryCode}
          onChange={onLoginChange}
          hasError={error.hasError}
          errorText={error.message}
          options={PhoneCodesByCountry}
        />
      </Col>
      <Button dataTestId="button-continue" onClick={onLoginSubmit} variant={ButtonVariant.Primary}>
        {t('CheckPhoneNumber-btn-submit-login', 'Continue')}
      </Button>
    </Col>
  );
};

export default CheckPhoneNumber;
