import { globalLocaleConfigMap, HVH_LOCALE } from 'src/utils/localization/constants';
import { getCountryCode, getStage } from 'src/utils/apis/apiHelper';
import { IHVHLocaleConfiguration, IHVHLocaleSetting, LocaleOption, LocaleOptions } from 'src/utils/localization/types';
import { Stage } from 'src/utils/commonTypes';
import pickBy from 'lodash/pickBy';
import Cookies from 'js-cookie';
import { setToSessionStorage } from 'src/utils/helper';
import { parseSearchParamFromHash } from 'src/utils/urlUtils';
import i18n from 'src/i18n';

export const getCurrentLocale = (): string => {
  const queryParams = parseSearchParamFromHash(window.location.hash);
  const { locale } = queryParams;

  const validLocales = getValidLocales();
  if (locale && validLocales.includes(locale)) {
    // update locale value in cookies and sessionStorage
    syncLocaleValue(locale);
    return locale;
  }

  const cookieLocale = Cookies.get(HVH_LOCALE);
  if (cookieLocale && validLocales.includes(cookieLocale)) {
    return cookieLocale;
  }

  // Get the user's preferred languages and take the first locale as their default
  const preferredLanguage = getPreferredLanguage();
  if (preferredLanguage) {
    // update locale value in cookies and sessionStorage
    syncLocaleValue(preferredLanguage);

    return preferredLanguage;
  }

  const currentLocale = getDefaultLocale();
  // update locale value in cookies and sessionStorage
  syncLocaleValue(currentLocale);
  return currentLocale;
};

export const syncLocaleValue = (locale: string): void => {
  Cookies.set(HVH_LOCALE, locale, { secure: true });
  setToSessionStorage('locale', locale);
  updateI18nLocale(locale);
};

// Get the user's preferred languages and take the first locale as their default
export const getPreferredLanguage = () => {
  const userLanguages = navigator.languages;
  return userLanguages[0];
};

export const getCurrentLocaleDisplayName = (): string => {
  const supportedLocales: { [key: string]: IHVHLocaleSetting } = getSupportedLocales();
  const currentLocale = getCurrentLocale();

  return supportedLocales[currentLocale]['display-name'];
};

export const getLocaleConfig = (countryCodeOverride?: string): IHVHLocaleConfiguration => {
  const countryCode = countryCodeOverride || getCountryCode();
  return globalLocaleConfigMap[countryCode];
};

export const getDefaultLocale = (countryCode?: string): string => {
  const localeSetting = getLocaleConfig(countryCode);
  return localeSetting['default-locale'];
};

export const getSupportedLocales = (): { [key: string]: IHVHLocaleSetting } => {
  const stage = getStage();
  const localeConfig = getLocaleConfig();
  const localeSettings = localeConfig.locales;
  return pickBy(localeSettings, (value) => {
    if (stage !== Stage.PreProd && stage !== Stage.Prod) {
      // For non-prod environments, we want to consider all locales are supported on UI.
      return true;
    }

    // For Preprod and Prod, we only consider GA locales to be supported.
    return value.status === 'GA';
  });
};

export const getLocaleOptions = (): LocaleOptions => {
  const supportedLocales: { [key: string]: IHVHLocaleSetting } = getSupportedLocales();
  const localOptions: LocaleOptions = [];

  Object.keys(supportedLocales).forEach((key) => {
    const localeOption: LocaleOption = {
      locale: key,
      'mobile-display-name': key.substring(0, 2).toUpperCase(),
      'display-name': supportedLocales[key]['display-name'],
    };

    localOptions.push(localeOption);
  });

  return localOptions;
};

export const getValidLocales = (): string[] => {
  const supportedLocales: { [key: string]: IHVHLocaleSetting } = getSupportedLocales();
  return Object.keys(supportedLocales);
};

export const updateI18nLocale = (locale: string) => {
  (async () => {
    await i18n.changeLanguage(locale).catch((err) => {
      /* eslint-disable-next-line no-console */
      console.error('failed to change language to ' + locale, err);
    });
  })();
};
