import { from, Observable, of } from 'rxjs';
import { flatMap, map, catchError } from 'rxjs/internal/operators';
import { ofType } from 'redux-observable';
import { verifyPhoneApi } from '../utils/apis/serviceApi';
import { parseErrorMessage } from '../utils/helper';
import { actionVerifyPhoneSuccess } from '../actions/actions';
import {
  VERIFY_PHONE,
  VERIFY_PHONE_FAILED,
  VERIFY_PHONE_SUCCESS,
  VERIFY_PHONE_CALLBACK_SUCCESS,
} from '../actions/actionTypes';

export const verifyPhoneEpic = (action$: Observable<any>) => {
  return action$.pipe(
    ofType(VERIFY_PHONE),
    flatMap((action: any) =>
      from<Promise<any>>(verifyPhoneApi(action.payload)).pipe(
        flatMap(async (response) => {
          return response.data;
        }),
        map((x) => actionVerifyPhoneSuccess(x, action.callback)),
        catchError((error) => {
          const { response } = error;
          if (action.onError) {
            action.onError(response);
          }
          return of({
            type: VERIFY_PHONE_FAILED,
            payload: parseErrorMessage(response.data),
          });
        }),
      ),
    ),
  );
};

export const verifyPhoneSuccessEpic = (action$: Observable<any>) => {
  return action$.pipe(
    ofType(VERIFY_PHONE_SUCCESS),
    map((action) => {
      if (action.callback) {
        action.callback(action.payload);
      }
      return { type: VERIFY_PHONE_CALLBACK_SUCCESS, payload: null };
    }),
  );
};
