import {
  ASHUSEndpoint,
  AtoZDomains,
  BBMXUnifiedEndpoint,
  BBSignOutEndPointsByCountryFullName,
  BBUKApplicationEndpoint,
  BBUKEndpoint,
  BBUSEndpoint,
  BBUSUnifiedEndpoint,
  CSAuthServiceEndPointsByCountryFullName,
  CSAuthServiceEndPointsCloudFront,
  CSDomainByCountryFullName,
  CSDomainsUS,
  CSIntegrationEndPoints,
  PhoneNumberReminderCountryList,
  SFCountry,
  StageToLanguageByCountryFullName,
  StageToLocaleByCountryFullName,
  UnifiedDomainEnabled,
} from 'src/utils/constants';
import { CountryFullName, MarketPlace, Stage } from 'src/utils/commonTypes';
import { parseSearchParamFromHash, parseSearchParamFromLocationSearch } from 'src/utils/urlUtils';

interface stageCountryFullNameOverride {
  stageOverride?: Stage;
  countryFullNameOverride?: CountryFullName;
  isUnifiedDomain?: boolean;
}

export const getStage = (stageOverride?: Stage) => {
  const paramsFromHash = parseSearchParamFromHash(window.location.hash);
  const paramsFromSearch = parseSearchParamFromLocationSearch();
  const stageOverrideFromUrl = paramsFromHash.stageOverride
    ? paramsFromHash.stageOverride
    : paramsFromSearch.stageOverride
      ? paramsFromSearch.stageOverride
      : undefined;
  const stage = stageOverrideFromUrl ? stageOverrideFromUrl : stageOverride ? stageOverride : '{{Stage}}';
  return stage as Stage;
};

export const getCSAuthServiceEndpoint = () => {
  const countryFullName = getCountryFullName();
  const stage = getStage();
  return CSAuthServiceEndPointsByCountryFullName[countryFullName] &&
    CSAuthServiceEndPointsByCountryFullName[countryFullName][stage]
    ? CSAuthServiceEndPointsByCountryFullName[countryFullName][stage]
    : CSAuthServiceEndPointsCloudFront[stage];
};

export const getCSDomain = () => {
  const countryFullName = getCountryFullName();
  const stage = getStage();
  const endpoint =
    CSDomainByCountryFullName[countryFullName] && CSDomainByCountryFullName[countryFullName][stage]
      ? CSDomainByCountryFullName[countryFullName][stage]
      : CSDomainsUS[stage];
  return endpoint;
};

export const getAtoZDomain = () => {
  const stage = getStage();
  return AtoZDomains[stage];
};

export const getCountryCode = (): string => {
  // TODO: Need to revise this logic.. it should never default to US
  return window.sessionStorage.getItem('countryCode') || 'US';
};

export const getCSIntegrationEndpoint = (stageOverride?: Stage) => {
  const stage = getStage(stageOverride);
  const endpoint = CSIntegrationEndPoints[stage] || '{{CSEndpoint}}';
  return endpoint;
};

export const getBBUKEndpoint = (stageOverride?: Stage) => {
  const stage = getStage(stageOverride);
  const endpoint = BBUKEndpoint[stage];
  return endpoint;
};

export const getBBUKApplicationEndpoint = (stageOverride?: Stage) => {
  const stage = getStage(stageOverride);
  const endpoint = BBUKApplicationEndpoint[stage];
  return endpoint;
};

export const getBBUSEndpoint = (stageOverride?: Stage) => {
  const stage = getStage(stageOverride);
  return BBUSEndpoint[stage];
};

export const getBBMXUnifiedEndpoint = (stageOverride?: Stage): string => {
  const stage = getStage(stageOverride);
  return BBMXUnifiedEndpoint[stage] || BBMXUnifiedEndpoint['beta'];
};

export const getBBUSUnifiedEndpoint = (stageOverride?: Stage): string => {
  const stage = getStage(stageOverride);
  return BBUSUnifiedEndpoint[stage] || BBUSUnifiedEndpoint['beta'];
};

export const isUnifiedDomain = (stageOverride?: Stage): boolean => {
  const stage = getStage(stageOverride);
  return UnifiedDomainEnabled[stage] || false;
};

export const getBBEndpoint = ({ stageOverride, countryFullNameOverride }: stageCountryFullNameOverride) => {
  const countryFullName = countryFullNameOverride ? countryFullNameOverride : getCountryFullName();
  const stage = getStage(stageOverride);
  switch (countryFullName) {
    case CountryFullName.US:
      return isUnifiedDomain(stage) ? getBBUSUnifiedEndpoint(stage) : getBBUSEndpoint(stage);
    case CountryFullName.UK:
      return getBBUKApplicationEndpoint(stageOverride);
    case CountryFullName.MX:
      return getBBMXUnifiedEndpoint(stageOverride);
    case CountryFullName.DEVO:
      return getBBUKApplicationEndpoint(stage);
    default:
      return getBBUKApplicationEndpoint(stage);
  }
};

export const getASHUSEndpoint = (stageOverride?: Stage) => {
  const stage = getStage(stageOverride);
  return ASHUSEndpoint[stage];
};

export const getBBSignOutEndpoint = ({ stageOverride, countryFullNameOverride }: stageCountryFullNameOverride) => {
  const countryFullName = countryFullNameOverride ? countryFullNameOverride : getCountryFullName();
  const stage = getStage(stageOverride);
  const endpoint = BBSignOutEndPointsByCountryFullName[countryFullName][stage];
  return endpoint;
};

export const getSFCountry = (stageOverride?: Stage) => {
  const stage = getStage(stageOverride);
  return SFCountry[stage];
};

// TODO: need refactor this function
export const getLocale = ({ stageOverride, countryFullNameOverride }: stageCountryFullNameOverride): string => {
  const countryFullName = countryFullNameOverride ? countryFullNameOverride : getCountryFullName();
  const stage = getStage(stageOverride);
  return StageToLocaleByCountryFullName?.[countryFullName]?.[stage];
};

export const getLanguage = ({ stageOverride, countryFullNameOverride }: stageCountryFullNameOverride) => {
  const countryFullName = countryFullNameOverride ? countryFullNameOverride : getCountryFullName();
  const stage = getStage(stageOverride);
  return StageToLanguageByCountryFullName?.[countryFullName]?.[stage];
};

export const getBBRegion = (override?: Stage) => {
  const bbRegion = override ? override : '{{BBRegion}}';
  return bbRegion;
};

export const getCountryFullName = (countryCodeOverride?: string): CountryFullName => {
  const countryCode = countryCodeOverride || getCountryCode();
  return countryCode ? (CountryFullName as any)[countryCode] : CountryFullName.DEVO;
};

export const getMarketPlace = (override?: MarketPlace): MarketPlace => {
  return override ? override : ('{{Marketplace}}' as any as MarketPlace);
};

export const getSignOutRedirectUrl = ({ stageOverride, countryFullNameOverride }: stageCountryFullNameOverride) => {
  return getBBSignOutEndpoint({ stageOverride, countryFullNameOverride });
};

export const showPhoneNumberInputReminder = (override?: CountryFullName): boolean => {
  const countryFullName = override ? override : getCountryFullName();
  return PhoneNumberReminderCountryList.includes(countryFullName);
};

export const getOrganizationIdFromSession = (key: string): string | null => {
  return window.sessionStorage.getItem(key);
};
