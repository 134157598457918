import { GetCandidateDetailRequest, UpdateCandidateDetailRequest } from "src/utils/apis/appSyncAPI/types";
import store from "src/store/store";
import {
    actionGetCandidateDetail,
    actionResetCandidateDetail,
    actionUpdateCandidateDetail
} from "src/actions/CandidateDetailActions/candidateDetailActions";

export const boundGetCandidateDetail = (
    payload: GetCandidateDetailRequest,
    onSuccess?: Function,
    onError?: Function,
) => store.dispatch(actionGetCandidateDetail(payload, onSuccess, onError));
