import './polyfills';
import './sizmekTracker';
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {Spinner, SpinnerSize} from '@amzn/stencil-react-components/spinner';
import {Provider} from "react-redux";
import store, {persistor} from "./store/store";
import App from './components/App';
import AppInitWrapper from './components/AppInitWrapper';
import { I18nextProvider } from 'react-i18next';
import i18n from  './i18n';
import './logger';
import './index.scss';
import './images/favicon.ico';
import './resources/';
import {StencilProvider} from "@amzn/stencil-react-components/context";
import {PersistGate} from 'redux-persist/integration/react'
import defaultTheme from '@amzn/stencil-react-theme-default';
import {isRightToLeftTextEnabled, isStencil2021Theme} from "src/utils/featureFlag/featureFlag";
import {Store} from "redux";
import {translate as t} from "src/utils/translator";
import DirectAccessError from "src/components/DirectAccessError";
import {isValidRedirectUrl} from "src/utils/helper";
import { loadWAFEnv } from "src/utils/captcha";
import { AWSWAFCaptchaModal } from './components/AWSWAFCaptchaModal';
import {getCurrentLocale} from "src/utils/localization/localeHelper";

// This is to bypass ts error "Property 'dataLayerArray' does not exist on type 'Window & typeof globalThis'."
// window.dataLayerArray is introduced by Adobe Analytics script.
declare global {
  interface Window {
    dataLayerArray: any; // Adobe Analytics
    acDataLayer: any;    // AppCast tracker
    dataLayer: any;      // Google Pixel
    reduxStoreCS: Store;
    AWS_WAF_ENV: {
      JSAPI_URL: string;
      CAPTCHA_API_KEY: string;
    }
    AwsWafCaptcha: {
      renderCaptcha: Function;
    };
  }
}

window.acDataLayer = window.acDataLayer || [];
loadWAFEnv();

const divTags = document.querySelectorAll('.hvh-widget');
const currentLocale = getCurrentLocale();
let isRTL = false;

if (currentLocale === 'ar-AE' && isRightToLeftTextEnabled()) {
  isRTL = true;
}

divTags.forEach((divTag: Element) => {
  window.reduxStoreCS = store;
  if (!isValidRedirectUrl())
  {
    ReactDOM.render(<DirectAccessError />, divTag);
  } else {
    ReactDOM.render(
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <StencilProvider theme={defaultTheme} isRTL={isRTL}>
            <PersistGate
              loading={<Spinner size={SpinnerSize.Large} loadingText={t("Common-lbl-loading", "Loading") + '...'}/>}
              persistor={persistor}>
              <AppInitWrapper>
                <Suspense fallback={<Spinner size={SpinnerSize.Small}
                                             loadingText={t("Common-lbl-loading", "Loading") + '...'}/>}>
                  <div className={isStencil2021Theme() ? "stencil-2021" : ""}>
                    <App document={divTag}/>
                    <AWSWAFCaptchaModal />
                  </div>
                </Suspense>
              </AppInitWrapper>
            </PersistGate>
          </StencilProvider>
        </I18nextProvider>
      </Provider>,
      divTag
    );
  }
});
