import { LoadingAction, LoadingTypes } from 'src/actions/UIActions/UIActions';

export interface UIState {
  isLoading: boolean;
  alwaysShowSearchArea: boolean;
  showLocaleSelector: boolean;
}

export const initUIState: UIState = {
  isLoading: false,
  alwaysShowSearchArea: false,
  showLocaleSelector: true,
};

export default function uiReducer(state: UIState = initUIState, action: LoadingAction): UIState {
  switch (action.type) {
    case LoadingTypes.CREATE_CANDIDATE:
    case LoadingTypes.VERIFY_EMAIL:
    case LoadingTypes.VERIFY_PHONE:
    case LoadingTypes.VERIFY_SIGN_IN:
    case LoadingTypes.SIGN_IN:
    case LoadingTypes.GET_CANDIDATE:
    case LoadingTypes.CONFIRM_OTP:
    case LoadingTypes.CHECK_STATE:
    case LoadingTypes.UPDATE_PHONE:
    case LoadingTypes.LOADIND_START:
      return {
        ...state,
        isLoading: true,
      };
    case LoadingTypes.CREATE_CANDIDATE_FAILED:
    case LoadingTypes.CREATE_CANDIDATE_SUCCESS:
    case LoadingTypes.VERIFY_EMAIL_SUCCESS:
    case LoadingTypes.VERIFY_EMAIL_FAILED:
    case LoadingTypes.VERIFY_PHONE_SUCCESS:
    case LoadingTypes.VERIFY_PHONE_FAILED:
    case LoadingTypes.VERIFY_SIGN_IN_SUCCESS:
    case LoadingTypes.VERIFY_SIGN_IN_FAILED:
    case LoadingTypes.SIGN_IN_SUCCESS:
    case LoadingTypes.SIGN_IN_FAILED:
    case LoadingTypes.GET_CANDIDATE_SUCCESS:
    case LoadingTypes.GET_CANDIDATE_FAILED:
    case LoadingTypes.CONFIRM_OTP_SUCCESS:
    case LoadingTypes.CONFIRM_OTP_FAILED:
    case LoadingTypes.UPDATE_PHONE_SUCCESS:
    case LoadingTypes.UPDATE_PHONE_FAILED:
    case LoadingTypes.LOADIND_END:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
