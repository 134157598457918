import React, { FC } from 'react';
import { getCountryFullName } from 'src/utils/apis/apiHelper';
import { IconQuestionCircle } from '@amzn/stencil-react-components/icons';
import { Row } from '@amzn/stencil-react-components/layout';
import { Link } from '@amzn/stencil-react-components/link';
import { getLoginHelpPage } from 'src/utils/helper';
import useHVHTranslation from 'src/Hooks/useHVHTranslation';

const Helper: FC = () => {
  const { t } = useHVHTranslation();

  return (
    <div className="helper">
      <Row alignItems="center" gridGap={6}>
        <IconQuestionCircle size={16} />
        <Link
          id={'helper-link'}
          dataTestId="link-needHelp"
          href={`${getLoginHelpPage(getCountryFullName())}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('LoginPage-lbl-get-help', 'Get help')}
        </Link>
      </Row>
    </div>
  );
};

export default Helper;
